import { PeriodModel } from "../PeriodModel"
import { FinancialPeriodResourceV2ApiUpdateFinancialPeriodRequest } from "../../../client/backend-client/generated"
import { incomeStatementToInput } from "../../../utils/IncomeStatementMapper"

export const periodToMutationModel = (
  period: PeriodModel
): FinancialPeriodResourceV2ApiUpdateFinancialPeriodRequest => ({
  financialPeriodId: period.id,
  saveFinancialPeriodRequest: {
    incomeStatement: incomeStatementToInput(period.incomeStatement),
    prelevementTns: period.prelevementTns
  }
})
