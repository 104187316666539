/* tslint:disable */
/* eslint-disable */
/**
 * Appli métier
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: Yoda 1 & 2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { ApiNumericData } from "./api-numeric-data"

/**
 *
 * @export
 * @interface ApiPlanFinancementEntryKey
 */
export interface ApiPlanFinancementEntryKey {
  /**
   *
   * @type {string}
   * @memberof ApiPlanFinancementEntryKey
   */
  entryId: string
  /**
   *
   * @type {string}
   * @memberof ApiPlanFinancementEntryKey
   */
  label: string
  /**
   *
   * @type {string}
   * @memberof ApiPlanFinancementEntryKey
   */
  type: ApiPlanFinancementEntryKeyTypeEnum
  /**
   *
   * @type {ApiNumericData}
   * @memberof ApiPlanFinancementEntryKey
   */
  demarrage: ApiNumericData
}

/**
 * @export
 * @enum {string}
 */
export enum ApiPlanFinancementEntryKeyTypeEnum {
  BESOINS = "BESOINS",
  APPORTS = "APPORTS",
  PRIMES = "PRIMES",
  SUBVENTIONS_INVESTISSEMENTS = "SUBVENTIONS_INVESTISSEMENTS",
  PRETS_AT = "PRETS_AT",
  PRETS_HORS_AT_GARANTIS = "PRETS_HORS_AT_GARANTIS",
  PRETS_HORS_AT_NON_GARANTIS = "PRETS_HORS_AT_NON_GARANTIS",
  CREDIT_RELAIS_TVA = "CREDIT_RELAIS_TVA"
}
