import React, { FC, useContext, useState } from "react"
import { Card, Col, Row, Select, Space, Spin, Typography } from "antd"
import { Bank, BankPartnerV2, StructureDetailed } from "../../../client/backend-client/generated"
import "./PresentationStyle.scss"
import "leaflet/dist/leaflet.css"
import { AppMetierTitle } from "../../../components/AppMetierTitle/AppMetierTitle"
import { LeafletMap } from "./LeafletMap"
import { useGetBankPartners, useGetPartnerAgencies } from "../../../queries/ProjectQueries"
import { useUpdateNote } from "../../../queries/NoteQueries"
import { NoteContext } from "../../NotePage"
import { RegisStructureAdress } from "../../../client/backend-client/generated/model/regis-structure-adress"
import { ProjectContext } from "../../ProjectWrapper"

const { Text, Paragraph } = Typography

const titleColSpan = 10
const valueColSpan = 14

const NOT_CONCERNED = "Non concerné"
const NOT_DETERMINED = "Non déterminé"

export const PresentationBody: FC<{
  structure: StructureDetailed
}> = ({ structure }) => (
  <Card className="presentation">
    <Row align="top" gutter={[32, 0]} justify="center">
      <Col span={12}>
        <PresentationStructure structure={structure} />
      </Col>
      <Col span={12}>{<PresentationPartner />}</Col>
      <Col span={24} style={{ marginTop: "2em" }} className="presentation-loc">
        {structure.adresse && <PresentationLoc address={structure.adresse} />}
      </Col>
    </Row>
  </Card>
)

const PresentationStructure: FC<{
  structure: StructureDetailed
}> = ({ structure }) => {
  const { baseNotePath } = useContext(NoteContext)

  return (
    <>
      <Space style={{ alignItems: "baseline" }}>
        <AppMetierTitle level={4}>Structure</AppMetierTitle>
        {/*<NavLink to={`${baseNotePath}/infos/structure`}>
          <Tooltip title="Éditer la structure">
            <Button size="small" shape="circle" icon={<EditOutlined />} />
          </Tooltip>
        </NavLink>*/}
      </Space>
      <Row align="middle" gutter={[16, 32]} className="grey-spans">
        <Col span={titleColSpan}>
          <Text>Dénomination sociale</Text>
        </Col>
        <Col span={valueColSpan}>
          <Text strong>{structure.nom ?? "-"}</Text>
        </Col>
        <Col span={titleColSpan}>
          <Text>Statut juridique</Text>
        </Col>
        <Col span={valueColSpan}>
          <Text strong>{structure.statutJuridique?.libelle ?? "-"}</Text>
        </Col>
        <Col span={titleColSpan}>
          <Text>Secteur d'activité</Text>
        </Col>
        <Col span={valueColSpan}>
          <Text strong>{structure.secteurActivite?.libelle ?? "-"}</Text>
        </Col>
        <Col span={titleColSpan}>
          <Text>Statut coopératif</Text>
        </Col>
        <Col span={valueColSpan}>
          <Text strong>{structure.statutCooperatif?.libelle ?? "-"}</Text>
        </Col>
        <Col span={titleColSpan}>
          <Text>Agrément ESUS</Text>
        </Col>
        <Col span={valueColSpan}>
          <Text strong>{structure.agrementEsus?.libelle}</Text>
        </Col>
        <Col span={titleColSpan}>
          <Text>ESS</Text>
        </Col>
        <Col span={valueColSpan}>
          <Text strong>{structure.ess ? "Oui" : "Non"}</Text>
        </Col>
      </Row>
    </>
  )
}

const PresentationPartner: FC = () => {
  const { note } = useContext(NoteContext)
  const { project } = useContext(ProjectContext)
  const [bank, setBank] = useState<Bank | undefined>(note.bankPartner?.bank)

  const { data: bankPartners } = useGetBankPartners()
  const noteId = note.noteId
  const updateNote = useUpdateNote(noteId)

  const updateBankPartnerData = (data: BankPartnerV2) => {
    updateNote.mutate({
      noteId,
      bankPartnerV2: { ...data }
    })
  }

  const onChangeBank = (e: string) => {
    const bank = bankPartners?.find(b => b.id === e)
    if (bank) {
      setBank(bank)
      updateBankPartnerData({ bank: bank, agency: undefined })
    }
  }

  return (
    <>
      <AppMetierTitle level={4}>Partenaires</AppMetierTitle>
      <Row align="middle" gutter={[16, 32]} className="grey-spans">
        <Col span={titleColSpan}>
          <Text>Type de Prescripteur</Text>
        </Col>
        <Col span={valueColSpan}>
          <Text strong>{project.prescriber?.typePrescriber?.libelle ?? "-"}</Text>
        </Col>
        <Col span={titleColSpan}>
          <Text>Prescripteur</Text>
        </Col>
        <Col span={valueColSpan}>
          <Text strong>{project.prescriber?.codeLibelle.libelle ?? "-"}</Text>
        </Col>
        <Col span={titleColSpan}>
          <Text>Type d'accompagnateur</Text>
        </Col>
        <Col span={valueColSpan}>
          <Text strong>{project.companion?.typeCompanion?.libelle ?? "-"}</Text>
        </Col>
        <Col span={titleColSpan}>
          <Text>Accompagnateur</Text>
        </Col>
        <Col span={valueColSpan}>
          <Text strong>{project.companion?.codeLibelle.libelle ?? "-"}</Text>
        </Col>
        <Col span={titleColSpan}>
          <Text>Partenaire bancaire prévisionnel</Text>
        </Col>
        <Col span={valueColSpan}>
          <Select
            value={bank?.id}
            placeholder="Selectionnez une banque"
            style={{ width: 300 }}
            filterOption
            showSearch
            optionFilterProp="label"
            onSelect={onChangeBank}
            allowClear
            onClear={() => {
              setBank(undefined)
              updateBankPartnerData({ bank: undefined, agency: undefined })
            }}
            options={bankPartners
              ?.map(({ id, label }) => ({
                value: id,
                label
              }))
              .sort((a, b) => sortByLabels(a.label, b.label))}
          />
        </Col>
        <Col span={titleColSpan}>
          <Text>Agence bancaire prévisionnelle</Text>
        </Col>
        <Col span={valueColSpan}>
          {bank && bank.label !== NOT_DETERMINED && bank.label !== NOT_CONCERNED && (
            <AgencySelect bank={bank} />
          )}
        </Col>
      </Row>
    </>
  )
}

const sortByLabels = (a: string, b: string) => {
  if (a.toLowerCase().trim() === NOT_DETERMINED.toLowerCase()) {
    return -1
  }

  if (
    a.toLowerCase().trim() === NOT_DETERMINED.toLowerCase() &&
    b.toLowerCase().trim() === NOT_CONCERNED.toLowerCase()
  ) {
    return -1
  }

  if (a.toLowerCase().trim() === NOT_CONCERNED.toLowerCase()) {
    return -1
  }

  if (a.toLowerCase().trim() < b.toLowerCase().trim()) {
    return -1
  }

  if (a.toLowerCase().trim() > b.toLowerCase().trim()) {
    return 1
  }

  return 0
}

const PresentationLoc: FC<{
  address: RegisStructureAdress
}> = ({ address }) => (
  <>
    <AppMetierTitle level={4}>Localisation de la structure</AppMetierTitle>
    <Row align="top" className="white-spans">
      <Col span={titleColSpan / 2}>
        <Text>Adresse</Text>
      </Col>
      <Col span={valueColSpan / 2}>
        <Paragraph>
          <Text strong>{address.adresse1 ?? "-"}</Text>
        </Paragraph>
        <Paragraph>
          <Text strong>{`${address.codePostal ?? "-"} ${address.ville ?? "-"}`}</Text>
        </Paragraph>
      </Col>
    </Row>
    <Row style={{ marginBottom: "1em" }}>
      <Col span={24}>
        {address.latitude && address.longitude && (
          <LeafletMap latitude={address.latitude} longitude={address.longitude} />
        )}
      </Col>
    </Row>
  </>
)

const AgencySelect: FC<{
  bank: Bank
}> = ({ bank }) => {
  const { note } = useContext(NoteContext)
  const [agency, setAgency] = useState(note.bankPartner?.agency)
  const { isFetching, data: agencies } = useGetPartnerAgencies(bank.code)
  const updateNote = useUpdateNote(note.noteId)

  const updateBankPartnerData = (data: BankPartnerV2) => {
    updateNote.mutate({
      noteId: note.noteId,
      bankPartnerV2: { ...data }
    })
  }

  const handleOnChange = (id: string) => {
    const agency = agencies?.find(a => a.id === id)
    if (agency) {
      setAgency(agency)
      updateBankPartnerData({ bank, agency })
    }
  }

  const getSelector = () =>
    !!agencies && agencies.length > 0 ? (
      <Select
        value={agencies?.find(a => a.id === agency?.id)?.id}
        placeholder="Selectionnez une agence"
        style={{ width: 300 }}
        filterOption
        showSearch
        optionFilterProp="label"
        onSelect={handleOnChange}
        allowClear
        onClear={() => {
          setAgency(undefined)
          updateBankPartnerData({ bank, agency: undefined })
        }}
        options={agencies
          .map(({ id, label }) => ({
            value: id,
            label
          }))
          .sort((a, b) => sortByLabels(a.label, b.label))}
      />
    ) : (
      <span>Pas d'agence associée à cette banque</span>
    )

  return <>{isFetching ? <Spin /> : getSelector()}</>
}
