import {
  CategoryResultCategoryEnum,
  InvolvementProfileConfig,
  InvolvementProfileConfigProfileEnum,
  InvolvementQuestionCategoryEnum,
  InvolvementQuestionQuestionTypeEnum,
  Result,
  ResultInvolvementProfileV2Enum
} from "../../client/backend-client/generated"
import { ScoreOptionType } from "../../components/ScoreInput/ScoreInput"
import { CSSProperties } from "react"

export type InvolvementVersion = 1 | 2

type InvolvementCategoryConfig = {
  key: string
  label: string
  color: string
  involvementProfile: InvolvementProfileConfigProfileEnum
  radarChartOrder?: number
}

export const INVOLVEMENT_CATEGORY_GROUP_RECORD: Record<
  InvolvementQuestionCategoryEnum,
  InvolvementCategoryConfig
> = {
  EMPLOYMENT: {
    key: "employment",
    label: "Emploi",
    color: "#00b195",
    involvementProfile: InvolvementProfileConfigProfileEnum.EMPLOYER,
    radarChartOrder: 1
  },
  TERRITORY: {
    key: "territory",
    label: "Territoire",
    color: "#27aae1",
    involvementProfile: InvolvementProfileConfigProfileEnum.TERRITORIAL,
    radarChartOrder: 2
  },
  SOCIAL: {
    key: "social",
    label: "Social",
    color: "#f37173",
    involvementProfile: InvolvementProfileConfigProfileEnum.SOCIAL,
    radarChartOrder: 3
  },
  ENVIRONMENT: {
    key: "environment",
    label: "Environnement",
    color: "#f58345",
    involvementProfile: InvolvementProfileConfigProfileEnum.ECOLO,
    radarChartOrder: 4
  },
  GOVERNANCE: {
    key: "governance",
    label: "Gouvernance",
    color: "#3b63a2",
    involvementProfile: InvolvementProfileConfigProfileEnum.ETHICS,
    radarChartOrder: 5
  },
  OTHER: {
    key: "other",
    label: "Autre",
    color: "#777777",
    involvementProfile: InvolvementProfileConfigProfileEnum.OTHER
  }
}

export const sortCategoryResultByRadarChartOrder = (
  a: CategoryResultCategoryEnum,
  b: CategoryResultCategoryEnum
): number => {
  const aOrder = INVOLVEMENT_CATEGORY_GROUP_RECORD[a].radarChartOrder
  const bOrder = INVOLVEMENT_CATEGORY_GROUP_RECORD[b].radarChartOrder
  return !!aOrder && !!bOrder ? aOrder - bOrder : -1
}

//TODO - Wording en discussion coté FA
export const INVOLVEMENT_PROFILE_V2_RECORD: Record<ResultInvolvementProfileV2Enum, string> = {
  NEGATIVE_IMPACT: "Impact négatif",
  NEUTRAL_IMPACT: "Aucun pilier significatif",
  POSITIVE_IMPACT: "Impact significatif"
}

//TODO - Delete unused ?
export const INVOLVEMENT_PROFILE_RECORD: Record<InvolvementProfileConfigProfileEnum, string> = {
  NO_INVOLVEMENT: "Encore des progrès à faire",
  EMPLOYER: "Employeur",
  TERRITORIAL: "Territorial",
  SOCIAL: "Social",
  ECOLO: "Écolo",
  ETHICS: "Éthique",
  MULTI_COMMITTED: "Multiengagé",
  OTHER: "Autre"
}

export const V2_CHECKBOX_NEGATIVE_SCORE = 0

export const getInvolvementQuestionTitleStyle = (
  questionType: InvolvementQuestionQuestionTypeEnum,
  category: InvolvementQuestionCategoryEnum
): CSSProperties => {
  switch (questionType) {
    case InvolvementQuestionQuestionTypeEnum.EXCLUDE_CRITERIA:
      return { color: "var(--main-red-2)" }
    case InvolvementQuestionQuestionTypeEnum.PREVENTION:
      return { color: INVOLVEMENT_CATEGORY_GROUP_RECORD[category].color }
    default:
      return {}
  }
}

export enum InvolvementScoreInputType {
  SCORE = "SCORE",
  FORECAST_IMPACT = "FORECAST_IMPACT"
}

export const getInvolvementScoreOptions = (
  involvementScoreInputType: InvolvementScoreInputType,
  version: number
): ScoreOptionType[] => {
  const isFirstVersion = version === 1
  switch (involvementScoreInputType) {
    case InvolvementScoreInputType.SCORE:
      return [
        ...(isFirstVersion
          ? [
              {
                value: 0,
                label: "0",
                tooltip: "Aspect non travaillé : rien n’est fait / impact négatif"
              }
            ]
          : []),
        {
          value: 1,
          label: "1",
          tooltip: isFirstVersion
            ? "Aspect considéré : quelques actions sont menées / impact léger"
            : "Impact inexistant ou faible : rien n’est fait ou quelques actions non structurées"
        },
        {
          value: 2,
          label: "2",
          tooltip: isFirstVersion
            ? "Aspect structuré : actions significatives / impact conséquent"
            : "Impact moyen : actions structurées ou plan d’actions formalisé"
        },
        {
          value: 3,
          label: "3",
          tooltip: isFirstVersion
            ? "Aspect maîtrisé : cœur du projet"
            : "Impact fort : l’engagement est au cœur de la finalité de l’entreprise"
        }
      ]
    case InvolvementScoreInputType.FORECAST_IMPACT:
      return [
        {
          value: "+",
          label: "+",
          tooltip: "L’intervention renforce l'impact sur le critère"
        },
        {
          value: "=",
          label: "=",
          tooltip: "L’intervention maintient l'impact existant sur le critère"
        },
        {
          value: "",
          label: "\u00A0",
          tooltip: " L’intervention n’a pas d’impact sur le critère"
        }
      ]
    default:
      return []
  }
}

export const getProfileConfig = (
  profile: InvolvementProfileConfigProfileEnum,
  profileConfigs: InvolvementProfileConfig[]
): InvolvementProfileConfig | undefined => profileConfigs.find(c => c.profile === profile)

export const getInvolvementProfileLabelFromResult = (
  result: Result,
  profileConfig: InvolvementProfileConfig[] | undefined,
  isLoading: boolean
): string | undefined => {
  if (isLoading || !profileConfig || profileConfig.length === 0) {
    return undefined
  }
  const cornerstoneCategoryResults = result.categoryResults.filter(
    category => category.valid && !!category.mean && category.mean >= 2.0
  )
  switch (cornerstoneCategoryResults.length) {
    case 0:
      return getProfileConfig(InvolvementProfileConfigProfileEnum.NO_INVOLVEMENT, profileConfig)
        ?.label
    case 1:
    case 2:
      return cornerstoneCategoryResults
        .map(
          c =>
            getProfileConfig(
              INVOLVEMENT_CATEGORY_GROUP_RECORD[c.category].involvementProfile,
              profileConfig
            )?.label
        )
        .join(" ")
    default:
      return getProfileConfig(InvolvementProfileConfigProfileEnum.MULTI_COMMITTED, profileConfig)
        ?.label
  }
}
