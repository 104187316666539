import React from "react"
import Icon from "@ant-design/icons"
import { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon"

export const faSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 69 75">
    <path opacity="0.7" fill="#00b195" d="M20.44 37.04L0 25.24V48.84L20.44 37.04Z" />
    <path
      opacity="0.7"
      d="M0 57.04L37.64 37.04L0 17.05V0L68.02 37.04L0 74.09V57.04Z"
      fill="#04A58C"
    />
  </svg>
)

export const FAIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={faSvg} {...props} />
)
