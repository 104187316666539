import { KeycloakContext } from "../auth/KeycloakContext"
import { useContext } from "react"

export function useHeaders() {
  const { keycloak, updateToken } = useContext(KeycloakContext)

  return async () => {
    await updateToken()
    return {
      headers: { Authorization: `Bearer ${keycloak.token}` }
    }
  }
}
