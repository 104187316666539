import React from "react"
import { NumericFormat, NumericFormatProps } from "react-number-format"
import { formatSingleNumber } from "../../utils/formatNumber/FormatNumber"
import { Suffixes } from "../../utils/suffixes"

const defaultNumberProps: NumericFormatProps = {
  thousandSeparator: " ",
  decimalSeparator: ",",
  allowedDecimalSeparators: [",", "."],
  decimalScale: 2
}

export const NumberInput = (props: NumericFormatProps) => {
  return <NumericFormat {...defaultNumberProps} {...props} />
}

export const NumberText = (props: NumericFormatProps) => {
  // @ts-expect-error : Type undefined is not assignable to type number
  const numberValue = Math.abs(props.value)
  const computeSuffix = () => {
    if (numberValue > 1) {
      return props.suffix
    }
    if (props.suffix === Suffixes.YEARS) {
      return Suffixes.YEAR
    }
    return props.suffix
  }

  const propertyVal = props.value
    ? typeof props.value === "number"
      ? (props.value as number).toFixed(2).toString()
      : props.value.toString()
    : ""
  return (
    <NumericFormat
      displayType={"text"}
      {...defaultNumberProps}
      {...props}
      suffix={computeSuffix()}
      value={formatSingleNumber(propertyVal, 2)}
    />
  )
}
