/* eslint-disable @typescript-eslint/no-explicit-any */

import { I18nConfig, OutputData } from "@editorjs/editorjs"
import _ from "lodash"

const EDITOR_JS_VERSION = "2.30.6"

export const EMPTY_OUTPUT_DATA: OutputData = {
  time: Date.now(),
  version: EDITOR_JS_VERSION,
  blocks: [
    {
      type: "paragraph",
      data: {
        text: ""
      }
    }
  ]
}

export const toOutputData = (source?: string): OutputData => {
  if (source) {
    const jsonValue = JSON.parse(source)
    if (Object.prototype.hasOwnProperty.call(jsonValue, "blocks")) {
      return jsonValue
    }
  }
  return EMPTY_OUTPUT_DATA
}

export const areSameOutputData = (a: OutputData, b: OutputData) => _.isEqual(a.blocks, b.blocks)

export const EDITOR_JS_I18N_CONFIG: I18nConfig = {
  messages: {
    /**
     * Other below: translation of different UI components of the editor.js core
     */
    ui: {
      blockTunes: {
        toggler: {
          "Click to tune": "Cliquer pour régler",
          "or drag to move": "ou faites glisser pour déplacer"
        }
      },
      inlineToolbar: {
        converter: {
          "Convert to": "Convertir en"
        }
      },
      toolbar: {
        toolbox: {
          Add: "Ajouter",
          Filter: "Filtre"
        }
      }
    },

    /**
     * Section for translation Tool Names: both block and inline tools
     */
    toolNames: {
      Header: "Titre",
      Text: "Paragraphe",
      Heading: "Titre",
      List: "Liste",
      Warning: "Attention",
      Checklist: "Checklist",
      Quote: "Citation",
      Code: "Code",
      Delimiter: "Délimiter",
      "Raw HTML": "Ligne HTML",
      Table: "Tableau",
      Link: "Lien",
      Bold: "Gras",
      Italic: "Italique",
      InlineCode: "Code en ligne",
      Underline: "Souligner"
    },

    /**
     * Section for passing translations to the external tools classes
     */
    tools: {
      /**
       * Each subsection is the i18n dictionary that will be passed to the corresponded plugin
       * The name of a plugin should be equal the name you specify in the 'tool' section for that plugin
       */
      warning: {
        // <-- 'Warning' tool will accept this dictionary section
        Title: "Titre",
        Message: "Message"
      },

      table: {
        "Add row above": "Ajouter une ligne avant",
        "Add row below": "Ajouter une ligne après",
        "Delete row": "Supprimer une ligne",
        "With headings": "Avec titre",
        "Without headings": "Sans titre",
        Heading: "Titre"
      },

      /**
       * Link is the internal Inline Tool
       */
      link: {
        "Add a link": "Ajouter un lien"
      },
      /**
       * The "stub" is an internal block tool, used to fit blocks that does not have the corresponded plugin
       */
      stub: {
        "The block can not be displayed correctly.":
          "Le bloc ne peut pas être affiché correctement."
      }
    },

    /**
     * Section allows to translate Block Tunes
     */
    blockTunes: {
      /**
       * Each subsection is the i18n dictionary that will be passed to the corresponded Block Tune plugin
       * The name of a plugin should be equal the name you specify in the 'tunes' section for that plugin
       *
       * Also, there are few internal block tunes: "delete", "moveUp" and "moveDown"
       */
      delete: {
        Delete: "Effacer"
      },
      moveUp: {
        "Move up": "Déplacer au dessus"
      },
      moveDown: {
        "Move down": "Déplacer en dessous"
      }
    }
  }
}
