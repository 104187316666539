/* tslint:disable */
/* eslint-disable */
/**
 * Appli métier
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: Yoda 1 & 2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from "axios"
import { Configuration } from "../configuration"
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction
} from "../common"
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from "../base"
// @ts-ignore
import { NotePdfOptions } from "../model"
// @ts-ignore
import { PdfLink } from "../model"
// @ts-ignore
import { PdfMementoV2 } from "../model"
// @ts-ignore
import { PdfNoteDataV2 } from "../model"
/**
 * PdfResourceV2Api - axios parameter creator
 * @export
 */
export const PdfResourceV2ApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Generate PDF V2 link for note
     * @param {number} projectId
     * @param {NotePdfOptions} notePdfOptions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generatePdf: async (
      projectId: number,
      notePdfOptions: NotePdfOptions,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'projectId' is not null or undefined
      assertParamExists("generatePdf", "projectId", projectId)
      // verify required parameter 'notePdfOptions' is not null or undefined
      assertParamExists("generatePdf", "notePdfOptions", notePdfOptions)
      const localVarPath = `/v2.0/api/pdf/note/{projectId}`.replace(
        `{${"projectId"}}`,
        encodeURIComponent(String(projectId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        notePdfOptions,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary Get note\'s PDF V2 data by note id
     * @param {number} projectId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNoteData: async (
      projectId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'projectId' is not null or undefined
      assertParamExists("getNoteData", "projectId", projectId)
      const localVarPath = `/v2.0/api/pdf/{projectId}/data`.replace(
        `{${"projectId"}}`,
        encodeURIComponent(String(projectId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary Get note\'s memento PDF V2 data by note id
     * @param {number} projectId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPdfMementoDataV2: async (
      projectId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'projectId' is not null or undefined
      assertParamExists("getPdfMementoDataV2", "projectId", projectId)
      const localVarPath = `/v2.0/api/pdf/{projectId}/mementoData`.replace(
        `{${"projectId"}}`,
        encodeURIComponent(String(projectId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * PdfResourceV2Api - functional programming interface
 * @export
 */
export const PdfResourceV2ApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = PdfResourceV2ApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @summary Generate PDF V2 link for note
     * @param {number} projectId
     * @param {NotePdfOptions} notePdfOptions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async generatePdf(
      projectId: number,
      notePdfOptions: NotePdfOptions,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PdfLink>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.generatePdf(
        projectId,
        notePdfOptions,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Get note\'s PDF V2 data by note id
     * @param {number} projectId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNoteData(
      projectId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PdfNoteDataV2>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getNoteData(projectId, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Get note\'s memento PDF V2 data by note id
     * @param {number} projectId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPdfMementoDataV2(
      projectId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PdfMementoV2>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPdfMementoDataV2(
        projectId,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    }
  }
}

/**
 * PdfResourceV2Api - factory interface
 * @export
 */
export const PdfResourceV2ApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = PdfResourceV2ApiFp(configuration)
  return {
    /**
     *
     * @summary Generate PDF V2 link for note
     * @param {number} projectId
     * @param {NotePdfOptions} notePdfOptions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generatePdf(
      projectId: number,
      notePdfOptions: NotePdfOptions,
      options?: any
    ): AxiosPromise<PdfLink> {
      return localVarFp
        .generatePdf(projectId, notePdfOptions, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Get note\'s PDF V2 data by note id
     * @param {number} projectId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNoteData(projectId: number, options?: any): AxiosPromise<PdfNoteDataV2> {
      return localVarFp.getNoteData(projectId, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Get note\'s memento PDF V2 data by note id
     * @param {number} projectId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPdfMementoDataV2(projectId: number, options?: any): AxiosPromise<PdfMementoV2> {
      return localVarFp
        .getPdfMementoDataV2(projectId, options)
        .then(request => request(axios, basePath))
    }
  }
}

/**
 * Request parameters for generatePdf operation in PdfResourceV2Api.
 * @export
 * @interface PdfResourceV2ApiGeneratePdfRequest
 */
export interface PdfResourceV2ApiGeneratePdfRequest {
  /**
   *
   * @type {number}
   * @memberof PdfResourceV2ApiGeneratePdf
   */
  readonly projectId: number

  /**
   *
   * @type {NotePdfOptions}
   * @memberof PdfResourceV2ApiGeneratePdf
   */
  readonly notePdfOptions: NotePdfOptions
}

/**
 * Request parameters for getNoteData operation in PdfResourceV2Api.
 * @export
 * @interface PdfResourceV2ApiGetNoteDataRequest
 */
export interface PdfResourceV2ApiGetNoteDataRequest {
  /**
   *
   * @type {number}
   * @memberof PdfResourceV2ApiGetNoteData
   */
  readonly projectId: number
}

/**
 * Request parameters for getPdfMementoDataV2 operation in PdfResourceV2Api.
 * @export
 * @interface PdfResourceV2ApiGetPdfMementoDataV2Request
 */
export interface PdfResourceV2ApiGetPdfMementoDataV2Request {
  /**
   *
   * @type {number}
   * @memberof PdfResourceV2ApiGetPdfMementoDataV2
   */
  readonly projectId: number
}

/**
 * PdfResourceV2Api - object-oriented interface
 * @export
 * @class PdfResourceV2Api
 * @extends {BaseAPI}
 */
export class PdfResourceV2Api extends BaseAPI {
  /**
   *
   * @summary Generate PDF V2 link for note
   * @param {PdfResourceV2ApiGeneratePdfRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PdfResourceV2Api
   */
  public generatePdf(
    requestParameters: PdfResourceV2ApiGeneratePdfRequest,
    options?: AxiosRequestConfig
  ) {
    return PdfResourceV2ApiFp(this.configuration)
      .generatePdf(requestParameters.projectId, requestParameters.notePdfOptions, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get note\'s PDF V2 data by note id
   * @param {PdfResourceV2ApiGetNoteDataRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PdfResourceV2Api
   */
  public getNoteData(
    requestParameters: PdfResourceV2ApiGetNoteDataRequest,
    options?: AxiosRequestConfig
  ) {
    return PdfResourceV2ApiFp(this.configuration)
      .getNoteData(requestParameters.projectId, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get note\'s memento PDF V2 data by note id
   * @param {PdfResourceV2ApiGetPdfMementoDataV2Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PdfResourceV2Api
   */
  public getPdfMementoDataV2(
    requestParameters: PdfResourceV2ApiGetPdfMementoDataV2Request,
    options?: AxiosRequestConfig
  ) {
    return PdfResourceV2ApiFp(this.configuration)
      .getPdfMementoDataV2(requestParameters.projectId, options)
      .then(request => request(this.axios, this.basePath))
  }
}
