/* tslint:disable */
/* eslint-disable */
/**
 * Appli métier
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: Yoda 1 & 2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from "axios"
import { Configuration } from "../configuration"
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction
} from "../common"
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from "../base"
// @ts-ignore
import { ApiMasseSalariale } from "../model"
// @ts-ignore
import { MasseSalarialeFonctionInput } from "../model"
// @ts-ignore
import { SaveMasseSalarialeRequest } from "../model"
/**
 * MasseSalarialeResourceV2Api - axios parameter creator
 * @export
 */
export const MasseSalarialeResourceV2ApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @summary Delete salary mass
     * @param {string} noteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteMasseSalariale: async (
      noteId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'noteId' is not null or undefined
      assertParamExists("deleteMasseSalariale", "noteId", noteId)
      const localVarPath = `/v2.0/masseSalariale/{noteId}`.replace(
        `{${"noteId"}}`,
        encodeURIComponent(String(noteId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary Delete salary mass function by id
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteMasseSalarialeFonction: async (
      id: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("deleteMasseSalarialeFonction", "id", id)
      const localVarPath = `/v2.0/masseSalariale/masseSalarialeFonction/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary Get salary mass
     * @param {string} noteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMasseSalariale: async (
      noteId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'noteId' is not null or undefined
      assertParamExists("getMasseSalariale", "noteId", noteId)
      const localVarPath = `/v2.0/masseSalariale/{noteId}`.replace(
        `{${"noteId"}}`,
        encodeURIComponent(String(noteId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary Create or update salary mass function by noteId and id
     * @param {string} noteId
     * @param {MasseSalarialeFonctionInput} masseSalarialeFonctionInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveMasseSalarialeFonction: async (
      noteId: string,
      masseSalarialeFonctionInput: MasseSalarialeFonctionInput,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'noteId' is not null or undefined
      assertParamExists("saveMasseSalarialeFonction", "noteId", noteId)
      // verify required parameter 'masseSalarialeFonctionInput' is not null or undefined
      assertParamExists(
        "saveMasseSalarialeFonction",
        "masseSalarialeFonctionInput",
        masseSalarialeFonctionInput
      )
      const localVarPath = `/v2.0/masseSalariale/{noteId}/masseSalarialeFonction`.replace(
        `{${"noteId"}}`,
        encodeURIComponent(String(noteId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        masseSalarialeFonctionInput,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary Create or update salary mass
     * @param {SaveMasseSalarialeRequest} saveMasseSalarialeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateMasseSalariale: async (
      saveMasseSalarialeRequest: SaveMasseSalarialeRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'saveMasseSalarialeRequest' is not null or undefined
      assertParamExists(
        "updateMasseSalariale",
        "saveMasseSalarialeRequest",
        saveMasseSalarialeRequest
      )
      const localVarPath = `/v2.0/masseSalariale`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        saveMasseSalarialeRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * MasseSalarialeResourceV2Api - functional programming interface
 * @export
 */
export const MasseSalarialeResourceV2ApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = MasseSalarialeResourceV2ApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @summary Delete salary mass
     * @param {string} noteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteMasseSalariale(
      noteId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMasseSalariale(
        noteId,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Delete salary mass function by id
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteMasseSalarialeFonction(
      id: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMasseSalarialeFonction(
        id,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Get salary mass
     * @param {string} noteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getMasseSalariale(
      noteId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiMasseSalariale>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getMasseSalariale(noteId, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Create or update salary mass function by noteId and id
     * @param {string} noteId
     * @param {MasseSalarialeFonctionInput} masseSalarialeFonctionInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async saveMasseSalarialeFonction(
      noteId: string,
      masseSalarialeFonctionInput: MasseSalarialeFonctionInput,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiMasseSalariale>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.saveMasseSalarialeFonction(
        noteId,
        masseSalarialeFonctionInput,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Create or update salary mass
     * @param {SaveMasseSalarialeRequest} saveMasseSalarialeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateMasseSalariale(
      saveMasseSalarialeRequest: SaveMasseSalarialeRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiMasseSalariale>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateMasseSalariale(
        saveMasseSalarialeRequest,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    }
  }
}

/**
 * MasseSalarialeResourceV2Api - factory interface
 * @export
 */
export const MasseSalarialeResourceV2ApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = MasseSalarialeResourceV2ApiFp(configuration)
  return {
    /**
     *
     * @summary Delete salary mass
     * @param {string} noteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteMasseSalariale(noteId: string, options?: any): AxiosPromise<boolean> {
      return localVarFp
        .deleteMasseSalariale(noteId, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Delete salary mass function by id
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteMasseSalarialeFonction(id: number, options?: any): AxiosPromise<boolean> {
      return localVarFp
        .deleteMasseSalarialeFonction(id, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Get salary mass
     * @param {string} noteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMasseSalariale(noteId: string, options?: any): AxiosPromise<ApiMasseSalariale> {
      return localVarFp.getMasseSalariale(noteId, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Create or update salary mass function by noteId and id
     * @param {string} noteId
     * @param {MasseSalarialeFonctionInput} masseSalarialeFonctionInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveMasseSalarialeFonction(
      noteId: string,
      masseSalarialeFonctionInput: MasseSalarialeFonctionInput,
      options?: any
    ): AxiosPromise<ApiMasseSalariale> {
      return localVarFp
        .saveMasseSalarialeFonction(noteId, masseSalarialeFonctionInput, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Create or update salary mass
     * @param {SaveMasseSalarialeRequest} saveMasseSalarialeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateMasseSalariale(
      saveMasseSalarialeRequest: SaveMasseSalarialeRequest,
      options?: any
    ): AxiosPromise<ApiMasseSalariale> {
      return localVarFp
        .updateMasseSalariale(saveMasseSalarialeRequest, options)
        .then(request => request(axios, basePath))
    }
  }
}

/**
 * Request parameters for deleteMasseSalariale operation in MasseSalarialeResourceV2Api.
 * @export
 * @interface MasseSalarialeResourceV2ApiDeleteMasseSalarialeRequest
 */
export interface MasseSalarialeResourceV2ApiDeleteMasseSalarialeRequest {
  /**
   *
   * @type {string}
   * @memberof MasseSalarialeResourceV2ApiDeleteMasseSalariale
   */
  readonly noteId: string
}

/**
 * Request parameters for deleteMasseSalarialeFonction operation in MasseSalarialeResourceV2Api.
 * @export
 * @interface MasseSalarialeResourceV2ApiDeleteMasseSalarialeFonctionRequest
 */
export interface MasseSalarialeResourceV2ApiDeleteMasseSalarialeFonctionRequest {
  /**
   *
   * @type {number}
   * @memberof MasseSalarialeResourceV2ApiDeleteMasseSalarialeFonction
   */
  readonly id: number
}

/**
 * Request parameters for getMasseSalariale operation in MasseSalarialeResourceV2Api.
 * @export
 * @interface MasseSalarialeResourceV2ApiGetMasseSalarialeRequest
 */
export interface MasseSalarialeResourceV2ApiGetMasseSalarialeRequest {
  /**
   *
   * @type {string}
   * @memberof MasseSalarialeResourceV2ApiGetMasseSalariale
   */
  readonly noteId: string
}

/**
 * Request parameters for saveMasseSalarialeFonction operation in MasseSalarialeResourceV2Api.
 * @export
 * @interface MasseSalarialeResourceV2ApiSaveMasseSalarialeFonctionRequest
 */
export interface MasseSalarialeResourceV2ApiSaveMasseSalarialeFonctionRequest {
  /**
   *
   * @type {string}
   * @memberof MasseSalarialeResourceV2ApiSaveMasseSalarialeFonction
   */
  readonly noteId: string

  /**
   *
   * @type {MasseSalarialeFonctionInput}
   * @memberof MasseSalarialeResourceV2ApiSaveMasseSalarialeFonction
   */
  readonly masseSalarialeFonctionInput: MasseSalarialeFonctionInput
}

/**
 * Request parameters for updateMasseSalariale operation in MasseSalarialeResourceV2Api.
 * @export
 * @interface MasseSalarialeResourceV2ApiUpdateMasseSalarialeRequest
 */
export interface MasseSalarialeResourceV2ApiUpdateMasseSalarialeRequest {
  /**
   *
   * @type {SaveMasseSalarialeRequest}
   * @memberof MasseSalarialeResourceV2ApiUpdateMasseSalariale
   */
  readonly saveMasseSalarialeRequest: SaveMasseSalarialeRequest
}

/**
 * MasseSalarialeResourceV2Api - object-oriented interface
 * @export
 * @class MasseSalarialeResourceV2Api
 * @extends {BaseAPI}
 */
export class MasseSalarialeResourceV2Api extends BaseAPI {
  /**
   *
   * @summary Delete salary mass
   * @param {MasseSalarialeResourceV2ApiDeleteMasseSalarialeRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MasseSalarialeResourceV2Api
   */
  public deleteMasseSalariale(
    requestParameters: MasseSalarialeResourceV2ApiDeleteMasseSalarialeRequest,
    options?: AxiosRequestConfig
  ) {
    return MasseSalarialeResourceV2ApiFp(this.configuration)
      .deleteMasseSalariale(requestParameters.noteId, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Delete salary mass function by id
   * @param {MasseSalarialeResourceV2ApiDeleteMasseSalarialeFonctionRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MasseSalarialeResourceV2Api
   */
  public deleteMasseSalarialeFonction(
    requestParameters: MasseSalarialeResourceV2ApiDeleteMasseSalarialeFonctionRequest,
    options?: AxiosRequestConfig
  ) {
    return MasseSalarialeResourceV2ApiFp(this.configuration)
      .deleteMasseSalarialeFonction(requestParameters.id, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get salary mass
   * @param {MasseSalarialeResourceV2ApiGetMasseSalarialeRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MasseSalarialeResourceV2Api
   */
  public getMasseSalariale(
    requestParameters: MasseSalarialeResourceV2ApiGetMasseSalarialeRequest,
    options?: AxiosRequestConfig
  ) {
    return MasseSalarialeResourceV2ApiFp(this.configuration)
      .getMasseSalariale(requestParameters.noteId, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Create or update salary mass function by noteId and id
   * @param {MasseSalarialeResourceV2ApiSaveMasseSalarialeFonctionRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MasseSalarialeResourceV2Api
   */
  public saveMasseSalarialeFonction(
    requestParameters: MasseSalarialeResourceV2ApiSaveMasseSalarialeFonctionRequest,
    options?: AxiosRequestConfig
  ) {
    return MasseSalarialeResourceV2ApiFp(this.configuration)
      .saveMasseSalarialeFonction(
        requestParameters.noteId,
        requestParameters.masseSalarialeFonctionInput,
        options
      )
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Create or update salary mass
   * @param {MasseSalarialeResourceV2ApiUpdateMasseSalarialeRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MasseSalarialeResourceV2Api
   */
  public updateMasseSalariale(
    requestParameters: MasseSalarialeResourceV2ApiUpdateMasseSalarialeRequest,
    options?: AxiosRequestConfig
  ) {
    return MasseSalarialeResourceV2ApiFp(this.configuration)
      .updateMasseSalariale(requestParameters.saveMasseSalarialeRequest, options)
      .then(request => request(this.axios, this.basePath))
  }
}
