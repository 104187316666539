/* tslint:disable */
/* eslint-disable */
/**
 * Appli métier
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: Yoda 1 & 2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { NumericData } from "./numeric-data"

/**
 *
 * @export
 * @interface AddLoanRequest
 */
export interface AddLoanRequest {
  /**
   *
   * @type {string}
   * @memberof AddLoanRequest
   */
  amortizationPeriodicity: AddLoanRequestAmortizationPeriodicityEnum
  /**
   *
   * @type {NumericData}
   * @memberof AddLoanRequest
   */
  amount: NumericData
  /**
   *
   * @type {string}
   * @memberof AddLoanRequest
   */
  associationId: string
  /**
   *
   * @type {string}
   * @memberof AddLoanRequest
   */
  decisionMaker: AddLoanRequestDecisionMakerEnum
  /**
   *
   * @type {number}
   * @memberof AddLoanRequest
   */
  delayDuration: number
  /**
   *
   * @type {number}
   * @memberof AddLoanRequest
   */
  delegationId?: number
  /**
   *
   * @type {string}
   * @memberof AddLoanRequest
   */
  disbursementDate?: string
  /**
   *
   * @type {string}
   * @memberof AddLoanRequest
   */
  firstRepaymentDate?: string
  /**
   *
   * @type {string}
   * @memberof AddLoanRequest
   */
  holderId?: string
  /**
   *
   * @type {string}
   * @memberof AddLoanRequest
   */
  interestsPeriodicity?: AddLoanRequestInterestsPeriodicityEnum
  /**
   *
   * @type {number}
   * @memberof AddLoanRequest
   */
  productId?: number
  /**
   *
   * @type {string}
   * @memberof AddLoanRequest
   */
  productName?: string
  /**
   *
   * @type {number}
   * @memberof AddLoanRequest
   */
  rate: number
  /**
   *
   * @type {number}
   * @memberof AddLoanRequest
   */
  totalDuration: number
}

/**
 * @export
 * @enum {string}
 */
export enum AddLoanRequestAmortizationPeriodicityEnum {
  MONTHLY = "MONTHLY",
  BIMONTHLY = "BIMONTHLY",
  QUARTERLY = "QUARTERLY",
  SEMI_ANNUAL = "SEMI_ANNUAL",
  ANNUAL = "ANNUAL",
  IN_FINE = "IN_FINE"
}
/**
 * @export
 * @enum {string}
 */
export enum AddLoanRequestDecisionMakerEnum {
  FA = "FA",
  EXTERNAL = "EXTERNAL",
  UNKNOWN = "UNKNOWN"
}
/**
 * @export
 * @enum {string}
 */
export enum AddLoanRequestInterestsPeriodicityEnum {
  MONTHLY = "MONTHLY",
  BIMONTHLY = "BIMONTHLY",
  QUARTERLY = "QUARTERLY",
  SEMI_ANNUAL = "SEMI_ANNUAL",
  ANNUAL = "ANNUAL",
  IN_FINE = "IN_FINE"
}
