/* tslint:disable */
/* eslint-disable */
/**
 * Appli métier
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: Yoda 1 & 2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from "axios"
import { Configuration } from "../configuration"
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction
} from "../common"
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from "../base"
// @ts-ignore
import { Advisor } from "../model"
/**
 * CollaboratorResourceApi - axios parameter creator
 * @export
 */
export const CollaboratorResourceApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get collaborator by regis role id
     * @param {string} regisRoleId
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    getCollaborator: async (
      regisRoleId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'regisRoleId' is not null or undefined
      assertParamExists("getCollaborator", "regisRoleId", regisRoleId)
      const localVarPath = `/collaborators/{regisRoleId}`.replace(
        `{${"regisRoleId"}}`,
        encodeURIComponent(String(regisRoleId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * CollaboratorResourceApi - functional programming interface
 * @export
 */
export const CollaboratorResourceApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = CollaboratorResourceApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @summary Get collaborator by regis role id
     * @param {string} regisRoleId
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    async getCollaborator(
      regisRoleId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Advisor>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCollaborator(
        regisRoleId,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    }
  }
}

/**
 * CollaboratorResourceApi - factory interface
 * @export
 */
export const CollaboratorResourceApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = CollaboratorResourceApiFp(configuration)
  return {
    /**
     *
     * @summary Get collaborator by regis role id
     * @param {string} regisRoleId
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    getCollaborator(regisRoleId: string, options?: any): AxiosPromise<Advisor> {
      return localVarFp
        .getCollaborator(regisRoleId, options)
        .then(request => request(axios, basePath))
    }
  }
}

/**
 * Request parameters for getCollaborator operation in CollaboratorResourceApi.
 * @export
 * @interface CollaboratorResourceApiGetCollaboratorRequest
 */
export interface CollaboratorResourceApiGetCollaboratorRequest {
  /**
   *
   * @type {string}
   * @memberof CollaboratorResourceApiGetCollaborator
   */
  readonly regisRoleId: string
}

/**
 * CollaboratorResourceApi - object-oriented interface
 * @export
 * @class CollaboratorResourceApi
 * @extends {BaseAPI}
 */
export class CollaboratorResourceApi extends BaseAPI {
  /**
   *
   * @summary Get collaborator by regis role id
   * @param {CollaboratorResourceApiGetCollaboratorRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof CollaboratorResourceApi
   */
  public getCollaborator(
    requestParameters: CollaboratorResourceApiGetCollaboratorRequest,
    options?: AxiosRequestConfig
  ) {
    return CollaboratorResourceApiFp(this.configuration)
      .getCollaborator(requestParameters.regisRoleId, options)
      .then(request => request(this.axios, this.basePath))
  }
}
