/* tslint:disable */
/* eslint-disable */
/**
 * Appli métier
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: Yoda 1 & 2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from "axios"
import { Configuration } from "../configuration"
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction
} from "../common"
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from "../base"
// @ts-ignore
import { Advisor } from "../model"
// @ts-ignore
import { RegisAssociation } from "../model"
/**
 * AssociationResourceV2Api - axios parameter creator
 * @export
 */
export const AssociationResourceV2ApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get association by id
     * @param {string} associationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAssociation: async (
      associationId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'associationId' is not null or undefined
      assertParamExists("getAssociation", "associationId", associationId)
      const localVarPath = `/v2.0/associations/{associationId}`.replace(
        `{${"associationId"}}`,
        encodeURIComponent(String(associationId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary Get collaborators by association id
     * @param {string} associationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAssociationCollaborators: async (
      associationId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'associationId' is not null or undefined
      assertParamExists("getAssociationCollaborators", "associationId", associationId)
      const localVarPath = `/v2.0/associations/{associationId}/collaborators`.replace(
        `{${"associationId"}}`,
        encodeURIComponent(String(associationId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary Get all associations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAssociations: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/v2.0/associations`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary Migrate deposits by association id and contact id
     * @param {string} associationId
     * @param {string} [contactId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    migrateDeposits: async (
      associationId: string,
      contactId?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'associationId' is not null or undefined
      assertParamExists("migrateDeposits", "associationId", associationId)
      const localVarPath = `/v2.0/associations/{associationId}/migrate-deposit`.replace(
        `{${"associationId"}}`,
        encodeURIComponent(String(associationId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      if (contactId !== undefined) {
        localVarQueryParameter["contactId"] = contactId
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * AssociationResourceV2Api - functional programming interface
 * @export
 */
export const AssociationResourceV2ApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AssociationResourceV2ApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @summary Get association by id
     * @param {string} associationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAssociation(
      associationId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegisAssociation>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAssociation(
        associationId,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Get collaborators by association id
     * @param {string} associationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAssociationCollaborators(
      associationId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Advisor>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAssociationCollaborators(
        associationId,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Get all associations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAssociations(
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RegisAssociation>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAssociations(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Migrate deposits by association id and contact id
     * @param {string} associationId
     * @param {string} [contactId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async migrateDeposits(
      associationId: string,
      contactId?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.migrateDeposits(
        associationId,
        contactId,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    }
  }
}

/**
 * AssociationResourceV2Api - factory interface
 * @export
 */
export const AssociationResourceV2ApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = AssociationResourceV2ApiFp(configuration)
  return {
    /**
     *
     * @summary Get association by id
     * @param {string} associationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAssociation(associationId: string, options?: any): AxiosPromise<RegisAssociation> {
      return localVarFp
        .getAssociation(associationId, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Get collaborators by association id
     * @param {string} associationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAssociationCollaborators(
      associationId: string,
      options?: any
    ): AxiosPromise<Array<Advisor>> {
      return localVarFp
        .getAssociationCollaborators(associationId, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Get all associations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAssociations(options?: any): AxiosPromise<Array<RegisAssociation>> {
      return localVarFp.getAssociations(options).then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Migrate deposits by association id and contact id
     * @param {string} associationId
     * @param {string} [contactId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    migrateDeposits(
      associationId: string,
      contactId?: string,
      options?: any
    ): AxiosPromise<string> {
      return localVarFp
        .migrateDeposits(associationId, contactId, options)
        .then(request => request(axios, basePath))
    }
  }
}

/**
 * Request parameters for getAssociation operation in AssociationResourceV2Api.
 * @export
 * @interface AssociationResourceV2ApiGetAssociationRequest
 */
export interface AssociationResourceV2ApiGetAssociationRequest {
  /**
   *
   * @type {string}
   * @memberof AssociationResourceV2ApiGetAssociation
   */
  readonly associationId: string
}

/**
 * Request parameters for getAssociationCollaborators operation in AssociationResourceV2Api.
 * @export
 * @interface AssociationResourceV2ApiGetAssociationCollaboratorsRequest
 */
export interface AssociationResourceV2ApiGetAssociationCollaboratorsRequest {
  /**
   *
   * @type {string}
   * @memberof AssociationResourceV2ApiGetAssociationCollaborators
   */
  readonly associationId: string
}

/**
 * Request parameters for migrateDeposits operation in AssociationResourceV2Api.
 * @export
 * @interface AssociationResourceV2ApiMigrateDepositsRequest
 */
export interface AssociationResourceV2ApiMigrateDepositsRequest {
  /**
   *
   * @type {string}
   * @memberof AssociationResourceV2ApiMigrateDeposits
   */
  readonly associationId: string

  /**
   *
   * @type {string}
   * @memberof AssociationResourceV2ApiMigrateDeposits
   */
  readonly contactId?: string
}

/**
 * AssociationResourceV2Api - object-oriented interface
 * @export
 * @class AssociationResourceV2Api
 * @extends {BaseAPI}
 */
export class AssociationResourceV2Api extends BaseAPI {
  /**
   *
   * @summary Get association by id
   * @param {AssociationResourceV2ApiGetAssociationRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AssociationResourceV2Api
   */
  public getAssociation(
    requestParameters: AssociationResourceV2ApiGetAssociationRequest,
    options?: AxiosRequestConfig
  ) {
    return AssociationResourceV2ApiFp(this.configuration)
      .getAssociation(requestParameters.associationId, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get collaborators by association id
   * @param {AssociationResourceV2ApiGetAssociationCollaboratorsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AssociationResourceV2Api
   */
  public getAssociationCollaborators(
    requestParameters: AssociationResourceV2ApiGetAssociationCollaboratorsRequest,
    options?: AxiosRequestConfig
  ) {
    return AssociationResourceV2ApiFp(this.configuration)
      .getAssociationCollaborators(requestParameters.associationId, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get all associations
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AssociationResourceV2Api
   */
  public getAssociations(options?: AxiosRequestConfig) {
    return AssociationResourceV2ApiFp(this.configuration)
      .getAssociations(options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Migrate deposits by association id and contact id
   * @param {AssociationResourceV2ApiMigrateDepositsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AssociationResourceV2Api
   */
  public migrateDeposits(
    requestParameters: AssociationResourceV2ApiMigrateDepositsRequest,
    options?: AxiosRequestConfig
  ) {
    return AssociationResourceV2ApiFp(this.configuration)
      .migrateDeposits(requestParameters.associationId, requestParameters.contactId, options)
      .then(request => request(this.axios, this.basePath))
  }
}
