import { useAppContext } from "../../config/context"
import { useHeaders } from "../../queries/axios-headers"
import { useQuery } from "@tanstack/react-query"
import { extract } from "../../client/backend-client/AxiosUtils"

export const useGetHistory = (noteId: string, projectId: string) => {
  const { historyResourceApi } = useAppContext()
  const getHeaders = useHeaders()

  const { isLoading, data, error } = useQuery({
    queryKey: ["history", noteId, projectId],
    queryFn: async () => {
      const { headers } = await getHeaders()
      return historyResourceApi
        .getNoteHistory({ noteId, structureId: projectId }, { headers })
        .then(extract)
    },
    retry: 0,
    meta: {
      errorMessage: `Erreur lors de la récupération de l'historique`
    }
  })

  return {
    isLoading,
    data,
    error
  }
}
