import { useAppContext } from "../../config/context"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { extract } from "../../client/backend-client/AxiosUtils"
import { useHeaders } from "../../queries/axios-headers"
import {
  Memento,
  MementoInput,
  MementoResourceV2ApiGeneratePdf2Request
} from "../../client/backend-client/generated"

export const useGetMemento = (projectId: number) => {
  const { mementoResourceV2Api } = useAppContext()
  const getHeaders = useHeaders()

  const { isLoading, data, error } = useQuery({
    queryKey: ["memento", projectId],
    queryFn: async () => {
      const { headers } = await getHeaders()
      return mementoResourceV2Api.getMemento({ projectId }, { headers }).then(extract)
    },
    retry: 0,
    meta: {
      errorMessage: `Erreur lors de la récupération du mémento`
    }
  })

  return {
    isLoading,
    data,
    error
  }
}

export const useUpdateMemento = (projectId: number, noteId: string) => {
  const { mementoResourceV2Api } = useAppContext()
  const getHeaders = useHeaders()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (request: MementoInput) => {
      const { headers } = await getHeaders()
      return mementoResourceV2Api
        .updateMemento({ projectId, mementoInput: request }, { headers })
        .then(extract)
    },
    onSuccess: result => {
      queryClient.setQueryData<Memento>(["memento", projectId], result)
      queryClient.invalidateQueries({ queryKey: ["history", noteId, projectId.toString()] })
    },
    meta: {
      errorMessage: `Erreur lors de la mise à jour du mémento`
    }
  })
}

export const useGenerateMementoPdf = () => {
  const { mementoResourceV2Api } = useAppContext()
  const getHeaders = useHeaders()

  return useMutation({
    mutationFn: async (request: MementoResourceV2ApiGeneratePdf2Request) => {
      const { headers } = await getHeaders()
      return mementoResourceV2Api.generatePdf2(request, { headers }).then(extract)
    },
    meta: {
      errorMessage: `Erreur lors de la génération du pdf`
    }
  })
}
