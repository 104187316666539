/* tslint:disable */
/* eslint-disable */
/**
 * Appli métier
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: Yoda 1 & 2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface NoteSettings
 */
export interface NoteSettings {
  /**
   *
   * @type {string}
   * @memberof NoteSettings
   */
  noteId: string
  /**
   *
   * @type {boolean}
   * @memberof NoteSettings
   */
  kiloEuros: boolean
  /**
   *
   * @type {string}
   * @memberof NoteSettings
   */
  referenceAmount: NoteSettingsReferenceAmountEnum
  /**
   *
   * @type {boolean}
   * @memberof NoteSettings
   */
  assujettiImpotsSocietes?: boolean
  /**
   *
   * @type {boolean}
   * @memberof NoteSettings
   */
  impotsSurBeneficeComputed: boolean
  /**
   *
   * @type {boolean}
   * @memberof NoteSettings
   */
  prelevementTnsInCr: boolean
  /**
   *
   * @type {boolean}
   * @memberof NoteSettings
   */
  showDemarrage: boolean
  /**
   *
   * @type {boolean}
   * @memberof NoteSettings
   */
  showDetailPret: boolean
  /**
   *
   * @type {boolean}
   * @memberof NoteSettings
   */
  showPercentageCedant: boolean
  /**
   *
   * @type {boolean}
   * @memberof NoteSettings
   */
  showPercentagePrevisionnel: boolean
  /**
   *
   * @type {boolean}
   * @memberof NoteSettings
   */
  showBfrInverse: boolean
  /**
   *
   * @type {boolean}
   * @memberof NoteSettings
   */
  showPreviousPeriod: boolean
  /**
   *
   * @type {Array<string>}
   * @memberof NoteSettings
   */
  expandedDetailKeys: Array<string>
}

/**
 * @export
 * @enum {string}
 */
export enum NoteSettingsReferenceAmountEnum {
  NONE = "NONE",
  CHIFFRE_AFFAIRE = "CHIFFRE_AFFAIRE",
  PRODUITS_EXPLOITATION = "PRODUITS_EXPLOITATION"
}
