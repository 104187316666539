/* tslint:disable */
/* eslint-disable */
/**
 * Appli métier
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: Yoda 1 & 2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from "axios"
import { Configuration } from "../configuration"
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction
} from "../common"
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from "../base"
// @ts-ignore
import { ChangeLifecycleRequest } from "../model"
// @ts-ignore
import { Note } from "../model"
// @ts-ignore
import { SaveNoteRequest } from "../model"
/**
 * NoteResourceV2Api - axios parameter creator
 * @export
 */
export const NoteResourceV2ApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Change lifecycle
     * @param {string} noteId
     * @param {ChangeLifecycleRequest} changeLifecycleRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changeLifecycle: async (
      noteId: string,
      changeLifecycleRequest: ChangeLifecycleRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'noteId' is not null or undefined
      assertParamExists("changeLifecycle", "noteId", noteId)
      // verify required parameter 'changeLifecycleRequest' is not null or undefined
      assertParamExists("changeLifecycle", "changeLifecycleRequest", changeLifecycleRequest)
      const localVarPath = `/v2.0/note/{noteId}/lifecycle`.replace(
        `{${"noteId"}}`,
        encodeURIComponent(String(noteId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        changeLifecycleRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary Get active note by project id
     * @param {string} projectId
     * @param {string} userId
     * @param {string} userName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCurrentNote: async (
      projectId: string,
      userId: string,
      userName: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'projectId' is not null or undefined
      assertParamExists("getCurrentNote", "projectId", projectId)
      // verify required parameter 'userId' is not null or undefined
      assertParamExists("getCurrentNote", "userId", userId)
      // verify required parameter 'userName' is not null or undefined
      assertParamExists("getCurrentNote", "userName", userName)
      const localVarPath = `/v2.0/note/current/{projectId}`.replace(
        `{${"projectId"}}`,
        encodeURIComponent(String(projectId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      if (userId !== undefined && userId !== null) {
        localVarHeaderParameter["userId"] = String(userId)
      }

      if (userName !== undefined && userName !== null) {
        localVarHeaderParameter["userName"] = String(userName)
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary Get note by id
     * @param {string} noteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNote: async (noteId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'noteId' is not null or undefined
      assertParamExists("getNote", "noteId", noteId)
      const localVarPath = `/v2.0/note/{noteId}`.replace(
        `{${"noteId"}}`,
        encodeURIComponent(String(noteId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary Update note\'s creation ex nihilo
     * @param {string} noteId
     * @param {boolean} body
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    updateCreationExNihilo: async (
      noteId: string,
      body: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'noteId' is not null or undefined
      assertParamExists("updateCreationExNihilo", "noteId", noteId)
      // verify required parameter 'body' is not null or undefined
      assertParamExists("updateCreationExNihilo", "body", body)
      const localVarPath = `/v2.0/note/{noteId}/creation-ex-nihilo`.replace(
        `{${"noteId"}}`,
        encodeURIComponent(String(noteId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        body,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary Update note
     * @param {string} userId
     * @param {string} userName
     * @param {SaveNoteRequest} saveNoteRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateNote: async (
      userId: string,
      userName: string,
      saveNoteRequest: SaveNoteRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists("updateNote", "userId", userId)
      // verify required parameter 'userName' is not null or undefined
      assertParamExists("updateNote", "userName", userName)
      // verify required parameter 'saveNoteRequest' is not null or undefined
      assertParamExists("updateNote", "saveNoteRequest", saveNoteRequest)
      const localVarPath = `/v2.0/note`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      if (userId !== undefined && userId !== null) {
        localVarHeaderParameter["userId"] = String(userId)
      }

      if (userName !== undefined && userName !== null) {
        localVarHeaderParameter["userName"] = String(userName)
      }

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        saveNoteRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * NoteResourceV2Api - functional programming interface
 * @export
 */
export const NoteResourceV2ApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = NoteResourceV2ApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @summary Change lifecycle
     * @param {string} noteId
     * @param {ChangeLifecycleRequest} changeLifecycleRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async changeLifecycle(
      noteId: string,
      changeLifecycleRequest: ChangeLifecycleRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Note>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.changeLifecycle(
        noteId,
        changeLifecycleRequest,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Get active note by project id
     * @param {string} projectId
     * @param {string} userId
     * @param {string} userName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCurrentNote(
      projectId: string,
      userId: string,
      userName: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Note>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrentNote(
        projectId,
        userId,
        userName,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Get note by id
     * @param {string} noteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNote(
      noteId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Note>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getNote(noteId, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Update note\'s creation ex nihilo
     * @param {string} noteId
     * @param {boolean} body
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    async updateCreationExNihilo(
      noteId: string,
      body: boolean,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Note>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateCreationExNihilo(
        noteId,
        body,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Update note
     * @param {string} userId
     * @param {string} userName
     * @param {SaveNoteRequest} saveNoteRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateNote(
      userId: string,
      userName: string,
      saveNoteRequest: SaveNoteRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Note>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateNote(
        userId,
        userName,
        saveNoteRequest,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    }
  }
}

/**
 * NoteResourceV2Api - factory interface
 * @export
 */
export const NoteResourceV2ApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = NoteResourceV2ApiFp(configuration)
  return {
    /**
     *
     * @summary Change lifecycle
     * @param {string} noteId
     * @param {ChangeLifecycleRequest} changeLifecycleRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changeLifecycle(
      noteId: string,
      changeLifecycleRequest: ChangeLifecycleRequest,
      options?: any
    ): AxiosPromise<Note> {
      return localVarFp
        .changeLifecycle(noteId, changeLifecycleRequest, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Get active note by project id
     * @param {string} projectId
     * @param {string} userId
     * @param {string} userName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCurrentNote(
      projectId: string,
      userId: string,
      userName: string,
      options?: any
    ): AxiosPromise<Note> {
      return localVarFp
        .getCurrentNote(projectId, userId, userName, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Get note by id
     * @param {string} noteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNote(noteId: string, options?: any): AxiosPromise<Note> {
      return localVarFp.getNote(noteId, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Update note\'s creation ex nihilo
     * @param {string} noteId
     * @param {boolean} body
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    updateCreationExNihilo(noteId: string, body: boolean, options?: any): AxiosPromise<Note> {
      return localVarFp
        .updateCreationExNihilo(noteId, body, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Update note
     * @param {string} userId
     * @param {string} userName
     * @param {SaveNoteRequest} saveNoteRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateNote(
      userId: string,
      userName: string,
      saveNoteRequest: SaveNoteRequest,
      options?: any
    ): AxiosPromise<Note> {
      return localVarFp
        .updateNote(userId, userName, saveNoteRequest, options)
        .then(request => request(axios, basePath))
    }
  }
}

/**
 * Request parameters for changeLifecycle operation in NoteResourceV2Api.
 * @export
 * @interface NoteResourceV2ApiChangeLifecycleRequest
 */
export interface NoteResourceV2ApiChangeLifecycleRequest {
  /**
   *
   * @type {string}
   * @memberof NoteResourceV2ApiChangeLifecycle
   */
  readonly noteId: string

  /**
   *
   * @type {ChangeLifecycleRequest}
   * @memberof NoteResourceV2ApiChangeLifecycle
   */
  readonly changeLifecycleRequest: ChangeLifecycleRequest
}

/**
 * Request parameters for getCurrentNote operation in NoteResourceV2Api.
 * @export
 * @interface NoteResourceV2ApiGetCurrentNoteRequest
 */
export interface NoteResourceV2ApiGetCurrentNoteRequest {
  /**
   *
   * @type {string}
   * @memberof NoteResourceV2ApiGetCurrentNote
   */
  readonly projectId: string

  /**
   *
   * @type {string}
   * @memberof NoteResourceV2ApiGetCurrentNote
   */
  readonly userId: string

  /**
   *
   * @type {string}
   * @memberof NoteResourceV2ApiGetCurrentNote
   */
  readonly userName: string
}

/**
 * Request parameters for getNote operation in NoteResourceV2Api.
 * @export
 * @interface NoteResourceV2ApiGetNoteRequest
 */
export interface NoteResourceV2ApiGetNoteRequest {
  /**
   *
   * @type {string}
   * @memberof NoteResourceV2ApiGetNote
   */
  readonly noteId: string
}

/**
 * Request parameters for updateCreationExNihilo operation in NoteResourceV2Api.
 * @export
 * @interface NoteResourceV2ApiUpdateCreationExNihiloRequest
 */
export interface NoteResourceV2ApiUpdateCreationExNihiloRequest {
  /**
   *
   * @type {string}
   * @memberof NoteResourceV2ApiUpdateCreationExNihilo
   */
  readonly noteId: string

  /**
   *
   * @type {boolean}
   * @memberof NoteResourceV2ApiUpdateCreationExNihilo
   */
  readonly body: boolean
}

/**
 * Request parameters for updateNote operation in NoteResourceV2Api.
 * @export
 * @interface NoteResourceV2ApiUpdateNoteRequest
 */
export interface NoteResourceV2ApiUpdateNoteRequest {
  /**
   *
   * @type {string}
   * @memberof NoteResourceV2ApiUpdateNote
   */
  readonly userId: string

  /**
   *
   * @type {string}
   * @memberof NoteResourceV2ApiUpdateNote
   */
  readonly userName: string

  /**
   *
   * @type {SaveNoteRequest}
   * @memberof NoteResourceV2ApiUpdateNote
   */
  readonly saveNoteRequest: SaveNoteRequest
}

/**
 * NoteResourceV2Api - object-oriented interface
 * @export
 * @class NoteResourceV2Api
 * @extends {BaseAPI}
 */
export class NoteResourceV2Api extends BaseAPI {
  /**
   *
   * @summary Change lifecycle
   * @param {NoteResourceV2ApiChangeLifecycleRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NoteResourceV2Api
   */
  public changeLifecycle(
    requestParameters: NoteResourceV2ApiChangeLifecycleRequest,
    options?: AxiosRequestConfig
  ) {
    return NoteResourceV2ApiFp(this.configuration)
      .changeLifecycle(requestParameters.noteId, requestParameters.changeLifecycleRequest, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get active note by project id
   * @param {NoteResourceV2ApiGetCurrentNoteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NoteResourceV2Api
   */
  public getCurrentNote(
    requestParameters: NoteResourceV2ApiGetCurrentNoteRequest,
    options?: AxiosRequestConfig
  ) {
    return NoteResourceV2ApiFp(this.configuration)
      .getCurrentNote(
        requestParameters.projectId,
        requestParameters.userId,
        requestParameters.userName,
        options
      )
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get note by id
   * @param {NoteResourceV2ApiGetNoteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NoteResourceV2Api
   */
  public getNote(requestParameters: NoteResourceV2ApiGetNoteRequest, options?: AxiosRequestConfig) {
    return NoteResourceV2ApiFp(this.configuration)
      .getNote(requestParameters.noteId, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Update note\'s creation ex nihilo
   * @param {NoteResourceV2ApiUpdateCreationExNihiloRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof NoteResourceV2Api
   */
  public updateCreationExNihilo(
    requestParameters: NoteResourceV2ApiUpdateCreationExNihiloRequest,
    options?: AxiosRequestConfig
  ) {
    return NoteResourceV2ApiFp(this.configuration)
      .updateCreationExNihilo(requestParameters.noteId, requestParameters.body, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Update note
   * @param {NoteResourceV2ApiUpdateNoteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NoteResourceV2Api
   */
  public updateNote(
    requestParameters: NoteResourceV2ApiUpdateNoteRequest,
    options?: AxiosRequestConfig
  ) {
    return NoteResourceV2ApiFp(this.configuration)
      .updateNote(
        requestParameters.userId,
        requestParameters.userName,
        requestParameters.saveNoteRequest,
        options
      )
      .then(request => request(this.axios, this.basePath))
  }
}
