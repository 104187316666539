import { PeriodModel } from "./PeriodModel"
import { ApiIncomeStatement, ApiNumericData } from "../../client/backend-client/generated"

export type AllPeriods = {
  previous: PeriodModel[]
  forecast: PeriodModel[]
  intermediary?: PeriodModel
}

export const somePeriodHasDetailForIncomeStatementField = (
  periods: PeriodModel[],
  fieldKey: keyof ApiIncomeStatement
): boolean =>
  periods.some(
    period =>
      period.incomeStatement[fieldKey] &&
      // @ts-expect-error : Property hasDetail does not exist on type
      period.incomeStatement[fieldKey]["hasDetail"] === true
  )

export const mergeNumericDataPercentages = (
  fromPeriod: PeriodModel,
  toPeriod: PeriodModel,
  dataGetter: (p: PeriodModel) => ApiNumericData
): ApiNumericData => {
  const fromData = dataGetter(fromPeriod)
  const toData = dataGetter(toPeriod)
  return {
    ...fromData,
    percentageEvolution: toData.percentageEvolution,
    percentageReference: toData.percentageReference
  }
}

export const mergeNumericDataPercentagesIfDefined = (
  fromPeriod: PeriodModel,
  toPeriod: PeriodModel,
  dataGetter: (p: PeriodModel) => ApiNumericData | undefined
): ApiNumericData | undefined => {
  const fromData = dataGetter(fromPeriod)
  const toData = dataGetter(toPeriod)

  if (fromData && toData) {
    return {
      ...fromData,
      percentageEvolution: toData.percentageEvolution,
      percentageReference: toData.percentageReference
    }
  }
  return toData
}

export const mergePeriods = (from: PeriodModel, to: PeriodModel): PeriodModel => ({
  ...from,
  prelevementTns: to.prelevementTns,
  remboursementsEmprunts: {
    ...from.remboursementsEmprunts,
    total: to.remboursementsEmprunts.total
  },
  besoinsFixes: to.besoinsFixes,
  pointMort: to.pointMort,
  margeDeManoeuvre: to.margeDeManoeuvre,
  bfr: {
    ...from.bfr,
    bfrReel: {
      ...from.bfr.bfrReel,
      variation: to.bfr.bfrReel.variation
    }
  },
  planFinancement: {
    ...from.planFinancement,
    remboursementsPretsPasses: to.planFinancement.remboursementsPretsPasses,
    solde: to.planFinancement.solde,
    soldeCumule: to.planFinancement.soldeCumule,
    totalBesoins: to.planFinancement.totalBesoins,
    totalRessources: to.planFinancement.totalRessources
  },
  incomeStatement: {
    ...from.incomeStatement,
    impotsSurBenefices: {
      ...to.incomeStatement.impotsSurBenefices
    },
    chiffre_affaire: {
      ...from.incomeStatement.chiffre_affaire,
      total: to.incomeStatement.chiffre_affaire.hasDetail
        ? to.incomeStatement.chiffre_affaire.total
        : mergeNumericDataPercentages(from, to, p => p.incomeStatement.chiffre_affaire.total),
      detailLines: {
        ...from.incomeStatement.chiffre_affaire.detailLines,
        lines: from.incomeStatement.chiffre_affaire.detailLines.lines.map(fromLine => ({
          ...fromLine,
          required:
            to.incomeStatement.chiffre_affaire.detailLines.lines.find(
              toLine => toLine.id === fromLine.id
            )?.required || fromLine.required,
          value: mergeNumericDataPercentages(
            from,
            to,
            p =>
              p.incomeStatement.chiffre_affaire.detailLines.lines.find(
                toLine => toLine.id === fromLine.id
              )?.value || fromLine.value
          )
        }))
      },
      hasDetail: to.incomeStatement.chiffre_affaire.hasDetail
    },
    subventions_exploitation: mergeNumericDataPercentages(
      from,
      to,
      p => p.incomeStatement.subventions_exploitation
    ),
    subventions_exploitation_details: from.incomeStatement.subventions_exploitation_details &&
      to.incomeStatement.subventions_exploitation_details && {
        ...from.incomeStatement.subventions_exploitation_details,
        total: to.incomeStatement.subventions_exploitation_details.hasDetail
          ? to.incomeStatement.subventions_exploitation_details.total
          : mergeNumericDataPercentages(
              from,
              to,
              p => p.incomeStatement.subventions_exploitation_details!.total
            ),
        detailLines: {
          ...from.incomeStatement.subventions_exploitation_details.detailLines,
          lines: from.incomeStatement.subventions_exploitation_details.detailLines.lines.map(
            fromLine => ({
              ...fromLine,
              required:
                to.incomeStatement.subventions_exploitation_details!.detailLines.lines.find(
                  toLine => toLine.id === fromLine.id
                )?.required || fromLine.required,
              value: mergeNumericDataPercentages(
                from,
                to,
                p =>
                  p.incomeStatement.subventions_exploitation_details!.detailLines.lines.find(
                    toLine => toLine.id === fromLine.id
                  )?.value || fromLine.value
              )
            })
          )
        },
        hasDetail: to.incomeStatement.subventions_exploitation_details.hasDetail
      },
    aides_postes: mergeNumericDataPercentages(from, to, p => p.incomeStatement.aides_postes),
    autres_produits_exploitation: {
      ...from.incomeStatement.autres_produits_exploitation,
      total: to.incomeStatement.autres_produits_exploitation.hasDetail
        ? to.incomeStatement.autres_produits_exploitation.total
        : mergeNumericDataPercentages(
            from,
            to,
            p => p.incomeStatement.autres_produits_exploitation.total
          ),
      hasDetail: to.incomeStatement.autres_produits_exploitation.hasDetail,
      prodution_stockee: mergeNumericDataPercentagesIfDefined(
        from,
        to,
        p => p.incomeStatement.autres_produits_exploitation.prodution_stockee
      ),
      production_immobilisee: mergeNumericDataPercentagesIfDefined(
        from,
        to,
        p => p.incomeStatement.autres_produits_exploitation.production_immobilisee
      ),
      transfert_charges: mergeNumericDataPercentagesIfDefined(
        from,
        to,
        p => p.incomeStatement.autres_produits_exploitation.transfert_charges
      ),
      reprises_amortissement_provisions: mergeNumericDataPercentagesIfDefined(
        from,
        to,
        p => p.incomeStatement.autres_produits_exploitation.reprises_amortissement_provisions
      ),
      adhesions: mergeNumericDataPercentagesIfDefined(
        from,
        to,
        p => p.incomeStatement.autres_produits_exploitation.adhesions
      ),
      autres_produits_exploitation: mergeNumericDataPercentagesIfDefined(
        from,
        to,
        p => p.incomeStatement.autres_produits_exploitation.autres_produits_exploitation
      )
    },
    totalProduitsExploitation: to.incomeStatement.totalProduitsExploitation,
    achat_marchandises: {
      ...from.incomeStatement.achat_marchandises,
      total: to.incomeStatement.achat_marchandises.hasDetail
        ? to.incomeStatement.achat_marchandises.total
        : mergeNumericDataPercentages(from, to, p => p.incomeStatement.achat_marchandises.total),
      hasDetail: to.incomeStatement.achat_marchandises.hasDetail,
      achat_marchandises: mergeNumericDataPercentagesIfDefined(
        from,
        to,
        p => p.incomeStatement.achat_marchandises.achat_marchandises
      ),
      achat_matieres_premieres: mergeNumericDataPercentagesIfDefined(
        from,
        to,
        p => p.incomeStatement.achat_marchandises.achat_matieres_premieres
      ),
      variation_stock: mergeNumericDataPercentagesIfDefined(
        from,
        to,
        p => p.incomeStatement.achat_marchandises.variation_stock
      )
    },
    autres_achats: {
      ...from.incomeStatement.autres_achats,
      total: to.incomeStatement.autres_achats.hasDetail
        ? to.incomeStatement.autres_achats.total
        : mergeNumericDataPercentages(from, to, p => p.incomeStatement.autres_achats.total),
      hasDetail: to.incomeStatement.autres_achats.hasDetail,
      fournitures_consommables: {
        ...from.incomeStatement.autres_achats.fournitures_consommables,
        lines: from.incomeStatement.autres_achats.fournitures_consommables.lines.map(fromLine => ({
          ...fromLine,
          required:
            to.incomeStatement.autres_achats.fournitures_consommables.lines.find(
              toLine => toLine.id === fromLine.id
            )?.required || fromLine.required,
          value: mergeNumericDataPercentages(
            from,
            to,
            p =>
              p.incomeStatement.autres_achats.fournitures_consommables.lines.find(
                toLine => toLine.id === fromLine.id
              )?.value || fromLine.value
          )
        }))
      },
      charges_externes: {
        ...from.incomeStatement.autres_achats.charges_externes,
        lines: from.incomeStatement.autres_achats.charges_externes.lines.map(fromLine => ({
          ...fromLine,
          required:
            to.incomeStatement.autres_achats.charges_externes.lines.find(
              toLine => toLine.id === fromLine.id
            )?.required || fromLine.required,
          value: mergeNumericDataPercentages(
            from,
            to,
            p =>
              p.incomeStatement.autres_achats.charges_externes.lines.find(
                toLine => toLine.id === fromLine.id
              )?.value || fromLine.value
          )
        }))
      },
      totalChargesVariables: to.incomeStatement.autres_achats.totalChargesVariables
    },
    taxes: {
      ...from.incomeStatement.taxes,
      total: to.incomeStatement.taxes.hasDetail
        ? to.incomeStatement.taxes.total
        : mergeNumericDataPercentages(from, to, p => p.incomeStatement.taxes.total),
      hasDetail: to.incomeStatement.taxes.hasDetail,
      cfe_cvae: mergeNumericDataPercentagesIfDefined(
        from,
        to,
        p => p.incomeStatement.taxes.cfe_cvae
      ),
      csg_crds: mergeNumericDataPercentagesIfDefined(
        from,
        to,
        p => p.incomeStatement.taxes.csg_crds
      ),
      taxe_apprentissage: mergeNumericDataPercentagesIfDefined(
        from,
        to,
        p => p.incomeStatement.taxes.taxe_apprentissage
      ),
      taxe_formation_professionnelle: mergeNumericDataPercentagesIfDefined(
        from,
        to,
        p => p.incomeStatement.taxes.taxe_formation_professionnelle
      ),
      taxes_diverses: mergeNumericDataPercentagesIfDefined(
        from,
        to,
        p => p.incomeStatement.taxes.taxes_diverses
      )
    },
    traitementsSalaires: mergeNumericDataPercentages(
      from,
      to,
      p => p.incomeStatement.traitementsSalaires
    ),
    cotisationsSocialesPatronales: mergeNumericDataPercentages(
      from,
      to,
      p => p.incomeStatement.cotisationsSocialesPatronales
    ),
    tauxDeChargesPatronales: to.incomeStatement.tauxDeChargesPatronales,
    cotisationsSocialesDirigeants: mergeNumericDataPercentages(
      from,
      to,
      p => p.incomeStatement.cotisationsSocialesDirigeants
    ),
    tauxDeChargesDirigeants: to.incomeStatement.tauxDeChargesDirigeants,
    autresChargesDePersonnel: mergeNumericDataPercentages(
      from,
      to,
      p => p.incomeStatement.autresChargesDePersonnel
    ),
    dotationsAmortissementsProvisions: mergeNumericDataPercentages(
      from,
      to,
      p => p.incomeStatement.dotationsAmortissementsProvisions
    ),
    autresChargesExploitation: mergeNumericDataPercentages(
      from,
      to,
      p => p.incomeStatement.autresChargesExploitation
    ),
    totalChargesExploitations: to.incomeStatement.totalChargesExploitations,
    resultatsExploitation: to.incomeStatement.resultatsExploitation,
    resultatFinancier: {
      ...from.incomeStatement.resultatFinancier,
      total: to.incomeStatement.resultatFinancier.hasDetail
        ? to.incomeStatement.resultatFinancier.total
        : mergeNumericDataPercentages(from, to, p => p.incomeStatement.resultatFinancier.total),
      hasDetail: to.incomeStatement.resultatFinancier.hasDetail,
      produitsFinanciers: mergeNumericDataPercentagesIfDefined(
        from,
        to,
        p => p.incomeStatement.resultatFinancier.produitsFinanciers
      ),
      reprisesSurProvisionsFinancieres: mergeNumericDataPercentagesIfDefined(
        from,
        to,
        p => p.incomeStatement.resultatFinancier.reprisesSurProvisionsFinancieres
      ),
      chargesFinancieres: mergeNumericDataPercentagesIfDefined(
        from,
        to,
        p => p.incomeStatement.resultatFinancier.chargesFinancieres
      ),
      dotationsAuxProvisionsFinancieres: mergeNumericDataPercentagesIfDefined(
        from,
        to,
        p => p.incomeStatement.resultatFinancier.dotationsAuxProvisionsFinancieres
      ),
      chargeInterets: mergeNumericDataPercentagesIfDefined(
        from,
        to,
        p => p.incomeStatement.resultatFinancier.chargeInterets
      )
    },
    resultatExceptionnel: {
      ...from.incomeStatement.resultatExceptionnel,
      total: to.incomeStatement.resultatExceptionnel.hasDetail
        ? to.incomeStatement.resultatExceptionnel.total
        : mergeNumericDataPercentages(from, to, p => p.incomeStatement.resultatExceptionnel.total),
      hasDetail: to.incomeStatement.resultatExceptionnel.hasDetail,
      produitsExceptionnels: mergeNumericDataPercentagesIfDefined(
        from,
        to,
        p => p.incomeStatement.resultatExceptionnel.produitsExceptionnels
      ),
      produitsCessionsActifs: mergeNumericDataPercentagesIfDefined(
        from,
        to,
        p => p.incomeStatement.resultatExceptionnel.produitsCessionsActifs
      ),
      reprisesSurProvisionsExceptionnelles: mergeNumericDataPercentagesIfDefined(
        from,
        to,
        p => p.incomeStatement.resultatExceptionnel.reprisesSurProvisionsExceptionnelles
      ),
      quotePartSubventionsVireesAuResultat: mergeNumericDataPercentagesIfDefined(
        from,
        to,
        p => p.incomeStatement.resultatExceptionnel.quotePartSubventionsVireesAuResultat
      ),
      chargesExceptionnelles: mergeNumericDataPercentagesIfDefined(
        from,
        to,
        p => p.incomeStatement.resultatExceptionnel.chargesExceptionnelles
      ),
      dotationsAuxProvisionsExceptionnelles: mergeNumericDataPercentagesIfDefined(
        from,
        to,
        p => p.incomeStatement.resultatExceptionnel.dotationsAuxProvisionsExceptionnelles
      ),
      valeursComptablesDesCessionsDactifs: mergeNumericDataPercentagesIfDefined(
        from,
        to,
        p => p.incomeStatement.resultatExceptionnel.valeursComptablesDesCessionsDactifs
      )
    },
    participationInteressement: mergeNumericDataPercentages(
      from,
      to,
      p => p.incomeStatement.participationInteressement
    ),
    reportRessourcesNonUtilisees: mergeNumericDataPercentages(
      from,
      to,
      p => p.incomeStatement.reportRessourcesNonUtilisees
    ),
    engagementARealiserRessourcesAffectees: mergeNumericDataPercentages(
      from,
      to,
      p => p.incomeStatement.engagementARealiserRessourcesAffectees
    ),
    impotsSurBeneficesInput: mergeNumericDataPercentages(
      from,
      to,
      p => p.incomeStatement.impotsSurBeneficesInput
    ),
    resultatNet: to.incomeStatement.resultatNet,
    totalChargesFixes: to.incomeStatement.totalChargesFixes,
    totalChargesVariables: to.incomeStatement.totalChargesVariables,
    tauxChargesVariables: to.incomeStatement.tauxChargesVariables,
    productionExercice: to.incomeStatement.productionExercice,
    margeGlobale: to.incomeStatement.margeGlobale,
    valeurAjoutee: to.incomeStatement.valeurAjoutee,
    excedentBrutExploitation: to.incomeStatement.excedentBrutExploitation
  }
})
