import React from "react"
import { NoteDictionary } from "./dictionary"
import { PeriodModel } from "../../pages/financial/PeriodModel"
import { CRLink } from "../../components/links/CRLink"
import { FinancialPlanLink } from "../../components/links/FinancialPlanLink"
import { AddFinancialPeriodRequestPeriodTypeEnum } from "../../client/backend-client/generated"

export const structureExistanteDictionary: NoteDictionary = {
  cr: {
    ongletName: "Compte de résultat",
    pageTitle: "Compte de résultat",
    linkText: "Compte de résultat"
  },
  bfr: {
    menuLabel: "BFR"
  },
  differencesSaisies: {
    targetTitle: "Données saisies dans le compte de résultat",
    crExplanation: (
      <div>
        À tout moment vous pouvez remplacer les données du <CRLink /> par celles issues de cette
        page.
      </div>
    ),
    crSuccessText: (
      <div>
        Vous pouvez consulter le <CRLink /> mis à jour.
      </div>
    ),
    pfExplanation: (
      <div>
        À tout moment vous pouvez remplacer les données du <FinancialPlanLink /> par celles issues
        de cette page.
      </div>
    ),
    pfSuccessText: (
      <div>
        Vous pouvez consulter le <FinancialPlanLink /> mis à jour.
      </div>
    ),
    masseSalarialeMoreInformation: (
      <p>
        Attention, les données du <CRLink /> ont peut-être été calculées plus précisément !.
      </p>
    )
  },
  noteSettings: {
    tvaTabLink: () => "settings#tabsexercices-tva",
    tabExerciceTitle: "Exercices",
    baseDateLabel: () => "Date de clôture du dernier exercice comptable disponible",
    addPeriodLabel: periodType =>
      periodType === AddFinancialPeriodRequestPeriodTypeEnum.PREVISIONNEL
        ? "Ajouter un exercice prévisionnel"
        : "Ajouter un exercice passé"
  },
  periodTitle: (p: PeriodModel) =>
    p.startDate.year() === p.endDate.year()
      ? `${p.startDate.year()}`
      : `${p.startDate.year()}-${p.endDate.year()}`,
  bilan: {
    ongletName: "Bilan passé",
    pageTitle: "Bilan passé",
    linkText: "Bilan passé"
  },
  exerciceCedantLabel: "Exercices passés",
  commentAnterieurLabel: "Commentaire - Comptes passés",
  benchmarkAnterieurPeriodLabel: "Exercice passé",
  operations: {
    ongletName: "Opérations futures",
    pageTitle: "Opérations futures",
    linkText: "Opérations futures"
  },
  pretsPasse: {
    ongletName: "Prêts passés",
    pageTitle: "Prêts passés",
    linkText: "Prêts passés"
  },
  pretTVADemarrage: {
    label: "Crédits relais TVA",
    button: "un crédit relais TVA"
  },
  noExerciceText: "Aucune date de clôture du dernier exercice n'a été renseignée",
  noExerciceLink:
    "Vous devez saisir une date de clôture du dernier exercice pour pouvoir créer vos exercices comptables dans l’onglet",
  evolutionPercentagePassedLabel: "% d'évolution des exercices passés"
}
