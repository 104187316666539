import { DebouncedState } from "use-debounce"
import { EffectCallback } from "react"

export const INPUT_DEBOUNCE_DEFAULT_DELAY = 200
export const SEARCH_DEBOUNCE_DEFAULT_DELAY = 1000
export const UPDATE_DEBOUNCE_DEFAULT_DELAY = 1300
export const LONG_UPDATE_DEBOUNCE_DEFAULT_DELAY = 1800

export const getFlushEffectCallback =
  (debounceState: DebouncedState<() => void>): EffectCallback =>
  () => {
    return () => {
      if (debounceState.isPending()) {
        debounceState.flush()
      }
    }
  }
