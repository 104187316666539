import React, { FC, useContext, useEffect, useRef, useState } from "react"
import { NoteTypeEnum, PdfPreferences } from "../../../client/backend-client/generated"
import { NoteContext } from "../../NotePage"
import { useUpdateNote } from "../../../queries/NoteQueries"
import { Button, Col, Flex, Modal, Row, Space, Typography } from "antd"
import { AppMetierSwitch } from "../../../components/AppMetierSwitch"
import { useDictionary } from "../../../utils/dictionary/dictionary"
import { retrievePercentageText } from "../../financial/income-statement/IncomeStatementForm"
import Loader from "../../../components/Loader/Loader"

const { Text } = Typography

export const PdfDialogContent: FC<{
  isOpen: boolean
  cancelClick: () => void
  generateFct: (pdfOptions: PdfPreferences) => void
}> = ({ isOpen, cancelClick, generateFct }) => {
  const dictionary = useDictionary()
  const { note, noteId, noteSettings } = useContext(NoteContext)
  const [pdfPreferences, setPdfPreferences] = useState<PdfPreferences>(note.pdfPreferences)
  const [savePrefs, setSavePrefs] = useState<boolean>(true)
  const [showSpinner, setShowSpinner] = useState<boolean>(false)

  const refs = useRef({
    isMounted: false
  })

  const updateNoteMutation = useUpdateNote(noteId)

  useEffect(() => {
    if (refs.current.isMounted) {
      if (savePrefs) {
        updateNoteMutation.mutate({
          noteId,
          pdfPreferences: pdfPreferences
        })
      }
    } else {
      refs.current.isMounted = true
    }
  }, [pdfPreferences, savePrefs])

  return (
    <Modal
      open={isOpen}
      width={750}
      closeIcon={false}
      destroyOnClose
      afterClose={() => {
        setShowSpinner(false)
      }}
      footer={[
        <div key="footer-buttons" style={{ marginTop: "30px", textAlign: "center" }}>
          {!showSpinner && (
            <Space direction="horizontal">
              <Button
                type="primary"
                shape="round"
                size="large"
                style={{
                  backgroundColor: "var(--main-violet)",
                  fontWeight: "bold"
                }}
                onClick={() => {
                  generateFct(pdfPreferences)
                  setShowSpinner(true)
                }}
              >
                Générer la note
              </Button>
              <Button shape="round" size="large" onClick={cancelClick}>
                Annuler
              </Button>
            </Space>
          )}
          {showSpinner && (
            <div>
              Veuillez patienter pendant la génération de la note pdf
              <br />
              <Loader />
            </div>
          )}
        </div>
      ]}
      style={{
        minWidth: "35%"
      }}
    >
      <Flex vertical>
        <Text
          style={{
            color: "var(--main-green-1)",
            fontSize: "20px",
            marginBottom: "15px",
            lineHeight: "26px"
          }}
        >
          Contenu de la note
        </Text>
        <Text style={{ paddingBottom: "15px" }}>
          Certaines rubriques sont optionnelles, cochez celles que vous souhaitez intégrer à la note
          PDF :
        </Text>
      </Flex>
      <Row style={{ padding: "0 15px 15px 15px" }}>
        <Col span={12}>
          <Flex vertical>
            <Text>Présentation</Text>
            <Text>Analyse du projet</Text>
            <AppMetierSwitch
              style={{ paddingLeft: "30px" }}
              checked={pdfPreferences.revelateurEngagement}
              onChange={() =>
                setPdfPreferences(pdfPreferences => ({
                  ...pdfPreferences,
                  revelateurEngagement: !pdfPreferences.revelateurEngagement
                }))
              }
              label={"Révélateur d'engagement"}
            />
          </Flex>
        </Col>
      </Row>
      <Row style={{ padding: "0 15px 15px 15px" }}>
        <Col span={12}>
          <Flex vertical>
            <Text strong>1. Évaluation du projet</Text>
            <Text>Environnement</Text>
            <Text>Positionnement</Text>
            <Text>Moyens</Text>
            <Text>Équipe</Text>
          </Flex>
        </Col>
        <Col span={12}>
          <Flex vertical>
            <Text strong>2. Analyse financière</Text>
            <Text>{dictionary.cr.ongletName}</Text>
            {note.type === NoteTypeEnum.REPRISE && pdfPreferences.showAnterieurPeriodsOption && (
              <AppMetierSwitch
                style={{ paddingLeft: "30px" }}
                checked={pdfPreferences.allAnterieursPeriods}
                onChange={() =>
                  setPdfPreferences(pdfPreferences => ({
                    ...pdfPreferences,
                    allAnterieursPeriods: !pdfPreferences.allAnterieursPeriods
                  }))
                }
                label={"Afficher tous les exercices du cédant"}
                />
            )}
            {pdfPreferences.showCaDetails && (
              <AppMetierSwitch
                style={{ paddingLeft: "30px" }}
                checked={pdfPreferences.caDetails}
                onChange={() =>
                  setPdfPreferences(pdfPreferences => ({
                    ...pdfPreferences,
                    caDetails: !pdfPreferences.caDetails
                  }))
                }
                label={"Détail du CA"}
                />
            )}
            {pdfPreferences.showSubventionDetails && (
              <AppMetierSwitch
                style={{ paddingLeft: "30px" }}
                checked={pdfPreferences.subventionDetails}
                onChange={() =>
                  setPdfPreferences(pdfPreferences => ({
                    ...pdfPreferences,
                    subventionDetails: !pdfPreferences.subventionDetails
                  }))
                }
                label={"Détail des subventions d'exploitation"}
              />
            )}
            <AppMetierSwitch
              style={{ paddingLeft: "30px" }}
              checked={pdfPreferences.sig}
              onChange={() =>
                setPdfPreferences(pdfPreferences => ({
                  ...pdfPreferences,
                  sig: !pdfPreferences.sig
                }))
              }
              label={"SIG"}
            />
            {pdfPreferences.showChargesDetails && (
              <AppMetierSwitch
                style={{ paddingLeft: "30px" }}
                checked={pdfPreferences.chargesDetails}
                onChange={() =>
                  setPdfPreferences(pdfPreferences => ({
                    ...pdfPreferences,
                    chargesDetails: !pdfPreferences.chargesDetails
                  }))
                }
                label={"Détail des charges"}
              />
            )}
            {noteSettings.referenceAmount !== "NONE" && (
              <AppMetierSwitch
                style={{ paddingLeft: "30px" }}
                checked={pdfPreferences.pourcentageCR}
                onChange={() =>
                  setPdfPreferences(pdfPreferences => ({
                    ...pdfPreferences,
                    pourcentageCR: !pdfPreferences.pourcentageCR
                  }))
                }
                label={retrievePercentageText(noteSettings.referenceAmount)}
              />
            )}
            <Text>Plan de financement</Text>
            <AppMetierSwitch
              style={{ paddingLeft: "30px" }}
              checked={pdfPreferences.indicateurs}
              onChange={() =>
                setPdfPreferences(pdfPreferences => ({
                  ...pdfPreferences,
                  indicateurs: !pdfPreferences.indicateurs
                }))
              }
              label={"Indicateurs financiers (ratios)"}
            />
            <AppMetierSwitch
              style={{ paddingLeft: "30px" }}
              checked={pdfPreferences.rappelBFR}
              onChange={() =>
                setPdfPreferences(pdfPreferences => ({
                  ...pdfPreferences,
                  rappelBFR: !pdfPreferences.rappelBFR
                }))
              }
              label={"Rappel BFR (ratios)"}
            />
            <AppMetierSwitch
              checked={pdfPreferences.benchmark}
              onChange={() =>
                setPdfPreferences(pdfPreferences => ({
                  ...pdfPreferences,
                  benchmark: !pdfPreferences.benchmark
                }))
              }
              label={"Benchmark"}
            />
            {pdfPreferences.showInvestissements && (
              <AppMetierSwitch
                checked={pdfPreferences.investissements}
                onChange={() =>
                  setPdfPreferences(pdfPreferences => ({
                    ...pdfPreferences,
                    investissements: !pdfPreferences.investissements
                  }))
                }
                label={"Investissements"}
              />
            )}
            {pdfPreferences.showBilanCedant &&
              (note.type === NoteTypeEnum.STRUCTURE_EXISTANTE ? (
                <Text>{dictionary.bilan.ongletName}</Text>
              ) : (
                <AppMetierSwitch
                  checked={pdfPreferences.bilanCedant}
                  onChange={() =>
                    setPdfPreferences(pdfPreferences => ({
                      ...pdfPreferences,
                      bilanCedant: !pdfPreferences.bilanCedant
                    }))
                  }
                  label={dictionary.bilan.ongletName}
                />
              ))}
          </Flex>
        </Col>
      </Row>
      <Flex justify={"center"} style={{ paddingTop: "10px" }}>
        <AppMetierSwitch
          style={{ fontWeight: "bold" }}
          checked={savePrefs}
          onChange={() => setSavePrefs(!savePrefs)}
          label={"Conserver ces choix pour le prochain export PDF de cette note."}
        />
      </Flex>
    </Modal>
  )
}
