import { InvolvementQuestionCategoryEnum } from "../../client/backend-client/generated"
import React from "react"
import EmploymentIcon from "../../assets/icons/EmploymentIcon"
import { INVOLVEMENT_CATEGORY_GROUP_RECORD } from "./InvolvementUtils"
import GovernanceIcon from "../../assets/icons/GovernanceIcon"
import EnvironmentIcon from "../../assets/icons/EnvironmentIcon"
import SocialIcon from "../../assets/icons/SocialIcon"
import TerritoryIcon from "../../assets/icons/TerritoryIcon"

export const getInvolvementIcon = (
  category: InvolvementQuestionCategoryEnum,
  iconSize: number,
  x?: number,
  y?: number,
  iconClassName?: string
): React.ReactNode => {
  const size = iconSize
  const color = INVOLVEMENT_CATEGORY_GROUP_RECORD[category].color

  switch (category) {
    case InvolvementQuestionCategoryEnum.EMPLOYMENT:
      return (
        <EmploymentIcon
          className={iconClassName}
          fill={color}
          width={size}
          height={size * 1.1}
          x={x}
          y={y}
        />
      )
    case InvolvementQuestionCategoryEnum.TERRITORY:
      return (
        <TerritoryIcon
          className={iconClassName}
          fill={color}
          width={size}
          height={size}
          x={x}
          y={y}
        />
      )
    case InvolvementQuestionCategoryEnum.SOCIAL:
      return (
        <SocialIcon className={iconClassName} fill={color} width={size} height={size} x={x} y={y} />
      )
    case InvolvementQuestionCategoryEnum.ENVIRONMENT:
      return (
        <EnvironmentIcon
          className={iconClassName}
          fill={color}
          width={size}
          height={size}
          x={x}
          y={y}
        />
      )
    case InvolvementQuestionCategoryEnum.GOVERNANCE:
      return (
        <GovernanceIcon
          className={iconClassName}
          fill={color}
          width={size}
          height={size}
          x={x}
          y={y}
        />
      )
    default:
      return <></>
  }
}
