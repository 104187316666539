import produce from "immer"
import {
  ApiIncomeStatement,
  ApiNumericData,
  IncomeStatementInput
} from "../client/backend-client/generated"

export type NumericDataWithDetail = {
  total: ApiNumericData
  hasDetail: boolean
}

const numericDataWithDetailToInput = <I extends NumericDataWithDetail, R>(data: I): R => {
  // @ts-expect-error : Type I is not assignable to type R
  return produce(data, draft => {
    // @ts-expect-error : The operand of a delete operator must be optional.
    delete draft.hasDetail
  })
}

export const incomeStatementToInput = (
  incomeStatement: ApiIncomeStatement
): IncomeStatementInput => ({
  chiffre_affaire: {
    total: incomeStatement.chiffre_affaire.total,
    detailLines: incomeStatement.chiffre_affaire.detailLines.lines
  },
  subventions_exploitation: incomeStatement.subventions_exploitation,
  subventions_exploitation_details: incomeStatement.subventions_exploitation_details && {
    total: incomeStatement.subventions_exploitation_details.total,
    detailLines: incomeStatement.subventions_exploitation_details.detailLines.lines
  },
  aides_postes: incomeStatement.aides_postes,
  autres_produits_exploitation: numericDataWithDetailToInput(
    incomeStatement.autres_produits_exploitation
  ),
  achat_marchandises: numericDataWithDetailToInput(incomeStatement.achat_marchandises),
  autres_achats: {
    total: incomeStatement.autres_achats.total,
    totalChargesVariables: incomeStatement.autres_achats.totalChargesVariables,
    charges_externes: incomeStatement.autres_achats.charges_externes.lines,
    fournitures_consommables: incomeStatement.autres_achats.fournitures_consommables.lines
  },
  taxes: numericDataWithDetailToInput(incomeStatement.taxes),
  traitementsSalaires: incomeStatement.traitementsSalaires,
  cotisationsSocialesPatronales: incomeStatement.cotisationsSocialesPatronales,
  cotisationsSocialesDirigeants: incomeStatement.cotisationsSocialesDirigeants,
  autresChargesDePersonnel: incomeStatement.autresChargesDePersonnel,
  dotationsAmortissementsProvisions: incomeStatement.dotationsAmortissementsProvisions,
  autresChargesExploitation: incomeStatement.autresChargesExploitation,
  resultatFinancier: numericDataWithDetailToInput(incomeStatement.resultatFinancier),
  resultatExceptionnel: numericDataWithDetailToInput(incomeStatement.resultatExceptionnel),
  participationInteressement: incomeStatement.participationInteressement,
  reportRessourcesNonUtilisees: incomeStatement.reportRessourcesNonUtilisees,
  engagementARealiserRessourcesAffectees: incomeStatement.engagementARealiserRessourcesAffectees,
  impotsSurBeneficesInput: incomeStatement.impotsSurBeneficesInput
})
