/* tslint:disable */
/* eslint-disable */
/**
 * Appli métier
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: Yoda 1 & 2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { OperationProductDelegationLine } from "./operation-product-delegation-line"

/**
 *
 * @export
 * @interface OperationProductDelegation
 */
export interface OperationProductDelegation {
  /**
   *
   * @type {number}
   * @memberof OperationProductDelegation
   */
  id?: number
  /**
   *
   * @type {Array<string>}
   * @memberof OperationProductDelegation
   */
  decisionMakers: Array<OperationProductDelegationDecisionMakersEnum>
  /**
   *
   * @type {Array<OperationProductDelegationLine>}
   * @memberof OperationProductDelegation
   */
  lines: Array<OperationProductDelegationLine>
}

/**
 * @export
 * @enum {string}
 */
export enum OperationProductDelegationDecisionMakersEnum {
  FA = "FA",
  EXTERNAL = "EXTERNAL",
  UNKNOWN = "UNKNOWN"
}
