import React from "react"
import { NoteDictionary } from "./dictionary"
import { PeriodModel } from "../../pages/financial/PeriodModel"
import { CRLink } from "../../components/links/CRLink"
import { FinancialPlanLink } from "../../components/links/FinancialPlanLink"

export const creationDictionary: NoteDictionary = {
  cr: {
    ongletName: "CR prévisionnel",
    pageTitle: "Compte de résultat prévisionnel",
    linkText: "CR prévisionnel"
  },
  bfr: {
    menuLabel: "BFR prévisionnel"
  },
  differencesSaisies: {
    targetTitle: "Données saisies dans le Compte de résultat",
    crExplanation: (
      <div>
        À tout moment vous pouvez remplacer les données du <CRLink /> par celles issues de cette
        page.
      </div>
    ),
    crSuccessText: (
      <div>
        Vous pouvez consulter le <CRLink /> mis à jour.
      </div>
    ),
    pfExplanation: (
      <div>
        À tout moment vous pouvez remplacer les données du <FinancialPlanLink /> par celles issues
        de cette page.
      </div>
    ),
    pfSuccessText: (
      <div>
        Vous pouvez consulter le <FinancialPlanLink /> mis à jour.
      </div>
    ),
    masseSalarialeMoreInformation: (
      <p>
        Attention, les données du <CRLink /> ont peut-être été calculées plus précisément !.
      </p>
    )
  },
  noteSettings: {
    tvaTabLink: () => "settings#tabsexercices-tva",
    tabExerciceTitle: "Prévisionnel",
    baseDateLabel: () => "Date de début d'activité",
    addPeriodLabel: () => "Ajouter un exercice"
  },
  periodTitle: (p: PeriodModel, index: number) => `Exercice n°${index + 1}`,
  bilan: {
    ongletName: "",
    pageTitle: "",
    linkText: ""
  },
  exerciceCedantLabel: "",
  commentAnterieurLabel: "",
  benchmarkAnterieurPeriodLabel: "",
  operations: {
    ongletName: "Opérations futures",
    pageTitle: "Opérations futures",
    linkText: "Opérations futures"
  },
  pretsPasse: {
    ongletName: "",
    pageTitle: "",
    linkText: ""
  },
  pretTVADemarrage: {
    label: "Prêt CT démarrage",
    button: "un prêt CT démarrage"
  },
  noExerciceText: "Aucune date de début d'activité n'a été renseignée",
  noExerciceLink:
    "Vous devez saisir une date de début d’activité pour pouvoir créer vos exercices comptables dans l’onglet",
  evolutionPercentagePassedLabel: ""
}
