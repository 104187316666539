import React, { FC, SVGProps } from "react"

const TerritoryIcon: FC<SVGProps<SVGSVGElement>> = ({
  height = "16",
  width = "16",
  color = "black",
  fill = "black",
  viewBox = "0 0 150 150",
  ...props
}) => {
  return (
    <svg
      id="Calque_1"
      data-name="Calque 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox}
      color={color}
      fill={fill}
      height={height}
      width={width}
      {...props}
    >
      <path d="M105.58,99h0L91.17,84.62a25.94,25.94,0,0,0,3.56-13.1,26.29,26.29,0,0,0-14.52-23.4,12.16,12.16,0,0,0-23.21,0A26.11,26.11,0,1,0,81.74,94.06l14.39,14.39A6.68,6.68,0,0,0,105.58,99ZM63.24,50.78h0a5.5,5.5,0,0,1,3.84-4.25h0l.15,0a5.63,5.63,0,0,1,1.57-.16,5.09,5.09,0,0,1,1.26.2,5.16,5.16,0,0,1,1.79.88,5.47,5.47,0,0,1-3.26,9.86,4.94,4.94,0,0,1-1.1-.12A5.42,5.42,0,0,1,63.29,53a4.8,4.8,0,0,1,0-2.25Zm-2,12.48c.17.27.36.54.53.81l.42.65.54.79.4.57c.18.28.38.55.56.82l.3.4.62.87a.85.85,0,0,1,.14.19c1.35,1.81,2.41,3.16,2.74,3.55a1.66,1.66,0,0,0,.49.39,1.35,1.35,0,0,0,.49.11s.08,0,.12,0l.07,0a1.38,1.38,0,0,0,.71-.25,1.15,1.15,0,0,0,.33-.26c.32-.39,1.38-1.73,2.72-3.54,0-.07.09-.13.15-.2l.64-.89c.08-.13.17-.24.27-.38.2-.29.41-.57.61-.88l.34-.47.59-.89.37-.57c.2-.28.38-.58.57-.87l.39-.63c.19-.28.36-.58.54-.88s.26-.44.4-.66.32-.57.48-.85l.38-.71c.16-.27.3-.55.44-.83s.24-.47.35-.71.26-.54.37-.8.22-.49.31-.73.22-.5.3-.74.17-.48.24-.72.16-.47.23-.7,0-.23.08-.35a21.25,21.25,0,1,1-23.77,0,2.61,2.61,0,0,0,.08.36c.06.21.14.44.21.65s.16.5.26.75.18.48.28.71.21.51.33.78.24.49.35.75.26.51.38.76.27.51.41.78.27.51.41.77.3.52.46.79.29.48.44.74.32.54.49.8Z" />
    </svg>
  )
}

export default TerritoryIcon
