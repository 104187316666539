import dayjs, { Dayjs } from "dayjs"

export type DaysjsFormat = "DD/MM/YYYY" | "YYYY-MM-DD" | "YYYY"

const DEFAULT_FORMAT = "dddd DD MMMM à HH:mm"

export const formatDayjs = (date: Dayjs, format: DaysjsFormat = "DD/MM/YYYY") =>
  date.locale("fr").format(format)

export const getMinDate = (
  first: Dayjs | undefined | null,
  second: Dayjs | undefined | null
): Dayjs | undefined => {
  if (!!first && !!second) {
    return dayjs.min([first, second]) ?? undefined
  }
  return first ?? second ?? undefined
}

export const dateIsBetween = (date: Dayjs, lowBoundary: Dayjs, highBoundary: Dayjs) => {
  return (
    date.isSame(lowBoundary, "day") ||
    date.isSame(highBoundary, "day") ||
    (date.isAfter(lowBoundary, "day") && date.isBefore(highBoundary, "day"))
  )
}

export const localBrowserDayjs = (date: string, format?: string) => {
  return dayjs
    .utc(date)
    .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
    .format(format || DEFAULT_FORMAT)
}
