import React, { ChangeEvent, FC, useEffect, useRef, useState } from "react"
import { handleUserKeyPress } from "../../utils/keyboard-navigation"
import { ApiNumericData } from "../../client/backend-client/generated"
import {
  computeValue,
  formatAsNumber,
  normalizeDecimalSeparator
} from "../../utils/formatNumber/FormatNumber"
import { Stylable } from "../../utils/StyledUtils"
import { Input } from "antd"
import "./NumericDataInput.scss"
import { CalculatorOutlined } from "@ant-design/icons"
import { useDebouncedCallback } from "use-debounce"
import { INPUT_DEBOUNCE_DEFAULT_DELAY } from "../../utils/DebounceUtils"

interface NumericDataInputProps {
  numericData?: ApiNumericData | null
  /**
   *
   * @param numericData the updated ApiNumericData
   * @param valid is true if the formula is valid, false otherwise
   */
  onChange: (numericData: ApiNumericData) => void
  showComputations: boolean
  isCalculated: boolean
  isEuro?: boolean
  isPourcentage?: boolean
  status?: "" | "error" | "warning"
  disabled?: boolean
  name?: string
}

const isComputation = (numericData?: ApiNumericData | null) => {
  if (!numericData || !numericData.detail || Number(numericData.detail) === numericData.value) {
    return false
  }

  const displayAsString = numericData.detail.toString()
  return (
    (displayAsString.indexOf("+") +
      displayAsString.indexOf("-") +
      displayAsString.indexOf("*") +
      displayAsString.indexOf("/")) /
      4 >
    -1
  )
}

const isValid = (numericData?: ApiNumericData | null) =>
  !numericData ||
  !numericData.detail ||
  !isComputation(numericData) ||
  computeValue(numericData.detail) !== undefined

const getValue = (numericData: ApiNumericData, isCalculated: boolean) => {
  const result =
    numericData.value !== null && numericData.value !== undefined
      ? numericData.value === 0 && (numericData.detail === null || numericData.detail === "")
        ? isCalculated
          ? numericData.value.toString()
          : ""
        : numericData.value.toString()
      : ""
  return formatAsNumber(result, 2)
}

const getDetail = (numericData: ApiNumericData) => {
  return numericData.detail ? numericData.detail : ""
}

/**
 *
 * @param numericData the ApiNumericData to display
 * @param onChange called when the user leaves focus from the field
 * @param disable disable input
 * @param showComputations
 * @constructor
 */
export const NumericDataInput: FC<NumericDataInputProps & Stylable> = ({
  numericData,
  onChange,
  showComputations,
  isCalculated,
  isEuro = true,
  isPourcentage = false,
  status = "",
  disabled = false,
  name = ""
}) => {
  const [data, setData] = useState(numericData)
  const [isOperation, setIsOperation] = useState(isComputation(numericData))
  const inKiloEuros = !!numericData?.kiloEuros
  const [computationDisplayed, setComputationDisplayed] = useState(false)
  const [valid, setValid] = useState(isValid(numericData))
  const [display, setDisplay] = useState<string>(
    numericData ? (valid ? getValue(numericData, isCalculated) : getDetail(numericData)) : ""
  )
  const [leaveFocus, setLeaveFocus] = useState(true)

  useEffect(() => {
    window.addEventListener("keydown", handleUserKeyPress)

    return () => {
      window.removeEventListener("keydown", handleUserKeyPress)
    }
  }, [])

  const refs = useRef({
    isMounted: false
  })

  const debouncedOnChange = useDebouncedCallback(() => {
    if (refs.current.isMounted) {
      if (data) {
        onChange(data)
      }
    } else {
      refs.current.isMounted = true
    }
  }, INPUT_DEBOUNCE_DEFAULT_DELAY)

  useEffect(() => debouncedOnChange(), [debouncedOnChange, data])

  const unit = () => (isEuro ? (inKiloEuros ? "K€" : "€") : isPourcentage ? " %" : "j")

  const onFocus = () => {
    if (data && data.detail) {
      if (!data.value && data.value !== 0) {
        setDisplay("")
      } else {
        setDisplay(normalizeDecimalSeparator(data.detail))
      }
    }
    setLeaveFocus(false)
  }

  const getToBeDisplayed = (data?: ApiNumericData | null) => {
    if (data) {
      const detail = getDetail(data)
      if (!isOperation || detail === undefined || detail === null || detail === "") {
        return getValue(data, false)
      }
      return detail
    }
    return ""
  }

  if (showComputations && !computationDisplayed) {
    const toBeDisplayed = getToBeDisplayed(data)
    setDisplay(toBeDisplayed)
    setComputationDisplayed(true)
  }
  if (!showComputations && computationDisplayed) {
    setDisplay(data ? getValue(data, isCalculated) : "")
    setComputationDisplayed(false)
  }

  const updateData = (value: string) => {
    const sanitizedValue = value.replace(/,/g, ".").replace(/\s/g, "")
    const numericValue = computeValue(sanitizedValue)
    const newData: ApiNumericData = {
      kiloEuros: isEuro ? (numericData?.kiloEuros ? numericData.kiloEuros : false) : false,
      value: numericValue,
      detail: sanitizedValue
    }
    setData(newData)
    setIsOperation(isComputation(newData))
    setValid(isValid(newData))
  }

  const onLeaveFocus = () => {
    setLeaveFocus(true)
    if (valid && data && !computationDisplayed) {
      setDisplay(getValue(data, isCalculated))
    }
  }

  return (
    <Input
      status={!valid ? "error" : status}
      className={"numeric-data-input"}
      disabled={disabled || isCalculated}
      onFocus={onFocus}
      onBlur={onLeaveFocus}
      onChange={(event: ChangeEvent<HTMLInputElement>) => {
        const eventValue = normalizeDecimalSeparator(event.target.value)
        setDisplay(eventValue)
        if (!leaveFocus) {
          updateData(eventValue)
        }
      }}
      value={display === null ? undefined : display}
      suffix={display ? <span>{unit()}</span> : <span />}
      prefix={isOperation && <CalculatorOutlined />}
      name={name}
    />
  )
}
