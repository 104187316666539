import React from "react"
import { PeriodModel } from "./PeriodModel"

interface PeriodsContextI {
  previousPeriods: PeriodModel[]
  forecastPeriods: PeriodModel[]
  intermediaryPeriod?: PeriodModel
}

export const PeriodsContext = React.createContext<PeriodsContextI>({} as PeriodsContextI)
