import React, { FC, useContext, useEffect, useState } from "react"
import { NoteContext } from "../../NotePage"
import Loader from "../../../components/Loader/Loader"
import { ProjectContext } from "../../ProjectWrapper"
import { FilePdfOutlined, HomeOutlined } from "@ant-design/icons"
import { Breadcrumb, Button, Col, Row, Typography } from "antd"
import { PresentationHeader } from "./PresentationHeader"
import { PresentationBody } from "./PresentationBody"
import { useNoteGeneratePdf } from "../../../queries/NoteQueries"
import { PdfDialogContent } from "./PdfDialogContent"
import { PdfPreferences } from "../../../client/backend-client/generated"
import { PdfSuccessModal } from "../../../components/PdfSuccessModal/PdfSuccessModal"
import { useGetAssociation, useGetCollaborators } from "../../../queries/AssociationQueries"
import { useGetStructure } from "../../../queries/StructureQueries"

const { Text, Title } = Typography

export const Presentation: FC = () => {
  const { projectName, baseNotePath } = useContext(NoteContext)
  const { associationId, projectId, structureId } = useContext(ProjectContext)
  const { isLoading: isStructureLoading, data: structure } = useGetStructure(structureId)
  const { isLoading: isAssociationLoading, data: association } = useGetAssociation(associationId)
  const { isLoading: areCollaboratorsLoading, data: collaborators } =
    useGetCollaborators(associationId)

  const [isOpenModalPdfPreferences, setOpenModalPdfPreferences] = useState(false)
  const [linkPdf, setLinkPdf] = useState<string>("")
  const [pdfSuccess, setPdfSuccess] = useState(false)

  const {
    isSuccess: isSuccessGeneratePdf,
    isError,
    mutate: generatePdfMutation,
    data: pdfLink
  } = useNoteGeneratePdf()
  const generatePdf = (pdfOptions: PdfPreferences) => {
    generatePdfMutation({
      projectId: projectId,
      notePdfOptions: pdfOptions
    })
  }

  useEffect(() => {
    if (isSuccessGeneratePdf && pdfLink) {
      setOpenModalPdfPreferences(false)
      setLinkPdf(pdfLink.link)
      // reason for the setTimeout below : https://github.com/facebook/react/issues/17355 / https://stackoverflow.com/a/76945127
      setTimeout(() => window.open(pdfLink.link, "_blank"), 0)
      setPdfSuccess(true)
    }
  }, [isSuccessGeneratePdf, isError])

  return !isAssociationLoading && !areCollaboratorsLoading && !isStructureLoading ? (
    <>
      <Breadcrumb
        className="breadcrumb"
        items={[
          { title: <HomeOutlined /> },
          {
            href: `${baseNotePath}/presentation`,
            title: projectName
          },
          { title: "Présentation" }
        ]}
      />
      <Row justify="space-between" align="middle" gutter={[16, 16]} style={{marginBottom: "1em"}}>
        <Col>
          <Title style={{marginBottom: 0}}>{projectName}</Title>
        </Col>
        <Col>
          <Button
            onClick={() => setOpenModalPdfPreferences(!isOpenModalPdfPreferences)}
            title="Générer la note PDF"
            type="primary"
            shape="round"
            size="large"
            style={{ backgroundColor: "var(--main-violet)" }}
          >
            <Text strong style={{ color: "white" }}>
              Générer la note PDF
            </Text>
            <FilePdfOutlined />
          </Button>
          <PdfDialogContent
            isOpen={isOpenModalPdfPreferences}
            cancelClick={() => setOpenModalPdfPreferences(false)}
            generateFct={generatePdf}
          />
          <PdfSuccessModal
            pdfSuccess={pdfSuccess}
            linkPdf={linkPdf}
            successText="Votre note pdf a bien été générée."
            linkText="Si la note pdf ne s'ouvre pas automatiquement, retrouvez là"
            onClose={() => setPdfSuccess(false)}
          />
        </Col>
      </Row>
      {association && collaborators && (
        <PresentationHeader association={association} collaborators={collaborators} />
      )}
      {structure && <PresentationBody structure={structure} />}
    </>
  ) : (
    <Loader />
  )
}
