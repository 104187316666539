import React, { FC, ReactNode } from "react"
import { Button, Modal, Typography } from "antd"

const { Text } = Typography

export const UpdateDialog: FC<{
  isOpen: boolean
  dialogBody: ReactNode
  cancelClick: () => void
  updateFunction: () => void
}> = ({ isOpen, dialogBody, cancelClick, updateFunction }) => (
  <Modal
    open={isOpen}
    title={"Attention"}
    onCancel={cancelClick}
    footer={[
      <Button key="cancel-button" onClick={cancelClick}>
        Annuler
      </Button>,
      <Button key="confirm-button" type="primary" onClick={updateFunction}>
        Confirmer
      </Button>
    ]}
  >
    <Text>{dialogBody}</Text>
  </Modal>
)
