/* tslint:disable */
/* eslint-disable */
/**
 * Appli métier
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: Yoda 1 & 2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from "axios"
import { Configuration } from "../configuration"
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction
} from "../common"
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from "../base"
// @ts-ignore
import { StructureDetailed } from "../model"
// @ts-ignore
import { UpdateStructureRequest } from "../model"
/**
 * StructureResourceApi - axios parameter creator
 * @export
 */
export const StructureResourceApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get regis structure by id
     * @param {string} structureId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStructure: async (
      structureId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'structureId' is not null or undefined
      assertParamExists("getStructure", "structureId", structureId)
      const localVarPath = `/structures/{structureId}`.replace(
        `{${"structureId"}}`,
        encodeURIComponent(String(structureId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary Update regis structure
     * @param {string} structureId
     * @param {number} projectId
     * @param {UpdateStructureRequest} updateStructureRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateStructure: async (
      structureId: string,
      projectId: number,
      updateStructureRequest: UpdateStructureRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'structureId' is not null or undefined
      assertParamExists("updateStructure", "structureId", structureId)
      // verify required parameter 'projectId' is not null or undefined
      assertParamExists("updateStructure", "projectId", projectId)
      // verify required parameter 'updateStructureRequest' is not null or undefined
      assertParamExists("updateStructure", "updateStructureRequest", updateStructureRequest)
      const localVarPath = `/structures/{structureId}/project/{projectId}`
        .replace(`{${"structureId"}}`, encodeURIComponent(String(structureId)))
        .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateStructureRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * StructureResourceApi - functional programming interface
 * @export
 */
export const StructureResourceApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = StructureResourceApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @summary Get regis structure by id
     * @param {string} structureId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getStructure(
      structureId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StructureDetailed>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getStructure(structureId, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Update regis structure
     * @param {string} structureId
     * @param {number} projectId
     * @param {UpdateStructureRequest} updateStructureRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateStructure(
      structureId: string,
      projectId: number,
      updateStructureRequest: UpdateStructureRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StructureDetailed>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateStructure(
        structureId,
        projectId,
        updateStructureRequest,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    }
  }
}

/**
 * StructureResourceApi - factory interface
 * @export
 */
export const StructureResourceApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = StructureResourceApiFp(configuration)
  return {
    /**
     *
     * @summary Get regis structure by id
     * @param {string} structureId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStructure(structureId: string, options?: any): AxiosPromise<StructureDetailed> {
      return localVarFp.getStructure(structureId, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Update regis structure
     * @param {string} structureId
     * @param {number} projectId
     * @param {UpdateStructureRequest} updateStructureRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateStructure(
      structureId: string,
      projectId: number,
      updateStructureRequest: UpdateStructureRequest,
      options?: any
    ): AxiosPromise<StructureDetailed> {
      return localVarFp
        .updateStructure(structureId, projectId, updateStructureRequest, options)
        .then(request => request(axios, basePath))
    }
  }
}

/**
 * Request parameters for getStructure operation in StructureResourceApi.
 * @export
 * @interface StructureResourceApiGetStructureRequest
 */
export interface StructureResourceApiGetStructureRequest {
  /**
   *
   * @type {string}
   * @memberof StructureResourceApiGetStructure
   */
  readonly structureId: string
}

/**
 * Request parameters for updateStructure operation in StructureResourceApi.
 * @export
 * @interface StructureResourceApiUpdateStructureRequest
 */
export interface StructureResourceApiUpdateStructureRequest {
  /**
   *
   * @type {string}
   * @memberof StructureResourceApiUpdateStructure
   */
  readonly structureId: string

  /**
   *
   * @type {number}
   * @memberof StructureResourceApiUpdateStructure
   */
  readonly projectId: number

  /**
   *
   * @type {UpdateStructureRequest}
   * @memberof StructureResourceApiUpdateStructure
   */
  readonly updateStructureRequest: UpdateStructureRequest
}

/**
 * StructureResourceApi - object-oriented interface
 * @export
 * @class StructureResourceApi
 * @extends {BaseAPI}
 */
export class StructureResourceApi extends BaseAPI {
  /**
   *
   * @summary Get regis structure by id
   * @param {StructureResourceApiGetStructureRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StructureResourceApi
   */
  public getStructure(
    requestParameters: StructureResourceApiGetStructureRequest,
    options?: AxiosRequestConfig
  ) {
    return StructureResourceApiFp(this.configuration)
      .getStructure(requestParameters.structureId, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Update regis structure
   * @param {StructureResourceApiUpdateStructureRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StructureResourceApi
   */
  public updateStructure(
    requestParameters: StructureResourceApiUpdateStructureRequest,
    options?: AxiosRequestConfig
  ) {
    return StructureResourceApiFp(this.configuration)
      .updateStructure(
        requestParameters.structureId,
        requestParameters.projectId,
        requestParameters.updateStructureRequest,
        options
      )
      .then(request => request(this.axios, this.basePath))
  }
}
