import React, { FC, useContext } from "react"
import { Navigate } from "react-router"

import Loader from "../../components/Loader/Loader"
import { KeycloakContext } from "../../auth/KeycloakContext"

export interface RouteDefinition {
  path: string
  Component: React.FunctionComponent
  secured?: boolean
}

export const RouteWithSubRoutes: FC<RouteDefinition> = ({
  secured,
  Component
}: RouteDefinition) => {
  const { authStatus } = useContext(KeycloakContext)

  const waitAuth = secured && authStatus === "PENDING"
  const validAuth = !secured || authStatus === "AUTHENTICATED"

  if (waitAuth) {
    return <Loader label={"Chargement ..."} />
  }

  return (
    <>
      {!validAuth && <Navigate to={"/unauthorized"} />}
      <Component />
    </>
  )
}
