import React, { Dispatch, FC, SetStateAction, useContext, useState } from "react"

import { InvolvementCheckPage } from "./InvolvementCheckPage"
import { NoteContext } from "../NotePage"
import { InvolvementQuestion, Result } from "../../client/backend-client/generated"
import Loader from "../../components/Loader/Loader"
import { useGetInvolvementCheckResult, useGetInvolvementQuestions } from "./InvolvementCheckQueries"

interface InvolvementCheckWrapperContextI {
  questions: Array<InvolvementQuestion>
  involvementCheckResult: Result
  forceV2: boolean

  setForceV2: Dispatch<SetStateAction<boolean>>
}

export const InvolvementCheckWrapperContext = React.createContext<InvolvementCheckWrapperContextI>(
  {} as InvolvementCheckWrapperContextI
)

export const InvolvementCheckPageWrapper: FC = () => {
  const { noteId } = useContext(NoteContext)
  const [forceV2, setForceV2] = useState(false)

  const { isLoading: areQuestionsLoading, data: questions } = useGetInvolvementQuestions(
    noteId,
    forceV2
  )

  const { isLoading: isInvolvementCheckResultLoading, data: involvementCheckResult } =
    useGetInvolvementCheckResult(noteId, forceV2)

  if (areQuestionsLoading || isInvolvementCheckResultLoading) {
    return <Loader />
  }

  return (
    <>
      {!!involvementCheckResult && !!questions && (
        <InvolvementCheckWrapperContext.Provider
          value={{
            questions,
            involvementCheckResult,
            forceV2,
            setForceV2
          }}
        >
          <InvolvementCheckPage />
        </InvolvementCheckWrapperContext.Provider>
      )}
    </>
  )
}
