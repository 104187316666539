import Keycloak from "keycloak-js"
import React, { useEffect, useRef, useState } from "react"

import Loader from "../components/Loader/Loader"
import environment from "../config/environment"
import { Maybe } from "../utils/utils"

export interface UserModel {
  attributes: {
    picture: Array<string>
  }
  email: string
  emailVerified: boolean
  firstName: string
  id: string
  lastName: string
  roles?: string[]
  userProfilesMetaData: {
    attributes: Array<{
      displayName: string
      name: string
      readOnly: boolean
      required: boolean
    }>
  }
  username: string
}

type AuthState = "AUTHENTICATED" | "NOT_AUTHENTICATED" | "PENDING" | "CONNECTION_ERROR"

const keycloak = new Keycloak({
  url: environment.AUTH_URL,
  realm: "france-active-internal",
  clientId: "beta-front-yoda"
})

interface IKeycloakContext {
  user?: Maybe<UserModel>
  keycloak: Keycloak
  authStatus: AuthState
  updateToken: () => Promise<void>
}

export const KeycloakContext = React.createContext<IKeycloakContext>({} as IKeycloakContext)

export const KeycloakProvider = ({ children }: { children: React.JSX.Element }) => {
  const [user, setUser] = useState<Maybe<UserModel>>()
  const [authStatus, setStatus] = useState<AuthState>("PENDING")
  const didKeycloakInit = useRef(false)

  const updateToken = async () => {
    try {
      await keycloak.updateToken(400)
    } catch (error) {
      await keycloak.logout()
    }
  }

  const initKeycloak = async () => {
    try {
      if (!didKeycloakInit.current) {
        didKeycloakInit.current = true
        const authenticated = await keycloak.init({
          onLoad: "login-required",
          checkLoginIframe: false
        })
        if (!authenticated) {
          setStatus("NOT_AUTHENTICATED")
          return
        }

        const userProfile = await keycloak.loadUserProfile()
        // @ts-expect-error : types of property attributes are incompatible.
        setUser({
          ...user,
          ...userProfile,
          id: keycloak.subject!,
          roles: keycloak.tokenParsed?.realm_access?.roles
        })
        setStatus("AUTHENTICATED")

        setInterval(updateToken, 300000)
      }
    } catch (error) {
      setStatus("CONNECTION_ERROR")
      console.error("Authentication error : " + error)
    }
  }

  useEffect(() => {
    initKeycloak()
  }, [])

  if (authStatus === "PENDING") {
    return <Loader label={"Chargement du profil"} />
  }

  return (
    <KeycloakContext.Provider value={{ keycloak, user, authStatus, updateToken }}>
      {children}
    </KeycloakContext.Provider>
  )
}
