/* tslint:disable */
/* eslint-disable */
/**
 * Appli métier
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: Yoda 1 & 2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from "axios"
import { Configuration } from "../configuration"
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction
} from "../common"
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from "../base"
// @ts-ignore
import { AddFinancialPeriodRequest } from "../model"
// @ts-ignore
import { ApiAllPeriods } from "../model"
// @ts-ignore
import { ApiFinancialPeriod } from "../model"
// @ts-ignore
import { InitFinancialPeriodsRequest } from "../model"
// @ts-ignore
import { SaveFinancialPeriodRequest } from "../model"
// @ts-ignore
import { UpdateFinancialPeriodDatesRequest } from "../model"
/**
 * FinancialPeriodResourceV2Api - axios parameter creator
 * @export
 */
export const FinancialPeriodResourceV2ApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @summary Add a financial period
     * @param {AddFinancialPeriodRequest} addFinancialPeriodRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addFinancialPeriod: async (
      addFinancialPeriodRequest: AddFinancialPeriodRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'addFinancialPeriodRequest' is not null or undefined
      assertParamExists(
        "addFinancialPeriod",
        "addFinancialPeriodRequest",
        addFinancialPeriodRequest
      )
      const localVarPath = `/v2.0/financialPeriods`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        addFinancialPeriodRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary Delete a financial period
     * @param {string} financialPeriodId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteFinancialPeriod: async (
      financialPeriodId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'financialPeriodId' is not null or undefined
      assertParamExists("deleteFinancialPeriod", "financialPeriodId", financialPeriodId)
      const localVarPath = `/v2.0/financialPeriods/{financialPeriodId}`.replace(
        `{${"financialPeriodId"}}`,
        encodeURIComponent(String(financialPeriodId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary Fixes financial periods details entry id problems
     * @param {string} noteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fixFinancialPeriodsDetails: async (
      noteId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'noteId' is not null or undefined
      assertParamExists("fixFinancialPeriodsDetails", "noteId", noteId)
      const localVarPath = `/v2.0/financialPeriods/reinit-details`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      if (noteId !== undefined) {
        localVarQueryParameter["noteId"] = noteId
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary Get all financial periods by note id
     * @param {string} noteId
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    getApiAllPeriods: async (
      noteId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'noteId' is not null or undefined
      assertParamExists("getApiAllPeriods", "noteId", noteId)
      const localVarPath = `/v2.0/financialPeriods`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      if (noteId !== undefined) {
        localVarQueryParameter["noteId"] = noteId
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary Get all financial periods by note id
     * @param {string} noteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFinancialPeriods: async (
      noteId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'noteId' is not null or undefined
      assertParamExists("getFinancialPeriods", "noteId", noteId)
      const localVarPath = `/v2.0/financialPeriods/raw`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      if (noteId !== undefined) {
        localVarQueryParameter["noteId"] = noteId
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary Init financial periods
     * @param {InitFinancialPeriodsRequest} initFinancialPeriodsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    initFinancialPeriods: async (
      initFinancialPeriodsRequest: InitFinancialPeriodsRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'initFinancialPeriodsRequest' is not null or undefined
      assertParamExists(
        "initFinancialPeriods",
        "initFinancialPeriodsRequest",
        initFinancialPeriodsRequest
      )
      const localVarPath = `/v2.0/financialPeriods/init`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        initFinancialPeriodsRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary Shift first forecast period to last previous period
     * @param {string} noteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    shiftFirstForecastToLastPreviousPeriod: async (
      noteId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'noteId' is not null or undefined
      assertParamExists("shiftFirstForecastToLastPreviousPeriod", "noteId", noteId)
      const localVarPath = `/v2.0/financialPeriods/shift-first-forecast`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      if (noteId !== undefined) {
        localVarQueryParameter["noteId"] = noteId
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary Update a financial period
     * @param {string} financialPeriodId
     * @param {SaveFinancialPeriodRequest} saveFinancialPeriodRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateFinancialPeriod: async (
      financialPeriodId: string,
      saveFinancialPeriodRequest: SaveFinancialPeriodRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'financialPeriodId' is not null or undefined
      assertParamExists("updateFinancialPeriod", "financialPeriodId", financialPeriodId)
      // verify required parameter 'saveFinancialPeriodRequest' is not null or undefined
      assertParamExists(
        "updateFinancialPeriod",
        "saveFinancialPeriodRequest",
        saveFinancialPeriodRequest
      )
      const localVarPath = `/v2.0/financialPeriods/{financialPeriodId}`.replace(
        `{${"financialPeriodId"}}`,
        encodeURIComponent(String(financialPeriodId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        saveFinancialPeriodRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary Update a financial period\'s dates
     * @param {string} financialPeriodId
     * @param {UpdateFinancialPeriodDatesRequest} updateFinancialPeriodDatesRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateFinancialPeriodDates: async (
      financialPeriodId: string,
      updateFinancialPeriodDatesRequest: UpdateFinancialPeriodDatesRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'financialPeriodId' is not null or undefined
      assertParamExists("updateFinancialPeriodDates", "financialPeriodId", financialPeriodId)
      // verify required parameter 'updateFinancialPeriodDatesRequest' is not null or undefined
      assertParamExists(
        "updateFinancialPeriodDates",
        "updateFinancialPeriodDatesRequest",
        updateFinancialPeriodDatesRequest
      )
      const localVarPath = `/v2.0/financialPeriods/{financialPeriodId}/dates`.replace(
        `{${"financialPeriodId"}}`,
        encodeURIComponent(String(financialPeriodId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateFinancialPeriodDatesRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * FinancialPeriodResourceV2Api - functional programming interface
 * @export
 */
export const FinancialPeriodResourceV2ApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = FinancialPeriodResourceV2ApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @summary Add a financial period
     * @param {AddFinancialPeriodRequest} addFinancialPeriodRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addFinancialPeriod(
      addFinancialPeriodRequest: AddFinancialPeriodRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.addFinancialPeriod(
        addFinancialPeriodRequest,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Delete a financial period
     * @param {string} financialPeriodId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteFinancialPeriod(
      financialPeriodId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFinancialPeriod(
        financialPeriodId,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Fixes financial periods details entry id problems
     * @param {string} noteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async fixFinancialPeriodsDetails(
      noteId: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiFinancialPeriod>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.fixFinancialPeriodsDetails(
        noteId,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Get all financial periods by note id
     * @param {string} noteId
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    async getApiAllPeriods(
      noteId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiAllPeriods>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getApiAllPeriods(noteId, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Get all financial periods by note id
     * @param {string} noteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getFinancialPeriods(
      noteId: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiFinancialPeriod>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getFinancialPeriods(noteId, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Init financial periods
     * @param {InitFinancialPeriodsRequest} initFinancialPeriodsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async initFinancialPeriods(
      initFinancialPeriodsRequest: InitFinancialPeriodsRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiFinancialPeriod>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.initFinancialPeriods(
        initFinancialPeriodsRequest,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Shift first forecast period to last previous period
     * @param {string} noteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async shiftFirstForecastToLastPreviousPeriod(
      noteId: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiFinancialPeriod>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.shiftFirstForecastToLastPreviousPeriod(noteId, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Update a financial period
     * @param {string} financialPeriodId
     * @param {SaveFinancialPeriodRequest} saveFinancialPeriodRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateFinancialPeriod(
      financialPeriodId: string,
      saveFinancialPeriodRequest: SaveFinancialPeriodRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiFinancialPeriod>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateFinancialPeriod(
        financialPeriodId,
        saveFinancialPeriodRequest,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Update a financial period\'s dates
     * @param {string} financialPeriodId
     * @param {UpdateFinancialPeriodDatesRequest} updateFinancialPeriodDatesRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateFinancialPeriodDates(
      financialPeriodId: string,
      updateFinancialPeriodDatesRequest: UpdateFinancialPeriodDatesRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiFinancialPeriod>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateFinancialPeriodDates(
        financialPeriodId,
        updateFinancialPeriodDatesRequest,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    }
  }
}

/**
 * FinancialPeriodResourceV2Api - factory interface
 * @export
 */
export const FinancialPeriodResourceV2ApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = FinancialPeriodResourceV2ApiFp(configuration)
  return {
    /**
     *
     * @summary Add a financial period
     * @param {AddFinancialPeriodRequest} addFinancialPeriodRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addFinancialPeriod(
      addFinancialPeriodRequest: AddFinancialPeriodRequest,
      options?: any
    ): AxiosPromise<string> {
      return localVarFp
        .addFinancialPeriod(addFinancialPeriodRequest, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Delete a financial period
     * @param {string} financialPeriodId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteFinancialPeriod(financialPeriodId: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .deleteFinancialPeriod(financialPeriodId, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Fixes financial periods details entry id problems
     * @param {string} noteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    fixFinancialPeriodsDetails(
      noteId: string,
      options?: any
    ): AxiosPromise<Array<ApiFinancialPeriod>> {
      return localVarFp
        .fixFinancialPeriodsDetails(noteId, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Get all financial periods by note id
     * @param {string} noteId
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    getApiAllPeriods(noteId: string, options?: any): AxiosPromise<ApiAllPeriods> {
      return localVarFp.getApiAllPeriods(noteId, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Get all financial periods by note id
     * @param {string} noteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFinancialPeriods(noteId: string, options?: any): AxiosPromise<Array<ApiFinancialPeriod>> {
      return localVarFp
        .getFinancialPeriods(noteId, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Init financial periods
     * @param {InitFinancialPeriodsRequest} initFinancialPeriodsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    initFinancialPeriods(
      initFinancialPeriodsRequest: InitFinancialPeriodsRequest,
      options?: any
    ): AxiosPromise<Array<ApiFinancialPeriod>> {
      return localVarFp
        .initFinancialPeriods(initFinancialPeriodsRequest, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Shift first forecast period to last previous period
     * @param {string} noteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    shiftFirstForecastToLastPreviousPeriod(
      noteId: string,
      options?: any
    ): AxiosPromise<Array<ApiFinancialPeriod>> {
      return localVarFp
        .shiftFirstForecastToLastPreviousPeriod(noteId, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Update a financial period
     * @param {string} financialPeriodId
     * @param {SaveFinancialPeriodRequest} saveFinancialPeriodRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateFinancialPeriod(
      financialPeriodId: string,
      saveFinancialPeriodRequest: SaveFinancialPeriodRequest,
      options?: any
    ): AxiosPromise<Array<ApiFinancialPeriod>> {
      return localVarFp
        .updateFinancialPeriod(financialPeriodId, saveFinancialPeriodRequest, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Update a financial period\'s dates
     * @param {string} financialPeriodId
     * @param {UpdateFinancialPeriodDatesRequest} updateFinancialPeriodDatesRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateFinancialPeriodDates(
      financialPeriodId: string,
      updateFinancialPeriodDatesRequest: UpdateFinancialPeriodDatesRequest,
      options?: any
    ): AxiosPromise<Array<ApiFinancialPeriod>> {
      return localVarFp
        .updateFinancialPeriodDates(financialPeriodId, updateFinancialPeriodDatesRequest, options)
        .then(request => request(axios, basePath))
    }
  }
}

/**
 * Request parameters for addFinancialPeriod operation in FinancialPeriodResourceV2Api.
 * @export
 * @interface FinancialPeriodResourceV2ApiAddFinancialPeriodRequest
 */
export interface FinancialPeriodResourceV2ApiAddFinancialPeriodRequest {
  /**
   *
   * @type {AddFinancialPeriodRequest}
   * @memberof FinancialPeriodResourceV2ApiAddFinancialPeriod
   */
  readonly addFinancialPeriodRequest: AddFinancialPeriodRequest
}

/**
 * Request parameters for deleteFinancialPeriod operation in FinancialPeriodResourceV2Api.
 * @export
 * @interface FinancialPeriodResourceV2ApiDeleteFinancialPeriodRequest
 */
export interface FinancialPeriodResourceV2ApiDeleteFinancialPeriodRequest {
  /**
   *
   * @type {string}
   * @memberof FinancialPeriodResourceV2ApiDeleteFinancialPeriod
   */
  readonly financialPeriodId: string
}

/**
 * Request parameters for fixFinancialPeriodsDetails operation in FinancialPeriodResourceV2Api.
 * @export
 * @interface FinancialPeriodResourceV2ApiFixFinancialPeriodsDetailsRequest
 */
export interface FinancialPeriodResourceV2ApiFixFinancialPeriodsDetailsRequest {
  /**
   *
   * @type {string}
   * @memberof FinancialPeriodResourceV2ApiFixFinancialPeriodsDetails
   */
  readonly noteId: string
}

/**
 * Request parameters for getApiAllPeriods operation in FinancialPeriodResourceV2Api.
 * @export
 * @interface FinancialPeriodResourceV2ApiGetApiAllPeriodsRequest
 */
export interface FinancialPeriodResourceV2ApiGetApiAllPeriodsRequest {
  /**
   *
   * @type {string}
   * @memberof FinancialPeriodResourceV2ApiGetApiAllPeriods
   */
  readonly noteId: string
}

/**
 * Request parameters for getFinancialPeriods operation in FinancialPeriodResourceV2Api.
 * @export
 * @interface FinancialPeriodResourceV2ApiGetFinancialPeriodsRequest
 */
export interface FinancialPeriodResourceV2ApiGetFinancialPeriodsRequest {
  /**
   *
   * @type {string}
   * @memberof FinancialPeriodResourceV2ApiGetFinancialPeriods
   */
  readonly noteId: string
}

/**
 * Request parameters for initFinancialPeriods operation in FinancialPeriodResourceV2Api.
 * @export
 * @interface FinancialPeriodResourceV2ApiInitFinancialPeriodsRequest
 */
export interface FinancialPeriodResourceV2ApiInitFinancialPeriodsRequest {
  /**
   *
   * @type {InitFinancialPeriodsRequest}
   * @memberof FinancialPeriodResourceV2ApiInitFinancialPeriods
   */
  readonly initFinancialPeriodsRequest: InitFinancialPeriodsRequest
}

/**
 * Request parameters for shiftFirstForecastToLastPreviousPeriod operation in FinancialPeriodResourceV2Api.
 * @export
 * @interface FinancialPeriodResourceV2ApiShiftFirstForecastToLastPreviousPeriodRequest
 */
export interface FinancialPeriodResourceV2ApiShiftFirstForecastToLastPreviousPeriodRequest {
  /**
   *
   * @type {string}
   * @memberof FinancialPeriodResourceV2ApiShiftFirstForecastToLastPreviousPeriod
   */
  readonly noteId: string
}

/**
 * Request parameters for updateFinancialPeriod operation in FinancialPeriodResourceV2Api.
 * @export
 * @interface FinancialPeriodResourceV2ApiUpdateFinancialPeriodRequest
 */
export interface FinancialPeriodResourceV2ApiUpdateFinancialPeriodRequest {
  /**
   *
   * @type {string}
   * @memberof FinancialPeriodResourceV2ApiUpdateFinancialPeriod
   */
  readonly financialPeriodId: string

  /**
   *
   * @type {SaveFinancialPeriodRequest}
   * @memberof FinancialPeriodResourceV2ApiUpdateFinancialPeriod
   */
  readonly saveFinancialPeriodRequest: SaveFinancialPeriodRequest
}

/**
 * Request parameters for updateFinancialPeriodDates operation in FinancialPeriodResourceV2Api.
 * @export
 * @interface FinancialPeriodResourceV2ApiUpdateFinancialPeriodDatesRequest
 */
export interface FinancialPeriodResourceV2ApiUpdateFinancialPeriodDatesRequest {
  /**
   *
   * @type {string}
   * @memberof FinancialPeriodResourceV2ApiUpdateFinancialPeriodDates
   */
  readonly financialPeriodId: string

  /**
   *
   * @type {UpdateFinancialPeriodDatesRequest}
   * @memberof FinancialPeriodResourceV2ApiUpdateFinancialPeriodDates
   */
  readonly updateFinancialPeriodDatesRequest: UpdateFinancialPeriodDatesRequest
}

/**
 * FinancialPeriodResourceV2Api - object-oriented interface
 * @export
 * @class FinancialPeriodResourceV2Api
 * @extends {BaseAPI}
 */
export class FinancialPeriodResourceV2Api extends BaseAPI {
  /**
   *
   * @summary Add a financial period
   * @param {FinancialPeriodResourceV2ApiAddFinancialPeriodRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FinancialPeriodResourceV2Api
   */
  public addFinancialPeriod(
    requestParameters: FinancialPeriodResourceV2ApiAddFinancialPeriodRequest,
    options?: AxiosRequestConfig
  ) {
    return FinancialPeriodResourceV2ApiFp(this.configuration)
      .addFinancialPeriod(requestParameters.addFinancialPeriodRequest, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Delete a financial period
   * @param {FinancialPeriodResourceV2ApiDeleteFinancialPeriodRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FinancialPeriodResourceV2Api
   */
  public deleteFinancialPeriod(
    requestParameters: FinancialPeriodResourceV2ApiDeleteFinancialPeriodRequest,
    options?: AxiosRequestConfig
  ) {
    return FinancialPeriodResourceV2ApiFp(this.configuration)
      .deleteFinancialPeriod(requestParameters.financialPeriodId, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Fixes financial periods details entry id problems
   * @param {FinancialPeriodResourceV2ApiFixFinancialPeriodsDetailsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FinancialPeriodResourceV2Api
   */
  public fixFinancialPeriodsDetails(
    requestParameters: FinancialPeriodResourceV2ApiFixFinancialPeriodsDetailsRequest,
    options?: AxiosRequestConfig
  ) {
    return FinancialPeriodResourceV2ApiFp(this.configuration)
      .fixFinancialPeriodsDetails(requestParameters.noteId, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get all financial periods by note id
   * @param {FinancialPeriodResourceV2ApiGetApiAllPeriodsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof FinancialPeriodResourceV2Api
   */
  public getApiAllPeriods(
    requestParameters: FinancialPeriodResourceV2ApiGetApiAllPeriodsRequest,
    options?: AxiosRequestConfig
  ) {
    return FinancialPeriodResourceV2ApiFp(this.configuration)
      .getApiAllPeriods(requestParameters.noteId, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get all financial periods by note id
   * @param {FinancialPeriodResourceV2ApiGetFinancialPeriodsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FinancialPeriodResourceV2Api
   */
  public getFinancialPeriods(
    requestParameters: FinancialPeriodResourceV2ApiGetFinancialPeriodsRequest,
    options?: AxiosRequestConfig
  ) {
    return FinancialPeriodResourceV2ApiFp(this.configuration)
      .getFinancialPeriods(requestParameters.noteId, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Init financial periods
   * @param {FinancialPeriodResourceV2ApiInitFinancialPeriodsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FinancialPeriodResourceV2Api
   */
  public initFinancialPeriods(
    requestParameters: FinancialPeriodResourceV2ApiInitFinancialPeriodsRequest,
    options?: AxiosRequestConfig
  ) {
    return FinancialPeriodResourceV2ApiFp(this.configuration)
      .initFinancialPeriods(requestParameters.initFinancialPeriodsRequest, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Shift first forecast period to last previous period
   * @param {FinancialPeriodResourceV2ApiShiftFirstForecastToLastPreviousPeriodRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FinancialPeriodResourceV2Api
   */
  public shiftFirstForecastToLastPreviousPeriod(
    requestParameters: FinancialPeriodResourceV2ApiShiftFirstForecastToLastPreviousPeriodRequest,
    options?: AxiosRequestConfig
  ) {
    return FinancialPeriodResourceV2ApiFp(this.configuration)
      .shiftFirstForecastToLastPreviousPeriod(requestParameters.noteId, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Update a financial period
   * @param {FinancialPeriodResourceV2ApiUpdateFinancialPeriodRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FinancialPeriodResourceV2Api
   */
  public updateFinancialPeriod(
    requestParameters: FinancialPeriodResourceV2ApiUpdateFinancialPeriodRequest,
    options?: AxiosRequestConfig
  ) {
    return FinancialPeriodResourceV2ApiFp(this.configuration)
      .updateFinancialPeriod(
        requestParameters.financialPeriodId,
        requestParameters.saveFinancialPeriodRequest,
        options
      )
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Update a financial period\'s dates
   * @param {FinancialPeriodResourceV2ApiUpdateFinancialPeriodDatesRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FinancialPeriodResourceV2Api
   */
  public updateFinancialPeriodDates(
    requestParameters: FinancialPeriodResourceV2ApiUpdateFinancialPeriodDatesRequest,
    options?: AxiosRequestConfig
  ) {
    return FinancialPeriodResourceV2ApiFp(this.configuration)
      .updateFinancialPeriodDates(
        requestParameters.financialPeriodId,
        requestParameters.updateFinancialPeriodDatesRequest,
        options
      )
      .then(request => request(this.axios, this.basePath))
  }
}
