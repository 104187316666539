import React, { FC, useContext } from "react"
import { Button, Modal, Typography } from "antd"
import { NoteContext } from "../../NotePage"
import { useUpdateNote } from "../../../queries/NoteQueries"
import { NoteTypeEnum } from "../../../client/backend-client/generated"
import Loader from "../../../components/Loader/Loader"

const { Text } = Typography

export const CreationExNihiloDialog: FC = () => {
  const { note, noteId } = useContext(NoteContext)
  const updateUpdateNoteMutation = useUpdateNote(noteId)

  const updateCreationExNihilo = (creationExNihilo: boolean) => {
    updateUpdateNoteMutation.mutate({ noteId, creationExNihilo })
  }

  return (
    <Modal
      open={note.type === NoteTypeEnum.UNDEFINED_CREATION}
      closable={false}
      title={'Note en phase de vie "Création"'}
      footer={[
        <Button key="yes-button" onClick={() => updateCreationExNihilo(false)}>
          Oui
        </Button>,
        <Button key="no-button" type="primary" onClick={() => updateCreationExNihilo(true)}>
          Non
        </Button>
      ]}
    >
      <Text>Souhaitez-vous saisir des données comptables liées à des exercices antérieurs ?</Text>
      {updateUpdateNoteMutation.isPending && <Loader />}
    </Modal>
  )
}
