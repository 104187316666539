import React, { FC } from "react"
import { OutputData } from "@editorjs/editorjs"
import edjsHTML from "editorjs-html"
import parse from "html-react-parser"

const edjsParser = edjsHTML()

export const EditorTextParser: FC<{ data: OutputData }> = ({ data }) => {
  // array of html elements
  const html = edjsParser.parse(data)

  return <div className="text-container">{parse(html.join(""))}</div>
}
