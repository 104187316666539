import React, { useContext } from "react"

import "./NavBar.scss"
import environment from "../../config/environment"
import { KeycloakContext } from "../../auth/KeycloakContext"
import { Layout, Flex, Typography, Tooltip, Button, Tag } from "antd"
import { LogoutOutlined, UserOutlined } from "@ant-design/icons"
import { Link } from "react-router-dom"

const { Header } = Layout
const { Text } = Typography

export const NAV_BAR_HEIGHT = 65

const Logo = () => (
  <Flex align="center" gap="middle">
    <Link to={`/home`} style={{ height: NAV_BAR_HEIGHT }}>
      <Text
        style={{
          fontSize: 28,
          fontWeight: 700,
          color: "var(--main-green-1)",
          lineHeight: "inherit"
        }}
      >
        YODA
      </Text>
    </Link>
    <Tag bordered={false}>version {environment.APP_VERSION}</Tag>
  </Flex>
)

export function UserMenu() {
  const { keycloak } = useContext(KeycloakContext)
  const user = keycloak.profile

  return (
    <Flex gap={"small"} align="center">
      <UserOutlined style={{ color: "var(--main-green-1)" }} />
      <Text>
        {user?.firstName} {user?.lastName}
      </Text>
      <Tooltip title="Déconnexion">
        <LogoutOutlined
          style={{ color: "var(--main-green-1)" }}
          onClick={() => keycloak.logout()}
        />
      </Tooltip>
    </Flex>
  )
}

export const NavBar: React.FC = () => {
  return (
    <Header
      style={{
        backgroundColor: "white",
        height: NAV_BAR_HEIGHT,
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        zIndex: 100,
        justifyContent: "space-between",
        marginBottom: 1,
        boxShadow:
          "0 0 0 1px rgba(16, 22, 26, 0.1), 0 0 0 rgba(16, 22, 26, 0), 0 1px 1px rgba(16, 22, 26, 0.2)"
      }}
    >
      <Flex align="center" justify={"space-between"} style={{ height: NAV_BAR_HEIGHT }}>
        <Flex gap="middle">
          <Logo />
        </Flex>
        <Flex gap={"middle"}>
          <Button
            onClick={() =>
              window.open(
                "https://support-fa.atlassian.net/servicedesk/customer/portal/3/create/53",
                "_blank"
              )
            }
            type="primary"
            shape="round"
            size="large"
          >
            <Text strong style={{ color: "white" }}>
              Aide
            </Text>
          </Button>
          <UserMenu />
        </Flex>
      </Flex>
    </Header>
  )
}
