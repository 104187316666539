import React from "react"
import ReactDOM from "react-dom/client"
import "./index.scss"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import { KeycloakProvider } from "./auth/KeycloakContext"
import AppCtx from "./config/context"
import { BrowserRouter } from "react-router-dom"

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
  <>
    <KeycloakProvider>
      <BrowserRouter>
        <AppCtx>
          <App />
        </AppCtx>
      </BrowserRouter>
    </KeycloakProvider>
  </>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
