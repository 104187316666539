import { useAppContext } from "../../config/context"
import { useHeaders } from "../../queries/axios-headers"
import { useQuery } from "@tanstack/react-query"
import { extract } from "../../client/backend-client/AxiosUtils"
import { ProjectResourceV2ApiFindProjectsRequest } from "../../client/backend-client/generated"
import { KeycloakContext } from "../../auth/KeycloakContext"
import _ from "lodash"
import { useContext } from "react"

export const useGetProjects = (request: ProjectResourceV2ApiFindProjectsRequest) => {
  const { projectResourceV2Api } = useAppContext()
  const getHeaders = useHeaders()
  const { isLoading, isInitialLoading, data, error } = useQuery({
    queryKey: [
      "search",
      request.searchString,
      _.toArray(request.searchFields).join(","),
      _.toArray(request.filterOnProjectActivityArea).join(","),
      _.toArray(request.filterOnProjectPhase).join(","),
      request.filterOnCommitteeDate,
      request.filterOnLoggedAdvisor
    ],

    queryFn: async () => {
      const { headers } = await getHeaders()
      return projectResourceV2Api.findProjects(request, { headers }).then(extract)
    },
    retry: 0,
    meta: {
      errorMessage: `Erreur lors de la récupération des projets`
    }
  })

  return {
    isLoading,
    isInitialLoading,
    data,
    error
  }
}

export const useGetRecentlyViewedProjectsByAdvisorId = (advisorId?: string) => {
  const { projectResourceV2Api } = useAppContext()
  const { keycloak } = useContext(KeycloakContext)
  const getHeaders = useHeaders()

  const userId = advisorId || keycloak.profile?.id

  const { isLoading, data, error } = useQuery({
    queryKey: ["recently-viewed-projects", userId],
    queryFn: async () => {
      const { headers } = await getHeaders()
      return projectResourceV2Api
        .getRecentlyViewedProjectsByAdvisorId({ advisorId: userId }, { headers })
        .then(extract)
    },
    retry: 0,
    meta: {
      errorMessage: `Erreur lors de la récupération des projets`
    }
  })

  return {
    isLoading,
    data,
    error
  }
}
