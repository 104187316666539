/* tslint:disable */
/* eslint-disable */
/**
 * Appli métier
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: Yoda 1 & 2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface IncomeRequest
 */
export interface IncomeRequest {
  /**
   *
   * @type {string}
   * @memberof IncomeRequest
   */
  id?: string
  /**
   *
   * @type {string}
   * @memberof IncomeRequest
   */
  contactType: IncomeRequestContactTypeEnum
  /**
   *
   * @type {string}
   * @memberof IncomeRequest
   */
  label: string
  /**
   *
   * @type {number}
   * @memberof IncomeRequest
   */
  amount?: number
  /**
   *
   * @type {number}
   * @memberof IncomeRequest
   */
  fromMonth?: number
  /**
   *
   * @type {number}
   * @memberof IncomeRequest
   */
  endMonth?: number
}

/**
 * @export
 * @enum {string}
 */
export enum IncomeRequestContactTypeEnum {
  ENTREPRENEUR = "ENTREPRENEUR",
  PARTNER = "PARTNER",
  CONTACT = "CONTACT"
}
