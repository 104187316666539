import React, { FC } from "react"
import { Col, Flex, List, Modal, Row, Typography } from "antd"
import { MinusOutlined, PlusOutlined } from "@ant-design/icons"
import { NoteLifecycleEnum } from "../../../client/backend-client/generated"

const { Text } = Typography

export const LifecycleChangeDialog: FC<{
  isOpen: boolean
  cancelClick: () => void
  changeFct: () => void
  addedPanel: string[]
  removedPanel: string[]
}> = ({ isOpen, cancelClick, changeFct, addedPanel, removedPanel }) => {
  return (
    <Modal
      width={1000}
      open={isOpen}
      closeIcon={false}
      destroyOnClose={true}
      onCancel={cancelClick}
      onOk={changeFct}
    >
      <Row justify="space-around">
        <Col span={12}>
          <List
            header={
              <Flex justify="center">
                <Text strong>Eléments ajoutés</Text>
              </Flex>
            }
            dataSource={addedPanel.length > 0 ? addedPanel : ["Rien"]}
            renderItem={txt => (
              <List.Item
                style={{ display: "flex", justifyContent: "flex-start", columnGap: "20px" }}
              >
                <PlusOutlined style={{ color: "var(--main-green-4)" }} />
                <Text>{txt}</Text>
              </List.Item>
            )}
          />
        </Col>
        <Col span={12}>
          <List
            header={
              <Flex justify="center">
                <Text strong>Eléments supprimés</Text>
              </Flex>
            }
            dataSource={removedPanel.length > 0 ? removedPanel : ["Rien"]}
            renderItem={txt => (
              <List.Item
                style={{ display: "flex", justifyContent: "flex-start", columnGap: "20px" }}
              >
                <MinusOutlined style={{ color: "var(--main-red-2)" }} />
                <Text>{txt}</Text>
              </List.Item>
            )}
          />
        </Col>
      </Row>
    </Modal>
  )
}

interface LifeCycleColumn {
  name: string
  userInfo: string[]
}

const columnFinancingPlan: LifeCycleColumn = {
  name: "financingPlan",
  userInfo: ["La colonne de démarrage du plan de financement"]
}

const columnPastExercices: LifeCycleColumn = {
  name: "pastExercices",
  userInfo: [
    "Un exercice passé (dans tous les onglets concernés)",
    "Une section 'Bilan passé/cédant'",
    "Une section 'Prêts passés'"
  ]
}

const columnTransferorBalance: LifeCycleColumn = {
  name: "transferorBalance",
  userInfo: ['Les informations en commentaire du cédant (page "vue d\'ensemble")']
}

export const getColumnsFromLifecycle = (lifecycle: string): LifeCycleColumn[] => {
  switch (lifecycle) {
    case NoteLifecycleEnum.CREATION.toString():
      return [columnPastExercices]
    case NoteLifecycleEnum.CREATION.toString().concat("_EX_NIHILO"):
      return [columnFinancingPlan]
    case NoteLifecycleEnum.REPRISE.toString():
      return [columnPastExercices, columnTransferorBalance]
    case NoteLifecycleEnum.DEVELOPMENT.toString():
      return [columnPastExercices]
    case NoteLifecycleEnum.REBOUND.toString():
      return [columnPastExercices]
    case NoteLifecycleEnum.SCALE.toString():
      return [columnPastExercices]
    default:
      throw new Error(`Les changement de/vers la phase de vie ${lifecycle} ne sont pas possibles`)
  }
}
