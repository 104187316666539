import React, { FC, useContext } from "react"
import { Card, Space, Tag, Timeline, Typography } from "antd"
import {
  ClockCircleOutlined,
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  FileOutlined,
  FilePdfOutlined
} from "@ant-design/icons"
import { History, HistoryActionTypeEnum } from "../../client/backend-client/generated"
import { NoteContext } from "../NotePage"
import { localBrowserDayjs } from "../../utils/DayjsUtils"
import { TimeLineItemProps } from "antd/es/timeline/TimelineItem"

const { Paragraph } = Typography

function toItem(event: History): TimeLineItemProps {
  const { projectName } = useContext(NoteContext)

  switch (event.actionType) {
    case HistoryActionTypeEnum.UPDATE_STATUS:
      return {}
    case HistoryActionTypeEnum.UPDATE:
      return {}
    case HistoryActionTypeEnum.CREATE:
      return {
        children: (
          <Space direction="vertical">
            <Tag bordered={false} icon={<ClockCircleOutlined />} color="green">
              {localBrowserDayjs(event.date)}
            </Tag>
            <Paragraph>
              Création de la note
              {event.userName.length > 0 && (
                <>
                  {" "}
                  par <b>{event.userName}</b>
                </>
              )}
            </Paragraph>
          </Space>
        ),
        dot: <FileOutlined style={{ color: "var(--main-green-1)", fontSize: "24px" }} />
      }
    case HistoryActionTypeEnum.DUPLICATE:
      return {
        children: (
          <Space direction={"vertical"}>
            <Tag bordered={false} icon={<ClockCircleOutlined />} color="green">
              {localBrowserDayjs(event.date)}
            </Tag>
            <Paragraph>
              Création par duplication de la note {projectName}
              {event.userName.length > 0 && (
                <>
                  {" "}
                  par <b>{event.userName}</b>
                </>
              )}
            </Paragraph>
          </Space>
        ),
        dot: <CopyOutlined style={{ color: "var(--main-green-1)", fontSize: "24px" }} />
      }
    case HistoryActionTypeEnum.UPDATE_SCORE:
      return {
        children: (
          <Space direction="vertical">
            <Tag bordered={false} icon={<ClockCircleOutlined />} color="green">
              {localBrowserDayjs(event.date)}
            </Tag>
            <Paragraph>
              Le scoring d'octroi a été mis à jour
              {event.userName.length > 0 && (
                <>
                  {" "}
                  par <b>{event.userName}</b>
                </>
              )}
            </Paragraph>
          </Space>
        ),
        dot: <EditOutlined style={{ color: "var(--main-green-1)", fontSize: "24px" }} />
      }
    case HistoryActionTypeEnum.REMOVE_OUTIL:
      return {
        children: (
          <Space direction={"vertical"}>
            <Tag bordered={false} icon={<ClockCircleOutlined />} color="green">
              {localBrowserDayjs(event.date)}
            </Tag>
            <Paragraph>
              Un outil a été abandonné
              {event.userName.length > 0 && (
                <>
                  {" "}
                  par <b>{event.userName}</b>
                </>
              )}
            </Paragraph>
          </Space>
        ),
        dot: <DeleteOutlined style={{ color: "var(--main-green-1)", fontSize: "24px" }} />
      }
    case HistoryActionTypeEnum.PUBLISH_MEMENTO:
      return {
        children: (
          <Space direction={"vertical"}>
            <Tag bordered={false} icon={<ClockCircleOutlined />}>
              {localBrowserDayjs(event.date)}
            </Tag>
            <Paragraph>
              Le Memento a été publié
              {event.userName.length > 0 && (
                <>
                  {" "}
                  par <b>{event.userName}</b>
                </>
              )}
            </Paragraph>
          </Space>
        ),
        dot: <FilePdfOutlined style={{ color: "var(--main-green-1)", fontSize: "24px" }} />
      }
    case HistoryActionTypeEnum.UNPUBLISH_MEMENTO:
      return {
        children: (
          <Space direction={"vertical"}>
            <Tag bordered={false} icon={<ClockCircleOutlined />} color="green">
              {localBrowserDayjs(event.date)}
            </Tag>
            <Paragraph>
              Le Memento a été dépublié
              {event.userName.length > 0 && (
                <>
                  {" "}
                  par <b>{event.userName}</b>
                </>
              )}
            </Paragraph>
          </Space>
        ),
        dot: <FilePdfOutlined style={{ color: "var(--main-green-1)", fontSize: "24px" }} />
      }
    case HistoryActionTypeEnum.UPDATE_MEMENTO:
      return {
        children: (
          <Space direction={"vertical"}>
            <Tag bordered={false} icon={<ClockCircleOutlined />} color="green">
              {localBrowserDayjs(event.date)}
            </Tag>
            <Paragraph>
              Le Memento a été mis à jour
              {event.userName.length > 0 && (
                <>
                  {" "}
                  par <b>{event.userName}</b>
                </>
              )}
            </Paragraph>
          </Space>
        ),
        dot: <FilePdfOutlined style={{ color: "var(--main-green-1)", fontSize: "24px" }} />
      }
  }
}

export const HistoryForm: FC<{ history: History[] }> = ({ history }) => {
  return (
    <Card>
      <Timeline
        mode={"left"}
        items={history
          .filter(
            ev =>
              ev.actionType !== HistoryActionTypeEnum.UPDATE_STATUS &&
              ev.actionType !== HistoryActionTypeEnum.UPDATE
          )
          .map(event => toItem(event))}
      />
    </Card>
  )
}
