import React, { FC, useContext } from "react"
import {
  CategoryResult,
  CategoryResultCategoryEnum,
  InvolvementQuestionCategoryEnum,
  ResultInvolvementProfileV2Enum
} from "../../client/backend-client/generated"
import { InvolvementCheckWrapperContext } from "./InvolvementCheckPageWrapper"
import {
  INVOLVEMENT_CATEGORY_GROUP_RECORD,
  InvolvementVersion,
  sortCategoryResultByRadarChartOrder
} from "./InvolvementUtils"
import { PolarAngleAxis, PolarGrid, PolarRadiusAxis, Radar, RadarChart } from "recharts"
import { getInvolvementIcon } from "./InvolvementIconManager"

export type InvolvementChartData = {
  score: number
  category: CategoryResultCategoryEnum
}

export const InvolvementChart: FC<{
  version: InvolvementVersion
}> = ({ version }) => {
  const { involvementCheckResult } = useContext(InvolvementCheckWrapperContext)

  const data: InvolvementChartData[] = Object.values(CategoryResultCategoryEnum)
    .filter(category => category !== CategoryResultCategoryEnum.OTHER)
    .sort(sortCategoryResultByRadarChartOrder)
    .map(category =>
      categoryResultToChartScore(
        version,
        category,
        involvementCheckResult.categoryResults.find(
          categoryResult => categoryResult.category === category
        )
      )
    )

  const chartColor =
    version == 2 &&
    involvementCheckResult.involvementProfileV2 === ResultInvolvementProfileV2Enum.NEGATIVE_IMPACT
      ? "var(--main-red-2)"
      : "var(--main-green-4)"

  return (
    <>
      {data && data.length > 0 && (
        <RadarChart cx={150} cy={150} outerRadius={100} width={300} height={300} data={data}>
          <PolarGrid />
          <PolarRadiusAxis domain={[-10, 100]} axisLine={false} tick={false} />
          <PolarAngleAxis dataKey="category" tick={renderLabel} />
          <Radar
            name="Results"
            dataKey="score"
            stroke={chartColor}
            fill={chartColor}
            fillOpacity={0.6}
          />
        </RadarChart>
      )}
    </>
  )
}

export const categoryResultToChartScore = (
  version: InvolvementVersion,
  category: CategoryResultCategoryEnum,
  categoryResult?: CategoryResult
): InvolvementChartData => ({
  category: category,
  score: categoryResult
    ? version === 1
      ? (categoryResult.valid ? categoryResult.mean : 0) * 33
      : handleScore(categoryResult.score ?? 0, version === 2)
    : 0
})

const handleScore = (score: number, isV2: boolean) => {
  if (!isV2) {
    return score * 33
  }

  switch (score) {
    case 1:
      return 20
    case 2:
      return 50
    case 3:
      return 100
    default:
      return -10
  }
}

interface RenderingContextLabelCb {
  x: number
  y: number
  payload: { index: number; value: InvolvementQuestionCategoryEnum }
}

const renderLabel: (data: RenderingContextLabelCb) => React.ReactNode = ({ x, y, payload }) => {
  const size = 60
  const category: InvolvementQuestionCategoryEnum = payload.value

  switch (INVOLVEMENT_CATEGORY_GROUP_RECORD[category].radarChartOrder) {
    case 1:
      return <>{getInvolvementIcon(category, size, x - size / 2, y - size)}</>
    case 2:
      return <>{getInvolvementIcon(category, size, x, y - size / 2)}</>
    case 3:
      return <>{getInvolvementIcon(category, size, x, y)}</>
    case 4:
      return <>{getInvolvementIcon(category, size, x - size, y)}</>
    case 5:
      return <>{getInvolvementIcon(category, size, x - size, y - size / 2)}</>
    default:
      return <></>
  }
}
