import React, { FC, ReactNode, useEffect, useState } from "react"
import { AppMetierTitle } from "../../components/AppMetierTitle/AppMetierTitle"
import { SortOrder } from "antd/es/table/interface"
import _ from "lodash"
import {
  ExportOutlined,
  RightCircleTwoTone,
  SearchOutlined,
  ShopOutlined,
  TeamOutlined
} from "@ant-design/icons"
import {
  Button,
  Card,
  Checkbox,
  Col,
  Collapse,
  Divider,
  Flex,
  Input,
  Row,
  Select,
  SelectProps,
  Space,
  Table,
  TablePaginationConfig,
  Tag,
  Tooltip,
  Typography
} from "antd"
import {
  NoubaLightProject,
  NoubaLightProjectProjectPhaseEnum,
  ProjectResourceV2ApiFindProjectsRequest
} from "../../client/backend-client/generated"
import { Link } from "react-router-dom"
import { useGetProjects, useGetRecentlyViewedProjectsByAdvisorId } from "./SearchQueries"
import dayjs from "dayjs"
import {
  AppMetierDateInput,
  DISPLAY_DATE_FORMAT,
  SERVER_DATE_FORMAT
} from "../../components/AppMetierDateInput/AppMetierDateInput"
import { useGetUserSettings, useUpdateUserSetting } from "./SettingsQueries"
import "./HomePage.scss"
import { useDebouncedCallback } from "use-debounce"
import { FAIcon } from "../../assets/icons/fa-svg"
import { formatDayjs } from "../../utils/DayjsUtils"
import { UPDATE_DEBOUNCE_DEFAULT_DELAY } from "../../utils/DebounceUtils"
import { ReferenceCode, useGetReference } from "../../queries/ReferenceQueries"

const { Text } = Typography
const { Column } = Table

/**
 * @param a first string to compare
 * @param b second string to compare
 * @param multiplier makes defined element win over undefined element regardless of sort order
 */
const makeDiff = (a?: string, b?: string, multiplier: number = 1): number => {
  if (!!a && !!b) {
    return dayjs(a).diff(dayjs(b))
  }
  if (!!a && (b === undefined || b === null)) {
    return multiplier
  }
  if (!!b && (a === undefined || a === null)) {
    return -1 * multiplier
  }
  return 0
}

interface PhaseDisplay {
  displayName: string
  displayColor: string
}

const ProjectPhaseDisplay: Record<NoubaLightProjectProjectPhaseEnum, PhaseDisplay> = {
  ETUDE: { displayName: "Attente de décision", displayColor: "cyan" },
  ANALYSE: { displayName: "Analyse", displayColor: "gold" },
  APPRV: { displayName: "Mise en place", displayColor: "processing" },
  SUIVI: { displayName: "Suivi", displayColor: "success" },
  TERMN: { displayName: "Terminé", displayColor: "purple" },
  ABNDN: { displayName: "Abandonné", displayColor: "orange" },
  REFUS: { displayName: "Refusé", displayColor: "error" }
}

enum SortKeys {
  ALPHABETICAL_ASC = "ALPHABETICAL_ASC",
  ALPHABETICAL_DESC = "ALPHABETICAL_DESC",
  CREATION_DATE_ASC = "CREATION_DATE_ASC",
  CREATION_DATE_DESC = "CREATION_DATE_DESC",
  LAST_MODIFICATION_DATE_ASC = "LAST_MODIFICATION_DATE_ASC",
  LAST_MODIFICATION_DATE_DESC = "LAST_MODIFICATION_DATE_DESC"
}

const SORT_VALUES: Record<
  SortKeys,
  {
    label: string
    sortFct: (a: NoubaLightProject, b: NoubaLightProject) => number
    sortOrder: SortOrder
  }
> = {
  ALPHABETICAL_ASC: {
    label: "Ordre alphabétique (asc.)",
    sortFct: (a, b) => (a.structure > b.structure ? 1 : a.structure < b.structure ? -1 : 0),
    sortOrder: "ascend"
  },
  ALPHABETICAL_DESC: {
    label: "Ordre alphabétique (desc.)",
    sortFct: (a, b) => (a.structure > b.structure ? 1 : a.structure < b.structure ? -1 : 0),
    sortOrder: "descend"
  },
  CREATION_DATE_ASC: {
    label: "Date de création (asc.)",
    sortFct: (a, b) => makeDiff(a.creationDate, b.creationDate, -1),
    sortOrder: "ascend"
  },
  CREATION_DATE_DESC: {
    label: "Date de création (desc.)",
    sortFct: (a, b) => makeDiff(a.creationDate, b.creationDate),
    sortOrder: "descend"
  },
  LAST_MODIFICATION_DATE_ASC: {
    label: "Date de modification (asc.)",
    sortFct: (a, b) => makeDiff(a.lastModificationDate, b.lastModificationDate, -1),
    sortOrder: "ascend"
  },
  LAST_MODIFICATION_DATE_DESC: {
    label: "Date de modification (desc.)",
    sortFct: (a, b) => makeDiff(a.lastModificationDate, b.lastModificationDate),
    sortOrder: "descend"
  }
}

const isSearchStringValid = (searchString?: string): boolean =>
  !!searchString && searchString.trim().length > 2

const isRequestValid = (request: ProjectResourceV2ApiFindProjectsRequest): boolean => {
  return (
    isSearchStringValid(request.searchString) ||
    !!request.filterOnProjectActivityArea?.size ||
    request.filterOnLoggedAdvisor === true ||
    !!request.filterOnProjectPhase?.size
  )
}

type TagRender = SelectProps["tagRender"]

export const HomePage: FC = () => {
  const initialRequest: ProjectResourceV2ApiFindProjectsRequest = {
    searchString: "",
    searchFields: undefined,
    filterOnLoggedAdvisor: true,
    filterOnCommitteeDate: undefined,
    filterOnProjectPhase: new Set<string>([NoubaLightProjectProjectPhaseEnum.ANALYSE]),
    filterOnProjectActivityArea: undefined
  }
  const [request, setRequest] = useState<ProjectResourceV2ApiFindProjectsRequest>(initialRequest)
  const [validatedRequest, setValidatedRequest] =
    useState<ProjectResourceV2ApiFindProjectsRequest>(initialRequest)
  const [isCurrentRequestValid, setIsCurrentRequestValid] = useState<boolean>(true)
  const [searchTextTemp, setSearchTextTemp] = useState<string>("")
  const [sortedField, setSortedField] = useState<SortKeys>(SortKeys.LAST_MODIFICATION_DATE_DESC)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const { isInitialLoading, data: projects } = useGetProjects(validatedRequest)
  const { isLoading: areRecentlyViewedProjectLoading, data: recentlyViewedProjects } =
    useGetRecentlyViewedProjectsByAdvisorId()
  const { isLoading: activityAreasLoading, data: activityAreasData } = useGetReference(
    ReferenceCode.SECTEUR_ACTIVITE
  )
  const { isLoading: areSettingsLoading, data: settings } = useGetUserSettings()
  const updateUserSettingMutation = useUpdateUserSetting()

  const colorTheme = "var(--main-green-1)"

  const debouncedSetRequest = useDebouncedCallback(() => {
    setRequest({
      ...request,
      searchString: searchTextTemp
    })
  }, UPDATE_DEBOUNCE_DEFAULT_DELAY)

  useEffect(() => debouncedSetRequest(), [debouncedSetRequest, searchTextTemp])

  useEffect(() => {
    if (isRequestValid(request)) {
      setIsCurrentRequestValid(true)
      setValidatedRequest({
        ...request,
        searchString: isSearchStringValid(searchTextTemp) ? searchTextTemp : ""
      })
    } else {
      setIsCurrentRequestValid(false)
    }
  }, [request])

  useEffect(() => {
    // Reset page to default one when sort order is changed
    setCurrentPage(1)
  }, [sortedField])

  const tagRender: TagRender = (props: {
    label: ReactNode
    value: NoubaLightProjectProjectPhaseEnum
    closable: boolean
    onClose: (e: React.MouseEvent<HTMLElement>) => void
  }) => {
    const { label, value, closable, onClose } = props
    return (
      <Tag
        color={ProjectPhaseDisplay[value].displayColor}
        closable={closable}
        onClose={onClose}
        style={{ marginInlineEnd: 4 }}
      >
        {label}
      </Tag>
    )
  }

  const getPhaseOptions = (): SelectProps["options"] =>
    Object.entries(ProjectPhaseDisplay).map(([phaseCode, displayInfos]) => ({
      label: displayInfos.displayName,
      value: phaseCode
    }))

  const getActivityAreaOptions = (): SelectProps["options"] =>
    activityAreasData?.map(({ libelle, code }) => ({
      label: libelle,
      value: code
    }))

  const renderColumn = (
    id: number,
    {
      id: projectId,
      structure,
      label,
      codePeps,
      holdersBirthNames,
      creationDate,
      lastModificationDate,
      advisor,
      projectPhase
    }: NoubaLightProject
  ) => {
    return (
      <Link to={`/association/${codePeps}/project/${projectId}/note/presentation`}>
        <Card key={`${id}_card_item`} size="small" hoverable>
          <Row>
            <Col span={6}>
              <Space direction="vertical">
                <Space size="small">
                  <ShopOutlined />
                  <Text style={{ color: colorTheme }}>{structure}</Text>
                </Space>
                <Text strong={true} style={{ color: "black" }}>
                  {label}
                </Text>
              </Space>
            </Col>
            <Col span={6}>
              <Space size="small">
                <TeamOutlined />
                <Text style={{ color: "grey" }}>{holdersBirthNames}</Text>
              </Space>
            </Col>
            <Col span={5}>
              <Space direction="vertical" align="end">
                {creationDate && (
                  <Text> Crée le {dayjs(creationDate).format(DISPLAY_DATE_FORMAT)}</Text>
                )}
                {lastModificationDate && (
                  <Text>Modifiée le {dayjs(lastModificationDate).format(DISPLAY_DATE_FORMAT)}</Text>
                )}
              </Space>
            </Col>
            <Col span={5} style={{ textAlign: "end" }}>
              <Space direction="vertical" align="end">
                {projectPhase && (
                  <Tag color={ProjectPhaseDisplay[projectPhase].displayColor}>
                    {ProjectPhaseDisplay[projectPhase].displayName}
                  </Tag>
                )}
                {advisor && (
                  <Space size="small">
                    <FAIcon style={{ color: colorTheme }} />
                    <Text>
                      {advisor.firstName} {advisor.lastName}
                    </Text>
                  </Space>
                )}
              </Space>
            </Col>
            <Col span={2} style={{ alignSelf: "center", textAlign: "end" }}>
              <Tooltip title="Ouvrir dans un nouvel onglet">
                <Button
                  icon={<ExportOutlined />}
                  onClick={event => {
                    event.preventDefault()
                    window.open(
                      `/association/${codePeps}/project/${projectId}/note/presentation`,
                      "_blank"
                    )
                  }}
                />
              </Tooltip>
            </Col>
          </Row>
        </Card>
      </Link>
    )
  }

  return (
    <>
      <AppMetierTitle
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "1em"
        }}
        level={5}
      >
        {"Bienvenue sur YODA !"}
      </AppMetierTitle>
      <Card
        bordered={false}
        style={{
          marginTop: 20,
          marginLeft: 25,
          marginRight: 25,
          marginBottom: 20
        }}
      >
        <Divider plain style={{ padding: "0 29.5px" }}>
          RECHERCHER UNE NOTE D'ANALYSE
        </Divider>
        <Row
          gutter={[16, 16]}
          align="middle"
          justify="space-between"
          style={{ marginBottom: "1em" }}
        >
          <Col span={2}>
            <Text>Rechercher par: </Text>
          </Col>
          <Col span={8}>
            <Input
              onChange={txt => setSearchTextTemp(txt.target.value)}
              addonBefore={<SearchOutlined />}
              allowClear
              placeholder="Projets, porteurs, structures"
            />

            {request.searchString && request.searchString.trim().length < 3 && (
              <Text strong>Veuillez taper au minimum 3 caractères pour lancer la recherche</Text>
            )}
          </Col>
          <Col span={14} style={{ textAlign: "right" }}>
            <Space direction="horizontal" size={"middle"}>
              <Text>Trier par :</Text>
              <Select
                defaultValue={SortKeys.LAST_MODIFICATION_DATE_DESC}
                onChange={setSortedField}
                options={Object.entries(SORT_VALUES).map(([value, { label }]) => ({
                  value,
                  label
                }))}
              />
            </Space>
          </Col>
        </Row>
        <Row gutter={[16, 16]} align="middle" justify="start" style={{ marginBottom: "1em" }}>
          <Col span={2}>
            <Text>Filtrer par :</Text>
          </Col>
          <Col span={2}>
            <Checkbox
              checked={request.filterOnLoggedAdvisor}
              onChange={({ target: { checked } }) => {
                setRequest(request => ({
                  ...request,
                  filterOnLoggedAdvisor: checked ?? false
                }))
              }}
            >
              Mes notes
            </Checkbox>
          </Col>
          <Col span={6}>
            <Select
              placeholder={"Statut du projet"}
              style={{ width: "100%" }}
              allowClear
              mode="multiple"
              tagRender={tagRender}
              optionRender={option => (
                <Flex gap="middle" align="center">
                  <Text>{option.data.label}</Text>
                  <Tag
                    style={{ height: 10, width: 30 }}
                    color={
                      ProjectPhaseDisplay[option.data.value as NoubaLightProjectProjectPhaseEnum]
                        .displayColor
                    }
                  />
                </Flex>
              )}
              onClear={() =>
                setRequest(request => ({
                  ...request,
                  filterOnProjectPhase: undefined
                }))
              }
              defaultValue={_.toArray(request.filterOnProjectPhase)}
              options={getPhaseOptions()}
              onChange={values =>
                setRequest(request => ({
                  ...request,
                  filterOnProjectPhase: new Set(values)
                }))
              }
            />
          </Col>
          <Col span={7}>
            <Select
              loading={activityAreasLoading}
              placeholder={"Secteurs d'activité"}
              style={{ width: "100%" }}
              allowClear
              mode="multiple"
              defaultValue={_.toArray(request.filterOnProjectActivityArea)}
              options={getActivityAreaOptions()}
              onClear={() =>
                setRequest(request => ({
                  ...request,
                  filterOnProjectActivityArea: undefined
                }))
              }
              onChange={values =>
                setRequest(request => ({
                  ...request,
                  filterOnProjectActivityArea: new Set(values)
                }))
              }
            />
          </Col>
          <Col span={7}>
            <AppMetierDateInput
              onCleared={() =>
                setRequest(request => {
                  return {
                    ...request,
                    filterOnCommitteeDate: undefined
                  }
                })
              }
              placeholder={"Date de comité"}
              value={
                request.filterOnCommitteeDate ? dayjs(request.filterOnCommitteeDate) : undefined
              }
              style={{ width: "100%" }}
              allowClear
              onChange={date => {
                const formatted = formatDayjs(dayjs(date), SERVER_DATE_FORMAT)
                setRequest(request => {
                  return {
                    ...request,
                    filterOnCommitteeDate: formatted
                  }
                })
              }}
            />
          </Col>
        </Row>

        <Table<NoubaLightProject>
          rowKey={record => `${record.id}_search_result`}
          locale={{
            emptyText: isCurrentRequestValid
              ? "Aucune note correspondant à votre recherche n’a été trouvée"
              : "Veuillez renseigner au moins un filtre pour lancer la recherche"
          }}
          scroll={{ y: 515 }}
          loading={isInitialLoading && isCurrentRequestValid}
          dataSource={isCurrentRequestValid ? projects : []}
          pagination={{
            pageSizeOptions: [25],
            position: ["bottomRight"],
            total: isCurrentRequestValid ? projects?.length : 0,
            showTotal: (total, range) => `${range[0]}-${range[1]} de ${total} notes`,
            defaultPageSize: 25,
            defaultCurrent: 1,
            current: currentPage,
            showSizeChanger: true
          }}
          showSorterTooltip={false}
          showHeader={false}
          bordered={false}
          onChange={(pagination: TablePaginationConfig) => {
            // Maintain pagination feature
            setCurrentPage(pagination.current || 1)
          }}
        >
          <Column
            title="Résultats"
            key="search_column"
            sorter={{ compare: (a, b) => SORT_VALUES[sortedField].sortFct(a, b) }}
            sortOrder={SORT_VALUES[sortedField].sortOrder}
            render={renderColumn}
          />
        </Table>
        {!areSettingsLoading && settings && (
          <Collapse
            ghost
            style={{ padding: 0 }}
            defaultActiveKey={settings.showRecentlyViewedProject ? ["recentlyViewedProjects"] : []}
            expandIcon={({ isActive }) => (
              <RightCircleTwoTone
                twoToneColor="#00ccaa"
                style={{ fontSize: "1.25em", maxWidth: "17.5px" }}
                rotate={isActive ? 90 : 0}
                onClick={() => {
                  updateUserSettingMutation.mutate({
                    ...settings,
                    showRecentlyViewedProject: !settings.showRecentlyViewedProject
                  })
                }}
              />
            )}
            items={[
              {
                key: "recentlyViewedProjects",
                label: (
                  <Divider plain style={{ paddingRight: "29.5px" }}>
                    NOTES CONSULTÉES RÉCEMMENT
                  </Divider>
                ),
                headerClass: "recently-viewed-project-collapse-header",
                children: (
                  <Table<NoubaLightProject>
                    rowKey={record => `${record.id}_recently_viewed`}
                    locale={{
                      emptyText:
                        !areRecentlyViewedProjectLoading &&
                        (!recentlyViewedProjects || recentlyViewedProjects?.length === 0)
                          ? "Aucun note consultée récemment"
                          : ""
                    }}
                    loading={areRecentlyViewedProjectLoading}
                    dataSource={recentlyViewedProjects}
                    showSorterTooltip={false}
                    showHeader={false}
                    bordered={false}
                  >
                    <Column
                      key="recently_viewed_projects_table"
                      dataIndex="id"
                      title="Résultats notes consultées récemment"
                      render={renderColumn}
                    />
                  </Table>
                )
              }
            ]}
          />
        )}
      </Card>
    </>
  )
}
