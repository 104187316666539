export enum Suffixes {
  EUROS = " €",
  KILO_EUROS = " K€",
  DAYS = " j",
  PERCENTAGE = " %",
  MONTHS = " mois",
  YEARS = " ans",
  YEAR = " an",
  NONE = ""
}

export const eurosSuffix = (isKiloEuros: boolean) =>
  isKiloEuros ? Suffixes.KILO_EUROS : Suffixes.EUROS

export const yearSuffix = (value: number) => (Math.abs(value) > 1 ? Suffixes.YEARS : Suffixes.YEAR)
