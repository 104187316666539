import { useAppContext } from "../config/context"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { extract } from "../client/backend-client/AxiosUtils"
import { StructureDetailed, UpdateStructureRequest } from "../client/backend-client/generated"
import { useHeaders } from "./axios-headers"
import { areAddressEqual } from "./AddressQueries"

export const useGetStructure = (structureId: string) => {
  const { structureResourceApi } = useAppContext()
  const getHeaders = useHeaders()

  const { isLoading, data } = useQuery({
    queryKey: ["structure", structureId],
    queryFn: async () => {
      const { headers } = await getHeaders()
      return structureResourceApi.getStructure({ structureId }, { headers }).then(extract)
    },
    retry: 0,
    meta: {
      errorMessage: `Erreur lors de la récupération de la structure ${structureId}`
    }
  })

  return {
    isLoading,
    data
  }
}

export const useUpdateStructure = (structureId: string, projectId: number) => {
  const { structureResourceApi } = useAppContext()
  const getHeaders = useHeaders()
  const queryClient = useQueryClient()

  return useMutation({
    scope: {
      id: `update-structure-${structureId}`
    },
    mutationFn: async (request: UpdateStructureRequest) => {
      const { headers } = await getHeaders()
      return structureResourceApi
        .updateStructure({ structureId, projectId, updateStructureRequest: request }, { headers })
        .then(extract)
    },
    onSuccess: result => {
      queryClient.setQueryData<StructureDetailed>(["structure", structureId], result)
    },
    meta: {
      errorMessage: `Erreur lors de la mise à jour de la note`
    }
  })
}

export const toStructureRequest = (
  initialStructureRequest: UpdateStructureRequest,
  structureRequest: UpdateStructureRequest
): UpdateStructureRequest => {
  return {
    nom:
      initialStructureRequest.nom?.trim() !== structureRequest.nom?.trim()
        ? structureRequest.nom
        : undefined,
    nomCommercial:
      initialStructureRequest.nomCommercial?.trim() !== structureRequest.nomCommercial?.trim()
        ? structureRequest.nomCommercial
        : initialStructureRequest.nomCommercial,
    email:
      initialStructureRequest.email?.trim() !== structureRequest.email?.trim()
        ? structureRequest.email
        : initialStructureRequest.email,
    telephone:
      initialStructureRequest.telephone?.trim() !== structureRequest.telephone?.trim()
        ? structureRequest.telephone
        : initialStructureRequest.telephone,
    siret:
      initialStructureRequest.siret?.trim() !== structureRequest.siret?.trim()
        ? structureRequest.siret
        : undefined,
    dateCreation:
      initialStructureRequest.dateCreation !== structureRequest.dateCreation
        ? structureRequest.dateCreation
        : undefined,
    codeApe:
      initialStructureRequest.codeApe !== structureRequest.codeApe
        ? structureRequest.codeApe
        : initialStructureRequest.codeApe,
    finaliteSocietale:
      initialStructureRequest.finaliteSocietale !== structureRequest.finaliteSocietale
        ? structureRequest.finaliteSocietale
        : initialStructureRequest.finaliteSocietale,
    statutCooperatif:
      initialStructureRequest.statutCooperatif !== structureRequest.statutCooperatif
        ? structureRequest.statutCooperatif
        : initialStructureRequest.statutCooperatif,
    statutJuridique:
      initialStructureRequest.statutJuridique !== structureRequest.statutJuridique
        ? structureRequest.statutJuridique
        : initialStructureRequest.statutJuridique,
    territoireFragile:
      initialStructureRequest.territoireFragile !== structureRequest.territoireFragile
        ? structureRequest.territoireFragile
        : undefined,
    ess: initialStructureRequest.ess !== structureRequest.ess ? structureRequest.ess : undefined,
    agrementEsus:
      initialStructureRequest.agrementEsus !== structureRequest.agrementEsus
        ? structureRequest.agrementEsus
        : undefined,
    adresse: !areAddressEqual(initialStructureRequest.adresse, structureRequest.adresse)
      ? structureRequest.adresse
      : undefined
  }
}
