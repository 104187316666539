/* tslint:disable */
/* eslint-disable */
/**
 * Appli métier
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: Yoda 1 & 2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Advisor } from "./advisor"
import { BankPartnerV2 } from "./bank-partner-v2"
import { MuffinPartenaireBancaire } from "./muffin-partenaire-bancaire"
import { PdfPreferences } from "./pdf-preferences"

/**
 *
 * @export
 * @interface Note
 */
export interface Note {
  /**
   *
   * @type {string}
   * @memberof Note
   */
  structureId: string
  /**
   *
   * @type {string}
   * @memberof Note
   */
  noteId: string
  /**
   *
   * @type {string}
   * @memberof Note
   */
  comment?: string
  /**
   *
   * @type {string}
   * @memberof Note
   */
  commentCr?: string
  /**
   *
   * @type {number}
   * @memberof Note
   */
  numeroVersion: number
  /**
   *
   * @type {string}
   * @memberof Note
   */
  dateCreation: string
  /**
   *
   * @type {string}
   * @memberof Note
   */
  statut: NoteStatutEnum
  /**
   *
   * @type {string}
   * @memberof Note
   */
  conclusion?: string
  /**
   *
   * @type {string}
   * @memberof Note
   */
  modelEco: NoteModelEcoEnum
  /**
   *
   * @type {number}
   * @memberof Note
   */
  noteFinancier?: number
  /**
   *
   * @type {number}
   * @memberof Note
   */
  noteGlobale?: number
  /**
   *
   * @type {string}
   * @memberof Note
   */
  avancementBanque?: NoteAvancementBanqueEnum
  /**
   *
   * @type {PdfPreferences}
   * @memberof Note
   */
  pdfPreferences: PdfPreferences
  /**
   *
   * @type {MuffinPartenaireBancaire}
   * @memberof Note
   */
  partenaireBancaire?: MuffinPartenaireBancaire
  /**
   *
   * @type {BankPartnerV2}
   * @memberof Note
   */
  bankPartner?: BankPartnerV2
  /**
   *
   * @type {string}
   * @memberof Note
   */
  dateComite?: string
  /**
   *
   * @type {string}
   * @memberof Note
   */
  projectCommitteeDate?: string
  /**
   *
   * @type {Advisor}
   * @memberof Note
   */
  advisor?: Advisor
  /**
   *
   * @type {boolean}
   * @memberof Note
   */
  onlyPrime?: boolean
  /**
   *
   * @type {string}
   * @memberof Note
   */
  lifecycle: NoteLifecycleEnum
  /**
   *
   * @type {string}
   * @memberof Note
   */
  commentCrAnterieur?: string
  /**
   *
   * @type {boolean}
   * @memberof Note
   */
  creationExNihilo?: boolean
  /**
   *
   * @type {string}
   * @memberof Note
   */
  commentReason?: string
  /**
   *
   * @type {string}
   * @memberof Note
   */
  type: NoteTypeEnum
  /**
   *
   * @type {string}
   * @memberof Note
   */
  commentRevelateur?: string
  /**
   *
   * @type {string}
   * @memberof Note
   */
  commentBilanPasse?: string
  /**
   *
   * @type {string}
   * @memberof Note
   */
  lastSyncDate?: string
  /**
   *
   * @type {number}
   * @memberof Note
   */
  noubaProjectId?: number
}

/**
 * @export
 * @enum {string}
 */
export enum NoteStatutEnum {
  EN_COURS = "EN_COURS",
  ABANDON = "ABANDON",
  ATTENTE_DECISION = "ATTENTE_DECISION",
  FINALISEE = "FINALISEE"
}
/**
 * @export
 * @enum {string}
 */
export enum NoteModelEcoEnum {
  MARCHAND = "MARCHAND",
  NON_MARCHAND = "NON_MARCHAND",
  HYBRIDE = "HYBRIDE"
}
/**
 * @export
 * @enum {string}
 */
export enum NoteAvancementBanqueEnum {
  PAS_DE_CONTACT_OU_DEBUT_DE_LA_DEMANDE_EN_COURS = "PAS_DE_CONTACT_OU_DEBUT_DE_LA_DEMANDE_EN_COURS",
  EN_ATTENTE_REPONSE_DE_LA_BANQUE = "EN_ATTENTE_REPONSE_DE_LA_BANQUE",
  ACCORD_DE_PRINCIPE_ORAL = "ACCORD_DE_PRINCIPE_ORAL",
  ACCORD_ECRIT = "ACCORD_ECRIT",
  REFUS_DE_LA_BANQUE = "REFUS_DE_LA_BANQUE",
  NON_CONCERNE = "NON_CONCERNE"
}
/**
 * @export
 * @enum {string}
 */
export enum NoteLifecycleEnum {
  EMERGENCE = "EMERGENCE",
  CREATION = "CREATION",
  REPRISE = "REPRISE",
  DEVELOPMENT = "DEVELOPMENT",
  REBOUND = "REBOUND",
  SCALE = "SCALE"
}
/**
 * @export
 * @enum {string}
 */
export enum NoteTypeEnum {
  CREATION = "CREATION",
  REPRISE = "REPRISE",
  STRUCTURE_EXISTANTE = "STRUCTURE_EXISTANTE",
  UNDEFINED_CREATION = "UNDEFINED_CREATION"
}
