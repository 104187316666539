import React, { FC } from "react"
import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons"
import "./EvolutionValue.scss"

export const EvolutionValue: FC<{
  value?: number
}> = ({ value }) => {
  if (value === undefined || value === null) {
    return <></>
  }

  const icon = value === 0 ? <></> : value > 0 ? <ArrowUpOutlined /> : <ArrowDownOutlined />

  return (
    <span className="evolution-value">
      {icon} {Math.abs(value)} %
    </span>
  )
}
