import React, { CSSProperties, FC, useEffect, useState } from "react"
import { Button, Col, Layout, Menu, MenuProps, Row } from "antd"
import { SiderMenuItem, useSiderMenuItems } from "./SiderMenuItems"
import { Link, useLocation } from "react-router-dom"
import { DoubleLeftOutlined, DoubleRightOutlined } from "@ant-design/icons"
import { NAV_BAR_HEIGHT } from "../NavBar/NavBar"

const { Sider } = Layout

type MenuItem = Required<MenuProps>["items"][number]

const toMenuItem: (siderMenuItem: SiderMenuItem) => MenuItem = ({
  label,
  link,
  key,
  icon
}: SiderMenuItem) => ({
  label: link ? <Link to={link}>{label}</Link> : label,
  key,
  icon
})

const SIDER_MENU_WIDTH = "20em"
const SIDER_MENU_STYLE: CSSProperties = {
  overflow: "auto",
  height: `calc(100vh - ${NAV_BAR_HEIGHT.toString()}px)`,
  position: "sticky",
  zIndex: 10,
  top: NAV_BAR_HEIGHT,
  left: 0,
  bottom: 0,
  insetInlineStart: 0,
  scrollbarWidth: "thin",
  scrollbarGutter: "stable"
}

export const SiderMenu: FC = () => {
  const location = useLocation()

  const [collapsed, setCollapsed] = useState(false)
  const [selectedMenu, setSelectedMenu] = useState<string>()

  const siderMenuItems = useSiderMenuItems()

  useEffect(() => {
    setSelectedMenu(() => {
      const found = siderMenuItems.find(({ link }) => link === location.pathname)?.key
      return (
        found ??
        siderMenuItems
          .flatMap(({ children }) => (children ? children : []))
          .find(({ link }) => link === location.pathname)?.key
      )
    })
  }, [siderMenuItems, location])

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      trigger={null}
      onCollapse={value => setCollapsed(value)}
      width={SIDER_MENU_WIDTH}
      style={SIDER_MENU_STYLE}
      theme="light"
    >
      <Row justify="center">
        <Col>
          <Button
            type="default"
            shape="circle"
            size="large"
            onClick={() => setCollapsed(!collapsed)}
            style={{ marginBottom: 8, marginTop: 8 }}
          >
            {collapsed ? <DoubleRightOutlined /> : <DoubleLeftOutlined />}
          </Button>
        </Col>
      </Row>
      <Menu
        theme="light"
        selectedKeys={selectedMenu ? [selectedMenu] : undefined}
        defaultOpenKeys={["financial", "analysis"]}
        mode="inline"
        items={siderMenuItems.map(
          i =>
            ({
              ...toMenuItem(i),
              children: i.children?.map(toMenuItem)
            }) as MenuItem
        )}
      />
    </Sider>
  )
}
