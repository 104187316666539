import React, { FC } from "react"
import { useDictionary } from "../../utils/dictionary/dictionary"
import { NavLink } from "react-router-dom"

export const CRLink: FC = () => {
  const {
    cr: { linkText }
  } = useDictionary()
  return <NavLink to={"../income-statement"}>{linkText}</NavLink>
}
