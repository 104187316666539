import React, { FC } from "react"
import { MapContainer, Marker, TileLayer } from "react-leaflet"
import { icon } from "leaflet"

import iconMain from "leaflet/dist/images/marker-icon.png"
import iconShadow from "leaflet/dist/images/marker-shadow.png"

const DefaultIcon = icon({
  iconUrl: iconMain,
  shadowUrl: iconShadow
})

export const LeafletMap: FC<{
  latitude: number
  longitude: number
  mapContainerHeight?: string
}> = ({ latitude, longitude, mapContainerHeight = "50vh" }) => (
  <MapContainer
    center={[latitude, longitude]}
    zoom={14}
    scrollWheelZoom={false}
    style={{
      width: "100%",
      height: mapContainerHeight
    }}
  >
    <TileLayer
      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    />
    <Marker position={[latitude, longitude]} icon={DefaultIcon} />
  </MapContainer>
)
