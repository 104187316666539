import React, { FC, SVGProps } from "react"

const EnvironmentIcon: FC<SVGProps<SVGSVGElement>> = ({
  height = "16",
  width = "16",
  color = "black",
  fill = "black",
  viewBox = "0 0 150 150",
  ...props
}) => {
  return (
    <svg
      id="Calque_1"
      data-name="Calque 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox}
      color={color}
      fill={fill}
      height={height}
      width={width}
      {...props}
    >
      <path d="M111.93,94.32s-1.69-1.53-3.08-2.84c-2.37-2.23-5.85-1.28-6.19-1.18L89.23,94a5.58,5.58,0,0,0-5.58-5.52H72.43C68.12,83.89,61.31,83.84,61,83.84h-7.6V82.65a1.75,1.75,0,0,0-1.75-1.75H39.25a1.75,1.75,0,0,0-1.75,1.75v22.83a1.75,1.75,0,0,0,1.75,1.75H51.66a1.75,1.75,0,0,0,1.75-1.75v-.31l17,5.57a25.66,25.66,0,0,0,7.08,1.11,25.34,25.34,0,0,0,9.32-2.25l24.75-12.42a1.78,1.78,0,0,0,.95-1.33A1.75,1.75,0,0,0,111.93,94.32Zm-62,9.41H41V84.4h8.91Zm35.36,2.72a22,22,0,0,1-7.8,1.9,22.48,22.48,0,0,1-6-.94l-18.06-5.92V87.34H61c.06,0,6.1.07,9.3,4a1.75,1.75,0,0,0,1.35.64h12a2.08,2.08,0,0,1,0,4.16H67.36a1.75,1.75,0,1,0,0,3.5H83.65A5.55,5.55,0,0,0,87.5,98l16.11-4.37c.4-.12,2-.4,2.84.35l1.25,1.17Z" />
      <path d="M60,72.31a11.24,11.24,0,0,0,5.23,1.49,7.59,7.59,0,0,0,2.28-.35,9.65,9.65,0,0,0,4.86-3.92A64.33,64.33,0,0,1,78,82.85a1.75,1.75,0,0,0,1.62,1.26h.07A1.75,1.75,0,0,0,81.31,83a40.52,40.52,0,0,1,4.27-8c1.27,2.23,3.55,4.92,7.09,4.92,5.25,0,9.72-4.45,11.39-11.33a28,28,0,0,0-.6-13.86,1.7,1.7,0,0,0-1.07-1.1,1.73,1.73,0,0,0-1.53.17,26.34,26.34,0,0,1-5.55,2.59c-.59.18-1.24.34-1.93.52-3.19.82-7.15,1.84-10,4.55a7.94,7.94,0,0,0-2.21,6.82A6.31,6.31,0,0,0,83.36,72a44.57,44.57,0,0,0-3.43,5.53,69.35,69.35,0,0,0-5.05-10.83c2.49-1.54,6.77-4.95,6.33-10A13.55,13.55,0,0,0,71.56,44.6c-1.34-.45-2.92-.83-4.6-1.22-4.15-1-8.85-2.08-12-4.79a1.73,1.73,0,0,0-1.39-.42,1.78,1.78,0,0,0-1.23.77c-.14.23-3.62,5.65-2.34,15.46C51.29,63.93,54.28,69.28,60,72.31ZM84.6,67.63A4.43,4.43,0,0,1,85.76,64c2.22-2.1,5.69-3,8.49-3.71.73-.19,1.42-.37,2-.55a25.1,25.1,0,0,0,4.36-1.82,23.69,23.69,0,0,1,0,9.84c-1.28,5.25-4.41,8.65-8,8.65-2.45,0-4.06-3-4.72-4.48a20,20,0,0,1,6.46-5A1.75,1.75,0,1,0,93,63.68a23.28,23.28,0,0,0-7.37,5.61A2.74,2.74,0,0,1,84.6,67.63Zm-30.08-25c3.55,2.3,8,3.34,11.64,4.2,1.59.37,3.1.72,4.27,1.12a10.12,10.12,0,0,1,7.29,9.14c.28,3.14-2.81,5.53-4.66,6.68A64.29,64.29,0,0,0,60.43,49.07a1.75,1.75,0,1,0-2.18,2.74A63,63,0,0,1,70.41,66.15c-1.25,2.22-2.54,3.52-3.93,4s-2.87.12-4.81-.9C57,66.75,54.61,62.32,53.51,54A23.69,23.69,0,0,1,54.52,42.59Z" />
    </svg>
  )
}

export default EnvironmentIcon
