import { useAppContext } from "../config/context"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { extract } from "../client/backend-client/AxiosUtils"
import {
  CheckTermsOfUseRequest,
  Project,
  UpdateProjectRequest
} from "../client/backend-client/generated"
import { useHeaders } from "./axios-headers"
import _ from "lodash"

export const useGetProject = (projectId: number) => {
  const {
    projectResourceV2Api,
    userHeaders: { xRequestResourceForAtIdX }
  } = useAppContext()
  const getHeaders = useHeaders()

  const { isLoading, data, error } = useQuery({
    queryKey: ["project", projectId],
    queryFn: async () => {
      const { headers } = await getHeaders()
      return projectResourceV2Api
        .getProject({ projectId, xRequestResourceForAtIdX }, { headers })
        .then(extract)
    },
    retry: 0,
    meta: {
      errorMessage: `Erreur lors de la récupération du projet ${projectId}`
    }
  })

  return {
    isLoading,
    data,
    error
  }
}

export const useUpdateProject = (projectId: number) => {
  const { projectResourceV2Api } = useAppContext()
  const getHeaders = useHeaders()
  const queryClient = useQueryClient()

  return useMutation({
    scope: {
      id: `update-project-${projectId}`
    },
    mutationFn: async (request: UpdateProjectRequest) => {
      const { headers } = await getHeaders()
      return projectResourceV2Api
        .updateProject({ projectId, updateProjectRequest: request }, { headers })
        .then(extract)
    },
    onSuccess: result => {
      queryClient.setQueryData<Project>(["project", projectId], result)
    },
    meta: {
      errorMessage: `Erreur lors de la mise à jour de la note`
    }
  })
}

export const useGetBankPartners = () => {
  const { fundingResourceApi } = useAppContext()
  const getHeaders = useHeaders()

  const { isLoading, data } = useQuery({
    queryKey: ["bank-partner-bank"],
    queryFn: async () => {
      const { headers } = await getHeaders()
      return fundingResourceApi.getBankPartners({ headers }).then(extract)
    },
    retry: 0,
    meta: {
      errorMessage: "Erreur lors de la récupération des partenaires bancaires"
    }
  })

  return {
    isLoading,
    data
  }
}

export const useGetPartnerAgencies = (bankCode?: string) => {
  const { fundingResourceApi } = useAppContext()
  const getHeaders = useHeaders()

  const { isFetching, refetch, isLoading, data } = useQuery({
    queryKey: ["bank-partner-agencies", bankCode],
    queryFn: async () => {
      const { headers } = await getHeaders()
      return fundingResourceApi.getAgencies({ bankCode }, { headers }).then(extract)
    },
    retry: 0,
    meta: {
      errorMessage: "Erreur lors de la récupération des agences bancaires"
    }
  })

  return {
    refetch,
    isFetching,
    isLoading,
    data
  }
}

export const useCheckTermsOfUseQuery = (projectId: number) => {
  const getHeaders = useHeaders()
  const { projectResourceV2Api } = useAppContext()

  return useMutation({
    mutationFn: async (request: CheckTermsOfUseRequest) => {
      const { headers } = await getHeaders()
      return projectResourceV2Api
        .checkTermsOfUse({ projectId, checkTermsOfUseRequest: request }, { headers })
        .then(extract)
    },
    meta: {
      errorMessage: `Erreur lors de la vérification des conditions d'utilisation`
    }
  })
}

export const toProjectRequest = (
  initialProjectRequest: UpdateProjectRequest,
  projectRequest: UpdateProjectRequest
): UpdateProjectRequest => {
  return {
    label:
      initialProjectRequest.label?.trim() !== projectRequest.label?.trim()
        ? projectRequest.label
        : undefined,
    shortDescription:
      initialProjectRequest.shortDescription?.trim() !== projectRequest.shortDescription?.trim()
        ? projectRequest.shortDescription
        : undefined,
    projectLeadCreatorCode:
      initialProjectRequest.projectLeadCreatorCode !== projectRequest.projectLeadCreatorCode
        ? projectRequest.projectLeadCreatorCode
        : undefined,
    projectLeadDelegatedCode:
      initialProjectRequest.projectLeadDelegatedCode !== projectRequest.projectLeadDelegatedCode
        ? projectRequest.projectLeadDelegatedCode
        : initialProjectRequest.projectLeadDelegatedCode,
    fundingObject:
      initialProjectRequest.fundingObject !== projectRequest.fundingObject
        ? projectRequest.fundingObject
        : undefined,
    prescriber:
      initialProjectRequest.prescriber?.code !== projectRequest.prescriber?.code
        ? projectRequest.prescriber
        : undefined,
    companion: projectRequest.companion?.code !== initialProjectRequest.companion?.code ? projectRequest.companion : initialProjectRequest.companion,
    isComplex:
      initialProjectRequest.isComplex !== projectRequest.isComplex
        ? projectRequest.isComplex
        : undefined,
    complexComment:
      initialProjectRequest.complexComment?.trim() !== projectRequest.complexComment?.trim()
        ? projectRequest.complexComment
        : undefined,
      donneesEmploisProjet:
        !_.isEqual(initialProjectRequest.donneesEmploisProjet, projectRequest.donneesEmploisProjet)
        ? projectRequest.donneesEmploisProjet
        :undefined
  }
}
