/* tslint:disable */
/* eslint-disable */
/**
 * Appli métier
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: Yoda 1 & 2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from "axios"
import { Configuration } from "../configuration"
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction
} from "../common"
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from "../base"
// @ts-ignore
import { Scoring } from "../model"
// @ts-ignore
import { ScoringCreationParametersV2 } from "../model"
// @ts-ignore
import { ScoringDeveloppementParametersV2 } from "../model"
/**
 * ScoringOctroiResourceV2Api - axios parameter creator
 * @export
 */
export const ScoringOctroiResourceV2ApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @summary Get note\'s scoring parameters for CREATION/REPRISE notes
     * @param {string} noteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkScoringCreation: async (
      noteId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'noteId' is not null or undefined
      assertParamExists("checkScoringCreation", "noteId", noteId)
      const localVarPath = `/v2.0/scoring/creation/check/{noteId}`.replace(
        `{${"noteId"}}`,
        encodeURIComponent(String(noteId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary Get note\'s scoring parameters for DEVELOPMENT notes
     * @param {string} noteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkScoringDevelopment: async (
      noteId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'noteId' is not null or undefined
      assertParamExists("checkScoringDevelopment", "noteId", noteId)
      const localVarPath = `/v2.0/scoring/development/check/{noteId}`.replace(
        `{${"noteId"}}`,
        encodeURIComponent(String(noteId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary Get scoring by note id
     * @param {string} noteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getScoring: async (noteId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'noteId' is not null or undefined
      assertParamExists("getScoring", "noteId", noteId)
      const localVarPath = `/v2.0/scoring/{noteId}`.replace(
        `{${"noteId"}}`,
        encodeURIComponent(String(noteId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary Update scoring for CREATION/REPRISE notes
     * @param {string} noteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateScoringCreation: async (
      noteId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'noteId' is not null or undefined
      assertParamExists("updateScoringCreation", "noteId", noteId)
      const localVarPath = `/v2.0/scoring/creation/{noteId}`.replace(
        `{${"noteId"}}`,
        encodeURIComponent(String(noteId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary Update scoring for DEVELOPMENT notes
     * @param {string} noteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateScoringDevelopment: async (
      noteId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'noteId' is not null or undefined
      assertParamExists("updateScoringDevelopment", "noteId", noteId)
      const localVarPath = `/v2.0/scoring/development/{noteId}`.replace(
        `{${"noteId"}}`,
        encodeURIComponent(String(noteId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * ScoringOctroiResourceV2Api - functional programming interface
 * @export
 */
export const ScoringOctroiResourceV2ApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ScoringOctroiResourceV2ApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @summary Get note\'s scoring parameters for CREATION/REPRISE notes
     * @param {string} noteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async checkScoringCreation(
      noteId: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScoringCreationParametersV2>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.checkScoringCreation(
        noteId,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Get note\'s scoring parameters for DEVELOPMENT notes
     * @param {string} noteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async checkScoringDevelopment(
      noteId: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScoringDeveloppementParametersV2>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.checkScoringDevelopment(
        noteId,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Get scoring by note id
     * @param {string} noteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getScoring(
      noteId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Scoring>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getScoring(noteId, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Update scoring for CREATION/REPRISE notes
     * @param {string} noteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateScoringCreation(
      noteId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Scoring>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateScoringCreation(
        noteId,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Update scoring for DEVELOPMENT notes
     * @param {string} noteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateScoringDevelopment(
      noteId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Scoring>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateScoringDevelopment(
        noteId,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    }
  }
}

/**
 * ScoringOctroiResourceV2Api - factory interface
 * @export
 */
export const ScoringOctroiResourceV2ApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = ScoringOctroiResourceV2ApiFp(configuration)
  return {
    /**
     *
     * @summary Get note\'s scoring parameters for CREATION/REPRISE notes
     * @param {string} noteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkScoringCreation(noteId: string, options?: any): AxiosPromise<ScoringCreationParametersV2> {
      return localVarFp
        .checkScoringCreation(noteId, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Get note\'s scoring parameters for DEVELOPMENT notes
     * @param {string} noteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkScoringDevelopment(
      noteId: string,
      options?: any
    ): AxiosPromise<ScoringDeveloppementParametersV2> {
      return localVarFp
        .checkScoringDevelopment(noteId, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Get scoring by note id
     * @param {string} noteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getScoring(noteId: string, options?: any): AxiosPromise<Scoring> {
      return localVarFp.getScoring(noteId, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Update scoring for CREATION/REPRISE notes
     * @param {string} noteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateScoringCreation(noteId: string, options?: any): AxiosPromise<Scoring> {
      return localVarFp
        .updateScoringCreation(noteId, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Update scoring for DEVELOPMENT notes
     * @param {string} noteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateScoringDevelopment(noteId: string, options?: any): AxiosPromise<Scoring> {
      return localVarFp
        .updateScoringDevelopment(noteId, options)
        .then(request => request(axios, basePath))
    }
  }
}

/**
 * Request parameters for checkScoringCreation operation in ScoringOctroiResourceV2Api.
 * @export
 * @interface ScoringOctroiResourceV2ApiCheckScoringCreationRequest
 */
export interface ScoringOctroiResourceV2ApiCheckScoringCreationRequest {
  /**
   *
   * @type {string}
   * @memberof ScoringOctroiResourceV2ApiCheckScoringCreation
   */
  readonly noteId: string
}

/**
 * Request parameters for checkScoringDevelopment operation in ScoringOctroiResourceV2Api.
 * @export
 * @interface ScoringOctroiResourceV2ApiCheckScoringDevelopmentRequest
 */
export interface ScoringOctroiResourceV2ApiCheckScoringDevelopmentRequest {
  /**
   *
   * @type {string}
   * @memberof ScoringOctroiResourceV2ApiCheckScoringDevelopment
   */
  readonly noteId: string
}

/**
 * Request parameters for getScoring operation in ScoringOctroiResourceV2Api.
 * @export
 * @interface ScoringOctroiResourceV2ApiGetScoringRequest
 */
export interface ScoringOctroiResourceV2ApiGetScoringRequest {
  /**
   *
   * @type {string}
   * @memberof ScoringOctroiResourceV2ApiGetScoring
   */
  readonly noteId: string
}

/**
 * Request parameters for updateScoringCreation operation in ScoringOctroiResourceV2Api.
 * @export
 * @interface ScoringOctroiResourceV2ApiUpdateScoringCreationRequest
 */
export interface ScoringOctroiResourceV2ApiUpdateScoringCreationRequest {
  /**
   *
   * @type {string}
   * @memberof ScoringOctroiResourceV2ApiUpdateScoringCreation
   */
  readonly noteId: string
}

/**
 * Request parameters for updateScoringDevelopment operation in ScoringOctroiResourceV2Api.
 * @export
 * @interface ScoringOctroiResourceV2ApiUpdateScoringDevelopmentRequest
 */
export interface ScoringOctroiResourceV2ApiUpdateScoringDevelopmentRequest {
  /**
   *
   * @type {string}
   * @memberof ScoringOctroiResourceV2ApiUpdateScoringDevelopment
   */
  readonly noteId: string
}

/**
 * ScoringOctroiResourceV2Api - object-oriented interface
 * @export
 * @class ScoringOctroiResourceV2Api
 * @extends {BaseAPI}
 */
export class ScoringOctroiResourceV2Api extends BaseAPI {
  /**
   *
   * @summary Get note\'s scoring parameters for CREATION/REPRISE notes
   * @param {ScoringOctroiResourceV2ApiCheckScoringCreationRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ScoringOctroiResourceV2Api
   */
  public checkScoringCreation(
    requestParameters: ScoringOctroiResourceV2ApiCheckScoringCreationRequest,
    options?: AxiosRequestConfig
  ) {
    return ScoringOctroiResourceV2ApiFp(this.configuration)
      .checkScoringCreation(requestParameters.noteId, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get note\'s scoring parameters for DEVELOPMENT notes
   * @param {ScoringOctroiResourceV2ApiCheckScoringDevelopmentRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ScoringOctroiResourceV2Api
   */
  public checkScoringDevelopment(
    requestParameters: ScoringOctroiResourceV2ApiCheckScoringDevelopmentRequest,
    options?: AxiosRequestConfig
  ) {
    return ScoringOctroiResourceV2ApiFp(this.configuration)
      .checkScoringDevelopment(requestParameters.noteId, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get scoring by note id
   * @param {ScoringOctroiResourceV2ApiGetScoringRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ScoringOctroiResourceV2Api
   */
  public getScoring(
    requestParameters: ScoringOctroiResourceV2ApiGetScoringRequest,
    options?: AxiosRequestConfig
  ) {
    return ScoringOctroiResourceV2ApiFp(this.configuration)
      .getScoring(requestParameters.noteId, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Update scoring for CREATION/REPRISE notes
   * @param {ScoringOctroiResourceV2ApiUpdateScoringCreationRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ScoringOctroiResourceV2Api
   */
  public updateScoringCreation(
    requestParameters: ScoringOctroiResourceV2ApiUpdateScoringCreationRequest,
    options?: AxiosRequestConfig
  ) {
    return ScoringOctroiResourceV2ApiFp(this.configuration)
      .updateScoringCreation(requestParameters.noteId, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Update scoring for DEVELOPMENT notes
   * @param {ScoringOctroiResourceV2ApiUpdateScoringDevelopmentRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ScoringOctroiResourceV2Api
   */
  public updateScoringDevelopment(
    requestParameters: ScoringOctroiResourceV2ApiUpdateScoringDevelopmentRequest,
    options?: AxiosRequestConfig
  ) {
    return ScoringOctroiResourceV2ApiFp(this.configuration)
      .updateScoringDevelopment(requestParameters.noteId, options)
      .then(request => request(this.axios, this.basePath))
  }
}
