/* tslint:disable */
/* eslint-disable */
/**
 * Appli métier
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: Yoda 1 & 2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from "axios"
import { Configuration } from "../configuration"
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction
} from "../common"
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from "../base"
// @ts-ignore
import { AddAnswerRequest } from "../model"
// @ts-ignore
import { CreateEntrepreneurRequest } from "../model"
// @ts-ignore
import { Entrepreneur } from "../model"
// @ts-ignore
import { EntrepreneurQuestionResult } from "../model"
// @ts-ignore
import { SaveHouseHoldRequest } from "../model"
// @ts-ignore
import { UpdateEntrepreneurRequest } from "../model"
/**
 * EntrepreneurResourceApi - axios parameter creator
 * @export
 */
export const EntrepreneurResourceApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Add or update an answer to an entrepreneur\'s question
     * @param {string} entrepreneurId
     * @param {AddAnswerRequest} addAnswerRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addAnswer: async (
      entrepreneurId: string,
      addAnswerRequest: AddAnswerRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'entrepreneurId' is not null or undefined
      assertParamExists("addAnswer", "entrepreneurId", entrepreneurId)
      // verify required parameter 'addAnswerRequest' is not null or undefined
      assertParamExists("addAnswer", "addAnswerRequest", addAnswerRequest)
      const localVarPath = `/entrepreneurs/{entrepreneurId}/answer`.replace(
        `{${"entrepreneurId"}}`,
        encodeURIComponent(String(entrepreneurId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        addAnswerRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary Create entrepreneur
     * @param {string} noteId
     * @param {CreateEntrepreneurRequest} createEntrepreneurRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createEntrepreneur: async (
      noteId: string,
      createEntrepreneurRequest: CreateEntrepreneurRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'noteId' is not null or undefined
      assertParamExists("createEntrepreneur", "noteId", noteId)
      // verify required parameter 'createEntrepreneurRequest' is not null or undefined
      assertParamExists(
        "createEntrepreneur",
        "createEntrepreneurRequest",
        createEntrepreneurRequest
      )
      const localVarPath = `/entrepreneurs/projects/{noteId}`.replace(
        `{${"noteId"}}`,
        encodeURIComponent(String(noteId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        createEntrepreneurRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary Delete an entrepreneur by muffin id
     * @param {string} idMuffin
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteEntrepreneur: async (
      idMuffin: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'idMuffin' is not null or undefined
      assertParamExists("deleteEntrepreneur", "idMuffin", idMuffin)
      const localVarPath = `/entrepreneurs/{idMuffin}`.replace(
        `{${"idMuffin"}}`,
        encodeURIComponent(String(idMuffin))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary Get entrepreneurs by note id
     * @param {string} noteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEntrepreneursByNoteId1: async (
      noteId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'noteId' is not null or undefined
      assertParamExists("getEntrepreneursByNoteId1", "noteId", noteId)
      const localVarPath = `/entrepreneurs/projects/{noteId}`.replace(
        `{${"noteId"}}`,
        encodeURIComponent(String(noteId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary Check if a project has an entrepreneur account
     * @param {string} projectId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    hasEntrepreneurAccount: async (
      projectId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'projectId' is not null or undefined
      assertParamExists("hasEntrepreneurAccount", "projectId", projectId)
      const localVarPath = `/entrepreneurs/projects/{projectId}/hasEntrepreneurAccount`.replace(
        `{${"projectId"}}`,
        encodeURIComponent(String(projectId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary Update entrepreneur\'s household infos
     * @param {string} entrepreneurId
     * @param {SaveHouseHoldRequest} saveHouseHoldRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveHouseHold: async (
      entrepreneurId: string,
      saveHouseHoldRequest: SaveHouseHoldRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'entrepreneurId' is not null or undefined
      assertParamExists("saveHouseHold", "entrepreneurId", entrepreneurId)
      // verify required parameter 'saveHouseHoldRequest' is not null or undefined
      assertParamExists("saveHouseHold", "saveHouseHoldRequest", saveHouseHoldRequest)
      const localVarPath = `/entrepreneurs/{entrepreneurId}/household`.replace(
        `{${"entrepreneurId"}}`,
        encodeURIComponent(String(entrepreneurId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        saveHouseHoldRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary Update entrepreneur
     * @param {string} entrepreneurId
     * @param {UpdateEntrepreneurRequest} updateEntrepreneurRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateEntrepreneur: async (
      entrepreneurId: string,
      updateEntrepreneurRequest: UpdateEntrepreneurRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'entrepreneurId' is not null or undefined
      assertParamExists("updateEntrepreneur", "entrepreneurId", entrepreneurId)
      // verify required parameter 'updateEntrepreneurRequest' is not null or undefined
      assertParamExists(
        "updateEntrepreneur",
        "updateEntrepreneurRequest",
        updateEntrepreneurRequest
      )
      const localVarPath = `/entrepreneurs/{entrepreneurId}`.replace(
        `{${"entrepreneurId"}}`,
        encodeURIComponent(String(entrepreneurId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateEntrepreneurRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * EntrepreneurResourceApi - functional programming interface
 * @export
 */
export const EntrepreneurResourceApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = EntrepreneurResourceApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @summary Add or update an answer to an entrepreneur\'s question
     * @param {string} entrepreneurId
     * @param {AddAnswerRequest} addAnswerRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addAnswer(
      entrepreneurId: string,
      addAnswerRequest: AddAnswerRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntrepreneurQuestionResult>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.addAnswer(
        entrepreneurId,
        addAnswerRequest,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Create entrepreneur
     * @param {string} noteId
     * @param {CreateEntrepreneurRequest} createEntrepreneurRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createEntrepreneur(
      noteId: string,
      createEntrepreneurRequest: CreateEntrepreneurRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Entrepreneur>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createEntrepreneur(
        noteId,
        createEntrepreneurRequest,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Delete an entrepreneur by muffin id
     * @param {string} idMuffin
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteEntrepreneur(
      idMuffin: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteEntrepreneur(
        idMuffin,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Get entrepreneurs by note id
     * @param {string} noteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getEntrepreneursByNoteId1(
      noteId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Entrepreneur>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getEntrepreneursByNoteId1(
        noteId,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Check if a project has an entrepreneur account
     * @param {string} projectId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async hasEntrepreneurAccount(
      projectId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.hasEntrepreneurAccount(
        projectId,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Update entrepreneur\'s household infos
     * @param {string} entrepreneurId
     * @param {SaveHouseHoldRequest} saveHouseHoldRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async saveHouseHold(
      entrepreneurId: string,
      saveHouseHoldRequest: SaveHouseHoldRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Entrepreneur>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.saveHouseHold(
        entrepreneurId,
        saveHouseHoldRequest,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Update entrepreneur
     * @param {string} entrepreneurId
     * @param {UpdateEntrepreneurRequest} updateEntrepreneurRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateEntrepreneur(
      entrepreneurId: string,
      updateEntrepreneurRequest: UpdateEntrepreneurRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Entrepreneur>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateEntrepreneur(
        entrepreneurId,
        updateEntrepreneurRequest,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    }
  }
}

/**
 * EntrepreneurResourceApi - factory interface
 * @export
 */
export const EntrepreneurResourceApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = EntrepreneurResourceApiFp(configuration)
  return {
    /**
     *
     * @summary Add or update an answer to an entrepreneur\'s question
     * @param {string} entrepreneurId
     * @param {AddAnswerRequest} addAnswerRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addAnswer(
      entrepreneurId: string,
      addAnswerRequest: AddAnswerRequest,
      options?: any
    ): AxiosPromise<EntrepreneurQuestionResult> {
      return localVarFp
        .addAnswer(entrepreneurId, addAnswerRequest, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Create entrepreneur
     * @param {string} noteId
     * @param {CreateEntrepreneurRequest} createEntrepreneurRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createEntrepreneur(
      noteId: string,
      createEntrepreneurRequest: CreateEntrepreneurRequest,
      options?: any
    ): AxiosPromise<Entrepreneur> {
      return localVarFp
        .createEntrepreneur(noteId, createEntrepreneurRequest, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Delete an entrepreneur by muffin id
     * @param {string} idMuffin
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteEntrepreneur(idMuffin: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .deleteEntrepreneur(idMuffin, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Get entrepreneurs by note id
     * @param {string} noteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEntrepreneursByNoteId1(noteId: string, options?: any): AxiosPromise<Array<Entrepreneur>> {
      return localVarFp
        .getEntrepreneursByNoteId1(noteId, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Check if a project has an entrepreneur account
     * @param {string} projectId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    hasEntrepreneurAccount(projectId: string, options?: any): AxiosPromise<boolean> {
      return localVarFp
        .hasEntrepreneurAccount(projectId, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Update entrepreneur\'s household infos
     * @param {string} entrepreneurId
     * @param {SaveHouseHoldRequest} saveHouseHoldRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveHouseHold(
      entrepreneurId: string,
      saveHouseHoldRequest: SaveHouseHoldRequest,
      options?: any
    ): AxiosPromise<Entrepreneur> {
      return localVarFp
        .saveHouseHold(entrepreneurId, saveHouseHoldRequest, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Update entrepreneur
     * @param {string} entrepreneurId
     * @param {UpdateEntrepreneurRequest} updateEntrepreneurRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateEntrepreneur(
      entrepreneurId: string,
      updateEntrepreneurRequest: UpdateEntrepreneurRequest,
      options?: any
    ): AxiosPromise<Entrepreneur> {
      return localVarFp
        .updateEntrepreneur(entrepreneurId, updateEntrepreneurRequest, options)
        .then(request => request(axios, basePath))
    }
  }
}

/**
 * Request parameters for addAnswer operation in EntrepreneurResourceApi.
 * @export
 * @interface EntrepreneurResourceApiAddAnswerRequest
 */
export interface EntrepreneurResourceApiAddAnswerRequest {
  /**
   *
   * @type {string}
   * @memberof EntrepreneurResourceApiAddAnswer
   */
  readonly entrepreneurId: string

  /**
   *
   * @type {AddAnswerRequest}
   * @memberof EntrepreneurResourceApiAddAnswer
   */
  readonly addAnswerRequest: AddAnswerRequest
}

/**
 * Request parameters for createEntrepreneur operation in EntrepreneurResourceApi.
 * @export
 * @interface EntrepreneurResourceApiCreateEntrepreneurRequest
 */
export interface EntrepreneurResourceApiCreateEntrepreneurRequest {
  /**
   *
   * @type {string}
   * @memberof EntrepreneurResourceApiCreateEntrepreneur
   */
  readonly noteId: string

  /**
   *
   * @type {CreateEntrepreneurRequest}
   * @memberof EntrepreneurResourceApiCreateEntrepreneur
   */
  readonly createEntrepreneurRequest: CreateEntrepreneurRequest
}

/**
 * Request parameters for deleteEntrepreneur operation in EntrepreneurResourceApi.
 * @export
 * @interface EntrepreneurResourceApiDeleteEntrepreneurRequest
 */
export interface EntrepreneurResourceApiDeleteEntrepreneurRequest {
  /**
   *
   * @type {string}
   * @memberof EntrepreneurResourceApiDeleteEntrepreneur
   */
  readonly idMuffin: string
}

/**
 * Request parameters for getEntrepreneursByNoteId1 operation in EntrepreneurResourceApi.
 * @export
 * @interface EntrepreneurResourceApiGetEntrepreneursByNoteId1Request
 */
export interface EntrepreneurResourceApiGetEntrepreneursByNoteId1Request {
  /**
   *
   * @type {string}
   * @memberof EntrepreneurResourceApiGetEntrepreneursByNoteId1
   */
  readonly noteId: string
}

/**
 * Request parameters for hasEntrepreneurAccount operation in EntrepreneurResourceApi.
 * @export
 * @interface EntrepreneurResourceApiHasEntrepreneurAccountRequest
 */
export interface EntrepreneurResourceApiHasEntrepreneurAccountRequest {
  /**
   *
   * @type {string}
   * @memberof EntrepreneurResourceApiHasEntrepreneurAccount
   */
  readonly projectId: string
}

/**
 * Request parameters for saveHouseHold operation in EntrepreneurResourceApi.
 * @export
 * @interface EntrepreneurResourceApiSaveHouseHoldRequest
 */
export interface EntrepreneurResourceApiSaveHouseHoldRequest {
  /**
   *
   * @type {string}
   * @memberof EntrepreneurResourceApiSaveHouseHold
   */
  readonly entrepreneurId: string

  /**
   *
   * @type {SaveHouseHoldRequest}
   * @memberof EntrepreneurResourceApiSaveHouseHold
   */
  readonly saveHouseHoldRequest: SaveHouseHoldRequest
}

/**
 * Request parameters for updateEntrepreneur operation in EntrepreneurResourceApi.
 * @export
 * @interface EntrepreneurResourceApiUpdateEntrepreneurRequest
 */
export interface EntrepreneurResourceApiUpdateEntrepreneurRequest {
  /**
   *
   * @type {string}
   * @memberof EntrepreneurResourceApiUpdateEntrepreneur
   */
  readonly entrepreneurId: string

  /**
   *
   * @type {UpdateEntrepreneurRequest}
   * @memberof EntrepreneurResourceApiUpdateEntrepreneur
   */
  readonly updateEntrepreneurRequest: UpdateEntrepreneurRequest
}

/**
 * EntrepreneurResourceApi - object-oriented interface
 * @export
 * @class EntrepreneurResourceApi
 * @extends {BaseAPI}
 */
export class EntrepreneurResourceApi extends BaseAPI {
  /**
   *
   * @summary Add or update an answer to an entrepreneur\'s question
   * @param {EntrepreneurResourceApiAddAnswerRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EntrepreneurResourceApi
   */
  public addAnswer(
    requestParameters: EntrepreneurResourceApiAddAnswerRequest,
    options?: AxiosRequestConfig
  ) {
    return EntrepreneurResourceApiFp(this.configuration)
      .addAnswer(requestParameters.entrepreneurId, requestParameters.addAnswerRequest, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Create entrepreneur
   * @param {EntrepreneurResourceApiCreateEntrepreneurRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EntrepreneurResourceApi
   */
  public createEntrepreneur(
    requestParameters: EntrepreneurResourceApiCreateEntrepreneurRequest,
    options?: AxiosRequestConfig
  ) {
    return EntrepreneurResourceApiFp(this.configuration)
      .createEntrepreneur(
        requestParameters.noteId,
        requestParameters.createEntrepreneurRequest,
        options
      )
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Delete an entrepreneur by muffin id
   * @param {EntrepreneurResourceApiDeleteEntrepreneurRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EntrepreneurResourceApi
   */
  public deleteEntrepreneur(
    requestParameters: EntrepreneurResourceApiDeleteEntrepreneurRequest,
    options?: AxiosRequestConfig
  ) {
    return EntrepreneurResourceApiFp(this.configuration)
      .deleteEntrepreneur(requestParameters.idMuffin, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get entrepreneurs by note id
   * @param {EntrepreneurResourceApiGetEntrepreneursByNoteId1Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EntrepreneurResourceApi
   */
  public getEntrepreneursByNoteId1(
    requestParameters: EntrepreneurResourceApiGetEntrepreneursByNoteId1Request,
    options?: AxiosRequestConfig
  ) {
    return EntrepreneurResourceApiFp(this.configuration)
      .getEntrepreneursByNoteId1(requestParameters.noteId, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Check if a project has an entrepreneur account
   * @param {EntrepreneurResourceApiHasEntrepreneurAccountRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EntrepreneurResourceApi
   */
  public hasEntrepreneurAccount(
    requestParameters: EntrepreneurResourceApiHasEntrepreneurAccountRequest,
    options?: AxiosRequestConfig
  ) {
    return EntrepreneurResourceApiFp(this.configuration)
      .hasEntrepreneurAccount(requestParameters.projectId, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Update entrepreneur\'s household infos
   * @param {EntrepreneurResourceApiSaveHouseHoldRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EntrepreneurResourceApi
   */
  public saveHouseHold(
    requestParameters: EntrepreneurResourceApiSaveHouseHoldRequest,
    options?: AxiosRequestConfig
  ) {
    return EntrepreneurResourceApiFp(this.configuration)
      .saveHouseHold(
        requestParameters.entrepreneurId,
        requestParameters.saveHouseHoldRequest,
        options
      )
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Update entrepreneur
   * @param {EntrepreneurResourceApiUpdateEntrepreneurRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof EntrepreneurResourceApi
   */
  public updateEntrepreneur(
    requestParameters: EntrepreneurResourceApiUpdateEntrepreneurRequest,
    options?: AxiosRequestConfig
  ) {
    return EntrepreneurResourceApiFp(this.configuration)
      .updateEntrepreneur(
        requestParameters.entrepreneurId,
        requestParameters.updateEntrepreneurRequest,
        options
      )
      .then(request => request(this.axios, this.basePath))
  }
}
