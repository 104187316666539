import React, { CSSProperties, FC } from "react"
import { Typography } from "antd"
import "./AppMetierTitle.scss"

const { Title } = Typography

export const AppMetierTitle: FC<{
  level: [1, 2, 3, 4, 5][number]
  children: React.ReactNode
  style?: CSSProperties
}> = ({ level, children, style }) => (
  <Title level={level} className="app-metier-title" style={style}>
    {children}
  </Title>
)
