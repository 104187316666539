/* tslint:disable */
/* eslint-disable */
/**
 * Appli métier
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: Yoda 1 & 2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { CategoryResult } from "./category-result"

/**
 *
 * @export
 * @interface Result
 */
export interface Result {
  /**
   *
   * @type {string}
   * @memberof Result
   */
  noteId: string
  /**
   *
   * @type {number}
   * @memberof Result
   */
  personalInvolvement?: number
  /**
   *
   * @type {Array<CategoryResult>}
   * @memberof Result
   */
  categoryResults: Array<CategoryResult>
  /**
   *
   * @type {number}
   * @memberof Result
   */
  version: number
  /**
   *
   * @type {string}
   * @memberof Result
   */
  involvementProfileV2?: ResultInvolvementProfileV2Enum
  /**
   *
   * @type {Array<string>}
   * @memberof Result
   */
  cornerstoneCategories: Array<ResultCornerstoneCategoriesEnum>
}

/**
 * @export
 * @enum {string}
 */
export enum ResultInvolvementProfileV2Enum {
  NEGATIVE_IMPACT = "NEGATIVE_IMPACT",
  NEUTRAL_IMPACT = "NEUTRAL_IMPACT",
  POSITIVE_IMPACT = "POSITIVE_IMPACT"
}
/**
 * @export
 * @enum {string}
 */
export enum ResultCornerstoneCategoriesEnum {
  EMPLOYMENT = "EMPLOYMENT",
  TERRITORY = "TERRITORY",
  SOCIAL = "SOCIAL",
  ENVIRONMENT = "ENVIRONMENT",
  GOVERNANCE = "GOVERNANCE",
  OTHER = "OTHER"
}
