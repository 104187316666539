/* tslint:disable */
/* eslint-disable */
/**
 * Appli métier
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: Yoda 1 & 2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { InvolvementQuestionResult } from "./involvement-question-result"

/**
 *
 * @export
 * @interface CategoryResult
 */
export interface CategoryResult {
  /**
   *
   * @type {string}
   * @memberof CategoryResult
   */
  category: CategoryResultCategoryEnum
  /**
   *
   * @type {number}
   * @memberof CategoryResult
   */
  mean: number
  /**
   *
   * @type {number}
   * @memberof CategoryResult
   */
  score: number
  /**
   *
   * @type {boolean}
   * @memberof CategoryResult
   */
  valid: boolean
  /**
   *
   * @type {Array<InvolvementQuestionResult>}
   * @memberof CategoryResult
   */
  questionResults: Array<InvolvementQuestionResult>
}

/**
 * @export
 * @enum {string}
 */
export enum CategoryResultCategoryEnum {
  EMPLOYMENT = "EMPLOYMENT",
  TERRITORY = "TERRITORY",
  SOCIAL = "SOCIAL",
  ENVIRONMENT = "ENVIRONMENT",
  GOVERNANCE = "GOVERNANCE",
  OTHER = "OTHER"
}
