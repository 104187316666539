import React, { FC, useContext } from "react"
import { NoteContext } from "../NotePage"
import { FilePdfOutlined } from "@ant-design/icons"
import { Breadcrumb } from "antd"
import { MementoForm } from "./MementoForm"
import { useGetMemento } from "./MementoQueries"
import { ProjectContext } from "../ProjectWrapper"
import Loader from "../../components/Loader/Loader"

export const MementoWrapper: FC = () => {
  const { projectId } = useContext(ProjectContext)
  const { projectName, baseNotePath } = useContext(NoteContext)
  const { isLoading, data: memento } = useGetMemento(projectId)

  if (isLoading) {
    return <Loader />
  }

  return (
    <>
      <Breadcrumb
        className="breadcrumb"
        items={[
          { title: <FilePdfOutlined /> },
          {
            href: `${baseNotePath}/memento`,
            title: projectName
          },
          { title: "Mémento" }
        ]}
      />
      {memento && <MementoForm memento={memento} />}
    </>
  )
}
