import React, { FC, useContext, useEffect, useState } from "react"
import { ScoreInput } from "../../components/ScoreInput/ScoreInput"
import {
  InvolvementQuestion,
  InvolvementQuestionQuestionTypeEnum
} from "../../client/backend-client/generated"
import { AppMetierTitle } from "../../components/AppMetierTitle/AppMetierTitle"
import { Checkbox, Col, ConfigProvider, Row, Space, Tooltip, Typography } from "antd"
import { EditorTextInput } from "../../components/Editor/EditorTextInput"
import { OutputData } from "@editorjs/editorjs"
import _ from "lodash"
import {
  getInvolvementQuestionTitleStyle,
  getInvolvementScoreOptions,
  INVOLVEMENT_CATEGORY_GROUP_RECORD,
  InvolvementScoreInputType,
  V2_CHECKBOX_NEGATIVE_SCORE
} from "./InvolvementUtils"
import { useUpdateInvolvementQuestionResult } from "./InvolvementCheckQueries"
import { areSameOutputData, toOutputData } from "../../components/Editor/utilities"
import { NoteContext } from "../NotePage"
import { InvolvementCheckWrapperContext } from "./InvolvementCheckPageWrapper"
import { useDebouncedCallback } from "use-debounce"
import { getFlushEffectCallback, UPDATE_DEBOUNCE_DEFAULT_DELAY } from "../../utils/DebounceUtils"

const { Text } = Typography

export const InvolvementCheckQuestionForm: FC<{
  question: InvolvementQuestion
  slateData?: string
  score?: number
  forecastImpact?: string
}> = ({
  question,
  slateData: slateDataProp,
  score: scoreProp,
  forecastImpact: forecastImpactProp
}) => {
  const { noteId } = useContext(NoteContext)
  const { forceV2 } = useContext(InvolvementCheckWrapperContext)

  const initialOutputData = toOutputData(slateDataProp)
  const updateQuestionResultMutation = useUpdateInvolvementQuestionResult()

  const [outputData, setOutputData] = useState<OutputData>(initialOutputData)
  const [score, setScore] = useState(scoreProp)
  const [forecastImpact, setForecastImpact] = useState(forecastImpactProp)

  const hasForecastImpact =
    question.questionVersion === 2 &&
    question.questionType === InvolvementQuestionQuestionTypeEnum.QUESTION

  const debouncedOnAnswerChange = useDebouncedCallback(() => {
    if (
      !areSameOutputData(outputData, initialOutputData) ||
      !_.isEqual(score, scoreProp) ||
      !_.isEqual(forecastImpact, forecastImpactProp)
    ) {
      updateQuestionResultMutation.mutate({
        noteId: noteId,
        questionResultInvolvementSaveRequest: {
          questionId: question.id,
          score: score,
          comment: JSON.stringify(outputData),
          forecastImpact: forecastImpact,
          forceV2: forceV2
        }
      })
    }
  }, UPDATE_DEBOUNCE_DEFAULT_DELAY)

  useEffect(debouncedOnAnswerChange, [outputData, score, forecastImpact])
  useEffect(getFlushEffectCallback(debouncedOnAnswerChange), [debouncedOnAnswerChange])

  return (
    <>
      <Space className="full-width-space" direction="vertical">
        <Tooltip placement="bottom" title={question.description}>
          <Space align="center">
            <AppMetierTitle
              style={getInvolvementQuestionTitleStyle(question.questionType, question.category)}
              level={5}
            >
              {question.text}
            </AppMetierTitle>
          </Space>
        </Tooltip>
        {/*This config provider is used to override primary colors for the ScoreInput component to match category colors*/}
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: INVOLVEMENT_CATEGORY_GROUP_RECORD[question.category].color
            }
          }}
        >
          <Row gutter={[16, 16]}>
            <Col
              span={24}
              xl={6}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <Space direction="vertical">
                <Text strong type="secondary">
                  Impact actuel
                </Text>
                {question.questionType === InvolvementQuestionQuestionTypeEnum.QUESTION ? (
                  <ScoreInput<number>
                    score={score}
                    size="middle"
                    options={getInvolvementScoreOptions(
                      InvolvementScoreInputType.SCORE,
                      question.questionVersion
                    )}
                    onChange={setScore}
                  />
                ) : (
                  <Tooltip placement="bottom" title="Impact négatif significatif sur le critère">
                    <Checkbox
                      defaultChecked={score === V2_CHECKBOX_NEGATIVE_SCORE}
                      onChange={event =>
                        setScore(event.target.checked ? V2_CHECKBOX_NEGATIVE_SCORE : 1)
                      }
                    >
                      {V2_CHECKBOX_NEGATIVE_SCORE}
                    </Checkbox>
                  </Tooltip>
                )}
              </Space>
            </Col>
            {hasForecastImpact && (
              <Col
                span={24}
                xl={6}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <Space direction="vertical" style={{ marginBottom: "1.7em" }}>
                  <Text strong type="secondary">
                    Impact prévisionnel après intervention
                  </Text>
                  <ScoreInput<string>
                    score={forecastImpact}
                    size="middle"
                    options={getInvolvementScoreOptions(
                      InvolvementScoreInputType.FORECAST_IMPACT,
                      question.questionVersion
                    )}
                    onChange={setForecastImpact}
                  />
                </Space>
              </Col>
            )}
            <Col span={24} xl={hasForecastImpact ? 12 : 18}>
              <EditorTextInput initialValue={outputData} onChange={value => setOutputData(value)} />
            </Col>
          </Row>
        </ConfigProvider>
      </Space>
    </>
  )
}
