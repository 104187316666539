/* tslint:disable */
/* eslint-disable */
/**
 * Appli métier
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: Yoda 1 & 2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from "axios"
import { Configuration } from "../configuration"
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction
} from "../common"
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from "../base"
// @ts-ignore
import { History } from "../model"
/**
 * HistoryRessourceApi - axios parameter creator
 * @export
 */
export const HistoryRessourceApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get note history
     * @param {string} noteId
     * @param {string} structureId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNoteHistory: async (
      noteId: string,
      structureId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'noteId' is not null or undefined
      assertParamExists("getNoteHistory", "noteId", noteId)
      // verify required parameter 'structureId' is not null or undefined
      assertParamExists("getNoteHistory", "structureId", structureId)
      const localVarPath = `/history/note/{noteId}/{structureId}`
        .replace(`{${"noteId"}}`, encodeURIComponent(String(noteId)))
        .replace(`{${"structureId"}}`, encodeURIComponent(String(structureId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * HistoryRessourceApi - functional programming interface
 * @export
 */
export const HistoryRessourceApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = HistoryRessourceApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @summary Get note history
     * @param {string} noteId
     * @param {string} structureId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNoteHistory(
      noteId: string,
      structureId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<History>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getNoteHistory(
        noteId,
        structureId,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    }
  }
}

/**
 * HistoryRessourceApi - factory interface
 * @export
 */
export const HistoryRessourceApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = HistoryRessourceApiFp(configuration)
  return {
    /**
     *
     * @summary Get note history
     * @param {string} noteId
     * @param {string} structureId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNoteHistory(
      noteId: string,
      structureId: string,
      options?: any
    ): AxiosPromise<Array<History>> {
      return localVarFp
        .getNoteHistory(noteId, structureId, options)
        .then(request => request(axios, basePath))
    }
  }
}

/**
 * Request parameters for getNoteHistory operation in HistoryRessourceApi.
 * @export
 * @interface HistoryRessourceApiGetNoteHistoryRequest
 */
export interface HistoryRessourceApiGetNoteHistoryRequest {
  /**
   *
   * @type {string}
   * @memberof HistoryRessourceApiGetNoteHistory
   */
  readonly noteId: string

  /**
   *
   * @type {string}
   * @memberof HistoryRessourceApiGetNoteHistory
   */
  readonly structureId: string
}

/**
 * HistoryRessourceApi - object-oriented interface
 * @export
 * @class HistoryRessourceApi
 * @extends {BaseAPI}
 */
export class HistoryRessourceApi extends BaseAPI {
  /**
   *
   * @summary Get note history
   * @param {HistoryRessourceApiGetNoteHistoryRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HistoryRessourceApi
   */
  public getNoteHistory(
    requestParameters: HistoryRessourceApiGetNoteHistoryRequest,
    options?: AxiosRequestConfig
  ) {
    return HistoryRessourceApiFp(this.configuration)
      .getNoteHistory(requestParameters.noteId, requestParameters.structureId, options)
      .then(request => request(this.axios, this.basePath))
  }
}
