import React, { FC, useEffect, useRef } from "react"
import EditorJS, { OutputData } from "@editorjs/editorjs"
import "./Editor.scss"
import { v4 as uuidv4 } from "uuid"
import { EDITOR_JS_TOOLS } from "./EditorToolConfig"
import { EDITOR_JS_I18N_CONFIG, EMPTY_OUTPUT_DATA } from "./utilities"

export type EditorProps = {
  onChange: (v: OutputData) => void
  placeHolder?: string
  initialValue?: OutputData
  style?: React.CSSProperties
}

export const EditorTextInput: FC<EditorProps> = ({
  onChange,
  placeHolder,
  initialValue = EMPTY_OUTPUT_DATA,
  style
}) => {
  const editorJsRefs = useRef<{
    editorJsInstance: EditorJS | null
    uniqueId: string
  }>({
    editorJsInstance: null,
    uniqueId: uuidv4()
  })

  const initEditor = () => {
    const editorjs = new EditorJS({
      holder: editorJsRefs.current.uniqueId,
      data: initialValue ?? EMPTY_OUTPUT_DATA,
      placeholder: placeHolder,
      defaultBlock: "paragraph",
      inlineToolbar: true,
      tools: EDITOR_JS_TOOLS,
      i18n: EDITOR_JS_I18N_CONFIG,
      onChange: async () => {
        const content = await editorjs.saver?.save()
        if(content) onChange(content)
      },
      onReady: () => {
        editorJsRefs.current.editorJsInstance = editorjs
      }
    })
  }

  // This will run only once
  useEffect(() => {
    if (editorJsRefs.current.editorJsInstance === null) {
      initEditor()
    }

    return () => {
      editorJsRefs.current.editorJsInstance?.destroy()
      editorJsRefs.current.editorJsInstance = null
    }
  }, [])

  return (
    <>
      {!!editorJsRefs.current.uniqueId && (
        <div id={editorJsRefs.current.uniqueId} style={style} className="editor"></div>
      )}
    </>
  )
}
