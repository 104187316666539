import { RouteDefinition } from "./RouteWithSubRoutes"
import React from "react"
import { ProjectWrapper } from "../../pages/ProjectWrapper"
import { HomePage } from "../../pages/home/HomePage"

interface RouteDefinitionExtended extends RouteDefinition {
  enabled: boolean
}

export const routes: RouteDefinitionExtended[] = [
  {
    path: "/home",
    Component: HomePage,
    secured: true,
    enabled: true
  },
  {
    path: "/association/:associationId/project/:projectId/note/*",
    Component: ProjectWrapper,
    secured: true,
    enabled: true
  },
  {
    path: "/unauthorized",
    Component: () => <div>Non autorisé</div>,
    secured: false,
    enabled: true
  }
]
