import dayjs, { Dayjs } from "dayjs"
import {
  ApiBfr,
  ApiBfrAnterieur,
  ApiBilan,
  ApiCaf,
  ApiCalculatedData,
  ApiFinancialPeriod,
  ApiFinancialPeriodPeriodTypeEnum,
  ApiIncomeStatement,
  ApiNumericData,
  ApiPeriodPlanFinancement,
  ApiRemboursementsEmprunts,
  ApiTva
} from "../../client/backend-client/generated"

export const DEFAULT_PERIOD_NUMBER: number = 3

export interface PeriodModel {
  id: string
  startDate: Dayjs
  endDate: Dayjs
  incomeStatement: ApiIncomeStatement
  tva: ApiTva
  caf: ApiCaf
  bfr: ApiBfr
  remboursementsEmprunts: ApiRemboursementsEmprunts
  besoinsFixes: ApiCalculatedData
  pointMort: ApiCalculatedData
  margeDeManoeuvre: ApiCalculatedData
  duration: number
  planFinancement: ApiPeriodPlanFinancement
  prelevementTns: ApiNumericData
  type: ApiFinancialPeriodPeriodTypeEnum
  bilanCedant?: ApiBilan
  bfrAnterieur?: ApiBfrAnterieur
}

export const fromFinancialPeriod = (
  period: Pick<
    ApiFinancialPeriod,
    | "startDate"
    | "endDate"
    | "id"
    | "incomeStatement"
    | "tva"
    | "caf"
    | "bfr"
    | "remboursementsEmprunts"
    | "besoinsFixes"
    | "pointMort"
    | "margeDeManoeuvre"
    | "duration"
    | "periodPlanFinancement"
    | "prelevementTns"
    | "periodType"
    | "bilanCedant"
    | "bfrAnterieur"
  >
): PeriodModel => ({
  id: period.id,
  startDate: dayjs(period.startDate),
  endDate: dayjs(period.endDate),
  incomeStatement: period.incomeStatement,
  tva: period.tva,
  caf: period.caf,
  bfr: period.bfr,
  remboursementsEmprunts: period.remboursementsEmprunts,
  besoinsFixes: period.besoinsFixes,
  pointMort: period.pointMort,
  margeDeManoeuvre: period.margeDeManoeuvre,
  duration: period.duration,
  planFinancement: period.periodPlanFinancement,
  prelevementTns: period.prelevementTns,
  type: period.periodType,
  bilanCedant: period.bilanCedant,
  bfrAnterieur: period.bfrAnterieur
})

export const filterPeriodsByType = (
  periods: PeriodModel[],
  type: ApiFinancialPeriodPeriodTypeEnum
) => periods.filter(period => period.type === type)

export const sortPeriodsByDate = (periods: PeriodModel[]) => {
  return periods.sort(comparePeriodByDate)
}

export const comparePeriodByDate = (p1: PeriodModel, p2: PeriodModel) =>
  p1.startDate.isBefore(p2.startDate) ? -1 : 1

export const lastPeriod = (periods: PeriodModel[]) => periods.sort(comparePeriodByDate).reverse()[0]

export const firstPeriod = (periods: PeriodModel[]) => periods.sort(comparePeriodByDate)[0]
