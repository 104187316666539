import { evaluate } from "mathjs"

const computationOperators = ["+", "-", "*", "/"]

const formatPositiveNumber: (sanitizedString: string, decimalScale?: number) => string = (
  sanitizedString,
  decimalScale
) => {
  if (isComputation(sanitizedString)) {
    const op = computationOperators.find(op => sanitizedString.indexOf(op) > -1)!
    const formattedMembers = sanitizedString.split(op).map(s => formatAsNumber(s))
    const completePart = formattedMembers.filter(s => s !== "").join(` ${op} `)
    const incompletePart = formattedMembers.filter(s => s === "")
    if (incompletePart.length > 0) {
      return completePart + op
    }
    return completePart
  }
  return formatSingleNumber(sanitizedString, decimalScale)
}

const sanitize: (numStr: string) => { sanitizedString: string; sign: string } = numStr => {
  let noSpaceString = numStr.replace(/\s/g, "")
  noSpaceString = handleConsecutiveOperators(noSpaceString)
  return handleNegativeNumericExpression(noSpaceString)
}

const handleConsecutiveOperators = (numStr: string) => {
  const lastTwoChars = numStr.slice(numStr.length - 2, numStr.length)
  if (isOperator(lastTwoChars[0]) && isOperator(lastTwoChars[1])) {
    return numStr.slice(0, numStr.length - 2) + lastTwoChars[1]
  }
  return numStr
}

const handleNegativeNumericExpression = (numStr: string) => {
  if (numStr[0] === "-") {
    return {
      sanitizedString: numStr.slice(1, numStr.length),
      sign: "-"
    }
  }
  return { sanitizedString: numStr, sign: "" }
}

const isOperator: (str: string) => boolean = str => {
  return computationOperators.indexOf(str) !== -1
}

const isComputation = (numericString: string) => {
  return computationOperators.filter(op => numericString.indexOf(op) > -1).length > 0
}

export const formatSingleNumber = (numStr: string, decimalScaleOverride?: number) => {
  const { decimalScale, fixedDecimalScale, prefix, suffix, allowNegative } = {
    decimalScale: decimalScaleOverride,
    fixedDecimalScale: decimalScaleOverride !== undefined,
    prefix: "",
    suffix: "",
    allowNegative: true
  }
  const { thousandSeparator, decimalSeparator } = {
    thousandSeparator: " ",
    decimalSeparator: ","
  }

  const regex = new RegExp(/[0-9]+\.$/)
  if (regex.test(numStr)) {
    return numStr
  }

  const roundedString = (+numStr).toString()

  let hasDecimalSeparator = roundedString.indexOf(".") !== -1 || (decimalScale && fixedDecimalScale)
  // eslint-disable-next-line prefer-const
  let { beforeDecimal, afterDecimal, addNegation } = splitDecimal(roundedString, allowNegative)

  // apply decimal precision if its defined
  if (decimalScale !== undefined) {
    afterDecimal = limitToScale(afterDecimal, decimalScale, fixedDecimalScale)
    if (afterDecimal === "00") {
      afterDecimal = ""
      hasDecimalSeparator = false
    }
  }

  if (thousandSeparator) {
    beforeDecimal = applyThousandSeparator(beforeDecimal, thousandSeparator)
  }

  // add prefix and suffix
  if (prefix) {
    beforeDecimal = prefix + beforeDecimal
  }
  if (suffix) {
    afterDecimal = afterDecimal + suffix
  }

  // restore negation sign
  if (addNegation) {
    beforeDecimal = `-${beforeDecimal}`
  }
  return beforeDecimal + ((hasDecimalSeparator && decimalSeparator) || "") + afterDecimal
}

const limitToScale = (numStr: string, scale: number, fixedDecimalScale: boolean) => {
  let str = ""
  const filler = fixedDecimalScale ? "0" : ""
  for (let i = 0; i <= scale - 1; i++) {
    str += numStr[i] || filler
  }
  return str
}

const splitDecimal = (numStr: string, allowNegative: boolean = true) => {
  const hasNegation = numStr[0] === "-"
  const addNegation = hasNegation && allowNegative
  const numString = numStr.replace("-", "")

  const parts = numString.split(".")
  const beforeDecimal = parts[0]
  const afterDecimal = parts[1] || ""

  return {
    beforeDecimal,
    afterDecimal,
    hasNegation,
    addNegation
  }
}

const applyThousandSeparator = (str: string, thousandSeparator: string) => {
  let index = str.search(/[1-9]/)
  index = index === -1 ? str.length : index
  return (
    str.substring(0, index) +
    str.substring(index, str.length).replace(thousandsGroupRegex, `$1${thousandSeparator}`)
  )
}

export const thousandsGroupRegex = /(\d)(?=(\d{3})+(?!\d))/g

export const normalizeDecimalSeparator = (value: string) => {
  return value.replace(/\./, ",")
}

export const formatAsNumber = (numStr: string | undefined, decimalScale?: number) => {
  if (!numStr) {
    return ""
  }
  const { sanitizedString, sign } = sanitize(numStr)
  return sign + formatPositiveNumber(sanitizedString, decimalScale)
}

export const computeValue = (value: string | undefined): number | undefined => {
  const regex = new RegExp(/\.$/)
  if (!value || regex.test(value)) {
    return undefined
  }

  try {
    return evaluate(value)
  } catch (e) {
    return undefined
  }
}
