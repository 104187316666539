import React, { FC, SVGProps } from "react"

const EmploymentIcon: FC<SVGProps<SVGSVGElement>> = ({
  height = "16",
  width = "16",
  color = "black",
  fill = "black",
  viewBox = "0 0 150 150",
  ...props
}) => {
  return (
    <svg
      id="Calque_1"
      data-name="Calque 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox}
      color={color}
      fill={fill}
      height={height}
      width={width}
      {...props}
    >
      <path d="M46.27,58.58a6.23,6.23,0,1,0-11.8,0,11,11,0,0,0-5.14,9.33v3a11,11,0,0,0,4.55,8.92v3.37a1.36,1.36,0,0,1-.68,1.09c-1.48.81-4.63,2.36-4.63,2.36a7.43,7.43,0,0,0-3.29,3.55l0,.09a9.84,9.84,0,0,0-.84,4.13v11.46a1.75,1.75,0,0,0,3.5,0V94.36l1.72,1.72a4.79,4.79,0,0,1,1,3.24v6.53a1.75,1.75,0,0,0,3.5,0V99.32a8.19,8.19,0,0,0-2-5.71l-3-3a3.36,3.36,0,0,1,1-.84c.13-.06,3.26-1.6,4.82-2.46a1.25,1.25,0,0,0,.24-.16l3.49,3.44v15.25a1.75,1.75,0,0,0,3.5,0V90.6l3.49-3.44a1.75,1.75,0,0,0,.25.16,21.18,21.18,0,0,0,2.2,1.12,1.67,1.67,0,0,0,.62.12,1.75,1.75,0,0,0,.65-3.38s-.45-.19-1.79-.92a1.33,1.33,0,0,1-.48-.46v0a1.51,1.51,0,0,1-.19-.57V79.8a11,11,0,0,0,4.56-8.92v-3A11,11,0,0,0,46.27,58.58Zm1.65,9.51a9.11,9.11,0,0,1-9.42-2.82,1.75,1.75,0,0,0-2.7-.12A7.78,7.78,0,0,1,32.88,67a7.55,7.55,0,0,1,15,.88ZM37.64,56.56a2.73,2.73,0,1,1,5.46,0,2.65,2.65,0,0,1-.08.63,10.81,10.81,0,0,0-5.3,0A2.65,2.65,0,0,1,37.64,56.56ZM32.83,70.75a14.15,14.15,0,0,0,4.16-2,12.7,12.7,0,0,0,8.4,3.17,13.59,13.59,0,0,0,2.48-.24,7.54,7.54,0,0,1-15-.8Zm10.7,13.56-3.16,3.1-3.15-3.1a4.54,4.54,0,0,0,.16-1.1V81.5a10.85,10.85,0,0,0,6,0v1.71A4.62,4.62,0,0,0,43.53,84.31Z" />
      <path d="M121.45,83.47c-.06,0-6.16-2.92-7.63-3.73a1.19,1.19,0,0,1-.48-.46v0a1.29,1.29,0,0,1-.19-.57V76.41a10.12,10.12,0,0,0,3.28-7.48v-.81a1.72,1.72,0,0,0,.75-.43,1.74,1.74,0,0,0,.53-1.25V63.07c0-7.69-6.56-9.73-10-9.73h-3.39c-6.86,0-8.68,6.44-8.68,9.85v3.25a1.76,1.76,0,0,0,.67,1.38,1.72,1.72,0,0,0,.61.29v.82a10.12,10.12,0,0,0,3.28,7.48v2.24a1.36,1.36,0,0,1-.68,1.09,1.75,1.75,0,0,0,.84,3.29,1.67,1.67,0,0,0,.84-.22l.25-.16,3.49,3.43v19.77a1.75,1.75,0,0,0,3.5,0V86.08l3.49-3.43.24.16c1.57.86,7.53,3.68,7.86,3.84l-2.06,2c-.5.43-2.09,2.09-2.09,5.76v11.47a1.75,1.75,0,0,0,3.5,0V94.38a4.47,4.47,0,0,1,.8-3l.17-.14L122,89.58a5.81,5.81,0,0,1,.1,1.07v15.2a1.75,1.75,0,0,0,3.5,0V90.65A7.57,7.57,0,0,0,121.45,83.47ZM100.38,68.93V67.22a14.54,14.54,0,0,0,3.26-2A28.07,28.07,0,0,0,112.92,68v1a6.28,6.28,0,1,1-12.54,0ZM99.1,63.19c0-.26.06-6.35,5.18-6.35h3.38c.67,0,6.54.24,6.54,6.23v1.52a23.05,23.05,0,0,1-9.85-3,1.8,1.8,0,0,0-1-.29,1.75,1.75,0,0,0-1.3.58,9.29,9.29,0,0,1-3,2ZM106.65,79a9.55,9.55,0,0,0,3-.48v.2a4.41,4.41,0,0,0,.16,1.1l-3.15,3.11-3.16-3.11a4,4,0,0,0,.17-1.1v-.2A9.55,9.55,0,0,0,106.65,79Z" />
      <path d="M92.84,78.76c-.53-.24-7.25-3.26-9-4.16a1.35,1.35,0,0,1-.72-1.09v-2a11.48,11.48,0,0,0,4.7-9.17V58.07a3.87,3.87,0,0,0,.85-.67,2.55,2.55,0,0,0,.71-1.71c0-.64.12-5.26-2.68-8.53A13.16,13.16,0,0,0,76.38,42.4h-3.3a13.4,13.4,0,0,0-9.65,4.76c-2.81,3.29-2.71,7.92-2.69,8.54v2.35a3.35,3.35,0,0,0,1.74,3v1.27a11.51,11.51,0,0,0,4.7,9.17v2a1.35,1.35,0,0,1-.72,1.12c-1.78.9-8.49,3.92-9,4.16-.8.32-4.51,2.16-4.51,8.65v18.44a1.75,1.75,0,0,0,3.5,0V87.41a9.66,9.66,0,0,1,.06-1.11l3.28,3.14a.81.81,0,0,0,.14.12s.93.82.93,3.13v13.16a1.75,1.75,0,0,0,3.5,0V92.69c0-3.7-1.67-5.38-2.21-5.84l-4.33-4.13a2.6,2.6,0,0,1,.86-.68l.18-.07c.27-.12,6-2.68,8.49-3.89L73.39,84v21.83a1.75,1.75,0,0,0,3.5,0V84L83,78.08c2.53,1.21,8.23,3.77,8.49,3.89l.14,0a2.84,2.84,0,0,1,.89.7l-4.32,4.13c-.54.46-2.21,2.14-2.21,5.84v13.16a1.75,1.75,0,0,0,3.5,0V92.69c0-2.3.88-3.09.87-3.09h0a1.08,1.08,0,0,0,.2-.16L93.8,86.3c0,.35,0,.71,0,1.11v18.44a1.75,1.75,0,0,0,3.5,0V87.41C97.35,80.92,93.64,79.08,92.84,78.76ZM66,62.34v-1.5a13.24,13.24,0,0,0,2.42-1.56,1.75,1.75,0,1,0-2.15-2.75,8.23,8.23,0,0,1-2,1.25V55.56s-.17-3.76,1.85-6.13a9.92,9.92,0,0,1,7-3.53h3.3a9,9,0,0,1,9.48,9.26c-.42.2-1.62.61-4.58.61a14.62,14.62,0,0,1-9.88-3.54,1.75,1.75,0,1,0-2.48,2.47,18.12,18.12,0,0,0,12.36,4.57,24.5,24.5,0,0,0,3-.17v3.24c0,4.58-4.11,8.31-9.15,8.31S66,66.92,66,62.34Zm9.16,11.81a13.29,13.29,0,0,0,4.45-.77v.12a4.88,4.88,0,0,0,.66,2.32l-5.11,5-5.12-5a4.85,4.85,0,0,0,.66-2.31v-.13A13.39,13.39,0,0,0,75.14,74.15Z" />
    </svg>
  )
}

export default EmploymentIcon
