/* tslint:disable */
/* eslint-disable */
/**
 * Appli métier
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: Yoda 1 & 2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface ChangeLifecycleRequest
 */
export interface ChangeLifecycleRequest {
  /**
   *
   * @type {boolean}
   * @memberof ChangeLifecycleRequest
   */
  creationExNihilo?: boolean
  /**
   *
   * @type {string}
   * @memberof ChangeLifecycleRequest
   */
  from: ChangeLifecycleRequestFromEnum
  /**
   *
   * @type {string}
   * @memberof ChangeLifecycleRequest
   */
  to: ChangeLifecycleRequestToEnum
}

/**
 * @export
 * @enum {string}
 */
export enum ChangeLifecycleRequestFromEnum {
  EMERGENCE = "EMERGENCE",
  CREATION = "CREATION",
  REPRISE = "REPRISE",
  DEVELOPMENT = "DEVELOPMENT",
  REBOUND = "REBOUND",
  SCALE = "SCALE"
}
/**
 * @export
 * @enum {string}
 */
export enum ChangeLifecycleRequestToEnum {
  EMERGENCE = "EMERGENCE",
  CREATION = "CREATION",
  REPRISE = "REPRISE",
  DEVELOPMENT = "DEVELOPMENT",
  REBOUND = "REBOUND",
  SCALE = "SCALE"
}
