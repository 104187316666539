/* tslint:disable */
/* eslint-disable */
/**
 * Appli métier
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: Yoda 1 & 2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { ApiNumericData } from "./api-numeric-data"

/**
 *
 * @export
 * @interface ApiCapitalSocial
 */
export interface ApiCapitalSocial {
  /**
   *
   * @type {string}
   * @memberof ApiCapitalSocial
   */
  chosenLabel: ApiCapitalSocialChosenLabelEnum
  /**
   *
   * @type {ApiNumericData}
   * @memberof ApiCapitalSocial
   */
  numericData: ApiNumericData
}

/**
 * @export
 * @enum {string}
 */
export enum ApiCapitalSocialChosenLabelEnum {
  CAPITAL_SOCIAL = "CAPITAL_SOCIAL",
  FONDS_ASSOCIATIFS = "FONDS_ASSOCIATIFS",
  APPORTS = "APPORTS"
}
