import React, { FC, SVGProps } from "react"

const SocialIcon: FC<SVGProps<SVGSVGElement>> = ({
  height = "16",
  width = "16",
  color = "black",
  fill = "black",
  viewBox = "0 0 150 150",
  ...props
}) => {
  return (
    <svg
      id="Calque_1"
      data-name="Calque 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox}
      color={color}
      fill={fill}
      height={height}
      width={width}
      {...props}
    >
      <path d="M58,56.16l6.26,6.26a2.13,2.13,0,0,0,3-3L61,53.15a2.13,2.13,0,0,0-3,3Zm51,28.37,8.11,6-2.45,3.32L105.33,87,83.75,97.32l11.3,9.59-2.46,3.33L79.13,98.83l-12.61-1.3-15.73,8.16-1.66-3.79,16.59-8.6,13.89,1.43,25-12c3.11-1.5,1.32-4.89-.17-4.46-1.06.31-6.83,3.06-6.84,3.07L95.8,77.67s5.5-2.62,7.24-3.26c3.43-1.74,1.15-4.93-.26-4.41-1.08.31-6.83,3.06-6.83,3.07L94.1,69.39s5.74-2.75,7.34-3.29c2.5-1.29,1.89-5-.84-4.15l-9.52,4.28-1.62-3.79,5.46-2.5c2.62-1.38,1.82-5.33-1.09-4.1L72.59,65.43l1.13-11-6.18-.64,2.64,2.64a6.27,6.27,0,0,1-4.12,10.68,6.28,6.28,0,0,1-6,6,6.25,6.25,0,0,1-9.06,5.27,6.27,6.27,0,0,1-10.23,2.1l-.28-.29-6,3.36-1.66-3.78,5.38-3.09A6.26,6.26,0,0,1,42,69.34a6.27,6.27,0,0,1,5.29-9.05,6.26,6.26,0,0,1,5.94-5.94,6.27,6.27,0,0,1,7.55-5.82c-.78-5.1,7.09-6.51,9.32-7.36,4.5-1.7,9.13-2.5,13,1.34l8.14,10,1-.43c4.32-1.81,8.46.92,8.42,5.53,5-.82,8,5.28,5.7,9.4A6.58,6.58,0,0,1,108,75.22a6.57,6.57,0,0,1,1,9.31ZM52,62.1l6.26,6.26a2.13,2.13,0,0,0,3-3l-6.26-6.26A2.13,2.13,0,0,0,52,62.1Zm.32,12.2a2.13,2.13,0,0,0,3-3L49.12,65a2.13,2.13,0,0,0-3,3Q49.24,71.17,52.36,74.3Zm-9.51,2.36.89.89a2.13,2.13,0,0,0,3-3l-.89-.89a2.13,2.13,0,0,0-3,3ZM87.36,54.28l-7.24-8.89c-2.8-2.53-5.44-1.53-8.55-.36-1.55.59-10.71,3.1-4.13,4.65l10.81,1.11-.83,8c3.31-1.48,6.68-2.89,9.94-4.46Z" />
    </svg>
  )
}

export default SocialIcon
