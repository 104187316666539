import React, { FC, useContext } from "react"
import {
  ApiCalculatedData,
  ApiFinancialPeriodPeriodTypeEnum,
  CalculDetailLinkEnum,
  CalculDetailUnitEnum
} from "../../client/backend-client/generated"
import { NoteDictionary, useDictionary } from "../../utils/dictionary/dictionary"
import { eurosSuffix, Suffixes } from "../../utils/suffixes"
import { NoteContext } from "../../pages/NotePage"
import { NumberText } from "../NumberText/NumberText"
import { Table, Typography } from "antd"
import { ColumnsType } from "antd/lib/table"
import { EditOutlined } from "@ant-design/icons"
import { NavLink } from "react-router-dom"

const { Text } = Typography

const linkToString = (
  link: CalculDetailLinkEnum,
  dictionary: NoteDictionary,
  periodType?: ApiFinancialPeriodPeriodTypeEnum
) => {
  switch (link) {
    case CalculDetailLinkEnum.CR:
      return "income-statement"
    case CalculDetailLinkEnum.BFR:
      return "bfr"
    case CalculDetailLinkEnum.CAF:
      return "caf"
    case CalculDetailLinkEnum.PF:
      return "plan"
    case CalculDetailLinkEnum.TVA:
      return dictionary.noteSettings.tvaTabLink(periodType)
    case CalculDetailLinkEnum.PRETS:
      return "operations"
    case CalculDetailLinkEnum.NONE:
      return null
    case CalculDetailLinkEnum.BILANCEDANT:
      return "past-balance-sheet"
    case CalculDetailLinkEnum.PRETS_PASSES:
      return "past-loans"
  }
}

const unitToString = (unit: CalculDetailUnitEnum, isKiloEuros: boolean) => {
  switch (unit) {
    case CalculDetailUnitEnum.EUROS:
    case CalculDetailUnitEnum.K_EUROS:
      return eurosSuffix(isKiloEuros)
    case CalculDetailUnitEnum.JOURS:
      return Suffixes.DAYS
    case CalculDetailUnitEnum.MONTH:
      return Suffixes.MONTHS
    case CalculDetailUnitEnum.NONE:
      return ""
  }
}

interface TableDataType {
  key: string
  calculation: string
  input: string
  unit: string
  modify?: string
}

export const FormulaPopoverContent: FC<{
  calculatedData: ApiCalculatedData
  suffix: string
  decimals?: number
  showDescription?: (value: number) => boolean
  optionalDescription?: string
  periodType?: ApiFinancialPeriodPeriodTypeEnum
}> = ({
  calculatedData,
  suffix,
  decimals,
  showDescription = () => false,
  optionalDescription = "",
  periodType
}) => {
  const { baseNotePath, noteSettings } = useContext(NoteContext)
  const dictionary = useDictionary()
  const description = calculatedData.detail.map(d => ({
    label: d.labelDetail,
    value: d.valuedDetail,
    unit: unitToString(d.unit, noteSettings.kiloEuros),
    link: linkToString(d.link, dictionary, periodType)
  }))

  const hasLinks = description.filter(desc => desc.link).length > 0

  const value = calculatedData.numericData?.value ?? 0.0
  const totalValue = suffix === Suffixes.PERCENTAGE ? value * 100 : value

  const sharedOnCell = (record: TableDataType) =>
    record.key === "optionalDescription" ? { colSpan: 0 } : {}

  const columns: ColumnsType<TableDataType> = [
    {
      title: "Le calcul",
      dataIndex: "calculation",
      onCell: record => ({
        colSpan: record.key === "optionalDescription" ? (hasLinks ? 4 : 3) : 1
      }),
      render: (value, record) => {
        if (record.key === "optionalDescription") {
          return <Text style={{ fontSize: "12px", fontStyle: "italic" }}>{value}</Text>
        }
        return <Text>{value}</Text>
      }
    },
    {
      title: "Votre saisie",
      dataIndex: "input",
      onCell: sharedOnCell,
      align: "right",
      render: (value, record) => {
        if (record.key === "total") {
          return (
            <Text>
              = <NumberText value={value} decimalScale={decimals} />
            </Text>
          )
        }
        return <Text>{value}</Text>
      }
    },
    {
      title: "Unité",
      dataIndex: "unit",
      onCell: sharedOnCell
    },
    ...(hasLinks
      ? [
          {
            title: "Modifier",
            dataIndex: "modify",
            onCell: sharedOnCell,
            render: (link?: string) => {
              if (link) {
                return (
                  <NavLink to={`${baseNotePath}/financial/${link}`}>
                    <EditOutlined />
                  </NavLink>
                )
              }
              return <></>
            }
          }
        ]
      : [])
  ]

  const data: TableDataType[] = [
    ...description.map((desc, index) => ({
      key: index.toString(),
      calculation: desc.label,
      input: desc.value,
      unit: desc.unit,
      ...(hasLinks &&
        desc.link && {
          modify: desc.link
        })
    })),
    ...(showDescription(calculatedData.numericData.value ?? 0)
      ? [
          {
            key: "optionalDescription",
            calculation: optionalDescription,
            input: "",
            unit: ""
          }
        ]
      : []),
    {
      key: "total",
      calculation: "Total",
      input: totalValue.toString(),
      unit: suffix
    }
  ]

  return (
    <Table
      columns={columns}
      dataSource={data}
      size={"small"}
      className={"table-grey-last-row"}
      pagination={false}
    />
  )
}
