/* tslint:disable */
/* eslint-disable */
/**
 * Appli métier
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: Yoda 1 & 2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface History
 */
export interface History {
  /**
   *
   * @type {number}
   * @memberof History
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof History
   */
  objectType: HistoryObjectTypeEnum
  /**
   *
   * @type {string}
   * @memberof History
   */
  objectId: string
  /**
   *
   * @type {string}
   * @memberof History
   */
  actionType: HistoryActionTypeEnum
  /**
   *
   * @type {string}
   * @memberof History
   */
  userId: string
  /**
   *
   * @type {string}
   * @memberof History
   */
  userName: string
  /**
   *
   * @type {string}
   * @memberof History
   */
  date: string
  /**
   *
   * @type {string}
   * @memberof History
   */
  createdDate?: string
  /**
   *
   * @type {string}
   * @memberof History
   */
  lastModifiedDate?: string
}

/**
 * @export
 * @enum {string}
 */
export enum HistoryObjectTypeEnum {
  NOTE = "NOTE",
  SCORE = "SCORE",
  MEMENTO = "MEMENTO"
}
/**
 * @export
 * @enum {string}
 */
export enum HistoryActionTypeEnum {
  CREATE = "CREATE",
  UPDATE = "UPDATE",
  DUPLICATE = "DUPLICATE",
  UPDATE_STATUS = "UPDATE_STATUS",
  UPDATE_SCORE = "UPDATE_SCORE",
  REMOVE_OUTIL = "REMOVE_OUTIL",
  PUBLISH_MEMENTO = "PUBLISH_MEMENTO",
  UNPUBLISH_MEMENTO = "UNPUBLISH_MEMENTO",
  UPDATE_MEMENTO = "UPDATE_MEMENTO"
}
