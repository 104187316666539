import React, {
  ChangeEvent,
  CSSProperties,
  FC,
  useEffect,
  useLayoutEffect,
  useRef,
  useState
} from "react"
import { handleUserKeyPress } from "../../utils/keyboard-navigation"
import { Input } from "antd"
import { useDebouncedCallback } from "use-debounce"
import { INPUT_DEBOUNCE_DEFAULT_DELAY } from "../../utils/DebounceUtils"

interface AppMetierTextInputProps {
  inputValue: string | undefined
  inKiloEuros?: boolean
  placeholder?: string
  suffix?: string
  maxLength?: number
  textAlignEnd?: boolean
  onChange: (value: string) => void
  disabled?: boolean
  pattern?: string
  status?: "" | "warning" | "error" | undefined
  style?: CSSProperties
  name?: string
}

export const AppMetierTextInput: FC<AppMetierTextInputProps> = ({
  inputValue,
  onChange,
  placeholder,
  disabled,
  maxLength,
  pattern,
  status,
  style,
  name
}) => {
  const [value, setValue] = useState(inputValue)

  useEffect(() => {
    setValue(value)
  }, [inputValue])

  useLayoutEffect(() => {
    window.addEventListener("keydown", handleUserKeyPress)
  })

  const refs = useRef({
    isMounted: false
  })

  const debouncedOnChange = useDebouncedCallback(() => {
    if (refs.current.isMounted) {
      if (value !== undefined) {
        onChange(value)
      }
    } else {
      refs.current.isMounted = true
    }
  }, INPUT_DEBOUNCE_DEFAULT_DELAY)

  useEffect(() => debouncedOnChange(), [debouncedOnChange, value])

  return (
    <Input
      value={value}
      onChange={(e: ChangeEvent<HTMLInputElement>) => setValue(e.target.value)}
      placeholder={placeholder}
      disabled={disabled}
      maxLength={maxLength}
      pattern={pattern}
      status={status}
      style={style}
      name={name}
    />
  )
}
