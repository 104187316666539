import React, { FC } from "react"
import { Space, Switch, Typography } from "antd"

const { Text } = Typography

export const AppMetierSwitch: FC<{
  checked: boolean
  onChange: () => void
  label: string
  checkedInnerLabel?: string
  uncheckedInnerLabel?: string
  disabled?: boolean
  style?: React.CSSProperties
}> = ({
  checked,
  onChange,
  label,
  checkedInnerLabel,
  uncheckedInnerLabel,
  disabled = false,
  style
}) => (
  <Space direction={"horizontal"} style={style}>
    <Switch
      size="small"
      checked={checked}
      onChange={onChange}
      disabled={disabled}
      checkedChildren={checkedInnerLabel}
      unCheckedChildren={uncheckedInnerLabel}
/>
    <Text>{label}</Text>
  </Space>
)
