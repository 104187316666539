/* tslint:disable */
/* eslint-disable */
/**
 * Appli métier
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: Yoda 1 & 2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { OperationProductDelegation } from "./operation-product-delegation"

/**
 *
 * @export
 * @interface OperationProduct
 */
export interface OperationProduct {
  /**
   *
   * @type {number}
   * @memberof OperationProduct
   */
  id?: number
  /**
   *
   * @type {boolean}
   * @memberof OperationProduct
   */
  authorizedOnBankLoan: boolean
  /**
   *
   * @type {boolean}
   * @memberof OperationProduct
   */
  authorizedOnSolidarityLoan: boolean
  /**
   *
   * @type {Array<OperationProductDelegation>}
   * @memberof OperationProduct
   */
  delegations: Array<OperationProductDelegation>
  /**
   *
   * @type {string}
   * @memberof OperationProduct
   */
  label?: string
  /**
   *
   * @type {boolean}
   * @memberof OperationProduct
   */
  needsHolder: boolean
  /**
   *
   * @type {string}
   * @memberof OperationProduct
   */
  type: OperationProductTypeEnum
}

/**
 * @export
 * @enum {string}
 */
export enum OperationProductTypeEnum {
  GUARANTEE = "GUARANTEE",
  LOAN = "LOAN",
  BONUS = "BONUS",
  UNKNOWN = "UNKNOWN"
}
