/* tslint:disable */
/* eslint-disable */
/**
 * Appli métier
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: Yoda 1 & 2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from "axios"
import { Configuration } from "../configuration"
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction
} from "../common"
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from "../base"
// @ts-ignore
import { ApiPlanFinancementRatios } from "../model"
/**
 * PlanFinancementRatiosResourceV2Api - axios parameter creator
 * @export
 */
export const PlanFinancementRatiosResourceV2ApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @summary Get financial plan ratios by note id
     * @param {string} noteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    ratios: async (noteId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'noteId' is not null or undefined
      assertParamExists("ratios", "noteId", noteId)
      const localVarPath = `/v2.0/planFinancementRatios/{noteId}`.replace(
        `{${"noteId"}}`,
        encodeURIComponent(String(noteId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * PlanFinancementRatiosResourceV2Api - functional programming interface
 * @export
 */
export const PlanFinancementRatiosResourceV2ApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    PlanFinancementRatiosResourceV2ApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @summary Get financial plan ratios by note id
     * @param {string} noteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async ratios(
      noteId: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiPlanFinancementRatios>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.ratios(noteId, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    }
  }
}

/**
 * PlanFinancementRatiosResourceV2Api - factory interface
 * @export
 */
export const PlanFinancementRatiosResourceV2ApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = PlanFinancementRatiosResourceV2ApiFp(configuration)
  return {
    /**
     *
     * @summary Get financial plan ratios by note id
     * @param {string} noteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    ratios(noteId: string, options?: any): AxiosPromise<ApiPlanFinancementRatios> {
      return localVarFp.ratios(noteId, options).then(request => request(axios, basePath))
    }
  }
}

/**
 * Request parameters for ratios operation in PlanFinancementRatiosResourceV2Api.
 * @export
 * @interface PlanFinancementRatiosResourceV2ApiRatiosRequest
 */
export interface PlanFinancementRatiosResourceV2ApiRatiosRequest {
  /**
   *
   * @type {string}
   * @memberof PlanFinancementRatiosResourceV2ApiRatios
   */
  readonly noteId: string
}

/**
 * PlanFinancementRatiosResourceV2Api - object-oriented interface
 * @export
 * @class PlanFinancementRatiosResourceV2Api
 * @extends {BaseAPI}
 */
export class PlanFinancementRatiosResourceV2Api extends BaseAPI {
  /**
   *
   * @summary Get financial plan ratios by note id
   * @param {PlanFinancementRatiosResourceV2ApiRatiosRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlanFinancementRatiosResourceV2Api
   */
  public ratios(
    requestParameters: PlanFinancementRatiosResourceV2ApiRatiosRequest,
    options?: AxiosRequestConfig
  ) {
    return PlanFinancementRatiosResourceV2ApiFp(this.configuration)
      .ratios(requestParameters.noteId, options)
      .then(request => request(this.axios, this.basePath))
  }
}
