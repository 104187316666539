/* tslint:disable */
/* eslint-disable */
/**
 * Appli métier
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: Yoda 1 & 2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from "axios"
import { Configuration } from "../configuration"
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction
} from "../common"
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from "../base"
// @ts-ignore
import { ApiPretPasse } from "../model"
// @ts-ignore
import { CreatePretPasseRequest } from "../model"
// @ts-ignore
import { UpdatePretPasseRequest } from "../model"
/**
 * PretResourceApi - axios parameter creator
 * @export
 */
export const PretResourceApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Add a new past loan
     * @param {CreatePretPasseRequest} createPretPasseRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addPret: async (
      createPretPasseRequest: CreatePretPasseRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'createPretPasseRequest' is not null or undefined
      assertParamExists("addPret", "createPretPasseRequest", createPretPasseRequest)
      const localVarPath = `/prets`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        createPretPasseRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary Delete all past loans by note id
     * @param {string} noteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteAllPretsOnNote: async (
      noteId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'noteId' is not null or undefined
      assertParamExists("deleteAllPretsOnNote", "noteId", noteId)
      const localVarPath = `/prets/deleteAllPrets/{noteId}`.replace(
        `{${"noteId"}}`,
        encodeURIComponent(String(noteId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary Delete a past loan by id
     * @param {string} idPret
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deletePretPasse: async (
      idPret: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'idPret' is not null or undefined
      assertParamExists("deletePretPasse", "idPret", idPret)
      const localVarPath = `/prets/{idPret}`.replace(
        `{${"idPret"}}`,
        encodeURIComponent(String(idPret))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary Get past loans by note id
     * @param {string} noteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPrets: async (noteId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'noteId' is not null or undefined
      assertParamExists("getPrets", "noteId", noteId)
      const localVarPath = `/prets/{noteId}`.replace(
        `{${"noteId"}}`,
        encodeURIComponent(String(noteId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary Update a past loan
     * @param {string} idPret
     * @param {UpdatePretPasseRequest} updatePretPasseRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePretPasse: async (
      idPret: string,
      updatePretPasseRequest: UpdatePretPasseRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'idPret' is not null or undefined
      assertParamExists("updatePretPasse", "idPret", idPret)
      // verify required parameter 'updatePretPasseRequest' is not null or undefined
      assertParamExists("updatePretPasse", "updatePretPasseRequest", updatePretPasseRequest)
      const localVarPath = `/prets/{idPret}`.replace(
        `{${"idPret"}}`,
        encodeURIComponent(String(idPret))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updatePretPasseRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * PretResourceApi - functional programming interface
 * @export
 */
export const PretResourceApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = PretResourceApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @summary Add a new past loan
     * @param {CreatePretPasseRequest} createPretPasseRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addPret(
      createPretPasseRequest: CreatePretPasseRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiPretPasse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.addPret(
        createPretPasseRequest,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Delete all past loans by note id
     * @param {string} noteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteAllPretsOnNote(
      noteId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAllPretsOnNote(
        noteId,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Delete a past loan by id
     * @param {string} idPret
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deletePretPasse(
      idPret: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deletePretPasse(idPret, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Get past loans by note id
     * @param {string} noteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPrets(
      noteId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiPretPasse>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPrets(noteId, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Update a past loan
     * @param {string} idPret
     * @param {UpdatePretPasseRequest} updatePretPasseRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updatePretPasse(
      idPret: string,
      updatePretPasseRequest: UpdatePretPasseRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiPretPasse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updatePretPasse(
        idPret,
        updatePretPasseRequest,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    }
  }
}

/**
 * PretResourceApi - factory interface
 * @export
 */
export const PretResourceApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = PretResourceApiFp(configuration)
  return {
    /**
     *
     * @summary Add a new past loan
     * @param {CreatePretPasseRequest} createPretPasseRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addPret(
      createPretPasseRequest: CreatePretPasseRequest,
      options?: any
    ): AxiosPromise<ApiPretPasse> {
      return localVarFp
        .addPret(createPretPasseRequest, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Delete all past loans by note id
     * @param {string} noteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteAllPretsOnNote(noteId: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .deleteAllPretsOnNote(noteId, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Delete a past loan by id
     * @param {string} idPret
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deletePretPasse(idPret: string, options?: any): AxiosPromise<boolean> {
      return localVarFp.deletePretPasse(idPret, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Get past loans by note id
     * @param {string} noteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPrets(noteId: string, options?: any): AxiosPromise<Array<ApiPretPasse>> {
      return localVarFp.getPrets(noteId, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Update a past loan
     * @param {string} idPret
     * @param {UpdatePretPasseRequest} updatePretPasseRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePretPasse(
      idPret: string,
      updatePretPasseRequest: UpdatePretPasseRequest,
      options?: any
    ): AxiosPromise<ApiPretPasse> {
      return localVarFp
        .updatePretPasse(idPret, updatePretPasseRequest, options)
        .then(request => request(axios, basePath))
    }
  }
}

/**
 * Request parameters for addPret operation in PretResourceApi.
 * @export
 * @interface PretResourceApiAddPretRequest
 */
export interface PretResourceApiAddPretRequest {
  /**
   *
   * @type {CreatePretPasseRequest}
   * @memberof PretResourceApiAddPret
   */
  readonly createPretPasseRequest: CreatePretPasseRequest
}

/**
 * Request parameters for deleteAllPretsOnNote operation in PretResourceApi.
 * @export
 * @interface PretResourceApiDeleteAllPretsOnNoteRequest
 */
export interface PretResourceApiDeleteAllPretsOnNoteRequest {
  /**
   *
   * @type {string}
   * @memberof PretResourceApiDeleteAllPretsOnNote
   */
  readonly noteId: string
}

/**
 * Request parameters for deletePretPasse operation in PretResourceApi.
 * @export
 * @interface PretResourceApiDeletePretPasseRequest
 */
export interface PretResourceApiDeletePretPasseRequest {
  /**
   *
   * @type {string}
   * @memberof PretResourceApiDeletePretPasse
   */
  readonly idPret: string
}

/**
 * Request parameters for getPrets operation in PretResourceApi.
 * @export
 * @interface PretResourceApiGetPretsRequest
 */
export interface PretResourceApiGetPretsRequest {
  /**
   *
   * @type {string}
   * @memberof PretResourceApiGetPrets
   */
  readonly noteId: string
}

/**
 * Request parameters for updatePretPasse operation in PretResourceApi.
 * @export
 * @interface PretResourceApiUpdatePretPasseRequest
 */
export interface PretResourceApiUpdatePretPasseRequest {
  /**
   *
   * @type {string}
   * @memberof PretResourceApiUpdatePretPasse
   */
  readonly idPret: string

  /**
   *
   * @type {UpdatePretPasseRequest}
   * @memberof PretResourceApiUpdatePretPasse
   */
  readonly updatePretPasseRequest: UpdatePretPasseRequest
}

/**
 * PretResourceApi - object-oriented interface
 * @export
 * @class PretResourceApi
 * @extends {BaseAPI}
 */
export class PretResourceApi extends BaseAPI {
  /**
   *
   * @summary Add a new past loan
   * @param {PretResourceApiAddPretRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PretResourceApi
   */
  public addPret(requestParameters: PretResourceApiAddPretRequest, options?: AxiosRequestConfig) {
    return PretResourceApiFp(this.configuration)
      .addPret(requestParameters.createPretPasseRequest, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Delete all past loans by note id
   * @param {PretResourceApiDeleteAllPretsOnNoteRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PretResourceApi
   */
  public deleteAllPretsOnNote(
    requestParameters: PretResourceApiDeleteAllPretsOnNoteRequest,
    options?: AxiosRequestConfig
  ) {
    return PretResourceApiFp(this.configuration)
      .deleteAllPretsOnNote(requestParameters.noteId, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Delete a past loan by id
   * @param {PretResourceApiDeletePretPasseRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PretResourceApi
   */
  public deletePretPasse(
    requestParameters: PretResourceApiDeletePretPasseRequest,
    options?: AxiosRequestConfig
  ) {
    return PretResourceApiFp(this.configuration)
      .deletePretPasse(requestParameters.idPret, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get past loans by note id
   * @param {PretResourceApiGetPretsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PretResourceApi
   */
  public getPrets(requestParameters: PretResourceApiGetPretsRequest, options?: AxiosRequestConfig) {
    return PretResourceApiFp(this.configuration)
      .getPrets(requestParameters.noteId, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Update a past loan
   * @param {PretResourceApiUpdatePretPasseRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PretResourceApi
   */
  public updatePretPasse(
    requestParameters: PretResourceApiUpdatePretPasseRequest,
    options?: AxiosRequestConfig
  ) {
    return PretResourceApiFp(this.configuration)
      .updatePretPasse(requestParameters.idPret, requestParameters.updatePretPasseRequest, options)
      .then(request => request(this.axios, this.basePath))
  }
}
