import React, { FC, useContext, useState } from "react"
import "leaflet/dist/leaflet.css"
import { HomeOutlined } from "@ant-design/icons"
import { Breadcrumb, Button, Modal, Space, Typography } from "antd"
import { InvolvementCheckWrapperContext } from "./InvolvementCheckPageWrapper"
import { InvolvementCheckForm } from "./InvolvementCheckForm"
import "./InvolvementCheckPage.scss"
import { InvolvementVersion } from "./InvolvementUtils"
import { NoteContext } from "../NotePage"

const { Text, Paragraph } = Typography

export const InvolvementCheckPage: FC = () => {
  const { projectName, baseNotePath } = useContext(NoteContext)
  const { involvementCheckResult, forceV2, setForceV2 } = useContext(InvolvementCheckWrapperContext)

  const [showForceV2Modal, setShowForceV2Modal] = useState(false)

  return (
    <>
      <Breadcrumb
        className="breadcrumb"
        items={[
          { title: <HomeOutlined /> },
          {
            href: `${baseNotePath}/involvement-check`,
            title: projectName
          },
          { title: "Révélateur d'engagement" }
        ]}
      />
      {involvementCheckResult.version == 1 && (
        <Space style={{ marginBottom: "1em" }}>
          <Button
            onClick={() => {
              setShowForceV2Modal(true)
            }}
          >
            Afficher la nouvelle version du révélateur d'engagement
          </Button>
          <Modal
            title="Attention"
            open={showForceV2Modal}
            onOk={() => {
              setShowForceV2Modal(false)
              setForceV2(true)
            }}
            onCancel={() => setShowForceV2Modal(false)}
          >
            <Text>
              <Paragraph strong>
                Êtes-vous sûr.e de vouloir passer à la nouvelle version du révélateur d'engagement ?
              </Paragraph>
              <Paragraph>
                Si vous commencez à remplir le nouveau formulaire du révélateur d'engagement, vous
                n'aurez plus accès à l'ancienne version.
              </Paragraph>
              <Paragraph>
                Pensez à conserver les réponses aux anciennes questions avant de basculer sur le
                nouveau formulaire
              </Paragraph>
            </Text>
          </Modal>
        </Space>
      )}
      {
        <InvolvementCheckForm
          version={forceV2 ? 2 : (involvementCheckResult.version as InvolvementVersion)}
        />
      }
    </>
  )
}
