/* tslint:disable */
/* eslint-disable */
/**
 * Appli métier
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: Yoda 1 & 2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { ApiCalculatedData } from "./api-calculated-data"

/**
 *
 * @export
 * @interface ApiBenchMarkPeriod
 */
export interface ApiBenchMarkPeriod {
  /**
   *
   * @type {string}
   * @memberof ApiBenchMarkPeriod
   */
  periodId: string
  /**
   *
   * @type {number}
   * @memberof ApiBenchMarkPeriod
   */
  effectifs?: number
  /**
   *
   * @type {ApiCalculatedData}
   * @memberof ApiBenchMarkPeriod
   */
  chiffreAffaire: ApiCalculatedData
  /**
   *
   * @type {ApiCalculatedData}
   * @memberof ApiBenchMarkPeriod
   */
  chiffreAffaireParPersonne: ApiCalculatedData
  /**
   *
   * @type {ApiCalculatedData}
   * @memberof ApiBenchMarkPeriod
   */
  margeBrut: ApiCalculatedData
  /**
   *
   * @type {ApiCalculatedData}
   * @memberof ApiBenchMarkPeriod
   */
  valeurAjoutee: ApiCalculatedData
  /**
   *
   * @type {ApiCalculatedData}
   * @memberof ApiBenchMarkPeriod
   */
  chargePersonnel: ApiCalculatedData
  /**
   *
   * @type {ApiCalculatedData}
   * @memberof ApiBenchMarkPeriod
   */
  ebe: ApiCalculatedData
  /**
   *
   * @type {ApiCalculatedData}
   * @memberof ApiBenchMarkPeriod
   */
  resultatFinancier: ApiCalculatedData
  /**
   *
   * @type {ApiCalculatedData}
   * @memberof ApiBenchMarkPeriod
   */
  resultatCourant: ApiCalculatedData
  /**
   *
   * @type {ApiCalculatedData}
   * @memberof ApiBenchMarkPeriod
   */
  pointMort: ApiCalculatedData
  /**
   *
   * @type {ApiCalculatedData}
   * @memberof ApiBenchMarkPeriod
   */
  margeManoeuvre: ApiCalculatedData
  /**
   *
   * @type {ApiCalculatedData}
   * @memberof ApiBenchMarkPeriod
   */
  rotationStock: ApiCalculatedData
  /**
   *
   * @type {ApiCalculatedData}
   * @memberof ApiBenchMarkPeriod
   */
  joursCa: ApiCalculatedData
  /**
   *
   * @type {string}
   * @memberof ApiBenchMarkPeriod
   */
  periodType: ApiBenchMarkPeriodPeriodTypeEnum
}

/**
 * @export
 * @enum {string}
 */
export enum ApiBenchMarkPeriodPeriodTypeEnum {
  PREVISIONNEL = "PREVISIONNEL",
  ANTERIEUR = "ANTERIEUR",
  INTERMEDIAIRE = "INTERMEDIAIRE"
}
