/* tslint:disable */
/* eslint-disable */
/**
 * Appli métier
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: Yoda 1 & 2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface SaveNoteSettingsRequest
 */
export interface SaveNoteSettingsRequest {
  /**
   *
   * @type {boolean}
   * @memberof SaveNoteSettingsRequest
   */
  kiloEuros?: boolean
  /**
   *
   * @type {string}
   * @memberof SaveNoteSettingsRequest
   */
  referenceAmount?: SaveNoteSettingsRequestReferenceAmountEnum
  /**
   *
   * @type {boolean}
   * @memberof SaveNoteSettingsRequest
   */
  assujettiImpotsSocietes?: boolean
  /**
   *
   * @type {boolean}
   * @memberof SaveNoteSettingsRequest
   */
  impotsSurBeneficeComputed?: boolean
  /**
   *
   * @type {boolean}
   * @memberof SaveNoteSettingsRequest
   */
  prelevementTnsInCr?: boolean
  /**
   *
   * @type {boolean}
   * @memberof SaveNoteSettingsRequest
   */
  showDemarrage?: boolean
  /**
   *
   * @type {boolean}
   * @memberof SaveNoteSettingsRequest
   */
  showDetailPret?: boolean
  /**
   *
   * @type {boolean}
   * @memberof SaveNoteSettingsRequest
   */
  showPercentageCedant?: boolean
  /**
   *
   * @type {boolean}
   * @memberof SaveNoteSettingsRequest
   */
  showPercentagePrevisionnel?: boolean
  /**
   *
   * @type {boolean}
   * @memberof SaveNoteSettingsRequest
   */
  showBfrInverse?: boolean
  /**
   *
   * @type {boolean}
   * @memberof SaveNoteSettingsRequest
   */
  showPreviousPeriod?: boolean
  /**
   *
   * @type {Array<string>}
   * @memberof SaveNoteSettingsRequest
   */
  expandedDetailKeys?: Array<string>
}

/**
 * @export
 * @enum {string}
 */
export enum SaveNoteSettingsRequestReferenceAmountEnum {
  NONE = "NONE",
  CHIFFRE_AFFAIRE = "CHIFFRE_AFFAIRE",
  PRODUITS_EXPLOITATION = "PRODUITS_EXPLOITATION"
}
