import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { extract } from "../../client/backend-client/AxiosUtils"
import { useAppContext } from "../../config/context"
import {
  Result,
  ResultResourceApiSaveQuestionResultRequest,
  ResultResourceApiSaveResultRequest
} from "../../client/backend-client/generated"
import { useHeaders } from "../../queries/axios-headers"

export const useGetInvolvementProfiles = () => {
  const { involvementCheckResourceApi } = useAppContext()
  const getHeaders = useHeaders()

  const { isLoading, data, error } = useQuery({
    queryKey: ["involvement-profiles"],
    queryFn: async () => {
      const { headers } = await getHeaders()
      return involvementCheckResourceApi.getProfiles({ headers }).then(extract)
    },
    retry: 0,
    meta: {
      errorMessage: `Erreur lors de la récupération des profils du révélateur d'engagement`
    }
  })

  return {
    isLoading,
    data,
    error
  }
}

export const useGetInvolvementQuestions = (noteId: string, forceV2: boolean = false) => {
  const { involvementCheckResourceApi } = useAppContext()
  const getHeaders = useHeaders()

  const { isLoading, data, error } = useQuery({
    queryKey: ["involvement-questions", noteId, forceV2],
    queryFn: async () => {
      const { headers } = await getHeaders()
      return involvementCheckResourceApi.getQuestion({ noteId, forceV2 }, { headers }).then(extract)
    },
    retry: 0,
    meta: {
      errorMessage: `Erreur lors de la récupération des questions du révélateur d'engagement`
    }
  })

  return {
    isLoading,
    data,
    error
  }
}

export const useGetInvolvementCheckResult = (noteId: string, forceV2: boolean = false) => {
  const { resultResourceApi } = useAppContext()
  const getHeaders = useHeaders()

  const { isLoading, data, error } = useQuery({
    queryKey: ["involvement-check-result", noteId, forceV2],
    queryFn: async () => {
      const { headers } = await getHeaders()
      return resultResourceApi.getResult({ noteId, forceV2 }, { headers }).then(extract)
    },
    retry: 0,
    meta: {
      errorMessage: `Erreur lors de la récupération des réponses au révélateur d'engagement`
    }
  })

  return {
    isLoading,
    data,
    error
  }
}

export const useUpdateInvolvementResult = () => {
  const { resultResourceApi } = useAppContext()
  const getHeaders = useHeaders()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (request: ResultResourceApiSaveResultRequest) => {
      const { headers } = await getHeaders()
      return resultResourceApi.saveResult(request, { headers }).then(extract)
    },
    meta: {
      errorMessage: `Erreur lors de la mise à jour de la réponse`
    },
    onSuccess: (updatedResult, variables) => {
      queryClient.setQueryData<Result>(
        ["involvement-check-result", variables.noteId, variables.resultSaveRequest.forceV2],
        updatedResult
      )
    }
  })
}

export const useUpdateInvolvementQuestionResult = () => {
  const { resultResourceApi } = useAppContext()
  const getHeaders = useHeaders()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (request: ResultResourceApiSaveQuestionResultRequest) => {
      const { headers } = await getHeaders()
      return resultResourceApi.saveQuestionResult(request, { headers }).then(extract)
    },
    meta: {
      errorMessage: `Erreur lors de la mise à jour de la réponse`
    },
    onSuccess: (_updatedResult, variables) => {
      queryClient.setQueryData<Result>(
        [
          "involvement-check-result",
          variables.noteId,
          variables.questionResultInvolvementSaveRequest.forceV2
        ],
        _updatedResult
      )
    }
  })
}
