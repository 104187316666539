/* tslint:disable */
/* eslint-disable */
/**
 * Appli métier
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: Yoda 1 & 2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from "axios"
import { Configuration } from "../configuration"
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction
} from "../common"
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from "../base"
// @ts-ignore
import { NoteSettings } from "../model"
// @ts-ignore
import { SaveNoteSettingsRequest } from "../model"
/**
 * NoteSettingsResourceApi - axios parameter creator
 * @export
 */
export const NoteSettingsResourceApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get note settings by note id
     * @param {string} noteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNoteSettings: async (
      noteId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'noteId' is not null or undefined
      assertParamExists("getNoteSettings", "noteId", noteId)
      const localVarPath = `/note-settings/{noteId}`.replace(
        `{${"noteId"}}`,
        encodeURIComponent(String(noteId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary Update a setting for note
     * @param {string} noteId
     * @param {SaveNoteSettingsRequest} saveNoteSettingsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateNoteSettings: async (
      noteId: string,
      saveNoteSettingsRequest: SaveNoteSettingsRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'noteId' is not null or undefined
      assertParamExists("updateNoteSettings", "noteId", noteId)
      // verify required parameter 'saveNoteSettingsRequest' is not null or undefined
      assertParamExists("updateNoteSettings", "saveNoteSettingsRequest", saveNoteSettingsRequest)
      const localVarPath = `/note-settings/{noteId}`.replace(
        `{${"noteId"}}`,
        encodeURIComponent(String(noteId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        saveNoteSettingsRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * NoteSettingsResourceApi - functional programming interface
 * @export
 */
export const NoteSettingsResourceApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = NoteSettingsResourceApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @summary Get note settings by note id
     * @param {string} noteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNoteSettings(
      noteId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NoteSettings>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getNoteSettings(noteId, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Update a setting for note
     * @param {string} noteId
     * @param {SaveNoteSettingsRequest} saveNoteSettingsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateNoteSettings(
      noteId: string,
      saveNoteSettingsRequest: SaveNoteSettingsRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NoteSettings>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateNoteSettings(
        noteId,
        saveNoteSettingsRequest,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    }
  }
}

/**
 * NoteSettingsResourceApi - factory interface
 * @export
 */
export const NoteSettingsResourceApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = NoteSettingsResourceApiFp(configuration)
  return {
    /**
     *
     * @summary Get note settings by note id
     * @param {string} noteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNoteSettings(noteId: string, options?: any): AxiosPromise<NoteSettings> {
      return localVarFp.getNoteSettings(noteId, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Update a setting for note
     * @param {string} noteId
     * @param {SaveNoteSettingsRequest} saveNoteSettingsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateNoteSettings(
      noteId: string,
      saveNoteSettingsRequest: SaveNoteSettingsRequest,
      options?: any
    ): AxiosPromise<NoteSettings> {
      return localVarFp
        .updateNoteSettings(noteId, saveNoteSettingsRequest, options)
        .then(request => request(axios, basePath))
    }
  }
}

/**
 * Request parameters for getNoteSettings operation in NoteSettingsResourceApi.
 * @export
 * @interface NoteSettingsResourceApiGetNoteSettingsRequest
 */
export interface NoteSettingsResourceApiGetNoteSettingsRequest {
  /**
   *
   * @type {string}
   * @memberof NoteSettingsResourceApiGetNoteSettings
   */
  readonly noteId: string
}

/**
 * Request parameters for updateNoteSettings operation in NoteSettingsResourceApi.
 * @export
 * @interface NoteSettingsResourceApiUpdateNoteSettingsRequest
 */
export interface NoteSettingsResourceApiUpdateNoteSettingsRequest {
  /**
   *
   * @type {string}
   * @memberof NoteSettingsResourceApiUpdateNoteSettings
   */
  readonly noteId: string

  /**
   *
   * @type {SaveNoteSettingsRequest}
   * @memberof NoteSettingsResourceApiUpdateNoteSettings
   */
  readonly saveNoteSettingsRequest: SaveNoteSettingsRequest
}

/**
 * NoteSettingsResourceApi - object-oriented interface
 * @export
 * @class NoteSettingsResourceApi
 * @extends {BaseAPI}
 */
export class NoteSettingsResourceApi extends BaseAPI {
  /**
   *
   * @summary Get note settings by note id
   * @param {NoteSettingsResourceApiGetNoteSettingsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NoteSettingsResourceApi
   */
  public getNoteSettings(
    requestParameters: NoteSettingsResourceApiGetNoteSettingsRequest,
    options?: AxiosRequestConfig
  ) {
    return NoteSettingsResourceApiFp(this.configuration)
      .getNoteSettings(requestParameters.noteId, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Update a setting for note
   * @param {NoteSettingsResourceApiUpdateNoteSettingsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NoteSettingsResourceApi
   */
  public updateNoteSettings(
    requestParameters: NoteSettingsResourceApiUpdateNoteSettingsRequest,
    options?: AxiosRequestConfig
  ) {
    return NoteSettingsResourceApiFp(this.configuration)
      .updateNoteSettings(
        requestParameters.noteId,
        requestParameters.saveNoteSettingsRequest,
        options
      )
      .then(request => request(this.axios, this.basePath))
  }
}
