import { Button, Col, Divider, Popover, Row, Space, Table, Tooltip, Typography } from "antd"
import React, { FC, Key, useContext, useEffect, useMemo, useRef, useState } from "react"
import {
  ApiAutresAchats,
  ApiCalculatedData,
  ApiDetailLine,
  ApiDetailLines,
  ApiEntryWithDetail,
  ApiNumericData,
  ApiOperations,
  NoteSettingsReferenceAmountEnum,
  NoteTypeEnum
} from "../../../client/backend-client/generated"
import { Suffixes } from "../../../utils/suffixes"
import { useDebouncedCallback } from "use-debounce"
import _ from "lodash"
import { NoteContext } from "../../NotePage"
import { AppMetierSwitch } from "../../../components/AppMetierSwitch"
import { useDictionary } from "../../../utils/dictionary/dictionary"
import { PeriodModel } from "../PeriodModel"
import { NumericDataInput } from "../../../components/NumericDataInput/NumericDataInput"
import { NumberText } from "../../../components/NumberText/NumberText"
import { periodToMutationModel } from "./IncomeStatementQueries"
import {
  corporateTaxesModalContent,
  DetailGroupKey,
  generateIncomeStatementTable1,
  generateIncomeStatementTable2,
  generateIncomeStatementTable3,
  generateIncomeStatementTable4,
  generateIncomeStatementTable5,
  generateIncomeStatementTable6,
  generateIncomeStatementTable7,
  generateIncomeStatementTable8,
  IncomeStatementTableRow,
  ParentDetailGroupKey,
  tnsModalContent
} from "./IncomeStatementTableData"
import {
  CloseCircleTwoTone,
  DeleteOutlined,
  DownCircleTwoTone,
  DownOutlined,
  EyeOutlined,
  LoadingOutlined,
  PlusOutlined,
  UpCircleTwoTone,
  WarningOutlined
} from "@ant-design/icons"
import { IncomeStatementOptions } from "./IncomeStatementOptions"
import { EvolutionValue } from "../../../components/EvolutionValue/EvolutionValue"
import { useUpdateNoteSettings } from "../../../queries/NoteSettingsQueries"
import { UpdateDialog } from "../../../components/dialog/UpdateDialog"
import { EditorTextInput } from "../../../components/Editor/EditorTextInput"
import { useUpdateNote } from "../../../queries/NoteQueries"
import { RenderExpandIconProps } from "rc-table/lib/interface"
import { AppMetierTextInput } from "../../../components/AppMetierTextInput/AppMetierTextInput"
import { AppMetierCheckbox } from "../../../components/AppMetierCheckbox/AppMetierCheckbox"
import { useUpdatePeriods } from "../settings/period/PeriodQueries"
import { TableTotalCell } from "../../../components/TableTotalCell/TableTotalCell"
import { areSameOutputData, toOutputData } from "../../../components/Editor/utilities"
import { v4 as uuidv4 } from "uuid"
import { mergePeriods } from "../PeriodUtils"
import { getTableMinWidth, instanceOfApiCalculatedData } from "../../../utils/TableUtils"
import { ColumnTitle } from "antd/es/table/interface"
import { NavLink } from "react-router-dom"
import { getFlushEffectCallback, UPDATE_DEBOUNCE_DEFAULT_DELAY } from "../../../utils/DebounceUtils"

const { Text, Title, Paragraph } = Typography

const { Column, ColumnGroup } = Table

const firstColumnWidth = 400
const periodColumnWidth = 300
const percentageColumnWith = 75

export const retrievePercentageText = (referenceAmount: string) =>
  referenceAmount === NoteSettingsReferenceAmountEnum.CHIFFRE_AFFAIRE
    ? "% du CA"
    : "% du total des produits d'exploitation"

export const retrievePercentageColumnTitle = (
  referenceAmount: string
): ColumnTitle<IncomeStatementTableRow> => {
  const columnLabel = retrievePercentageText(referenceAmount)
  return columnLabel.length < 25 ? columnLabel : <Tooltip title={columnLabel}>%...</Tooltip>
}

export const IncomeStatementForm: FC<{
  previousPeriods: PeriodModel[]
  intermediaryPeriod: PeriodModel | undefined
  forecastPeriods: PeriodModel[]
  operations: ApiOperations
}> = ({
  previousPeriods: previousPeriodsProp,
  intermediaryPeriod: intermediaryPeriodProp,
  forecastPeriods: forecastPeriodsProp,
  operations
}) => {
  const { note, noteId, noteSettings, baseNotePath } = useContext(NoteContext)
  const dictionary = useDictionary()

  const [showComputations, setShowComputations] = useState(false)
  const [showPrevious, setShowPrevious] = useState(
    (note.type === NoteTypeEnum.REPRISE || note.type === NoteTypeEnum.STRUCTURE_EXISTANTE) &&
      noteSettings.showPreviousPeriod
  )
  const showEvolutionColumn = note.type === NoteTypeEnum.REPRISE && previousPeriodsProp.length > 0
  const [expandedDetailKeys, setExpandedDetailKeys] = useState<Key[]>(
    noteSettings.expandedDetailKeys
  )

  const getDisplayedPeriodNumber = (showPreviousPeriods: boolean) =>
    (showPreviousPeriods ? previousPeriodsProp.length : 0) +
    (intermediaryPeriodProp ? 1 : 0) +
    forecastPeriodsProp.length

  const referencePercentageIsDisplayed = () =>
    (noteSettings.referenceAmount ?? NoteSettingsReferenceAmountEnum.NONE) !==
    NoteSettingsReferenceAmountEnum.NONE
  const showPreviousEvolution = () => noteSettings.showPercentageCedant ?? false
  const showForecastEvolution = () => noteSettings.showPercentagePrevisionnel ?? false
  const showPreviousIncomeStatementComment =
    (note.type === NoteTypeEnum.REPRISE || note.type === NoteTypeEnum.STRUCTURE_EXISTANTE) &&
    previousPeriodsProp.length > 0

  const [forecastPeriods, setForecastPeriods] = useState(forecastPeriodsProp)
  const [previousPeriods, setPreviousPeriods] = useState(previousPeriodsProp)
  const [intermediaryPeriod, setIntermediaryPeriod] = useState(intermediaryPeriodProp)
  const [debouncedForecastPeriods, setDebouncedForecastPeriods] = useState(forecastPeriodsProp)
  const [debouncedPreviousPeriods, setDebouncedPreviousPeriods] = useState(previousPeriodsProp)
  const [debouncedIntermediaryPeriod, setDebouncedIntermediaryPeriod] =
    useState(intermediaryPeriodProp)
  const [openTnsInIncomeStatementModal, setOpenTnsInIncomeStatementModal] = useState(false)
  const [tnsInIncomeStatement, setTnsInIncomeStatement] = useState(noteSettings.prelevementTnsInCr)
  const [openCorporateTaxesModal, setOpenCorporateTaxesModal] = useState(false)
  const [computedCorporateTaxes, setComputedCorporateTaxes] = useState(
    noteSettings.impotsSurBeneficeComputed
  )
  const [previousIncomeStatementComment, setPreviousIncomeStatementComment] = useState(
    toOutputData(note.commentCrAnterieur)
  )
  const [incomeStatementComment, setIncomeStatementComment] = useState(toOutputData(note.commentCr))

  const updatePeriods = useUpdatePeriods(noteId)
  const updateNote = useUpdateNote(noteId)
  const updateNoteSettings = useUpdateNoteSettings()

  const [dataSource1, setDataSource1] = useState<IncomeStatementTableRow[]>([])
  const [dataSource2, setDataSource2] = useState<IncomeStatementTableRow[]>([])
  const [dataSource3, setDataSource3] = useState<IncomeStatementTableRow[]>([])
  const [dataSource4, setDataSource4] = useState<IncomeStatementTableRow[]>([])
  const [dataSource5, setDataSource5] = useState<IncomeStatementTableRow[]>([])
  const [dataSource6, setDataSource6] = useState<IncomeStatementTableRow[]>([])
  const [dataSource7, setDataSource7] = useState<IncomeStatementTableRow[]>([])
  const [dataSource8, setDataSource8] = useState<IncomeStatementTableRow[]>([])

  const refs = useRef({
    tnsInIncomeStatement,
    computedCorporateTaxes,
    showPrevious,
    expandedDetailKeys
  })

  const getPeriodUpdates = () => {
    const previousPeriodsUpdates = previousPeriods.filter(
      (p, i) =>
        !_.isEqual(p.incomeStatement, previousPeriodsProp[i].incomeStatement) ||
        !_.isEqual(p.prelevementTns, previousPeriodsProp[i].prelevementTns)
    )

    const intermediaryPeriodUpdate =
      intermediaryPeriod &&
      (!_.isEqual(intermediaryPeriod?.caf, intermediaryPeriodProp?.caf) ||
        !_.isEqual(intermediaryPeriod?.incomeStatement, intermediaryPeriodProp?.incomeStatement))
        ? [intermediaryPeriod]
        : []

    const forecastPeriodsUpdates = forecastPeriods.filter(
      (p, i) =>
        !_.isEqual(p.incomeStatement, forecastPeriodsProp[i].incomeStatement) ||
        !_.isEqual(p.prelevementTns, forecastPeriodsProp[i].prelevementTns)
    )

    return previousPeriodsUpdates
      .concat(forecastPeriodsUpdates)
      .concat(intermediaryPeriodUpdate)
      .map(periodToMutationModel)
  }

  const debouncedUpdatePeriods = useDebouncedCallback(() => {
    const updates = getPeriodUpdates()
    if (updates.length > 0) {
      updatePeriods.mutate(updates)
    }
  }, UPDATE_DEBOUNCE_DEFAULT_DELAY)

  useEffect(debouncedUpdatePeriods, [forecastPeriods, previousPeriods, intermediaryPeriod])
  useEffect(getFlushEffectCallback(debouncedUpdatePeriods), [debouncedUpdatePeriods])

  const debouncedUpdateComments = useDebouncedCallback(() => {
    if (
      !areSameOutputData(previousIncomeStatementComment, toOutputData(note.commentCrAnterieur)) ||
      !areSameOutputData(incomeStatementComment, toOutputData(note.commentCr))
    ) {
      updateNote.mutate({
        noteId,
        commentCrAnterieur: JSON.stringify(previousIncomeStatementComment),
        commentCr: JSON.stringify(incomeStatementComment)
      })
    }
  }, UPDATE_DEBOUNCE_DEFAULT_DELAY)

  useEffect(debouncedUpdateComments, [previousIncomeStatementComment, incomeStatementComment])
  useEffect(getFlushEffectCallback(debouncedUpdateComments), [debouncedUpdateComments])

  useEffect(() => {
    if (!_.isEqual(previousPeriods, previousPeriodsProp)) {
      setDebouncedPreviousPeriods(
        previousPeriods.map((p, i) => mergePeriods(p, previousPeriodsProp[i]))
      )
    }

    if (!_.isEqual(intermediaryPeriod, intermediaryPeriodProp)) {
      if (intermediaryPeriod && intermediaryPeriodProp) {
        setDebouncedIntermediaryPeriod(mergePeriods(intermediaryPeriod, intermediaryPeriodProp))
      }
    }

    if (!_.isEqual(forecastPeriods, forecastPeriodsProp)) {
      setDebouncedForecastPeriods(
        forecastPeriods.map((p, i) => mergePeriods(p, forecastPeriodsProp[i]))
      )
    }
  }, [previousPeriodsProp, intermediaryPeriodProp, forecastPeriodsProp])

  useEffect(() => {
    if (refs.current.tnsInIncomeStatement !== tnsInIncomeStatement) {
      refs.current.tnsInIncomeStatement = tnsInIncomeStatement
      updateNoteSettings.mutate({
        noteId,
        saveNoteSettingsRequest: {
          prelevementTnsInCr: tnsInIncomeStatement
        }
      })
    }
  }, [tnsInIncomeStatement])

  useEffect(() => {
    if (refs.current.computedCorporateTaxes !== computedCorporateTaxes) {
      refs.current.computedCorporateTaxes = computedCorporateTaxes
      updateNoteSettings.mutate({
        noteId,
        saveNoteSettingsRequest: {
          impotsSurBeneficeComputed: computedCorporateTaxes
        }
      })
      if (!computedCorporateTaxes) {
        setForecastPeriods(forecastPeriods =>
          forecastPeriods.map(p => ({
            ...p,
            incomeStatement: {
              ...p.incomeStatement,
              impotsSurBeneficesInput: {
                ...p.incomeStatement.impotsSurBeneficesInput,
                value: undefined,
                detail: undefined
              }
            }
          }))
        )
      }
    }
  }, [computedCorporateTaxes])

  useEffect(() => {
    if (refs.current.showPrevious !== showPrevious) {
      refs.current.showPrevious = showPrevious
      updateNoteSettings.mutate({
        noteId,
        saveNoteSettingsRequest: {
          showPreviousPeriod: showPrevious
        }
      })
    }
  }, [showPrevious])

  useEffect(() => {
    if (refs.current.expandedDetailKeys !== expandedDetailKeys) {
      refs.current.expandedDetailKeys = expandedDetailKeys
      updateNoteSettings.mutate({
        noteId,
        saveNoteSettingsRequest: {
          expandedDetailKeys: expandedDetailKeys.map(key => key.toString())
        }
      })
    }
  }, [expandedDetailKeys])

  useEffect(() => {
    const rows1 = generateIncomeStatementTable1(
      noteSettings,
      debouncedPreviousPeriods[0] || debouncedForecastPeriods[0]
    )

    const rows2 = generateIncomeStatementTable2(
      tnsInIncomeStatement,
      noteSettings,
      debouncedPreviousPeriods[0] || debouncedForecastPeriods[0]
    )

    const rows3 = generateIncomeStatementTable3(noteSettings)
    const rows4 = generateIncomeStatementTable4(noteSettings, note.type)
    const rows5 = generateIncomeStatementTable5(noteSettings)
    const rows6 = generateIncomeStatementTable6(noteSettings)
    const rows7 = generateIncomeStatementTable7(noteSettings)
    const rows8 = generateIncomeStatementTable8(noteSettings)

    setDataSource1(rows1)
    setDataSource2(rows2)
    setDataSource3(rows3)
    setDataSource4(rows4)
    setDataSource5(rows5)
    setDataSource6(rows6)
    setDataSource7(rows7)
    setDataSource8(rows8)
  }, [
    debouncedForecastPeriods,
    debouncedPreviousPeriods,
    debouncedIntermediaryPeriod,
    tnsInIncomeStatement,
    noteSettings
  ])

  const allDebouncedPeriods = useMemo(
    () =>
      debouncedPreviousPeriods
        .concat(debouncedIntermediaryPeriod ? [debouncedIntermediaryPeriod] : [])
        .concat(debouncedForecastPeriods),
    [debouncedForecastPeriods, debouncedPreviousPeriods, debouncedIntermediaryPeriod]
  )

  const generateTotalPeriodColumn = (
    data: ApiCalculatedData | ApiNumericData,
    suffix: Suffixes,
    isLoansRepaymentLine: boolean = false,
    isFirstPreviousPeriodsColumn: boolean = false
  ) => (
    <>
      {isLoansRepaymentLine && isFirstPreviousPeriodsColumn ? (
        <Text>-</Text>
      ) : (
        <TableTotalCell
          data={data}
          suffix={suffix}
          optionalDescription={
            isLoansRepaymentLine
              ? "Le montant des dividendes est égal à 0 si le résultat du calcul ci-dessus est négatif"
              : undefined
          }
        />
      )}
    </>
  )

  const countForecastPeriodsColumns = () => {
    let forecastPeriodsColumnsCount = debouncedForecastPeriods.length
    if (referencePercentageIsDisplayed()) {
      forecastPeriodsColumnsCount *= 2
    }
    if (showForecastEvolution()) {
      forecastPeriodsColumnsCount += debouncedForecastPeriods.length - 1
    }
    return forecastPeriodsColumnsCount
  }

  const updateAllPeriods = (periodsUpdate: (periods: PeriodModel[]) => PeriodModel[]) => {
    setPreviousPeriods(periodsUpdate)
    setIntermediaryPeriod(period => (period ? periodsUpdate([period])[0] : period))
    setForecastPeriods(periodsUpdate)
  }

  const updateAllDebouncedPeriods = (periodsUpdate: (periods: PeriodModel[]) => PeriodModel[]) => {
    setDebouncedPreviousPeriods(periodsUpdate)
    setDebouncedIntermediaryPeriod(period => (period ? periodsUpdate([period])[0] : period))
    setDebouncedForecastPeriods(periodsUpdate)
  }

  const addDetailLine = ({
    parentDetailGroupKey,
    parentDetailGroupSelector,
    detailGroupKey,
    detailGroupSelector
  }: {
    parentDetailGroupKey: ParentDetailGroupKey
    parentDetailGroupSelector: (period: PeriodModel) => ApiEntryWithDetail | ApiAutresAchats
    detailGroupKey: DetailGroupKey
    detailGroupSelector: (period: PeriodModel) => ApiDetailLines
  }) => {
    const periodsUpdate = (periods: PeriodModel[]) => {
      const newLine: ApiDetailLine = {
        id: uuidv4(),
        label: "",
        value: { kiloEuros: noteSettings.kiloEuros },
        required: false,
        variable: false
      }
      return periods.map(p => {
        const parentDetailGroup = parentDetailGroupSelector(p)
        const detailGroup = detailGroupSelector(p)

        return {
          ...p,
          incomeStatement: {
            ...p.incomeStatement,
            [parentDetailGroupKey]: {
              ...parentDetailGroup,
              [detailGroupKey]: {
                ...detailGroup,
                lines: [...detailGroup.lines, newLine]
              }
            }
          }
        }
      })
    }

    updateAllPeriods(periodsUpdate)
  }

  const updateDetailLine = ({
    parentDetailGroupKey,
    parentDetailGroupSelector,
    detailGroupKey,
    detailGroupSelector,
    lineId,
    lineUpdateFn
  }: {
    parentDetailGroupKey: ParentDetailGroupKey
    parentDetailGroupSelector: (period: PeriodModel) => ApiEntryWithDetail | ApiAutresAchats
    detailGroupKey: DetailGroupKey
    detailGroupSelector: (period: PeriodModel) => ApiDetailLines
    lineId: string | undefined
    lineUpdateFn: (line: ApiDetailLine) => ApiDetailLine
  }) => {
    const periodsUpdate = (periods: PeriodModel[]) =>
      periods.map(p => {
        const parentDetailGroup = parentDetailGroupSelector(p)
        const detailGroup = detailGroupSelector(p)
        return {
          ...p,
          incomeStatement: {
            ...p.incomeStatement,
            [parentDetailGroupKey]: {
              ...parentDetailGroup,
              [detailGroupKey]: {
                ...detailGroup,
                lines: detailGroup.lines.map(l => (l.id === lineId ? lineUpdateFn(l) : l))
              }
            }
          }
        }
      })

    updateAllPeriods(periodsUpdate)
  }

  const deleteDetailLine = ({
    lineId,
    parentDetailGroupKey,
    parentDetailGroupSelector,
    detailGroupKey,
    detailGroupSelector
  }: {
    lineId: string | undefined
    parentDetailGroupKey: ParentDetailGroupKey
    parentDetailGroupSelector: (period: PeriodModel) => ApiEntryWithDetail | ApiAutresAchats
    detailGroupKey: DetailGroupKey
    detailGroupSelector: (period: PeriodModel) => ApiDetailLines
  }) => {
    const periodsUpdate = (periods: PeriodModel[]) =>
      periods.map(p => {
        const parentDetailGroup = parentDetailGroupSelector(p)
        const detailGroup = detailGroupSelector(p)
        return {
          ...p,
          incomeStatement: {
            ...p.incomeStatement,
            [parentDetailGroupKey]: {
              ...parentDetailGroup,
              [detailGroupKey]: {
                ...detailGroup,
                lines: detailGroup.lines.filter(l => l.id !== lineId)
              }
            }
          }
        }
      })

    updateAllPeriods(periodsUpdate)
    updateAllDebouncedPeriods(periodsUpdate)
  }

  const generateColumns = (innerTable: boolean) => (
    <>
      {innerTable ? (
        <Column
          title=""
          width={firstColumnWidth}
          key="line_label"
          className="income-statement-label-column"
          render={(
            _,
            {
              label,
              linkRelativePath,
              isTnsLine,
              isCorporateTaxesLine,
              isDeadCenterLine,
              detail
            }: IncomeStatementTableRow
          ) => (
            <>
              {(detail === undefined ||
                (detail?.required === true && detail?.isDetailLineAdder === false)) && (
                <>
                  <Text>{label}</Text>
                  {linkRelativePath && (
                    <NavLink
                      to={`${baseNotePath}/${linkRelativePath}`}
                      style={{ marginLeft: "1em" }}
                    >
                      <EyeOutlined />
                    </NavLink>
                  )}
                </>
              )}
              {detail && !detail.required && (
                <Space direction="horizontal">
                  <AppMetierTextInput
                    inputValue={label}
                    onChange={value => {
                      if (value.trim() !== "") {
                        updateDetailLine({
                          parentDetailGroupKey: detail.parentDetailGroupKey,
                          parentDetailGroupSelector: detail.parentDetailGroupSelector,
                          detailGroupKey: detail.detailGroupKey,
                          detailGroupSelector: detail.detailGroupSelector,
                          lineId: detail.id,
                          lineUpdateFn: line => ({ ...line, label: value })
                        })
                      }
                    }}
                    name=""
                  />
                  <Button
                    icon={<DeleteOutlined />}
                    onClick={() =>
                      deleteDetailLine({
                        lineId: detail.id,
                        parentDetailGroupKey: detail.parentDetailGroupKey,
                        parentDetailGroupSelector: detail.parentDetailGroupSelector,
                        detailGroupKey: detail.detailGroupKey,
                        detailGroupSelector: detail.detailGroupSelector
                      })
                    }
                  />
                </Space>
              )}
              {isDeadCenterLine &&
                operations.bankLoans.length === 0 &&
                operations.investments.length && (
                  <Tooltip title="Ce calcul du point mort n’inclut aucun prêt pour l’instant.">
                    <WarningOutlined />
                  </Tooltip>
                )}
              {isTnsLine && (
                <>
                  <AppMetierSwitch
                    checked={tnsInIncomeStatement}
                    onChange={() => setOpenTnsInIncomeStatementModal(true)}
                    label={"Dans le CR prévisionnel"}
                    style={{ float: "right" }}
                  />
                  <UpdateDialog
                    isOpen={openTnsInIncomeStatementModal}
                    dialogBody={tnsModalContent(tnsInIncomeStatement)}
                    cancelClick={() => setOpenTnsInIncomeStatementModal(false)}
                    updateFunction={() => {
                      setOpenTnsInIncomeStatementModal(false)
                      setTnsInIncomeStatement(!tnsInIncomeStatement)
                    }}
                  />
                </>
              )}
              {isCorporateTaxesLine && noteSettings.assujettiImpotsSocietes && (
                <>
                  <AppMetierSwitch
                    checked={computedCorporateTaxes}
                    onChange={() => setOpenCorporateTaxesModal(true)}
                    label={"Calcul auto dans le CR prévi."}
                    style={{ float: "right" }}
                  />
                  <UpdateDialog
                    isOpen={openCorporateTaxesModal}
                    dialogBody={corporateTaxesModalContent(computedCorporateTaxes)}
                    cancelClick={() => setOpenCorporateTaxesModal(false)}
                    updateFunction={() => {
                      setOpenCorporateTaxesModal(false)
                      setComputedCorporateTaxes(!computedCorporateTaxes)
                    }}
                  />
                </>
              )}
              {detail && detail.isDetailsGroupWithVariableLines && (
                <Text underline italic style={{ float: "right" }}>
                  Variable
                </Text>
              )}
              {detail && detail.variable !== undefined && (
                <AppMetierCheckbox
                  defaultChecked={detail.variable}
                  onChange={checked => {
                    updateDetailLine({
                      parentDetailGroupKey: detail.parentDetailGroupKey,
                      parentDetailGroupSelector: detail.parentDetailGroupSelector,
                      detailGroupKey: detail.detailGroupKey,
                      detailGroupSelector: detail.detailGroupSelector,
                      lineId: detail.id,
                      lineUpdateFn: line => ({ ...line, variable: checked })
                    })
                  }}
                  style={{ float: "right" }}
                  name=""
                />
              )}
              {detail && detail.isDetailLineAdder && (
                <Button
                  onClick={() =>
                    addDetailLine({
                      parentDetailGroupKey: detail.parentDetailGroupKey,
                      parentDetailGroupSelector: detail.parentDetailGroupSelector,
                      detailGroupKey: detail.detailGroupKey,
                      detailGroupSelector: detail.detailGroupSelector
                    })
                  }
                  disabled={updatePeriods.isPending}
                >
                  {updatePeriods.isPending ? <LoadingOutlined /> : <PlusOutlined />}
                  {label}
                </Button>
              )}
            </>
          )}
        />
      ) : (
        <Column
          title=""
          width={firstColumnWidth}
          key="line_label"
          render={(_, { label }: IncomeStatementWrapperTableRow) => (
            <Title level={4} style={{ marginTop: "0.5em" }}>
              {label}
            </Title>
          )}
        />
      )}
      {showPrevious && debouncedPreviousPeriods.length > 0 && (
        <ColumnGroup title={dictionary.exerciceCedantLabel} key="previous_periods_group">
          {debouncedPreviousPeriods.map((p, i) => (
            <ColumnGroup
              title={dictionary.periodTitle(p, i)}
              key={`previous_period_${p.id}_group`}
              align="center"
            >
              <Column
                title="Montant"
                key={`previous_period_${p.id}_amount`}
                align="right"
                className="inner-column-title previous-column"
                render={
                  innerTable
                    ? (
                        _,
                        {
                          isTotal,
                          isLoansRepaymentLine,
                          valueGetter,
                          periodUpdater,
                          suffix,
                          hasSomeFilledDetails,
                          detail,
                          onlyForFuturePeriods,
                          disabled
                        }: IncomeStatementTableRow
                      ) => {
                        if (
                          detail?.isDetailsGroupWithVariableLines === true ||
                          detail?.isDetailLineAdder === true ||
                          onlyForFuturePeriods === true
                        ) {
                          return undefined
                        }
                        const value = valueGetter(p)
                        return !instanceOfApiCalculatedData(value) &&
                          !isTotal &&
                          !hasSomeFilledDetails?.(allDebouncedPeriods) ? (
                          <NumericDataInput
                            numericData={value}
                            isEuro={suffix === Suffixes.EUROS || suffix === Suffixes.KILO_EUROS}
                            isPourcentage={suffix === Suffixes.PERCENTAGE}
                            showComputations={showComputations}
                            isCalculated={false}
                            onChange={value => {
                              if (periodUpdater && (value || value === 0)) {
                                setPreviousPeriods(previousPeriods =>
                                  previousPeriods.map(period =>
                                    period.id === p.id
                                      ? periodUpdater(period, value, detail?.variable)
                                      : period
                                  )
                                )
                              }
                            }}
                            disabled={disabled}
                            name=""
                          />
                        ) : (
                          generateTotalPeriodColumn(
                            value as ApiCalculatedData,
                            suffix,
                            isLoansRepaymentLine,
                            i === 0
                          )
                        )
                      }
                    : undefined
                }
              />
              {(showPreviousEvolution() && i > 0 && (
                <Column
                  title="% évolution"
                  key={`previous_period_${p.id}_evolution_percentage`}
                  align="right"
                  width={percentageColumnWith}
                  className="inner-column-title inner-column-text previous-column"
                  render={
                    innerTable
                      ? (_, { valueGetter }: IncomeStatementTableRow) => {
                          const value = valueGetter(p)
                          return (
                            <EvolutionValue
                              value={
                                instanceOfApiCalculatedData(value)
                                  ? value.numericData.percentageEvolution
                                  : value?.percentageEvolution
                              }
                            />
                          )
                        }
                      : undefined
                  }
                />
              )) || <></>}
              {(referencePercentageIsDisplayed() && (
                <Column
                  title={retrievePercentageColumnTitle(noteSettings.referenceAmount)}
                  key={`previous_period_${p.id}_reference_percentage`}
                  align="right"
                  className="inner-column-title inner-column-text previous-column"
                  width={percentageColumnWith}
                  render={
                    innerTable
                      ? (_, { valueGetter, suffix }: IncomeStatementTableRow) => {
                          if (suffix === Suffixes.PERCENTAGE) {
                            return undefined
                          }
                          const data = valueGetter(p)
                          const value = instanceOfApiCalculatedData(data)
                            ? data.numericData.percentageReference
                            : data?.percentageReference
                          return value ? (
                            <NumberText value={value} suffix={Suffixes.PERCENTAGE} />
                          ) : undefined
                        }
                      : undefined
                  }
                />
              )) || <></>}
            </ColumnGroup>
          ))}
        </ColumnGroup>
      )}
      {showEvolutionColumn && (
        <ColumnGroup title="Évolution" key="evolution" align="center">
          <Column
            title={"Évolution entre dernier exe cédant et exe n°1 prévi."}
            key="evolution"
            align="right"
            className={"inner-column-title intermediary-column"}
            render={
              innerTable
                ? (_, { valueGetter }: IncomeStatementTableRow) => {
                    const value = valueGetter(debouncedForecastPeriods[0])
                    return (
                      <EvolutionValue
                        value={
                          instanceOfApiCalculatedData(value)
                            ? value.numericData.percentageEvolution
                            : value?.percentageEvolution
                        }
                      />
                    )
                  }
                : undefined
            }
          />
        </ColumnGroup>
      )}
      {debouncedIntermediaryPeriod && (
        <ColumnGroup
          title="Situation intermédiaire"
          key={`intermediary_period_${debouncedIntermediaryPeriod.id}`}
          align="center"
        >
          <Column
            title="Montant"
            key={`intermediary_period_${debouncedIntermediaryPeriod.id}_amount`}
            align="right"
            className={"intermediary-column"}
            render={
              innerTable
                ? (
                    _,
                    {
                      isTotal,
                      isLoansRepaymentLine,
                      valueGetter,
                      periodUpdater,
                      suffix,
                      detail,
                      hasSomeFilledDetails,
                      disabled
                    }: IncomeStatementTableRow
                  ) => {
                    if (
                      detail?.isDetailsGroupWithVariableLines === true ||
                      detail?.isDetailLineAdder === true
                    ) {
                      return undefined
                    }
                    const value = valueGetter(debouncedIntermediaryPeriod)
                    return !instanceOfApiCalculatedData(value) &&
                      !isTotal &&
                      !hasSomeFilledDetails?.(allDebouncedPeriods) ? (
                      <NumericDataInput
                        numericData={value}
                        isEuro={suffix === Suffixes.EUROS || suffix === Suffixes.KILO_EUROS}
                        isPourcentage={suffix === Suffixes.PERCENTAGE}
                        showComputations={showComputations}
                        isCalculated={false}
                        onChange={value => {
                          if (periodUpdater && (value || value === 0)) {
                            setIntermediaryPeriod(intermediaryPeriod => {
                              if (intermediaryPeriod) {
                                return periodUpdater(intermediaryPeriod, value, detail?.variable)
                              }
                            })
                          }
                        }}
                        disabled={disabled}
                        name=""
                      />
                    ) : (
                      generateTotalPeriodColumn(
                        value as ApiCalculatedData,
                        suffix,
                        isLoansRepaymentLine
                      )
                    )
                  }
                : undefined
            }
          />
        </ColumnGroup>
      )}
      <ColumnGroup title="Exercices prévisionnels" key="forecast_periods">
        {debouncedForecastPeriods.map((p, i) => (
          <ColumnGroup
            title={dictionary.periodTitle(p, i)}
            key={`forecast_period_${p.id}`}
            align="center"
          >
            <Column
              title="Montant"
              key={`forecast_period_${p.id}_amount`}
              align="right"
              className={"inner-column-title"}
              render={
                innerTable
                  ? (
                      _,
                      {
                        isTotal,
                        isLoansRepaymentLine,
                        isTnsLine,
                        valueGetter,
                        periodUpdater,
                        suffix,
                        detail,
                        hasSomeFilledDetails,
                        disabled
                      }: IncomeStatementTableRow
                    ) => {
                      if (
                        detail?.isDetailsGroupWithVariableLines === true ||
                        detail?.isDetailLineAdder === true
                      ) {
                        return undefined
                      }
                      if (isTnsLine && !noteSettings.prelevementTnsInCr) {
                        return (
                          <Text>
                            Le prélèvement TNS est pris en compte comme un besoin du plan de
                            financement.
                          </Text>
                        )
                      }
                      const value = valueGetter(p)
                      return !instanceOfApiCalculatedData(value) &&
                        !isTotal &&
                        !hasSomeFilledDetails?.(allDebouncedPeriods) ? (
                        <NumericDataInput
                          numericData={value}
                          isEuro={suffix === Suffixes.EUROS || suffix === Suffixes.KILO_EUROS}
                          isPourcentage={suffix === Suffixes.PERCENTAGE}
                          showComputations={showComputations}
                          isCalculated={false}
                          onChange={value => {
                            if (periodUpdater && (value || value === 0)) {
                              setForecastPeriods(forecastPeriods =>
                                forecastPeriods.map(period =>
                                  period.id === p.id
                                    ? periodUpdater(period, value, detail?.variable)
                                    : period
                                )
                              )
                            }
                          }}
                          disabled={disabled}
                          name=""
                        />
                      ) : (
                        generateTotalPeriodColumn(
                          value as ApiCalculatedData,
                          suffix,
                          isLoansRepaymentLine
                        )
                      )
                    }
                  : undefined
              }
              onCell={({ isTnsLine }: IncomeStatementTableRow) => ({
                colSpan:
                  isTnsLine && !noteSettings.prelevementTnsInCr
                    ? i == 0
                      ? countForecastPeriodsColumns()
                      : 0
                    : 1
              })}
            />
            {(showForecastEvolution() && i > 0 && (
              <Column
                title="% évolution"
                key={`forecast_period_${p.id}_evolution_percentage`}
                align="right"
                width={percentageColumnWith}
                className="inner-column-title inner-column-text"
                render={
                  innerTable
                    ? (_, { valueGetter }: IncomeStatementTableRow) => {
                        const value = valueGetter(p)
                        return (
                          <EvolutionValue
                            value={
                              instanceOfApiCalculatedData(value)
                                ? value.numericData.percentageEvolution
                                : value?.percentageEvolution
                            }
                          />
                        )
                      }
                    : undefined
                }
                onCell={({ isTnsLine }: IncomeStatementTableRow) => ({
                  colSpan: isTnsLine && !noteSettings.prelevementTnsInCr ? 0 : 1
                })}
              />
            )) || <></>}
            {(referencePercentageIsDisplayed() && (
              <Column
                title={retrievePercentageColumnTitle(noteSettings.referenceAmount)}
                key={`forecast_period_${p.id}_reference_percentage`}
                align="right"
                className="inner-column-title inner-column-text"
                width={percentageColumnWith}
                render={
                  innerTable
                    ? (_, { valueGetter }: IncomeStatementTableRow) => {
                        const data = valueGetter(p)
                        const value = instanceOfApiCalculatedData(data)
                          ? data.numericData.percentageReference
                          : data?.percentageReference
                        return value ? (
                          <NumberText value={value} suffix={Suffixes.PERCENTAGE} />
                        ) : undefined
                      }
                    : undefined
                }
                onCell={({ isTnsLine }: IncomeStatementTableRow) => ({
                  colSpan: isTnsLine && !noteSettings.prelevementTnsInCr ? 0 : 1
                })}
              />
            )) || <></>}
          </ColumnGroup>
        ))}
      </ColumnGroup>
    </>
  )

  const deleteDetails = ({
    key: recordKey,
    details,
    periodUpdater: totalUpdater,
    valueGetter: totalGetter
  }: IncomeStatementTableRow) => {
    if (details) {
      const nestedDetails = details.flatMap(d => d.details ?? [])
      const detailsToDelete = nestedDetails.length ? nestedDetails : details

      const periodsUpdate = (periods: PeriodModel[], debouncedPeriods: PeriodModel[]) =>
        periods.map((p, i) => {
          const periodWithUpdatedDetails = detailsToDelete.reduce(
            (acc, { periodUpdater }: IncomeStatementTableRow) =>
              periodUpdater ? periodUpdater(acc, undefined, false, true) : acc,
            p
          )

          // Pick total from debounced value because it was not edited by the user when the details were filled in
          const total = totalGetter(debouncedPeriods[i]) as ApiNumericData
          return totalUpdater
            ? totalUpdater(periodWithUpdatedDetails, total)
            : periodWithUpdatedDetails
        })

      setDebouncedPreviousPeriods(periods => periodsUpdate(periods, debouncedPreviousPeriods))
      setDebouncedIntermediaryPeriod(period =>
        period && debouncedIntermediaryPeriod
          ? periodsUpdate([period], [debouncedIntermediaryPeriod])[0]
          : period
      )
      setDebouncedForecastPeriods(periods => periodsUpdate(periods, debouncedForecastPeriods))
      setPreviousPeriods(periods => periodsUpdate(periods, debouncedPreviousPeriods))
      setIntermediaryPeriod(period =>
        period && debouncedIntermediaryPeriod
          ? periodsUpdate([period], [debouncedIntermediaryPeriod])[0]
          : period
      )
      setForecastPeriods(periods => periodsUpdate(periods, debouncedForecastPeriods))
      setExpandedDetailKeys(keys => keys.filter(key => key !== recordKey))
    }
  }

  const expandIcon = ({ expanded, record }: RenderExpandIconProps<IncomeStatementTableRow>) => {
    const [openDialog, setOpenDialog] = useState(false)

    if (record.details) {
      return (
        <>
          {record.hasSomeFilledDetails?.(allDebouncedPeriods) === true && (
            <>
              <Tooltip title={"Supprimer les détails"}>
                <CloseCircleTwoTone
                  onClick={() => setOpenDialog(true)}
                  twoToneColor="#00ccaa"
                  style={{ fontSize: "16px", marginRight: "0.5em" }}
                />
              </Tooltip>
              <UpdateDialog
                isOpen={openDialog}
                dialogBody={
                  <>
                    <Paragraph>
                      <Text>
                        Êtes-vous sûr de vouloir supprimer le détail de{" "}
                        <Text strong>{record.label}</Text> ?
                      </Text>
                    </Paragraph>
                    <Text>Cette action ne peut pas être inversée.</Text>
                  </>
                }
                cancelClick={() => setOpenDialog(false)}
                updateFunction={() => {
                  deleteDetails(record)
                  setOpenDialog(false)
                }}
              />
            </>
          )}
          {expanded ? (
            <UpCircleTwoTone
              onClick={() => {
                setExpandedDetailKeys(keys => keys.filter(key => key !== record.key))
              }}
              twoToneColor="#00ccaa"
              style={{ fontSize: "16px", marginRight: "0.5em" }}
            />
          ) : (
            <DownCircleTwoTone
              onClick={() => {
                setExpandedDetailKeys(keys => [...keys, record.key])
              }}
              twoToneColor="#00ccaa"
              style={{ fontSize: "16px", marginRight: "0.5em" }}
            />
          )}
        </>
      )
    }
  }

  const rowClassName = (record: IncomeStatementTableRow) =>
    `${record.isTotal ? "result-row" : ""} ${
      record.highlighted ? "highlighted" : ""
    } ${record.className}`

  return (
    <>
      <Row
        gutter={[16, 16]}
        style={{
          minWidth: getTableMinWidth(
            getDisplayedPeriodNumber(showPrevious),
            periodColumnWidth,
            firstColumnWidth
          )
        }}
      >
        <Col span={24}>
          <Space direction="horizontal" size="large">
            <AppMetierSwitch
              label={"Voir tous les calculs"}
              checked={showComputations}
              onChange={() => {
                setShowComputations(!showComputations)
              }}
            />
            <Popover
              content={<IncomeStatementOptions />}
              trigger="click"
              placement={"bottom"}
              className={"pointer-cursor"}
            >
              Affichage des % <DownOutlined />
            </Popover>
            {(note.type === NoteTypeEnum.REPRISE ||
              note.type === NoteTypeEnum.STRUCTURE_EXISTANTE) &&
              debouncedPreviousPeriods.length !== 0 && (
                <AppMetierSwitch
                  label={`Afficher ${dictionary.exerciceCedantLabel.toLowerCase()}`}
                  checked={showPrevious}
                  onChange={() => {
                    setShowPrevious(!showPrevious)
                  }}
                />
              )}
          </Space>
        </Col>
        <Col span={24}>
          <Table<IncomeStatementWrapperTableRow>
            size={"small"}
            showHeader={true}
            className={"parent-table"}
            tableLayout={"fixed"}
            expandable={{
              expandedRowRender: ({ table }) => table,
              defaultExpandAllRows: true,
              showExpandColumn: false
            }}
            dataSource={[
              {
                key: "0",
                label: "Produits d'exploitation",
                table: (
                  <Table
                    dataSource={dataSource1}
                    size="small"
                    className={"table-hover nested-table"}
                    rowClassName={rowClassName}
                    pagination={false}
                    tableLayout={"fixed"}
                    showHeader={false}
                    expandable={{
                      expandedRowKeys: expandedDetailKeys,
                      childrenColumnName: "details",
                      showExpandColumn: true,
                      indentSize: 40,
                      expandIcon: expandIcon
                    }}
                  >
                    {generateColumns(true)}
                  </Table>
                )
              },
              {
                key: "1",
                label: "Charges d'exploitation",
                table: (
                  <Table
                    dataSource={dataSource2}
                    size="small"
                    className={"table-hover nested-table"}
                    rowClassName={rowClassName}
                    pagination={false}
                    tableLayout={"fixed"}
                    showHeader={false}
                    expandable={{
                      expandedRowKeys: expandedDetailKeys,
                      childrenColumnName: "details",
                      showExpandColumn: true,
                      indentSize: 40,
                      expandIcon: expandIcon
                    }}
                  >
                    {generateColumns(true)}
                  </Table>
                )
              },
              {
                key: "2",
                label: "Résultat d'exploitation",
                table: (
                  <Table
                    dataSource={dataSource3}
                    size="small"
                    className={"table-hover nested-table"}
                    rowClassName={rowClassName}
                    pagination={false}
                    tableLayout={"fixed"}
                    showHeader={false}
                  >
                    {generateColumns(true)}
                  </Table>
                )
              },
              {
                key: "3",
                label: "Résultat financier",
                table: (
                  <Table
                    dataSource={dataSource4}
                    size="small"
                    className={"table-hover nested-table"}
                    rowClassName={rowClassName}
                    pagination={false}
                    tableLayout={"fixed"}
                    showHeader={false}
                    expandable={{
                      expandedRowKeys: expandedDetailKeys,
                      childrenColumnName: "details",
                      showExpandColumn: true,
                      fixed: true,
                      indentSize: 40,
                      expandIcon: expandIcon
                    }}
                  >
                    {generateColumns(true)}
                  </Table>
                )
              },
              {
                key: "4",
                label: "Résultat exceptionnel",
                table: (
                  <Table
                    dataSource={dataSource5}
                    size="small"
                    className={"table-hover nested-table"}
                    rowClassName={rowClassName}
                    pagination={false}
                    tableLayout={"fixed"}
                    showHeader={false}
                    expandable={{
                      expandedRowKeys: expandedDetailKeys,
                      childrenColumnName: "details",
                      showExpandColumn: true,
                      fixed: true,
                      indentSize: 40,
                      expandIcon: expandIcon
                    }}
                  >
                    {generateColumns(true)}
                  </Table>
                )
              },
              {
                key: "5",
                label: "",
                table: (
                  <Table
                    dataSource={dataSource6}
                    size="small"
                    className={"table-hover nested-table"}
                    rowClassName={rowClassName}
                    pagination={false}
                    tableLayout={"fixed"}
                    showHeader={false}
                    expandable={{
                      childrenColumnName: "details",
                      showExpandColumn: true,
                      fixed: true,
                      indentSize: 40,
                      expandIcon: expandIcon
                    }}
                  >
                    {generateColumns(true)}
                  </Table>
                )
              },
              {
                key: "6",
                label: "Résultat net",
                table: (
                  <Table
                    dataSource={dataSource7}
                    size="small"
                    className={"table-hover nested-table"}
                    rowClassName={rowClassName}
                    pagination={false}
                    tableLayout={"fixed"}
                    showHeader={false}
                    expandable={{
                      childrenColumnName: "details",
                      showExpandColumn: true,
                      fixed: true,
                      indentSize: 40,
                      expandIcon: expandIcon
                    }}
                  >
                    {generateColumns(true)}
                  </Table>
                )
              },
              {
                key: "7",
                label: "SIG",
                table: (
                  <Table
                    dataSource={dataSource8}
                    size="small"
                    className={"table-hover nested-table"}
                    rowClassName={rowClassName}
                    pagination={false}
                    tableLayout={"fixed"}
                    showHeader={false}
                    expandable={{
                      childrenColumnName: "details",
                      showExpandColumn: true,
                      fixed: true,
                      indentSize: 40,
                      expandIcon: expandIcon
                    }}
                  >
                    {generateColumns(true)}
                  </Table>
                )
              }
            ]}
            pagination={false}
          >
            {generateColumns(false)}
          </Table>
        </Col>
      </Row>
      <Divider />
      <Row gutter={[16, 16]}>
        <Col span={24}>
          {showPreviousIncomeStatementComment && (
            <>
              <Title level={3}>{dictionary.commentAnterieurLabel}</Title>
              <EditorTextInput
                placeHolder={"Vous pouvez saisir un commentaire"}
                initialValue={previousIncomeStatementComment}
                onChange={v => setPreviousIncomeStatementComment(v)}
              />
            </>
          )}
        </Col>
        <Col span={24}>
          <Title level={3}>Commentaire - Compte de résultat prévisionnel</Title>
          <EditorTextInput
            placeHolder={"Vous pouvez saisir un commentaire"}
            initialValue={incomeStatementComment}
            onChange={v => setIncomeStatementComment(v)}
          />
        </Col>
      </Row>
    </>
  )
}

interface IncomeStatementWrapperTableRow {
  key: React.Key
  label: string
  table: React.ReactNode
}
