/* tslint:disable */
/* eslint-disable */
/**
 * Appli métier
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: Yoda 1 & 2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from "axios"
import { Configuration } from "../configuration"
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction
} from "../common"
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from "../base"
/**
 * TvaResourceApi - axios parameter creator
 * @export
 */
export const TvaResourceApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get TVA configuration table
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    get: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/tva`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * TvaResourceApi - functional programming interface
 * @export
 */
export const TvaResourceApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = TvaResourceApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @summary Get TVA configuration table
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async get(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<number>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.get(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    }
  }
}

/**
 * TvaResourceApi - factory interface
 * @export
 */
export const TvaResourceApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = TvaResourceApiFp(configuration)
  return {
    /**
     *
     * @summary Get TVA configuration table
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    get(options?: any): AxiosPromise<Array<number>> {
      return localVarFp.get(options).then(request => request(axios, basePath))
    }
  }
}

/**
 * TvaResourceApi - object-oriented interface
 * @export
 * @class TvaResourceApi
 * @extends {BaseAPI}
 */
export class TvaResourceApi extends BaseAPI {
  /**
   *
   * @summary Get TVA configuration table
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TvaResourceApi
   */
  public get(options?: AxiosRequestConfig) {
    return TvaResourceApiFp(this.configuration)
      .get(options)
      .then(request => request(this.axios, this.basePath))
  }
}
