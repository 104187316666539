/* tslint:disable */
/* eslint-disable */
/**
 * Appli métier
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: Yoda 1 & 2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from "axios"
import { Configuration } from "../configuration"
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction
} from "../common"
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from "../base"
// @ts-ignore
import { ApiBenchMark } from "../model"
// @ts-ignore
import { SaveBenchMarkRequest } from "../model"
/**
 * BenchMarkResourceV2Api - axios parameter creator
 * @export
 */
export const BenchMarkResourceV2ApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Delete benchmark
     * @param {string} noteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteBenchMark: async (
      noteId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'noteId' is not null or undefined
      assertParamExists("deleteBenchMark", "noteId", noteId)
      const localVarPath = `/v2.0/benchMark/{noteId}`.replace(
        `{${"noteId"}}`,
        encodeURIComponent(String(noteId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary Get benchmark by note id
     * @param {string} noteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBenchMark: async (
      noteId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'noteId' is not null or undefined
      assertParamExists("getBenchMark", "noteId", noteId)
      const localVarPath = `/v2.0/benchMark/{noteId}`.replace(
        `{${"noteId"}}`,
        encodeURIComponent(String(noteId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary Update benchmark
     * @param {SaveBenchMarkRequest} saveBenchMarkRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateBenchMark: async (
      saveBenchMarkRequest: SaveBenchMarkRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'saveBenchMarkRequest' is not null or undefined
      assertParamExists("updateBenchMark", "saveBenchMarkRequest", saveBenchMarkRequest)
      const localVarPath = `/v2.0/benchMark`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        saveBenchMarkRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * BenchMarkResourceV2Api - functional programming interface
 * @export
 */
export const BenchMarkResourceV2ApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = BenchMarkResourceV2ApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @summary Delete benchmark
     * @param {string} noteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteBenchMark(
      noteId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBenchMark(noteId, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Get benchmark by note id
     * @param {string} noteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBenchMark(
      noteId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiBenchMark>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBenchMark(noteId, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Update benchmark
     * @param {SaveBenchMarkRequest} saveBenchMarkRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateBenchMark(
      saveBenchMarkRequest: SaveBenchMarkRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiBenchMark>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateBenchMark(
        saveBenchMarkRequest,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    }
  }
}

/**
 * BenchMarkResourceV2Api - factory interface
 * @export
 */
export const BenchMarkResourceV2ApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = BenchMarkResourceV2ApiFp(configuration)
  return {
    /**
     *
     * @summary Delete benchmark
     * @param {string} noteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteBenchMark(noteId: string, options?: any): AxiosPromise<boolean> {
      return localVarFp.deleteBenchMark(noteId, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Get benchmark by note id
     * @param {string} noteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBenchMark(noteId: string, options?: any): AxiosPromise<ApiBenchMark> {
      return localVarFp.getBenchMark(noteId, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Update benchmark
     * @param {SaveBenchMarkRequest} saveBenchMarkRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateBenchMark(
      saveBenchMarkRequest: SaveBenchMarkRequest,
      options?: any
    ): AxiosPromise<ApiBenchMark> {
      return localVarFp
        .updateBenchMark(saveBenchMarkRequest, options)
        .then(request => request(axios, basePath))
    }
  }
}

/**
 * Request parameters for deleteBenchMark operation in BenchMarkResourceV2Api.
 * @export
 * @interface BenchMarkResourceV2ApiDeleteBenchMarkRequest
 */
export interface BenchMarkResourceV2ApiDeleteBenchMarkRequest {
  /**
   *
   * @type {string}
   * @memberof BenchMarkResourceV2ApiDeleteBenchMark
   */
  readonly noteId: string
}

/**
 * Request parameters for getBenchMark operation in BenchMarkResourceV2Api.
 * @export
 * @interface BenchMarkResourceV2ApiGetBenchMarkRequest
 */
export interface BenchMarkResourceV2ApiGetBenchMarkRequest {
  /**
   *
   * @type {string}
   * @memberof BenchMarkResourceV2ApiGetBenchMark
   */
  readonly noteId: string
}

/**
 * Request parameters for updateBenchMark operation in BenchMarkResourceV2Api.
 * @export
 * @interface BenchMarkResourceV2ApiUpdateBenchMarkRequest
 */
export interface BenchMarkResourceV2ApiUpdateBenchMarkRequest {
  /**
   *
   * @type {SaveBenchMarkRequest}
   * @memberof BenchMarkResourceV2ApiUpdateBenchMark
   */
  readonly saveBenchMarkRequest: SaveBenchMarkRequest
}

/**
 * BenchMarkResourceV2Api - object-oriented interface
 * @export
 * @class BenchMarkResourceV2Api
 * @extends {BaseAPI}
 */
export class BenchMarkResourceV2Api extends BaseAPI {
  /**
   *
   * @summary Delete benchmark
   * @param {BenchMarkResourceV2ApiDeleteBenchMarkRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BenchMarkResourceV2Api
   */
  public deleteBenchMark(
    requestParameters: BenchMarkResourceV2ApiDeleteBenchMarkRequest,
    options?: AxiosRequestConfig
  ) {
    return BenchMarkResourceV2ApiFp(this.configuration)
      .deleteBenchMark(requestParameters.noteId, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get benchmark by note id
   * @param {BenchMarkResourceV2ApiGetBenchMarkRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BenchMarkResourceV2Api
   */
  public getBenchMark(
    requestParameters: BenchMarkResourceV2ApiGetBenchMarkRequest,
    options?: AxiosRequestConfig
  ) {
    return BenchMarkResourceV2ApiFp(this.configuration)
      .getBenchMark(requestParameters.noteId, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Update benchmark
   * @param {BenchMarkResourceV2ApiUpdateBenchMarkRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BenchMarkResourceV2Api
   */
  public updateBenchMark(
    requestParameters: BenchMarkResourceV2ApiUpdateBenchMarkRequest,
    options?: AxiosRequestConfig
  ) {
    return BenchMarkResourceV2ApiFp(this.configuration)
      .updateBenchMark(requestParameters.saveBenchMarkRequest, options)
      .then(request => request(this.axios, this.basePath))
  }
}
