import React, { FC, useContext } from "react"
import {
  CategoryResultCategoryEnum,
  InvolvementQuestion,
  InvolvementQuestionCategoryEnum,
  InvolvementQuestionQuestionTypeEnum
} from "../../client/backend-client/generated"
import { AppMetierTitle } from "../../components/AppMetierTitle/AppMetierTitle"
import { Card, Divider, Space, Tooltip } from "antd"
import { InvolvementCheckWrapperContext } from "./InvolvementCheckPageWrapper"
import { INVOLVEMENT_CATEGORY_GROUP_RECORD, InvolvementVersion } from "./InvolvementUtils"
import { InvolvementCheckQuestionForm } from "./InvolvementCheckQuestionForm"
import { QuestionCircleOutlined } from "@ant-design/icons"
import { getInvolvementIcon } from "./InvolvementIconManager"

export const InvolvementCategoryForm: FC<{
  category: InvolvementQuestionCategoryEnum
  version: InvolvementVersion
}> = ({ category, version }) => {
  const { questions, involvementCheckResult } = useContext(InvolvementCheckWrapperContext)

  const categoryQuestions = questions.filter(
    question =>
      question.category === category &&
      question.questionType !== InvolvementQuestionQuestionTypeEnum.PREVENTION
  )

  const categoryResult = involvementCheckResult.categoryResults.find(
    value => value.category === CategoryResultCategoryEnum[category]
  )

  const categoryStyle = {
    color: INVOLVEMENT_CATEGORY_GROUP_RECORD[category].color
  }

  const renderQuestionForm = (question: InvolvementQuestion, index: number) => {
    const questionResult = categoryResult?.questionResults.find(q => q.questionId === question.id)
    return (
      <Space key={question.id} direction="vertical" className="full-width-space">
        <InvolvementCheckQuestionForm
          question={question}
          slateData={questionResult?.comment}
          score={questionResult?.score}
          forecastImpact={questionResult?.forecastImpact}
        />
        {index < categoryQuestions.length - 1 && <Divider />}
      </Space>
    )
  }

  return (
    <Card
      className="involvement-category-card"
      bordered
      style={{ borderColor: INVOLVEMENT_CATEGORY_GROUP_RECORD[category].color }}
      title={
        <Space align="center">
          {getInvolvementIcon(category, 75)}
          <AppMetierTitle level={3} style={{ ...categoryStyle, marginBottom: 0 }}>
            {INVOLVEMENT_CATEGORY_GROUP_RECORD[category].label}
          </AppMetierTitle>
        </Space>
      }
      extra={
        version === 1 && (
          <Tooltip
            placement="bottom"
            title="Renseignez au moins trois critères pour que cette dimension soit prise en compte"
          >
            <QuestionCircleOutlined style={{ ...categoryStyle, fontSize: "1.5em" }} />
          </Tooltip>
        )
      }
    >
      <Space direction="vertical" className="full-width-space">
        {categoryQuestions.map(renderQuestionForm)}
      </Space>
    </Card>
  )
}
