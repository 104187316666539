import { NoteDictionary } from "./dictionary"
import React from "react"
import { PeriodModel } from "../../pages/financial/PeriodModel"
import {
  AddFinancialPeriodRequestPeriodTypeEnum,
  ApiFinancialPeriodPeriodTypeEnum
} from "../../client/backend-client/generated"
import { CRLink } from "../../components/links/CRLink"
import { FinancialPlanLink } from "../../components/links/FinancialPlanLink"

export const repriseDictionary: NoteDictionary = {
  cr: {
    pageTitle: "Compte de résultat",
    ongletName: "Compte de résultat",
    linkText: "la partie prévisionnelle du Compte de résultat"
  },
  bfr: {
    menuLabel: "BFR"
  },
  differencesSaisies: {
    targetTitle: "Données saisies dans le Compte de résultat",
    crExplanation: (
      <div>
        À tout moment vous pouvez remplacer les données de <CRLink /> par celles issues de cette
        page.
      </div>
    ),
    crSuccessText: (
      <div>
        Vous pouvez consulter <CRLink /> mise à jour.
      </div>
    ),
    pfExplanation: (
      <div>
        À tout moment vous pouvez remplacer les données du <FinancialPlanLink /> par celles issues
        de cette page.
      </div>
    ),
    pfSuccessText: (
      <div>
        Vous pouvez consulter le <FinancialPlanLink /> mis à jour.
      </div>
    ),
    masseSalarialeMoreInformation: (
      <p>
        Attention, les données de <CRLink /> ont peut-être été calculées plus précisément !.
      </p>
    )
  },
  noteSettings: {
    tvaTabLink: periodType =>
      !periodType || periodType === ApiFinancialPeriodPeriodTypeEnum.PREVISIONNEL
        ? "settings#tabsexercices-tva"
        : "settings#tabscedant-tvaTab",
    tabExerciceTitle: "Prévisionnel",
    baseDateLabel: periodType =>
      periodType === ApiFinancialPeriodPeriodTypeEnum.PREVISIONNEL
        ? "Date de début d'activité"
        : "Date de clôture du dernier exercice disponible",
    addPeriodLabel: periodType =>
      periodType === AddFinancialPeriodRequestPeriodTypeEnum.PREVISIONNEL
        ? "Ajouter un exercice prévisionnel"
        : "Ajouter un exercice passé"
  },
  periodTitle: (p: PeriodModel, index: number) => {
    if (p.type === ApiFinancialPeriodPeriodTypeEnum.PREVISIONNEL) {
      return `Exercice n°${index + 1}`
    }
    return p.startDate.year() === p.endDate.year()
      ? `${p.startDate.year()}`
      : `${p.startDate.year()}-${p.endDate.year()}`
  },
  bilan: {
    ongletName: "Bilan du cédant",
    pageTitle: "Bilan du cédant",
    linkText: "Bilan du cédant"
  },
  exerciceCedantLabel: "Exercices du cédant",
  commentAnterieurLabel: "Commentaire - Comptes du cédant",
  benchmarkAnterieurPeriodLabel: "Cédant",
  operations: {
    ongletName: "Opérations futures",
    pageTitle: "Opérations futures",
    linkText: "Opérations futures"
  },
  pretsPasse: {
    ongletName: "",
    pageTitle: "",
    linkText: ""
  },
  pretTVADemarrage: {
    label: "Prêt CT démarrage",
    button: "un prêt CT démarrage"
  },
  noExerciceText: "Aucune date de début d'activité n'a été renseignée",
  noExerciceLink:
    "Vous devez saisir une date de début d’activité pour pouvoir créer vos exercices comptables dans l’onglet",
  evolutionPercentagePassedLabel: "% d'évolution cédant"
}
