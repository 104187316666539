import React, { FC, useContext } from "react"
import {
  InvolvementQuestion,
  InvolvementQuestionCategoryEnum,
  InvolvementQuestionQuestionTypeEnum,
  InvolvementQuestionResult
} from "../../client/backend-client/generated"
import { InvolvementCategoryForm } from "./InvolvementCategoryForm"
import { Card, Col, Divider, Row, Space } from "antd"
import { InvolvementCheckWrapperContext } from "./InvolvementCheckPageWrapper"
import { InvolvementCheckResultCard } from "./InvolvementCheckResultCard"
import { InvolvementVersion } from "./InvolvementUtils"
import { AppMetierTitle } from "../../components/AppMetierTitle/AppMetierTitle"
import { InvolvementCheckQuestionForm } from "./InvolvementCheckQuestionForm"

export const InvolvementCheckForm: FC<{
  version: InvolvementVersion
}> = ({ version }) => {
  const { questions, involvementCheckResult } = useContext(InvolvementCheckWrapperContext)

  const categories = Object.values(InvolvementQuestionCategoryEnum).filter(
    category => (version == 1 && category !== InvolvementQuestionCategoryEnum.OTHER) || true
  )

  const preventionQuestions = questions.filter(
    question => question.questionType === InvolvementQuestionQuestionTypeEnum.PREVENTION
  )

  const allQuestionResults: InvolvementQuestionResult[] =
    involvementCheckResult.categoryResults.reduce((questionResults, categoryResult) => {
      questionResults = questionResults.concat(categoryResult.questionResults)
      return questionResults
    }, [] as InvolvementQuestionResult[])

  const renderQuestionForm = (question: InvolvementQuestion, index: number) => {
    const questionResult = allQuestionResults.find(q => q.questionId === question.id)
    return (
      <Space direction="vertical" size="small" key={question.id}>
        <InvolvementCheckQuestionForm
          question={question}
          slateData={questionResult?.comment}
          score={questionResult?.score}
          forecastImpact={questionResult?.forecastImpact}
        />
        {index < preventionQuestions.length - 1 && <Divider />}
      </Space>
    )
  }

  return (
    <Row gutter={16}>
      <Col span={12} xl={16}>
        <Space direction="vertical" size="large" className="full-width-space">
          {categories
            .filter(category => questions.some(question => question.category === category))
            .map(category => (
              <InvolvementCategoryForm key={category} category={category} version={version} />
            ))}
          {version === 2 && (
            <Card
              className="involvement-category-card"
              bordered
              style={{ borderColor: "var(--main-grey-1)" }}
            >
              <AppMetierTitle level={3} style={{ color: "var(--main-grey-1)" }}>
                Indicateurs de prévention et d'accompagnement
              </AppMetierTitle>
              {preventionQuestions.map(renderQuestionForm)}
            </Card>
          )}
        </Space>
      </Col>
      <Col span={12} xl={8}>
        <InvolvementCheckResultCard version={version} />
      </Col>
    </Row>
  )
}
