import { useAppContext } from "../config/context"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { extract } from "../client/backend-client/AxiosUtils"
import {
  ChangeLifecycleRequestFromEnum,
  ChangeLifecycleRequestToEnum,
  Note,
  NoteLifecycleEnum,
  PdfResourceV2ApiGeneratePdfRequest,
  SaveNoteRequest
} from "../client/backend-client/generated"
import { useHeaders } from "./axios-headers"
import { KeycloakContext } from "../auth/KeycloakContext"
import { useContext } from "react"

export const useGetNote = (noteId: string) => {
  const { noteResourceV2Api } = useAppContext()
  const getHeaders = useHeaders()
  const { keycloak } = useContext(KeycloakContext)

  const { isLoading, data, error } = useQuery({
    queryKey: ["note", noteId],
    queryFn: async () => {
      const { headers } = await getHeaders()
      return noteResourceV2Api.getNote({ noteId }, { headers }).then(extract)
    },
    retry: 0,
    meta: {
      errorMessage: `Erreur lors de la récupération de la note`
    }
  })
  const queryClient = useQueryClient()
  queryClient.invalidateQueries({ queryKey: ["recently-viewed-projects", keycloak.profile?.id] })

  return {
    isLoading,
    data,
    error
  }
}

export const useUpdateNote = (noteId: string) => {
  const {
    noteResourceV2Api,
    userHeaders: { userId, userName }
  } = useAppContext()
  const getHeaders = useHeaders()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (request: SaveNoteRequest) => {
      const { headers } = await getHeaders()
      return noteResourceV2Api
        .updateNote({ userId, userName, saveNoteRequest: request }, { headers })
        .then(extract)
    },
    onSuccess: result => {
      queryClient.setQueryData<Note>(["note", noteId], result)
    },
    meta: {
      errorMessage: `Erreur lors de la mise à jour de la note`
    }
  })
}

export const useUpdateCreationToCreationExNihilo = (noteId: string) => {
  const { noteResourceV2Api } = useAppContext()
  const getHeaders = useHeaders()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (creationExNihilo: boolean) => {
      const { headers } = await getHeaders()
      return noteResourceV2Api
        .updateCreationExNihilo({ noteId, body: creationExNihilo }, { headers })
        .then(extract)
    },
    onSuccess: result => {
      queryClient.setQueryData<Note>(["note", noteId], result)
      queryClient.invalidateQueries({ queryKey: ["financial-periods", noteId] })
      queryClient.invalidateQueries({ queryKey: ["financial-plan", noteId] })
    },
    meta: {
      errorMessage: `Erreur lors du changement de phase de vie: vérifiez que la note possède bien au moins un exercice prévisionnel`
    }
  })
}

export const useUpdateLifecycle = (
  noteId: string,
  projectId: number,
  notePhaseFrom: NoteLifecycleEnum,
  notePhaseTo: NoteLifecycleEnum
) => {
  const { noteResourceV2Api } = useAppContext()
  const getHeaders = useHeaders()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (creationExNihilo?: boolean) => {
      const { headers } = await getHeaders()
      return noteResourceV2Api
        .changeLifecycle(
          {
            noteId,
            changeLifecycleRequest: {
              from: ChangeLifecycleRequestFromEnum[
                notePhaseFrom as keyof typeof ChangeLifecycleRequestFromEnum
              ],
              to: ChangeLifecycleRequestToEnum[
                notePhaseTo as keyof typeof ChangeLifecycleRequestToEnum
              ],
              creationExNihilo: creationExNihilo
            }
          },
          { headers }
        )
        .then(extract)
    },
    onSuccess: result => {
      queryClient.setQueryData<Note>(["note", noteId], result)
      queryClient.invalidateQueries({ queryKey: ["financial-periods", noteId] })
      queryClient.invalidateQueries({ queryKey: ["financial-plan", noteId] })
      queryClient.invalidateQueries({ queryKey: ["project", projectId] })
      queryClient.invalidateQueries({ queryKey: ["scoring", noteId] })
    },
    meta: {
      errorMessage:
        notePhaseFrom === NoteLifecycleEnum.REPRISE
          ? "Erreur suite à une donnée mal renseignée dans Nouba"
          : `Veuillez renseigner une date de début d'activité dans vos paramètres avant d’effectuer cette action`
    }
  })
}

export const useNoteGeneratePdf = () => {
  const { pdfResourceV2Api } = useAppContext()
  const getHeaders = useHeaders()

  return useMutation({
    mutationFn: async (request: PdfResourceV2ApiGeneratePdfRequest) => {
      const { headers } = await getHeaders()
      return pdfResourceV2Api.generatePdf(request, { headers }).then(extract)
    },
    meta: {
      errorMessage: `Erreur lors de la génération du pdf`
    }
  })
}
