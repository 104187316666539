import { CSSProperties, ReactNode } from "react"
import { ApiCalculatedData, ApiNumericData } from "../client/backend-client/generated"

export interface TableData {
  key: string
  rowTitle: ReactNode

  [index: string]: ReactNode
}

export const updateNumericData = (
  from: ApiNumericData | undefined,
  to: ApiNumericData
): ApiNumericData => ({
  ...from,
  value: to.value,
  detail: to.detail,
  kiloEuros: to.kiloEuros
})

export const getTableMinWidth = (
  dynamicColumnNumber: number = 0,
  dynamicColumnWidth: number = 0,
  fixedColumnWidth: number = 0
): number => fixedColumnWidth + dynamicColumnNumber * dynamicColumnWidth

export const SUB_LINE_TEXT_STYLE: CSSProperties = { marginLeft: "2em" }

export const instanceOfApiCalculatedData = (object?: object): object is ApiCalculatedData =>
  !!object && "numericData" in object

export const getApiDataValue = (
  apiData: ApiNumericData | ApiCalculatedData | undefined
): number | undefined => {
  if (apiData === undefined) {
    return undefined
  }
  if (instanceOfApiCalculatedData(apiData)) {
    return (apiData as ApiCalculatedData).numericData?.value
  }
  return (apiData as ApiNumericData).value
}
