import React, { FC, useContext, useEffect, useState } from "react"
import { Checkbox, Radio, Space, Typography } from "antd"
import { NoteContext } from "../../NotePage"
import { useUpdateNoteSettings } from "../../../queries/NoteSettingsQueries"
import {
  NoteSettingsReferenceAmountEnum,
  NoteTypeEnum,
  SaveNoteSettingsRequestReferenceAmountEnum
} from "../../../client/backend-client/generated"
import { useDictionary } from "../../../utils/dictionary/dictionary"

const { Text } = Typography

const referenceAmountMap = [
  {
    id: NoteSettingsReferenceAmountEnum.NONE,
    label: "Aucun"
  },
  {
    id: NoteSettingsReferenceAmountEnum.CHIFFRE_AFFAIRE,
    label: "En pourcentage du chiffre d'affaires"
  },
  {
    id: NoteSettingsReferenceAmountEnum.PRODUITS_EXPLOITATION,
    label: "En pourcentage du total des produits d'exploitation"
  }
]

export const IncomeStatementOptions: FC = () => {
  const { note, noteId, noteSettings } = useContext(NoteContext)
  const { evolutionPercentagePassedLabel } = useDictionary()

  const updateSettings = useUpdateNoteSettings()

  const [referenceAmount, setReferenceAmount] = useState(noteSettings.referenceAmount)
  const [transferorPercentage, setTransferorPercentage] = useState(
    noteSettings.showPercentageCedant
  )
  const [forecastPercentage, setForecastPercentage] = useState(
    noteSettings.showPercentagePrevisionnel
  )

  useEffect(() => {
    updateSettings.mutate({
      noteId,
      saveNoteSettingsRequest: {
        referenceAmount:
          SaveNoteSettingsRequestReferenceAmountEnum[
            referenceAmount as keyof typeof SaveNoteSettingsRequestReferenceAmountEnum
          ]
      }
    })
  }, [referenceAmount])

  useEffect(() => {
    updateSettings.mutate({
      noteId,
      saveNoteSettingsRequest: {
        showPercentageCedant: transferorPercentage
      }
    })
  }, [transferorPercentage])

  useEffect(() => {
    updateSettings.mutate({
      noteId,
      saveNoteSettingsRequest: {
        showPercentagePrevisionnel: forecastPercentage
      }
    })
  }, [forecastPercentage])

  return (
    <Space direction="vertical">
      <Text strong>Montant de référence</Text>
      <Radio.Group
        onChange={e =>
          setReferenceAmount(
            NoteSettingsReferenceAmountEnum[
              e.target.value as keyof typeof NoteSettingsReferenceAmountEnum
            ]
          )
        }
        value={referenceAmount}
      >
        <Space direction="vertical">
          {referenceAmountMap.map(p => (
            <Radio key={p.id} value={p.id}>
              {p.label}
            </Radio>
          ))}
        </Space>
      </Radio.Group>
      <Text strong>Évolution</Text>
      {note.type !== NoteTypeEnum.CREATION && (
        <Checkbox
          checked={transferorPercentage}
          onChange={e => setTransferorPercentage(e.target.checked)}
        >
          {evolutionPercentagePassedLabel}
        </Checkbox>
      )}
      <Checkbox
        checked={forecastPercentage}
        onChange={e => setForecastPercentage(e.target.checked)}
      >
        % d'évolution prévisionnel
      </Checkbox>
    </Space>
  )
}
