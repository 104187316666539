import React, { useEffect, useState } from "react"

import { Button } from "antd"
import { ArrowUpOutlined } from "@ant-design/icons"
import "./ScrollToTopButton.css"

export const ScrollToTop = () => {
  const [showTopBtn, setShowTopBtn] = useState(false)

  useEffect(() => {
    window.addEventListener("scroll", () => handleShowButtonOnScroll())

    return () => {
      window.removeEventListener("scroll", () => handleShowButtonOnScroll())
    }
  }, [])

  const handleShowButtonOnScroll = () => {
    const scrolled = document.documentElement.scrollTop
    if (scrolled > 400) {
      setShowTopBtn(true)
    } else {
      setShowTopBtn(false)
    }
  }
  const goToTop = () => {
    document.documentElement.style.scrollBehavior = "smooth"
    document.documentElement.scroll(0, 0)
  }

  return (
    <div className="scroll-to-top">
      <Button
        type="primary"
        className={showTopBtn ? "scroll-to-top-btn" : "scroll-to-top-btn hidden"}
        onClick={goToTop}
        icon={<ArrowUpOutlined />}
      />
    </div>
  )
}
