/* tslint:disable */
/* eslint-disable */
/**
 * Appli métier
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: Yoda 1 & 2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from "axios"
import { Configuration } from "../configuration"
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction
} from "../common"
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from "../base"
// @ts-ignore
import { MuffinYodaProject } from "../model"
// @ts-ignore
import { PorteurResponse } from "../model"
/**
 * ProjectResourceApi - axios parameter creator
 * @export
 */
export const ProjectResourceApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get yoda project by muffin id
     * @param {string} projectId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProject1: async (
      projectId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'projectId' is not null or undefined
      assertParamExists("getProject1", "projectId", projectId)
      const localVarPath = `/projects/{projectId}`.replace(
        `{${"projectId"}}`,
        encodeURIComponent(String(projectId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary Get yoda project\'s holders by muffin id
     * @param {string} projectId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProjectPorteurs: async (
      projectId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'projectId' is not null or undefined
      assertParamExists("getProjectPorteurs", "projectId", projectId)
      const localVarPath = `/projects/{projectId}/porteurs`.replace(
        `{${"projectId"}}`,
        encodeURIComponent(String(projectId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * ProjectResourceApi - functional programming interface
 * @export
 */
export const ProjectResourceApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ProjectResourceApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @summary Get yoda project by muffin id
     * @param {string} projectId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getProject1(
      projectId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MuffinYodaProject>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getProject1(projectId, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Get yoda project\'s holders by muffin id
     * @param {string} projectId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getProjectPorteurs(
      projectId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PorteurResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectPorteurs(
        projectId,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    }
  }
}

/**
 * ProjectResourceApi - factory interface
 * @export
 */
export const ProjectResourceApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = ProjectResourceApiFp(configuration)
  return {
    /**
     *
     * @summary Get yoda project by muffin id
     * @param {string} projectId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProject1(projectId: string, options?: any): AxiosPromise<MuffinYodaProject> {
      return localVarFp.getProject1(projectId, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Get yoda project\'s holders by muffin id
     * @param {string} projectId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProjectPorteurs(projectId: string, options?: any): AxiosPromise<PorteurResponse> {
      return localVarFp
        .getProjectPorteurs(projectId, options)
        .then(request => request(axios, basePath))
    }
  }
}

/**
 * Request parameters for getProject1 operation in ProjectResourceApi.
 * @export
 * @interface ProjectResourceApiGetProject1Request
 */
export interface ProjectResourceApiGetProject1Request {
  /**
   *
   * @type {string}
   * @memberof ProjectResourceApiGetProject1
   */
  readonly projectId: string
}

/**
 * Request parameters for getProjectPorteurs operation in ProjectResourceApi.
 * @export
 * @interface ProjectResourceApiGetProjectPorteursRequest
 */
export interface ProjectResourceApiGetProjectPorteursRequest {
  /**
   *
   * @type {string}
   * @memberof ProjectResourceApiGetProjectPorteurs
   */
  readonly projectId: string
}

/**
 * ProjectResourceApi - object-oriented interface
 * @export
 * @class ProjectResourceApi
 * @extends {BaseAPI}
 */
export class ProjectResourceApi extends BaseAPI {
  /**
   *
   * @summary Get yoda project by muffin id
   * @param {ProjectResourceApiGetProject1Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectResourceApi
   */
  public getProject1(
    requestParameters: ProjectResourceApiGetProject1Request,
    options?: AxiosRequestConfig
  ) {
    return ProjectResourceApiFp(this.configuration)
      .getProject1(requestParameters.projectId, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get yoda project\'s holders by muffin id
   * @param {ProjectResourceApiGetProjectPorteursRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectResourceApi
   */
  public getProjectPorteurs(
    requestParameters: ProjectResourceApiGetProjectPorteursRequest,
    options?: AxiosRequestConfig
  ) {
    return ProjectResourceApiFp(this.configuration)
      .getProjectPorteurs(requestParameters.projectId, options)
      .then(request => request(this.axios, this.basePath))
  }
}
