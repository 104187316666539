//TODO - Update config to fix ts type error
// eslint-disable-next-line
// @ts-nocheck

import { ToolConstructable, ToolSettings } from "@editorjs/editorjs/types/tools"
import Header from "@editorjs/header"
import NestedList from "@editorjs/nested-list"
import Underline from "@editorjs/underline"
import Quote from "@editorjs/quote"
import Paragraph from "@editorjs/paragraph"

export const EDITOR_JS_TOOLS: { [toolName: string]: ToolConstructable | ToolSettings } = {
  header: {
    class: Header,
    inlineToolbar: true
  },
  list: {
    class: NestedList,
    inlineToolbar: true
  },
  underline: Underline,
  paragraph: {
    class: Paragraph,
    inlineToolbar: true
  },
  quote: {
    class: Quote,
    config: {
      quotePlaceholder: "Citation",
      captionPlaceholder: "Source",
      alignment: "left"
    }
  }
}
