import { useAppContext } from "../config/context"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { extract } from "../client/backend-client/AxiosUtils"
import {
  NoteSettings,
  NoteSettingsResourceApiUpdateNoteSettingsRequest,
  SaveNoteSettingsRequestReferenceAmountEnum
} from "../client/backend-client/generated"
import { useHeaders } from "./axios-headers"

export const useGetNoteSettings = (noteId: string) => {
  const { noteSettingsResourceApi } = useAppContext()
  const getHeaders = useHeaders()

  const { isLoading, data, error } = useQuery({
    queryKey: ["note-settings", noteId],
    queryFn: async () => {
      const { headers } = await getHeaders()
      return noteSettingsResourceApi.getNoteSettings({ noteId }, { headers }).then(extract)
    },
    retry: 0,
    meta: {
      errorMessage: `Erreur lors de la récupération des paramètres de la note`
    }
  })

  return {
    isLoading,
    data,
    error
  }
}

export const useUpdateNoteSettings = () => {
  const { noteSettingsResourceApi } = useAppContext()
  const getHeaders = useHeaders()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (request: NoteSettingsResourceApiUpdateNoteSettingsRequest) => {
      const { headers } = await getHeaders()
      return noteSettingsResourceApi.updateNoteSettings(request, { headers }).then(extract)
    },
    onSuccess: (result, variables) => {
      queryClient.setQueryData<NoteSettings>(["note-settings", variables.noteId], result)
      queryClient.invalidateQueries({
        queryKey: ["financial-periods", variables.noteId]
      })
    },
    meta: {
      errorMessage: `Erreur lors de la mise à jour des paramètres de la note`
    }
  })
}

export const toNoteSettingsInput = (
  noteId: string,
  noteSettings: NoteSettings
): NoteSettingsResourceApiUpdateNoteSettingsRequest => ({
  noteId: noteId,
  saveNoteSettingsRequest: {
    kiloEuros: noteSettings.kiloEuros,
    referenceAmount: SaveNoteSettingsRequestReferenceAmountEnum[noteSettings.referenceAmount],
    assujettiImpotsSocietes: noteSettings.assujettiImpotsSocietes,
    impotsSurBeneficeComputed: noteSettings.impotsSurBeneficeComputed,
    prelevementTnsInCr: noteSettings.prelevementTnsInCr,
    showDemarrage: noteSettings.showDemarrage,
    showDetailPret: noteSettings.showDetailPret,
    showPercentageCedant: noteSettings.showPercentageCedant,
    showPercentagePrevisionnel: noteSettings.showPercentagePrevisionnel,
    showBfrInverse: noteSettings.showBfrInverse,
    showPreviousPeriod: noteSettings.showPreviousPeriod
  }
})
