import React, { FC } from "react"
import Loader from "../components/Loader/Loader"
import { useGetProject } from "../queries/ProjectQueries"
import { useRequiredParams } from "../utils/utils"
import { NotePage } from "./NotePage"
import { Project } from "../client/backend-client/generated"
import { Result } from "antd"

interface ProjectContextI {
  projectName: string
  projectId: number
  associationId: string
  structureId: string
  project: Project
}

export const ProjectContext = React.createContext<ProjectContextI>({} as ProjectContextI)

export const ProjectWrapper: FC = () => {
  const { associationId, projectId } = useRequiredParams<{
    associationId: string
    projectId: number
  }>()
  const {
    isLoading: isProjectLoading,
    data: project,
    error: projectError
  } = useGetProject(projectId)

  if (isProjectLoading) {
    return <Loader />
  }
  if (projectError) {
    return <Result status="error" />
  }
  return (
    <>
      {isProjectLoading && <Loader />}
      {!isProjectLoading && project && project.projectLeadCreatorCode && (
        <ProjectContext.Provider
          value={{
            associationId,
            projectId,
            projectName: project.label,
            structureId: project.structureId,
            project: project
          }}
        >
          <NotePage noteId={project.noteId} />
        </ProjectContext.Provider>
      )}
    </>
  )
}
