export interface Environment {
  production: boolean
  AUTH_URL: string
  API_URL: string
  APP_VERSION: string
}

const getEnv = (): Environment => {
  if (process.env.REACT_APP_ENV === "dev") {
    return {
      production: false,
      AUTH_URL: `${process.env.REACT_APP_AUTH_URL}`,
      API_URL: `${process.env.REACT_APP_API_URL}`,
      APP_VERSION: `${process.env.REACT_APP_VERSION ?? "locale"}`
    }
  }
  // @ts-expect-error : Property __env does not exist on type Window & typeof globalThi
  const env = window.__env || {}
  return {
    production: true,
    AUTH_URL: env.AUTH_URL,
    API_URL: env.BACK_URL,
    APP_VERSION: env.APP_VERSION
  }
}

export default getEnv()
