/* tslint:disable */
/* eslint-disable */
/**
 * Appli métier
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: Yoda 1 & 2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { NumericData } from "./numeric-data"

/**
 *
 * @export
 * @interface UpdateLoanRequest
 */
export interface UpdateLoanRequest {
  /**
   *
   * @type {string}
   * @memberof UpdateLoanRequest
   */
  amortizationPeriodicity?: UpdateLoanRequestAmortizationPeriodicityEnum
  /**
   *
   * @type {NumericData}
   * @memberof UpdateLoanRequest
   */
  amount?: NumericData
  /**
   *
   * @type {number}
   * @memberof UpdateLoanRequest
   */
  delayDuration?: number
  /**
   *
   * @type {number}
   * @memberof UpdateLoanRequest
   */
  delegationId?: number
  /**
   *
   * @type {string}
   * @memberof UpdateLoanRequest
   */
  disbursementDate?: string
  /**
   *
   * @type {NumericData}
   * @memberof UpdateLoanRequest
   */
  financialPlanStartup?: NumericData
  /**
   *
   * @type {string}
   * @memberof UpdateLoanRequest
   */
  firstRepaymentDate?: string
  /**
   *
   * @type {string}
   * @memberof UpdateLoanRequest
   */
  holderId?: string
  /**
   *
   * @type {number}
   * @memberof UpdateLoanRequest
   */
  productId?: number
  /**
   *
   * @type {string}
   * @memberof UpdateLoanRequest
   */
  productName?: string
  /**
   *
   * @type {string}
   * @memberof UpdateLoanRequest
   */
  interestsPeriodicity?: UpdateLoanRequestInterestsPeriodicityEnum
  /**
   *
   * @type {boolean}
   * @memberof UpdateLoanRequest
   */
  isInStartup?: boolean
  /**
   *
   * @type {number}
   * @memberof UpdateLoanRequest
   */
  rate?: number
  /**
   *
   * @type {string}
   * @memberof UpdateLoanRequest
   */
  title?: string
  /**
   *
   * @type {number}
   * @memberof UpdateLoanRequest
   */
  totalDuration?: number
}

/**
 * @export
 * @enum {string}
 */
export enum UpdateLoanRequestAmortizationPeriodicityEnum {
  MONTHLY = "MONTHLY",
  BIMONTHLY = "BIMONTHLY",
  QUARTERLY = "QUARTERLY",
  SEMI_ANNUAL = "SEMI_ANNUAL",
  ANNUAL = "ANNUAL",
  IN_FINE = "IN_FINE"
}
/**
 * @export
 * @enum {string}
 */
export enum UpdateLoanRequestInterestsPeriodicityEnum {
  MONTHLY = "MONTHLY",
  BIMONTHLY = "BIMONTHLY",
  QUARTERLY = "QUARTERLY",
  SEMI_ANNUAL = "SEMI_ANNUAL",
  ANNUAL = "ANNUAL",
  IN_FINE = "IN_FINE"
}
