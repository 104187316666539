/* tslint:disable */
/* eslint-disable */
/**
 * Appli métier
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: Yoda 1 & 2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from "axios"
import { Configuration } from "../configuration"
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction
} from "../common"
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from "../base"
// @ts-ignore
import { Memento } from "../model"
// @ts-ignore
import { MementoInput } from "../model"
// @ts-ignore
import { PdfLink } from "../model"
/**
 * MementoResourceV2Api - axios parameter creator
 * @export
 */
export const MementoResourceV2ApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Generate memento pdf link by project id
     * @param {number} projectId
     * @param {string} timeZone
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generatePdf2: async (
      projectId: number,
      timeZone: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'projectId' is not null or undefined
      assertParamExists("generatePdf2", "projectId", projectId)
      // verify required parameter 'timeZone' is not null or undefined
      assertParamExists("generatePdf2", "timeZone", timeZone)
      const localVarPath = `/v2.0/memento/{projectId}/pdf`.replace(
        `{${"projectId"}}`,
        encodeURIComponent(String(projectId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      if (timeZone !== undefined) {
        localVarQueryParameter["timeZone"] = timeZone
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary Get memento by project id
     * @param {number} projectId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMemento: async (
      projectId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'projectId' is not null or undefined
      assertParamExists("getMemento", "projectId", projectId)
      const localVarPath = `/v2.0/memento/{projectId}`.replace(
        `{${"projectId"}}`,
        encodeURIComponent(String(projectId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary Update memento by project id
     * @param {number} projectId
     * @param {MementoInput} mementoInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateMemento: async (
      projectId: number,
      mementoInput: MementoInput,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'projectId' is not null or undefined
      assertParamExists("updateMemento", "projectId", projectId)
      // verify required parameter 'mementoInput' is not null or undefined
      assertParamExists("updateMemento", "mementoInput", mementoInput)
      const localVarPath = `/v2.0/memento/{projectId}`.replace(
        `{${"projectId"}}`,
        encodeURIComponent(String(projectId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        mementoInput,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * MementoResourceV2Api - functional programming interface
 * @export
 */
export const MementoResourceV2ApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = MementoResourceV2ApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @summary Generate memento pdf link by project id
     * @param {number} projectId
     * @param {string} timeZone
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async generatePdf2(
      projectId: number,
      timeZone: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PdfLink>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.generatePdf2(
        projectId,
        timeZone,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Get memento by project id
     * @param {number} projectId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getMemento(
      projectId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Memento>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getMemento(projectId, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Update memento by project id
     * @param {number} projectId
     * @param {MementoInput} mementoInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateMemento(
      projectId: number,
      mementoInput: MementoInput,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Memento>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateMemento(
        projectId,
        mementoInput,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    }
  }
}

/**
 * MementoResourceV2Api - factory interface
 * @export
 */
export const MementoResourceV2ApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = MementoResourceV2ApiFp(configuration)
  return {
    /**
     *
     * @summary Generate memento pdf link by project id
     * @param {number} projectId
     * @param {string} timeZone
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generatePdf2(projectId: number, timeZone: string, options?: any): AxiosPromise<PdfLink> {
      return localVarFp
        .generatePdf2(projectId, timeZone, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Get memento by project id
     * @param {number} projectId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMemento(projectId: number, options?: any): AxiosPromise<Memento> {
      return localVarFp.getMemento(projectId, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Update memento by project id
     * @param {number} projectId
     * @param {MementoInput} mementoInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateMemento(
      projectId: number,
      mementoInput: MementoInput,
      options?: any
    ): AxiosPromise<Memento> {
      return localVarFp
        .updateMemento(projectId, mementoInput, options)
        .then(request => request(axios, basePath))
    }
  }
}

/**
 * Request parameters for generatePdf2 operation in MementoResourceV2Api.
 * @export
 * @interface MementoResourceV2ApiGeneratePdf2Request
 */
export interface MementoResourceV2ApiGeneratePdf2Request {
  /**
   *
   * @type {number}
   * @memberof MementoResourceV2ApiGeneratePdf2
   */
  readonly projectId: number

  /**
   *
   * @type {string}
   * @memberof MementoResourceV2ApiGeneratePdf2
   */
  readonly timeZone: string
}

/**
 * Request parameters for getMemento operation in MementoResourceV2Api.
 * @export
 * @interface MementoResourceV2ApiGetMementoRequest
 */
export interface MementoResourceV2ApiGetMementoRequest {
  /**
   *
   * @type {number}
   * @memberof MementoResourceV2ApiGetMemento
   */
  readonly projectId: number
}

/**
 * Request parameters for updateMemento operation in MementoResourceV2Api.
 * @export
 * @interface MementoResourceV2ApiUpdateMementoRequest
 */
export interface MementoResourceV2ApiUpdateMementoRequest {
  /**
   *
   * @type {number}
   * @memberof MementoResourceV2ApiUpdateMemento
   */
  readonly projectId: number

  /**
   *
   * @type {MementoInput}
   * @memberof MementoResourceV2ApiUpdateMemento
   */
  readonly mementoInput: MementoInput
}

/**
 * MementoResourceV2Api - object-oriented interface
 * @export
 * @class MementoResourceV2Api
 * @extends {BaseAPI}
 */
export class MementoResourceV2Api extends BaseAPI {
  /**
   *
   * @summary Generate memento pdf link by project id
   * @param {MementoResourceV2ApiGeneratePdf2Request} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MementoResourceV2Api
   */
  public generatePdf2(
    requestParameters: MementoResourceV2ApiGeneratePdf2Request,
    options?: AxiosRequestConfig
  ) {
    return MementoResourceV2ApiFp(this.configuration)
      .generatePdf2(requestParameters.projectId, requestParameters.timeZone, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get memento by project id
   * @param {MementoResourceV2ApiGetMementoRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MementoResourceV2Api
   */
  public getMemento(
    requestParameters: MementoResourceV2ApiGetMementoRequest,
    options?: AxiosRequestConfig
  ) {
    return MementoResourceV2ApiFp(this.configuration)
      .getMemento(requestParameters.projectId, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Update memento by project id
   * @param {MementoResourceV2ApiUpdateMementoRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MementoResourceV2Api
   */
  public updateMemento(
    requestParameters: MementoResourceV2ApiUpdateMementoRequest,
    options?: AxiosRequestConfig
  ) {
    return MementoResourceV2ApiFp(this.configuration)
      .updateMemento(requestParameters.projectId, requestParameters.mementoInput, options)
      .then(request => request(this.axios, this.basePath))
  }
}
