/* tslint:disable */
/* eslint-disable */
/**
 * Appli métier
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: Yoda 1 & 2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from "axios"
import { Configuration } from "../configuration"
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction
} from "../common"
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from "../base"
// @ts-ignore
import { QuestionResultInvolvementSaveRequest } from "../model"
// @ts-ignore
import { Result } from "../model"
// @ts-ignore
import { ResultSaveRequest } from "../model"
/**
 * ResultResourceApi - axios parameter creator
 * @export
 */
export const ResultResourceApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get involvement check results by note id
     * @param {string} noteId
     * @param {boolean} [forceV2]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getResult: async (
      noteId: string,
      forceV2?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'noteId' is not null or undefined
      assertParamExists("getResult", "noteId", noteId)
      const localVarPath = `/involvement_check/results/{noteId}`.replace(
        `{${"noteId"}}`,
        encodeURIComponent(String(noteId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      if (forceV2 !== undefined) {
        localVarQueryParameter["forceV2"] = forceV2
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary Save an answer to an involvement check question
     * @param {string} noteId
     * @param {QuestionResultInvolvementSaveRequest} questionResultInvolvementSaveRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveQuestionResult: async (
      noteId: string,
      questionResultInvolvementSaveRequest: QuestionResultInvolvementSaveRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'noteId' is not null or undefined
      assertParamExists("saveQuestionResult", "noteId", noteId)
      // verify required parameter 'questionResultInvolvementSaveRequest' is not null or undefined
      assertParamExists(
        "saveQuestionResult",
        "questionResultInvolvementSaveRequest",
        questionResultInvolvementSaveRequest
      )
      const localVarPath = `/involvement_check/results/{noteId}/questions`.replace(
        `{${"noteId"}}`,
        encodeURIComponent(String(noteId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        questionResultInvolvementSaveRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary Save an involvement check result
     * @param {string} noteId
     * @param {ResultSaveRequest} resultSaveRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveResult: async (
      noteId: string,
      resultSaveRequest: ResultSaveRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'noteId' is not null or undefined
      assertParamExists("saveResult", "noteId", noteId)
      // verify required parameter 'resultSaveRequest' is not null or undefined
      assertParamExists("saveResult", "resultSaveRequest", resultSaveRequest)
      const localVarPath = `/involvement_check/results/{noteId}`.replace(
        `{${"noteId"}}`,
        encodeURIComponent(String(noteId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        resultSaveRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * ResultResourceApi - functional programming interface
 * @export
 */
export const ResultResourceApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ResultResourceApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @summary Get involvement check results by note id
     * @param {string} noteId
     * @param {boolean} [forceV2]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getResult(
      noteId: string,
      forceV2?: boolean,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getResult(noteId, forceV2, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Save an answer to an involvement check question
     * @param {string} noteId
     * @param {QuestionResultInvolvementSaveRequest} questionResultInvolvementSaveRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async saveQuestionResult(
      noteId: string,
      questionResultInvolvementSaveRequest: QuestionResultInvolvementSaveRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.saveQuestionResult(
        noteId,
        questionResultInvolvementSaveRequest,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Save an involvement check result
     * @param {string} noteId
     * @param {ResultSaveRequest} resultSaveRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async saveResult(
      noteId: string,
      resultSaveRequest: ResultSaveRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.saveResult(
        noteId,
        resultSaveRequest,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    }
  }
}

/**
 * ResultResourceApi - factory interface
 * @export
 */
export const ResultResourceApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = ResultResourceApiFp(configuration)
  return {
    /**
     *
     * @summary Get involvement check results by note id
     * @param {string} noteId
     * @param {boolean} [forceV2]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getResult(noteId: string, forceV2?: boolean, options?: any): AxiosPromise<Result> {
      return localVarFp
        .getResult(noteId, forceV2, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Save an answer to an involvement check question
     * @param {string} noteId
     * @param {QuestionResultInvolvementSaveRequest} questionResultInvolvementSaveRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveQuestionResult(
      noteId: string,
      questionResultInvolvementSaveRequest: QuestionResultInvolvementSaveRequest,
      options?: any
    ): AxiosPromise<Result> {
      return localVarFp
        .saveQuestionResult(noteId, questionResultInvolvementSaveRequest, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Save an involvement check result
     * @param {string} noteId
     * @param {ResultSaveRequest} resultSaveRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveResult(
      noteId: string,
      resultSaveRequest: ResultSaveRequest,
      options?: any
    ): AxiosPromise<Result> {
      return localVarFp
        .saveResult(noteId, resultSaveRequest, options)
        .then(request => request(axios, basePath))
    }
  }
}

/**
 * Request parameters for getResult operation in ResultResourceApi.
 * @export
 * @interface ResultResourceApiGetResultRequest
 */
export interface ResultResourceApiGetResultRequest {
  /**
   *
   * @type {string}
   * @memberof ResultResourceApiGetResult
   */
  readonly noteId: string

  /**
   *
   * @type {boolean}
   * @memberof ResultResourceApiGetResult
   */
  readonly forceV2?: boolean
}

/**
 * Request parameters for saveQuestionResult operation in ResultResourceApi.
 * @export
 * @interface ResultResourceApiSaveQuestionResultRequest
 */
export interface ResultResourceApiSaveQuestionResultRequest {
  /**
   *
   * @type {string}
   * @memberof ResultResourceApiSaveQuestionResult
   */
  readonly noteId: string

  /**
   *
   * @type {QuestionResultInvolvementSaveRequest}
   * @memberof ResultResourceApiSaveQuestionResult
   */
  readonly questionResultInvolvementSaveRequest: QuestionResultInvolvementSaveRequest
}

/**
 * Request parameters for saveResult operation in ResultResourceApi.
 * @export
 * @interface ResultResourceApiSaveResultRequest
 */
export interface ResultResourceApiSaveResultRequest {
  /**
   *
   * @type {string}
   * @memberof ResultResourceApiSaveResult
   */
  readonly noteId: string

  /**
   *
   * @type {ResultSaveRequest}
   * @memberof ResultResourceApiSaveResult
   */
  readonly resultSaveRequest: ResultSaveRequest
}

/**
 * ResultResourceApi - object-oriented interface
 * @export
 * @class ResultResourceApi
 * @extends {BaseAPI}
 */
export class ResultResourceApi extends BaseAPI {
  /**
   *
   * @summary Get involvement check results by note id
   * @param {ResultResourceApiGetResultRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ResultResourceApi
   */
  public getResult(
    requestParameters: ResultResourceApiGetResultRequest,
    options?: AxiosRequestConfig
  ) {
    return ResultResourceApiFp(this.configuration)
      .getResult(requestParameters.noteId, requestParameters.forceV2, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Save an answer to an involvement check question
   * @param {ResultResourceApiSaveQuestionResultRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ResultResourceApi
   */
  public saveQuestionResult(
    requestParameters: ResultResourceApiSaveQuestionResultRequest,
    options?: AxiosRequestConfig
  ) {
    return ResultResourceApiFp(this.configuration)
      .saveQuestionResult(
        requestParameters.noteId,
        requestParameters.questionResultInvolvementSaveRequest,
        options
      )
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Save an involvement check result
   * @param {ResultResourceApiSaveResultRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ResultResourceApi
   */
  public saveResult(
    requestParameters: ResultResourceApiSaveResultRequest,
    options?: AxiosRequestConfig
  ) {
    return ResultResourceApiFp(this.configuration)
      .saveResult(requestParameters.noteId, requestParameters.resultSaveRequest, options)
      .then(request => request(this.axios, this.basePath))
  }
}
