import React, { FC, useContext } from "react";
import { Breadcrumb } from "antd";
import { HistoryOutlined } from "@ant-design/icons";
import { NoteContext } from "../NotePage";
import { useGetHistory } from "./HistoryQueries";
import { ProjectContext } from "../ProjectWrapper";
import Loader from "../../components/Loader/Loader";
import { HistoryForm } from "./HistoryForm";

export const HistoryFormWrapper: FC = () => {
  const { noteId, projectName, baseNotePath } = useContext(NoteContext);
  const { projectId } = useContext(ProjectContext);
  const { isLoading, data: history } = useGetHistory(
    noteId,
    projectId.toString()
  );

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Breadcrumb
        className="breadcrumb"
        items={[
          { title: <HistoryOutlined /> },
          {
            href: `${baseNotePath}/history`,
            title: projectName,
          },
          { title: "Historique" },
        ]}
      />
      {history && <HistoryForm history={history} />}
    </>
  );
};
