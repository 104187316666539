/* tslint:disable */
/* eslint-disable */
/**
 * Appli métier
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: Yoda 1 & 2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { ApiBfr } from "./api-bfr"
import { ApiBfrAnterieur } from "./api-bfr-anterieur"
import { ApiBilan } from "./api-bilan"
import { ApiCaf } from "./api-caf"
import { ApiCalculatedData } from "./api-calculated-data"
import { ApiIncomeStatement } from "./api-income-statement"
import { ApiNumericData } from "./api-numeric-data"
import { ApiPeriodPlanFinancement } from "./api-period-plan-financement"
import { ApiRemboursementsEmprunts } from "./api-remboursements-emprunts"
import { ApiTva } from "./api-tva"

/**
 *
 * @export
 * @interface ApiFinancialPeriod
 */
export interface ApiFinancialPeriod {
  /**
   *
   * @type {string}
   * @memberof ApiFinancialPeriod
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof ApiFinancialPeriod
   */
  noteId: string
  /**
   *
   * @type {string}
   * @memberof ApiFinancialPeriod
   */
  startDate: string
  /**
   *
   * @type {string}
   * @memberof ApiFinancialPeriod
   */
  endDate: string
  /**
   *
   * @type {number}
   * @memberof ApiFinancialPeriod
   */
  duration: number
  /**
   *
   * @type {ApiIncomeStatement}
   * @memberof ApiFinancialPeriod
   */
  incomeStatement: ApiIncomeStatement
  /**
   *
   * @type {ApiTva}
   * @memberof ApiFinancialPeriod
   */
  tva: ApiTva
  /**
   *
   * @type {ApiCaf}
   * @memberof ApiFinancialPeriod
   */
  caf: ApiCaf
  /**
   *
   * @type {ApiBfr}
   * @memberof ApiFinancialPeriod
   */
  bfr: ApiBfr
  /**
   *
   * @type {ApiRemboursementsEmprunts}
   * @memberof ApiFinancialPeriod
   */
  remboursementsEmprunts: ApiRemboursementsEmprunts
  /**
   *
   * @type {ApiCalculatedData}
   * @memberof ApiFinancialPeriod
   */
  besoinsFixes: ApiCalculatedData
  /**
   *
   * @type {ApiCalculatedData}
   * @memberof ApiFinancialPeriod
   */
  pointMort: ApiCalculatedData
  /**
   *
   * @type {ApiCalculatedData}
   * @memberof ApiFinancialPeriod
   */
  margeDeManoeuvre: ApiCalculatedData
  /**
   *
   * @type {ApiPeriodPlanFinancement}
   * @memberof ApiFinancialPeriod
   */
  periodPlanFinancement: ApiPeriodPlanFinancement
  /**
   *
   * @type {ApiNumericData}
   * @memberof ApiFinancialPeriod
   */
  prelevementTns: ApiNumericData
  /**
   *
   * @type {string}
   * @memberof ApiFinancialPeriod
   */
  periodType: ApiFinancialPeriodPeriodTypeEnum
  /**
   *
   * @type {number}
   * @memberof ApiFinancialPeriod
   */
  benchMarkEffectif: number
  /**
   *
   * @type {ApiBilan}
   * @memberof ApiFinancialPeriod
   */
  bilanCedant?: ApiBilan
  /**
   *
   * @type {ApiBfrAnterieur}
   * @memberof ApiFinancialPeriod
   */
  bfrAnterieur?: ApiBfrAnterieur
}

/**
 * @export
 * @enum {string}
 */
export enum ApiFinancialPeriodPeriodTypeEnum {
  PREVISIONNEL = "PREVISIONNEL",
  ANTERIEUR = "ANTERIEUR",
  INTERMEDIAIRE = "INTERMEDIAIRE"
}
