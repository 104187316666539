import React, { FC, SVGProps } from "react"

const GovernanceIcon: FC<SVGProps<SVGSVGElement>> = ({
  height = "16",
  width = "16",
  color = "black",
  fill = "black",
  viewBox = "0 0 150 150",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox}
      color={color}
      fill={fill}
      height={height}
      width={width}
      {...props}
    >
      <path d="M120,78.26,106.13,47.63a2,2,0,0,0-1.79-1.17H83.45a8.72,8.72,0,0,0-16.9,0H45.67a2,2,0,0,0-1.8,1.11L30,78.18a2.94,2.94,0,0,0-.17.87,15.88,15.88,0,1,0,31.75,0,1.84,1.84,0,0,0-.17-.81L48.72,50.4H66.47A8.66,8.66,0,0,0,73,57.08v40.8H64.24a6.24,6.24,0,0,0-6.18,6.19v4.14a2,2,0,0,0,2,1.89H89.7a2,2,0,0,0,2-1.89v-4.14a6.25,6.25,0,0,0-6.19-6.19H77V57.08a8.72,8.72,0,0,0,6.56-6.68h17.75L88.63,78.28a1.9,1.9,0,0,0-.17.81,15.88,15.88,0,1,0,31.75,0,2.09,2.09,0,0,0-.17-.83Zm-74.37-25L56.52,77.2H34.81Zm0,37.82a12.09,12.09,0,0,1-11.78-10H57.43a12.06,12.06,0,0,1-11.76,10Zm42.14,13v2.09H62v-2.09a2.3,2.3,0,0,1,2.24-2.25H85.56a2.31,2.31,0,0,1,2.25,2.25ZM75,53.38a4.77,4.77,0,1,1,4.77-4.77A4.78,4.78,0,0,1,75,53.38ZM115.19,77.2H93.48l10.85-23.92ZM104.33,91.1a12.09,12.09,0,0,1-11.78-10h23.54a12,12,0,0,1-11.76,10Z" />
    </svg>
  )
}

export default GovernanceIcon
