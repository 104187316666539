import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { useAppContext } from "../../../../config/context"
import { extract } from "../../../../client/backend-client/AxiosUtils"
import {
  ApiFinancialPeriod,
  ApiFinancialPeriodPeriodTypeEnum,
  FinancialPeriodResourceV2ApiAddFinancialPeriodRequest,
  FinancialPeriodResourceV2ApiDeleteFinancialPeriodRequest,
  FinancialPeriodResourceV2ApiInitFinancialPeriodsRequest,
  FinancialPeriodResourceV2ApiUpdateFinancialPeriodDatesRequest,
  FinancialPeriodResourceV2ApiUpdateFinancialPeriodRequest
} from "../../../../client/backend-client/generated"
import {
  filterPeriodsByType,
  fromFinancialPeriod,
  PeriodModel,
  sortPeriodsByDate
} from "../../PeriodModel"
import { SERVER_DATE_FORMAT } from "../../../../components/AppMetierDateInput/AppMetierDateInput"
import { formatDayjs } from "../../../../utils/DayjsUtils"
import { Dayjs } from "dayjs"
import { useHeaders } from "../../../../queries/axios-headers"
import { AllPeriods } from "../../PeriodUtils"

export const useGetPeriods = (noteId: string) => {
  const { financialPeriodResourceV2Api } = useAppContext()
  const getHeaders = useHeaders()

  const { isLoading, data, error } = useQuery({
    queryKey: ["financial-periods", noteId],
    queryFn: async () => {
      const { headers } = await getHeaders()
      return financialPeriodResourceV2Api
        .getFinancialPeriods({ noteId }, { headers })
        .then(result => mapApiResultsToPeriodModels(extract(result)))
    },
    retry: 0,
    meta: {
      errorMessage: `Erreur lors de la récupération des exercices comptables`
    }
  })

  return {
    error,
    isLoading,
    data: manageAllPeriods(data)
  }
}

export const useAddPeriod = (noteId: string) => {
  const { financialPeriodResourceV2Api } = useAppContext()
  const getHeaders = useHeaders()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (request: FinancialPeriodResourceV2ApiAddFinancialPeriodRequest) => {
      const { headers } = await getHeaders()
      return financialPeriodResourceV2Api.addFinancialPeriod(request, { headers })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["financial-periods", noteId]
      })
    },
    meta: {
      errorMessage: `Erreur lors de l'ajout de l'exercice comptable`
    }
  })
}

export const useInitPeriod = (noteId: string) => {
  const { financialPeriodResourceV2Api } = useAppContext()
  const getHeaders = useHeaders()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (request: FinancialPeriodResourceV2ApiInitFinancialPeriodsRequest) => {
      const { headers } = await getHeaders()
      return financialPeriodResourceV2Api.initFinancialPeriods(request, { headers })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["financial-periods", noteId]
      })
    },
    meta: {
      errorMessage: `Erreur lors de la création de la période`
    }
  })
}

export const useUpdatePeriods = (noteId: string) => {
  const { financialPeriodResourceV2Api } = useAppContext()
  const getHeaders = useHeaders()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (
      updateFinancialPeriodRequest: FinancialPeriodResourceV2ApiUpdateFinancialPeriodRequest[]
    ) => {
      const { headers } = await getHeaders()
      return Promise.all(
        updateFinancialPeriodRequest.map(u =>
          financialPeriodResourceV2Api.updateFinancialPeriod(u, { headers })
        )
      ).then(results => mapApiResultsToPeriodModels(extract(results[0])))
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["financial-periods", noteId] })
      queryClient.invalidateQueries({ queryKey: ["note", noteId] })
      queryClient.invalidateQueries({ queryKey: ["ratios", noteId] })
    },
    meta: {
      errorMessage: `Erreur lors de la mise à jour des exercices comptables`
    }
  })
}

export const useUpdatePeriodDates = (noteId: string) => {
  const { financialPeriodResourceV2Api } = useAppContext()
  const getHeaders = useHeaders()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (request: FinancialPeriodResourceV2ApiUpdateFinancialPeriodDatesRequest) => {
      const { headers } = await getHeaders()
      return financialPeriodResourceV2Api
        .updateFinancialPeriodDates(request, { headers })
        .then(result => extract(result).map(fromFinancialPeriod))
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["financial-periods", noteId]
      })
    },
    meta: {
      errorMessage: `Erreur lors de la mise à jour du plan de financement`
    }
  })
}

export const useShiftFirstForecastToLastPreviousPeriod = (noteId: string) => {
  const { financialPeriodResourceV2Api } = useAppContext()
  const getHeaders = useHeaders()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async () => {
      const { headers } = await getHeaders()
      return financialPeriodResourceV2Api
        .shiftFirstForecastToLastPreviousPeriod({ noteId }, { headers })
        .then(result => mapApiResultsToPeriodModels(extract(result)))
    },
    onSuccess: updatedPeriods => {
      queryClient.setQueryData<PeriodModel[]>(["financial-periods", noteId], updatedPeriods)
      queryClient.invalidateQueries({ queryKey: ["investments", noteId] })
    },
    meta: {
      errorMessage: `Erreur lors du basculement de la première période prévisionnelle en tant que dernière période passée`
    }
  })
}

export const useDeletePeriod = (noteId: string) => {
  const { financialPeriodResourceV2Api } = useAppContext()
  const getHeaders = useHeaders()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (request: FinancialPeriodResourceV2ApiDeleteFinancialPeriodRequest) => {
      const { headers } = await getHeaders()
      return financialPeriodResourceV2Api.deleteFinancialPeriod(request, { headers }).then(extract)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["financial-periods", noteId]
      })
    },
    meta: {
      errorMessage: `Erreur lors de la suppression de la période`
    }
  })
}

const manageAllPeriods = (periods: PeriodModel[] | undefined): AllPeriods => ({
  previous: periods
    ? sortPeriodsByDate(filterPeriodsByType(periods, ApiFinancialPeriodPeriodTypeEnum.ANTERIEUR))
    : [],
  forecast: periods
    ? sortPeriodsByDate(filterPeriodsByType(periods, ApiFinancialPeriodPeriodTypeEnum.PREVISIONNEL))
    : [],
  intermediary: periods?.find(
    period => period.type === ApiFinancialPeriodPeriodTypeEnum.INTERMEDIAIRE
  )
})

const mapApiResultsToPeriodModels = (results: ApiFinancialPeriod[] | undefined): PeriodModel[] =>
  results ? results.map(fromFinancialPeriod) : []

export const toStartDateMutationModel = (
  noteId: string,
  periodId: string,
  startDate: Dayjs
): FinancialPeriodResourceV2ApiUpdateFinancialPeriodDatesRequest => {
  return {
    financialPeriodId: periodId,
    updateFinancialPeriodDatesRequest: {
      noteId,
      startDate: formatDayjs(startDate, SERVER_DATE_FORMAT)
    }
  }
}

export const toEndDateMutationModel = (
  noteId: string,
  periodId: string,
  endDate: Dayjs
): FinancialPeriodResourceV2ApiUpdateFinancialPeriodDatesRequest => {
  return {
    financialPeriodId: periodId,
    updateFinancialPeriodDatesRequest: {
      noteId,
      endDate: formatDayjs(endDate, SERVER_DATE_FORMAT)
    }
  }
}
