import {
  Memento,
  MementoInput,
  NoteTypeEnum,
  PdfPreferences,
  RubriqueMementoAdvisorInput
} from "../../client/backend-client/generated"
import { CheckboxOptionType, CheckboxValueType } from "antd/es/checkbox/Group"

export type MementoKey = keyof Memento

export type MementoCheckboxOptionType = CheckboxOptionType & {
  linkRelativePath: string
  enabledOnlyWhen?: string
}

export const getMementoOptions = (
  noteType: NoteTypeEnum,
  pdfPreferences: PdfPreferences
): MementoCheckboxOptionType[] => [
  {
    label: "Description de l'analyse, Environnement positionnement moyen",
    value: "showAnalyse",
    linkRelativePath: "analysis/overview"
  },
  {
    label: "Conclusion de l'analyse",
    value: "showAnalyseConclusion",
    linkRelativePath: "analysis/overview"
  },
  {
    label: "Révélateur",
    value: "showRevelateur",
    linkRelativePath: "involvement-check"
  },
  {
    label: "Commentaire Compte de Résultat",
    value: "showCrComments",
    linkRelativePath: "financial/income-statement"
  },
  {
    label: "Compte de résultat",
    value: "showCr",
    linkRelativePath: "financial/past-balance-sheet"
  },
  {
    label: "Indicateurs du Compte de résultat",
    value: "showCrIndicateurs",
    linkRelativePath: "financial/income-statement",
    enabledOnlyWhen: "showCr"
  },
  ...(pdfPreferences.showCaDetails
    ? [
        {
          label: "Détails du Chiffre d'Affaires",
          value: "showDetailCa",
          linkRelativePath: "financial/income-statement",
          enabledOnlyWhen: "showCr"
        }
      ]
    : []),
  ...(pdfPreferences.showSubventionDetails
    ? [
        {
          label: "Détails des subventions d'exploitation",
          value: "showDetailSubvention",
          linkRelativePath: "financial/income-statement",
          enabledOnlyWhen: "showCr"
        }
      ]
    : []),
  {
    label: "BFR Simplifié",
    value: "showBfr",
    linkRelativePath: "financial/bfr"
  },
  {
    label: "Plan de financement",
    value: "showPlanFinancement",
    linkRelativePath: "financial/plan"
  },
  {
    label: "Plan de financement analytique",
    value: "showPfAnalytique",
    linkRelativePath: "financial/plan",
    enabledOnlyWhen: "showPlanFinancement"
  },
  ...([NoteTypeEnum.STRUCTURE_EXISTANTE, NoteTypeEnum.REPRISE].includes(noteType)
    ? [
        {
          label: NoteTypeEnum.STRUCTURE_EXISTANTE === noteType ? "Bilan passé" : "Bilan du cédant",
          value: "showBilan",
          linkRelativePath: "financial/past-balance-sheet"
        }
      ]
    : []),
  {
    label: "Investissement",
    value: "showInvestissements",
    linkRelativePath: "financial/investments"
  },
  {
    label: "Benchmark",
    value: "showBenchmark",
    linkRelativePath: "financial/benchmark"
  }
]

export const checkboxOptionToCheckboxValue = (
  checkboxOption: CheckboxOptionType
): CheckboxValueType => checkboxOption.value

export const initCheckList = (
  memento: Memento,
  mementoOptions: CheckboxOptionType[]
): CheckboxValueType[] =>
  mementoOptions
    .filter(option => memento[option.value as MementoKey] === true)
    .map(checkboxOptionToCheckboxValue)

export const toMementoInput = (
  checkList: CheckboxValueType[],
  sections: RubriqueMementoAdvisorInput[],
  mementoOptions: MementoCheckboxOptionType[]
): MementoInput => {
  const mementoInput: Partial<MementoInput> = {}
  mementoOptions.forEach(option => {
    mementoInput[option.value as keyof Omit<MementoInput, "lstRubriqueMementoAdvisor" | "export">] =
      checkList.includes(option.value)
  })
  return {
    ...mementoInput,
    lstRubriqueMementoAdvisor: sections,
    export: undefined
  } as MementoInput
}
