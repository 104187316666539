import React, { Key } from "react"
import { PeriodModel } from "../PeriodModel"
import {
  ApiAutresAchats,
  ApiCalculatedData,
  ApiDetailLine,
  ApiDetailLines,
  ApiEntryWithDetail,
  ApiFinancialPeriodPeriodTypeEnum,
  ApiIncomeStatement,
  ApiNumericData,
  NoteSettings,
  NoteTypeEnum
} from "../../../client/backend-client/generated"
import { Typography } from "antd"
import { eurosSuffix, Suffixes } from "../../../utils/suffixes"
import { updateNumericData } from "../../../utils/TableUtils"
import { Optional } from "../../../utils/utils"

const { Text, Paragraph } = Typography

export interface IncomeStatementDetailLine {
  id?: string
  isDetailsGroupWithVariableLines: boolean
  isDetailLineAdder: boolean
  required: boolean
  variable?: boolean
  parentDetailGroupKey: ParentDetailGroupKey
  parentDetailGroupSelector: (period: PeriodModel) => ApiEntryWithDetail | ApiAutresAchats
  detailGroupKey: DetailGroupKey
  detailGroupSelector: (period: PeriodModel) => ApiDetailLines
}

export type ParentDetailGroupKey = keyof Pick<
  ApiIncomeStatement,
  "chiffre_affaire" | "subventions_exploitation_details" | "autres_achats"
>

export type DetailGroupKey =
  | keyof Pick<ApiAutresAchats, "fournitures_consommables" | "charges_externes">
  | keyof Pick<ApiEntryWithDetail, "detailLines">

export interface IncomeStatementTableRow {
  key: Key
  label: string
  linkRelativePath?: string
  isTnsLine?: boolean
  isCorporateTaxesLine?: boolean
  isTotal?: boolean
  isDeadCenterLine?: boolean
  isLoansRepaymentLine?: boolean
  detail?: IncomeStatementDetailLine
  highlighted?: boolean
  onlyForFuturePeriods?: boolean
  valueGetter: (period: PeriodModel) => ApiNumericData | ApiCalculatedData | undefined
  hasSomeFilledDetails?: (periods: PeriodModel[]) => boolean
  periodUpdater?: (
    period: PeriodModel,
    numericData: ApiNumericData | undefined,
    variable?: boolean,
    removeOptionalDetails?: boolean
  ) => PeriodModel
  suffix: Suffixes
  details?: IncomeStatementTableRow[]
  disabled?: boolean
  className?: string
}

const DEFAULT_ENTRY_WITH_DETAIL: ApiEntryWithDetail = {
  total: {
    kiloEuros: false
  },
  hasDetail: false,
  detailLines: {
    lines: []
  }
}

const withKeys = (
  prefix: string,
  rows: Optional<IncomeStatementTableRow, "key">[]
): IncomeStatementTableRow[] =>
  rows.map((row, i) => ({
    key: row.key || `${prefix}_${i.toString()}`,
    ...row
  }))

const updateDetailLines = ({
  lines,
  lineId,
  newValue,
  variable,
  kiloEuros
}: {
  lines: ApiDetailLine[]
  lineId: string
  newValue: ApiNumericData | undefined
  variable: boolean | undefined
  kiloEuros: boolean
}): ApiDetailLine[] => {
  const existingLineIndex = lines.findIndex(l => l.id === lineId)
  const value = newValue ? newValue : { value: undefined, kiloEuros: kiloEuros }
  return existingLineIndex !== -1
    ? lines.map((l, index) =>
        index === existingLineIndex
          ? {
              ...l,
              value,
              ...(variable !== undefined && {
                variable
              })
            }
          : l
      )
    : [
        ...lines,
        {
          id: lineId,
          label: "",
          value,
          required: false,
          ...(variable !== undefined && {
            variable
          })
        } as ApiDetailLine
      ]
}

export const generateIncomeStatementTable1 = (
  noteSettings: NoteSettings,
  period: PeriodModel
): IncomeStatementTableRow[] =>
  withKeys("datasource1", [
    {
      key: "income_statement_turnover",
      label: "Chiffre d'affaires",
      suffix: eurosSuffix(noteSettings.kiloEuros),
      valueGetter: p => p && p.incomeStatement.chiffre_affaire.total,
      periodUpdater: (p, newValue) =>
        newValue
          ? {
              ...p,
              incomeStatement: {
                ...p.incomeStatement,
                chiffre_affaire: {
                  ...p.incomeStatement.chiffre_affaire,
                  total: updateNumericData(p.incomeStatement.chiffre_affaire.total, newValue)
                }
              }
            }
          : p,
      hasSomeFilledDetails: () => period.incomeStatement.chiffre_affaire.hasDetail,
      detail: {
        isDetailsGroupWithVariableLines: false,
        isDetailLineAdder: false,
        required: true,
        parentDetailGroupKey: "chiffre_affaire",
        parentDetailGroupSelector: p => p.incomeStatement.chiffre_affaire,
        detailGroupKey: "detailLines",
        detailGroupSelector: period => period.incomeStatement.chiffre_affaire.detailLines
      },
      details: withKeys("datasource1_details1", [
        ...period.incomeStatement.chiffre_affaire.detailLines.lines.map(
          line =>
            ({
              key: line.id,
              label: line.label,
              suffix: eurosSuffix(noteSettings.kiloEuros),
              detail: {
                id: line.id,
                isDetailsGroupWithVariableLines: false,
                required: line.required,
                parentDetailGroupKey: "chiffre_affaire",
                parentDetailGroupSelector: p => p.incomeStatement.chiffre_affaire,
                detailGroupKey: "detailLines",
                detailGroupSelector: period => period.incomeStatement.chiffre_affaire.detailLines
              },
              valueGetter: p =>
                p.incomeStatement.chiffre_affaire.detailLines.lines?.find(l => l.id === line.id)
                  ?.value,
              periodUpdater: (p, newValue, variable, removeOptionalDetails) => ({
                ...p,
                incomeStatement: {
                  ...p.incomeStatement,
                  chiffre_affaire: {
                    ...p.incomeStatement.chiffre_affaire,
                    detailLines: {
                      ...p.incomeStatement.chiffre_affaire.detailLines,
                      lines: updateDetailLines({
                        lines: p.incomeStatement.chiffre_affaire.detailLines.lines,
                        lineId: line.id,
                        newValue: newValue,
                        variable: variable,
                        kiloEuros: noteSettings.kiloEuros
                      }).filter(keepUnless(removeOptionalDetails))
                    }
                  }
                }
              })
            }) as IncomeStatementTableRow
        ),
        {
          label: "Ajouter une ligne de détail",
          suffix: Suffixes.NONE,
          valueGetter: () => undefined,
          detail: {
            isDetailsGroupWithVariableLines: false,
            isDetailLineAdder: true,
            required: true,
            parentDetailGroupKey: "chiffre_affaire",
            parentDetailGroupSelector: p => p.incomeStatement.chiffre_affaire,
            detailGroupKey: "detailLines",
            detailGroupSelector: period => period.incomeStatement.chiffre_affaire.detailLines
          }
        }
      ])
    },
    {
      key: "income_statement_subventions",
      label: "Subventions d'exploitation (hors aides aux postes)",
      suffix: eurosSuffix(noteSettings.kiloEuros),
      valueGetter: p =>
        p.incomeStatement.subventions_exploitation_details?.total ??
        p.incomeStatement.subventions_exploitation,
      periodUpdater: (p, newValue) =>
        newValue
          ? {
              ...p,
              incomeStatement: {
                ...p.incomeStatement,
                subventions_exploitation_details: {
                  ...(p.incomeStatement.subventions_exploitation_details ??
                    DEFAULT_ENTRY_WITH_DETAIL),
                  total: updateNumericData(
                    p.incomeStatement.subventions_exploitation_details?.total ??
                      DEFAULT_ENTRY_WITH_DETAIL.total,
                    newValue
                  )
                }
              }
            }
          : p,
      hasSomeFilledDetails: () =>
        period.incomeStatement.subventions_exploitation_details?.hasDetail ?? false,
      detail: {
        isDetailsGroupWithVariableLines: false,
        isDetailLineAdder: false,
        required: true,
        parentDetailGroupKey: "subventions_exploitation_details",
        parentDetailGroupSelector: p =>
          p.incomeStatement.subventions_exploitation_details ?? DEFAULT_ENTRY_WITH_DETAIL,
        detailGroupKey: "detailLines",
        detailGroupSelector: period =>
          period.incomeStatement.subventions_exploitation_details?.detailLines ??
          DEFAULT_ENTRY_WITH_DETAIL.detailLines
      },
      details: withKeys("datasource1_details3", [
        ...(period.incomeStatement.subventions_exploitation_details
          ? period.incomeStatement.subventions_exploitation_details.detailLines.lines.map(
              line =>
                ({
                  key: line.id,
                  label: line.label,
                  suffix: eurosSuffix(noteSettings.kiloEuros),
                  detail: {
                    id: line.id,
                    isDetailsGroupWithVariableLines: false,
                    required: line.required,
                    parentDetailGroupKey: "subventions_exploitation_details",
                    parentDetailGroupSelector: p =>
                      p.incomeStatement.subventions_exploitation_details,
                    detailGroupKey: "detailLines",
                    detailGroupSelector: period =>
                      period.incomeStatement.subventions_exploitation_details?.detailLines
                  },
                  valueGetter: p =>
                    p.incomeStatement.subventions_exploitation_details?.detailLines.lines?.find(
                      l => l.id === line.id
                    )?.value,
                  periodUpdater: (p, newValue, variable, removeOptionalDetails) => ({
                    ...p,
                    incomeStatement: {
                      ...p.incomeStatement,
                      subventions_exploitation_details: {
                        ...p.incomeStatement.subventions_exploitation_details,
                        detailLines: {
                          ...p.incomeStatement.subventions_exploitation_details?.detailLines,
                          lines: updateDetailLines({
                            lines:
                              p.incomeStatement.subventions_exploitation_details?.detailLines
                                .lines || [],
                            lineId: line.id,
                            newValue: newValue,
                            variable: variable,
                            kiloEuros: noteSettings.kiloEuros
                          }).filter(keepUnless(removeOptionalDetails))
                        }
                      }
                    }
                  })
                }) as IncomeStatementTableRow
            )
          : []),
        {
          label: "Ajouter une ligne de détail",
          suffix: Suffixes.NONE,
          valueGetter: () => undefined,
          detail: {
            isDetailsGroupWithVariableLines: false,
            isDetailLineAdder: true,
            required: true,
            parentDetailGroupKey: "subventions_exploitation_details",
            parentDetailGroupSelector: p =>
              p.incomeStatement.subventions_exploitation_details ?? DEFAULT_ENTRY_WITH_DETAIL,
            detailGroupKey: "detailLines",
            detailGroupSelector: period =>
              period.incomeStatement.subventions_exploitation_details?.detailLines ??
              DEFAULT_ENTRY_WITH_DETAIL.detailLines
          }
        }
      ])
    },
    {
      label: "Aides aux postes",
      suffix: eurosSuffix(noteSettings.kiloEuros),
      valueGetter: p => p.incomeStatement.aides_postes,
      periodUpdater: (p, newValue) =>
        newValue
          ? {
              ...p,
              incomeStatement: {
                ...p.incomeStatement,
                aides_postes: updateNumericData(p.incomeStatement.aides_postes, newValue)
              }
            }
          : p
    },
    {
      key: "income_statement_other_operating_products",
      label: "Autres produits d'exploitation",
      suffix: eurosSuffix(noteSettings.kiloEuros),
      valueGetter: p => p && p.incomeStatement.autres_produits_exploitation.total,
      hasSomeFilledDetails: periods =>
        periods.some(p => p.incomeStatement.autres_produits_exploitation.hasDetail),
      periodUpdater: (p, newValue) =>
        newValue
          ? {
              ...p,
              incomeStatement: {
                ...p.incomeStatement,
                autres_produits_exploitation: {
                  ...p.incomeStatement.autres_produits_exploitation,
                  total: updateNumericData(
                    p.incomeStatement.autres_produits_exploitation.total,
                    newValue
                  )
                }
              }
            }
          : p,
      details: withKeys("datasource1_details2", [
        {
          label: "Production stockée",
          suffix: eurosSuffix(noteSettings.kiloEuros),
          valueGetter: p => p.incomeStatement.autres_produits_exploitation.prodution_stockee,
          periodUpdater: (p, newValue) => ({
            ...p,
            incomeStatement: {
              ...p.incomeStatement,
              autres_produits_exploitation: {
                ...p.incomeStatement.autres_produits_exploitation,
                prodution_stockee: newValue
                  ? updateNumericData(
                      p.incomeStatement.autres_produits_exploitation.prodution_stockee,
                      newValue
                    )
                  : undefined
              }
            }
          })
        },
        {
          label: "Production immobilisée",
          suffix: eurosSuffix(noteSettings.kiloEuros),
          valueGetter: p => p.incomeStatement.autres_produits_exploitation.production_immobilisee,
          periodUpdater: (p, newValue) => ({
            ...p,
            incomeStatement: {
              ...p.incomeStatement,
              autres_produits_exploitation: {
                ...p.incomeStatement.autres_produits_exploitation,
                production_immobilisee: newValue
                  ? updateNumericData(
                      p.incomeStatement.autres_produits_exploitation.production_immobilisee,
                      newValue
                    )
                  : undefined
              }
            }
          })
        },
        {
          label: "Transfert de charges",
          suffix: eurosSuffix(noteSettings.kiloEuros),
          valueGetter: p => p.incomeStatement.autres_produits_exploitation.transfert_charges,
          periodUpdater: (p, newValue) => ({
            ...p,
            incomeStatement: {
              ...p.incomeStatement,
              autres_produits_exploitation: {
                ...p.incomeStatement.autres_produits_exploitation,
                transfert_charges: newValue
                  ? updateNumericData(
                      p.incomeStatement.autres_produits_exploitation.transfert_charges,
                      newValue
                    )
                  : undefined
              }
            }
          })
        },
        {
          label: "Reprises sur amortissements et provisions",
          suffix: eurosSuffix(noteSettings.kiloEuros),
          valueGetter: p =>
            p.incomeStatement.autres_produits_exploitation.reprises_amortissement_provisions,
          periodUpdater: (p, newValue) => ({
            ...p,
            incomeStatement: {
              ...p.incomeStatement,
              autres_produits_exploitation: {
                ...p.incomeStatement.autres_produits_exploitation,
                reprises_amortissement_provisions: newValue
                  ? updateNumericData(
                      p.incomeStatement.autres_produits_exploitation
                        .reprises_amortissement_provisions,
                      newValue
                    )
                  : undefined
              }
            }
          })
        },
        {
          label: "Adhésions",
          suffix: eurosSuffix(noteSettings.kiloEuros),
          valueGetter: p => p.incomeStatement.autres_produits_exploitation.adhesions,
          periodUpdater: (p, newValue) => ({
            ...p,
            incomeStatement: {
              ...p.incomeStatement,
              autres_produits_exploitation: {
                ...p.incomeStatement.autres_produits_exploitation,
                adhesions: newValue
                  ? updateNumericData(
                      p.incomeStatement.autres_produits_exploitation.adhesions,
                      newValue
                    )
                  : undefined
              }
            }
          })
        },
        {
          label: "Autres produits d'exploitation",
          suffix: eurosSuffix(noteSettings.kiloEuros),
          valueGetter: p =>
            p.incomeStatement.autres_produits_exploitation.autres_produits_exploitation,
          periodUpdater: (p, newValue) => ({
            ...p,
            incomeStatement: {
              ...p.incomeStatement,
              autres_produits_exploitation: {
                ...p.incomeStatement.autres_produits_exploitation,
                autres_produits_exploitation: newValue
                  ? updateNumericData(
                      p.incomeStatement.autres_produits_exploitation.autres_produits_exploitation,
                      newValue
                    )
                  : undefined
              }
            }
          })
        }
      ])
    },
    {
      label: "Total produits d'exploitation",
      suffix: eurosSuffix(noteSettings.kiloEuros),
      valueGetter: p => p.incomeStatement.totalProduitsExploitation,
      isTotal: true
    }
  ])

const keepUnless = (removeOptionalDetails: boolean | undefined) => (l: ApiDetailLine) =>
  removeOptionalDetails === true ? l.required : l

export const generateIncomeStatementTable2 = (
  tnsInIncomeStatement: boolean,
  noteSettings: NoteSettings,
  period: PeriodModel
): IncomeStatementTableRow[] =>
  withKeys("datasource2", [
    {
      key: "income_statement_purchases_raw_materials",
      label: "Achat marchandises et matières premières",
      suffix: eurosSuffix(noteSettings.kiloEuros),
      valueGetter: p => p && p.incomeStatement.achat_marchandises.total,
      hasSomeFilledDetails: periods =>
        periods.some(p => p.incomeStatement.achat_marchandises.hasDetail),
      periodUpdater: (p, newValue) =>
        newValue
          ? {
              ...p,
              incomeStatement: {
                ...p.incomeStatement,
                achat_marchandises: {
                  ...p.incomeStatement.achat_marchandises,
                  total: updateNumericData(p.incomeStatement.achat_marchandises.total, newValue)
                }
              }
            }
          : p,
      details: withKeys("datasource2_details1", [
        {
          label: "Achat marchandise",
          suffix: eurosSuffix(noteSettings.kiloEuros),
          valueGetter: p => p.incomeStatement.achat_marchandises.achat_marchandises,
          periodUpdater: (p, newValue) => ({
            ...p,
            incomeStatement: {
              ...p.incomeStatement,
              achat_marchandises: {
                ...p.incomeStatement.achat_marchandises,
                achat_marchandises: newValue
                  ? updateNumericData(
                      p.incomeStatement.achat_marchandises.achat_marchandises,
                      newValue
                    )
                  : undefined
              }
            }
          })
        },
        {
          label: "Achat matières premières",
          suffix: eurosSuffix(noteSettings.kiloEuros),
          valueGetter: p => p.incomeStatement.achat_marchandises.achat_matieres_premieres,
          periodUpdater: (p, newValue) => ({
            ...p,
            incomeStatement: {
              ...p.incomeStatement,
              achat_marchandises: {
                ...p.incomeStatement.achat_marchandises,
                achat_matieres_premieres: newValue
                  ? updateNumericData(
                      p.incomeStatement.achat_marchandises.achat_matieres_premieres,
                      newValue
                    )
                  : undefined
              }
            }
          })
        },
        {
          label: "Variation de stock",
          suffix: eurosSuffix(noteSettings.kiloEuros),
          valueGetter: p => p.incomeStatement.achat_marchandises.variation_stock,
          periodUpdater: (p, newValue) => ({
            ...p,
            incomeStatement: {
              ...p.incomeStatement,
              achat_marchandises: {
                ...p.incomeStatement.achat_marchandises,
                variation_stock: newValue
                  ? updateNumericData(
                      p.incomeStatement.achat_marchandises.variation_stock,
                      newValue
                    )
                  : undefined
              }
            }
          })
        }
      ])
    },
    {
      key: "income_statement_other_purchases",
      label: "Autres achats et charges externes",
      suffix: eurosSuffix(noteSettings.kiloEuros),
      valueGetter: p => p && p.incomeStatement.autres_achats.total,
      periodUpdater: (p, newValue) =>
        newValue
          ? {
              ...p,
              incomeStatement: {
                ...p.incomeStatement,
                autres_achats: {
                  ...p.incomeStatement.autres_achats,
                  total: updateNumericData(p.incomeStatement.autres_achats.total, newValue)
                }
              }
            }
          : p,
      hasSomeFilledDetails: periods =>
        periods.some(p => p && p.incomeStatement.autres_achats.hasDetail),
      details: [
        {
          key: "income_statement_consumable_supplies",
          label: "Fournitures consommables",
          suffix: eurosSuffix(noteSettings.kiloEuros),
          valueGetter: () => undefined,
          detail: {
            isDetailsGroupWithVariableLines: true,
            isDetailLineAdder: false,
            required: true,
            parentDetailGroupKey: "autres_achats",
            parentDetailGroupSelector: p => p.incomeStatement.autres_achats,
            detailGroupKey: "fournitures_consommables",
            detailGroupSelector: period =>
              period.incomeStatement.autres_achats.fournitures_consommables
          },
          details: withKeys("datasource2_details2_1", [
            ...period.incomeStatement.autres_achats.fournitures_consommables.lines.map(
              line =>
                ({
                  key: line.id,
                  label: line.label,
                  suffix: eurosSuffix(noteSettings.kiloEuros),
                  detail: {
                    id: line.id,
                    isDetailsGroupWithVariableLines: false,
                    isDetailLineAdder: false,
                    required: line.required,
                    variable: line.variable,
                    parentDetailGroupKey: "autres_achats",
                    parentDetailGroupSelector: p => p.incomeStatement.autres_achats,
                    detailGroupKey: "fournitures_consommables",
                    detailGroupSelector: period =>
                      period.incomeStatement.autres_achats.fournitures_consommables
                  },
                  valueGetter: p =>
                    p.incomeStatement.autres_achats.fournitures_consommables.lines?.find(
                      l => l.id === line.id
                    )?.value,
                  periodUpdater: (p, newValue, variable, removeOptionalDetails) => ({
                    ...p,
                    incomeStatement: {
                      ...p.incomeStatement,
                      autres_achats: {
                        ...p.incomeStatement.autres_achats,
                        fournitures_consommables: {
                          ...p.incomeStatement.autres_achats.fournitures_consommables,
                          lines: updateDetailLines({
                            lines: p.incomeStatement.autres_achats.fournitures_consommables.lines,
                            lineId: line.id,
                            newValue: newValue,
                            variable: variable,
                            kiloEuros: noteSettings.kiloEuros
                          }).filter(keepUnless(removeOptionalDetails))
                        }
                      }
                    }
                  })
                }) as IncomeStatementTableRow
            ),
            {
              label: "Ajouter une ligne de détail",
              suffix: Suffixes.NONE,
              valueGetter: () => undefined,
              detail: {
                isDetailsGroupWithVariableLines: false,
                isDetailLineAdder: true,
                required: true,
                parentDetailGroupKey: "autres_achats",
                parentDetailGroupSelector: p => p.incomeStatement.autres_achats,
                detailGroupKey: "fournitures_consommables",
                detailGroupSelector: period =>
                  period.incomeStatement.autres_achats.fournitures_consommables
              }
            }
          ])
        },
        {
          label: "Charges externes",
          key: "income_statement_external_charges",
          suffix: eurosSuffix(noteSettings.kiloEuros),
          valueGetter: () => undefined,
          detail: {
            isDetailsGroupWithVariableLines: true,
            isDetailLineAdder: false,
            required: true,
            parentDetailGroupKey: "autres_achats",
            parentDetailGroupSelector: p => p.incomeStatement.autres_achats,
            detailGroupKey: "charges_externes",
            detailGroupSelector: period => period.incomeStatement.autres_achats.charges_externes
          },
          details: withKeys("datasource2_details2_2", [
            ...period.incomeStatement.autres_achats.charges_externes.lines.map(
              line =>
                ({
                  key: line.id,
                  label: line.label,
                  suffix: eurosSuffix(noteSettings.kiloEuros),
                  detail: {
                    id: line.id,
                    isDetailsGroupWithVariableLines: false,
                    isDetailLineAdder: false,
                    required: line.required,
                    variable: line.variable,
                    parentDetailGroupKey: "autres_achats",
                    parentDetailGroupSelector: p => p.incomeStatement.autres_achats,
                    detailGroupKey: "charges_externes",
                    detailGroupSelector: period =>
                      period.incomeStatement.autres_achats.charges_externes
                  },
                  valueGetter: p =>
                    p.incomeStatement.autres_achats.charges_externes.lines?.find(
                      l => l.id === line.id
                    )?.value,
                  periodUpdater: (p, newValue, variable, removeOptionalDetails) => ({
                    ...p,
                    incomeStatement: {
                      ...p.incomeStatement,
                      autres_achats: {
                        ...p.incomeStatement.autres_achats,
                        charges_externes: {
                          ...p.incomeStatement.autres_achats.charges_externes,
                          lines: updateDetailLines({
                            lines: p.incomeStatement.autres_achats.charges_externes.lines,
                            lineId: line.id,
                            newValue: newValue,
                            variable: variable,
                            kiloEuros: noteSettings.kiloEuros
                          }).filter(keepUnless(removeOptionalDetails))
                        }
                      }
                    }
                  })
                }) as IncomeStatementTableRow
            ),
            {
              label: "Ajouter une ligne de détail",
              suffix: Suffixes.NONE,
              valueGetter: () => undefined,
              detail: {
                isDetailsGroupWithVariableLines: false,
                isDetailLineAdder: true,
                required: true,
                parentDetailGroupKey: "autres_achats",
                parentDetailGroupSelector: p => p.incomeStatement.autres_achats,
                detailGroupKey: "charges_externes",
                detailGroupSelector: period => period.incomeStatement.autres_achats.charges_externes
              }
            }
          ])
        }
      ]
    },
    {
      label: "Dont charges variables",
      suffix: eurosSuffix(noteSettings.kiloEuros),
      hasSomeFilledDetails: periods => periods.some(p => p.incomeStatement.autres_achats.hasDetail),
      valueGetter: p => p.incomeStatement.autres_achats.totalChargesVariables,
      periodUpdater: (p, newValue) =>
        newValue
          ? {
              ...p,
              incomeStatement: {
                ...p.incomeStatement,
                autres_achats: {
                  ...p.incomeStatement.autres_achats,
                  totalChargesVariables: updateNumericData(
                    p.incomeStatement.autres_achats.totalChargesVariables,
                    newValue
                  )
                }
              }
            }
          : p
    },
    {
      key: "income_statement_taxes",
      label: "Impôts et taxes",
      suffix: eurosSuffix(noteSettings.kiloEuros),
      valueGetter: p => p && p.incomeStatement.taxes.total,
      hasSomeFilledDetails: periods => periods.some(p => p.incomeStatement.taxes.hasDetail),
      periodUpdater: (p, newValue) =>
        newValue
          ? {
              ...p,
              incomeStatement: {
                ...p.incomeStatement,
                taxes: {
                  ...p.incomeStatement.taxes,
                  total: updateNumericData(p.incomeStatement.taxes.total, newValue)
                }
              }
            }
          : p,
      details: withKeys("datasource2_details3", [
        {
          label: "Contribution économique territoriale (CFE & CVAE)",
          suffix: eurosSuffix(noteSettings.kiloEuros),
          valueGetter: p => p.incomeStatement.taxes.cfe_cvae,
          periodUpdater: (p, newValue) => ({
            ...p,
            incomeStatement: {
              ...p.incomeStatement,
              taxes: {
                ...p.incomeStatement.taxes,
                cfe_cvae: newValue
                  ? updateNumericData(p.incomeStatement.taxes.cfe_cvae, newValue)
                  : undefined
              }
            }
          })
        },
        {
          label: "CSG CRDS non déductible",
          suffix: eurosSuffix(noteSettings.kiloEuros),
          valueGetter: p => p.incomeStatement.taxes.csg_crds,
          periodUpdater: (p, newValue) => ({
            ...p,
            incomeStatement: {
              ...p.incomeStatement,
              taxes: {
                ...p.incomeStatement.taxes,
                csg_crds: newValue
                  ? updateNumericData(p.incomeStatement.taxes.csg_crds, newValue)
                  : undefined
              }
            }
          })
        },
        {
          label: "Taxe d'apprentissage",
          suffix: eurosSuffix(noteSettings.kiloEuros),
          valueGetter: p => p.incomeStatement.taxes.taxe_apprentissage,
          periodUpdater: (p, newValue) => ({
            ...p,
            incomeStatement: {
              ...p.incomeStatement,
              taxes: {
                ...p.incomeStatement.taxes,
                taxe_apprentissage: newValue
                  ? updateNumericData(p.incomeStatement.taxes.taxe_apprentissage, newValue)
                  : undefined
              }
            }
          })
        },
        {
          label: "Taxe formation professionnelle",
          suffix: eurosSuffix(noteSettings.kiloEuros),
          valueGetter: p => p.incomeStatement.taxes.taxe_formation_professionnelle,
          periodUpdater: (p, newValue) => ({
            ...p,
            incomeStatement: {
              ...p.incomeStatement,
              taxes: {
                ...p.incomeStatement.taxes,
                taxe_formation_professionnelle: newValue
                  ? updateNumericData(
                      p.incomeStatement.taxes.taxe_formation_professionnelle,
                      newValue
                    )
                  : undefined
              }
            }
          })
        },
        {
          label: "Taxes diverses",
          suffix: eurosSuffix(noteSettings.kiloEuros),
          valueGetter: p => p.incomeStatement.taxes.taxes_diverses,
          periodUpdater: (p, newValue) => ({
            ...p,
            incomeStatement: {
              ...p.incomeStatement,
              taxes: {
                ...p.incomeStatement.taxes,
                taxes_diverses: newValue
                  ? updateNumericData(p.incomeStatement.taxes.taxes_diverses, newValue)
                  : undefined
              }
            }
          })
        }
      ])
    },
    {
      label: "Traitements et salaires bruts",
      suffix: eurosSuffix(noteSettings.kiloEuros),
      valueGetter: p => p.incomeStatement.traitementsSalaires,
      periodUpdater: (p, newValue) =>
        newValue
          ? {
              ...p,
              incomeStatement: {
                ...p.incomeStatement,
                traitementsSalaires: updateNumericData(
                  p.incomeStatement.traitementsSalaires,
                  newValue
                )
              }
            }
          : p
    },
    {
      label: "Cotisations sociales patronales",
      suffix: eurosSuffix(noteSettings.kiloEuros),
      valueGetter: p => p.incomeStatement.cotisationsSocialesPatronales,
      periodUpdater: (p, newValue) =>
        newValue
          ? {
              ...p,
              incomeStatement: {
                ...p.incomeStatement,
                cotisationsSocialesPatronales: updateNumericData(
                  p.incomeStatement.cotisationsSocialesPatronales,
                  newValue
                )
              }
            }
          : p
    },
    {
      label: "Taux de charges %",
      suffix: Suffixes.PERCENTAGE,
      valueGetter: p => p.incomeStatement.tauxDeChargesPatronales,
      isTotal: true
    },
    {
      label: "Prélèvement TNS",
      isTnsLine: true,
      suffix: eurosSuffix(noteSettings.kiloEuros),
      valueGetter: p => p.prelevementTns,
      periodUpdater: (p, newValue) =>
        newValue
          ? {
              ...p,
              prelevementTns: updateNumericData(p.prelevementTns, newValue)
            }
          : p
    },
    {
      label: "Cotisations sociales des dirigeants",
      suffix: eurosSuffix(noteSettings.kiloEuros),
      valueGetter: p => p.incomeStatement.cotisationsSocialesDirigeants,
      periodUpdater: (p, newValue) =>
        newValue
          ? {
              ...p,
              incomeStatement: {
                ...p.incomeStatement,
                cotisationsSocialesDirigeants: updateNumericData(
                  p.incomeStatement.cotisationsSocialesDirigeants,
                  newValue
                )
              }
            }
          : p
    },
    ...(tnsInIncomeStatement
      ? [
          {
            label: "Taux de charges %",
            suffix: Suffixes.PERCENTAGE,
            valueGetter: (p: PeriodModel) => p.incomeStatement.tauxDeChargesDirigeants,
            isTotal: true
          }
        ]
      : []),
    {
      label: "Autres charges de personnel",
      suffix: eurosSuffix(noteSettings.kiloEuros),
      valueGetter: p => p.incomeStatement.autresChargesDePersonnel,
      periodUpdater: (p, newValue) =>
        newValue
          ? {
              ...p,
              incomeStatement: {
                ...p.incomeStatement,
                autresChargesDePersonnel: updateNumericData(
                  p.incomeStatement.autresChargesDePersonnel,
                  newValue
                )
              }
            }
          : p
    },
    {
      label: "Dotations aux amortissements et provisions",
      suffix: eurosSuffix(noteSettings.kiloEuros),
      valueGetter: p => p.incomeStatement.dotationsAmortissementsProvisions,
      periodUpdater: (p, newValue) =>
        newValue
          ? {
              ...p,
              incomeStatement: {
                ...p.incomeStatement,
                dotationsAmortissementsProvisions: updateNumericData(
                  p.incomeStatement.dotationsAmortissementsProvisions,
                  newValue
                )
              }
            }
          : p
    },
    {
      label: "Autres charges d'exploitation",
      suffix: eurosSuffix(noteSettings.kiloEuros),
      valueGetter: p => p.incomeStatement.autresChargesExploitation,
      periodUpdater: (p, newValue) =>
        newValue
          ? {
              ...p,
              incomeStatement: {
                ...p.incomeStatement,
                autresChargesExploitation: updateNumericData(
                  p.incomeStatement.autresChargesExploitation,
                  newValue
                )
              }
            }
          : p
    },
    {
      label: "Total charges d'exploitation",
      suffix: eurosSuffix(noteSettings.kiloEuros),
      valueGetter: p => p.incomeStatement.totalChargesExploitations,
      isTotal: true
    }
  ])

export const generateIncomeStatementTable3 = (
  noteSettings: NoteSettings
): IncomeStatementTableRow[] =>
  withKeys("datasource3", [
    {
      label: "Résultat d'exploitation",
      suffix: eurosSuffix(noteSettings.kiloEuros),
      valueGetter: p => p.incomeStatement.resultatsExploitation,
      isTotal: true,
      highlighted: true
    }
  ])

export const generateIncomeStatementTable4 = (
  noteSettings: NoteSettings,
  noteType: NoteTypeEnum
): IncomeStatementTableRow[] =>
  withKeys("datasource4", [
    {
      key: "income_statement_financial_result",
      label: "Résultat financier",
      suffix: eurosSuffix(noteSettings.kiloEuros),
      className: "result-row",
      valueGetter: p => p && p.incomeStatement.resultatFinancier.total,
      hasSomeFilledDetails: periods =>
        periods.some(p => p.incomeStatement.resultatFinancier.hasDetail),
      periodUpdater: (p, newValue) =>
        newValue
          ? {
              ...p,
              incomeStatement: {
                ...p.incomeStatement,
                resultatFinancier: {
                  ...p.incomeStatement.resultatFinancier,
                  total: updateNumericData(p.incomeStatement.resultatFinancier.total, newValue)
                }
              }
            }
          : p,
      details: withKeys("datasource4_details1", [
        {
          label: "Produits financiers (hors 786)",
          suffix: eurosSuffix(noteSettings.kiloEuros),
          valueGetter: p => p.incomeStatement.resultatFinancier.produitsFinanciers,
          periodUpdater: (p, newValue) => ({
            ...p,
            incomeStatement: {
              ...p.incomeStatement,
              resultatFinancier: {
                ...p.incomeStatement.resultatFinancier,
                produitsFinanciers: newValue
                  ? updateNumericData(
                      p.incomeStatement.resultatFinancier.produitsFinanciers,
                      newValue
                    )
                  : undefined
              }
            }
          })
        },
        {
          label: "Reprises sur provisions financières (786)",
          suffix: eurosSuffix(noteSettings.kiloEuros),
          valueGetter: p => p.incomeStatement.resultatFinancier.reprisesSurProvisionsFinancieres,
          periodUpdater: (p, newValue) => ({
            ...p,
            incomeStatement: {
              ...p.incomeStatement,
              resultatFinancier: {
                ...p.incomeStatement.resultatFinancier,
                reprisesSurProvisionsFinancieres: newValue
                  ? updateNumericData(
                      p.incomeStatement.resultatFinancier.reprisesSurProvisionsFinancieres,
                      newValue
                    )
                  : undefined
              }
            }
          })
        },
        {
          label: "Charges financières (hors 661 / 686)",
          suffix: eurosSuffix(noteSettings.kiloEuros),
          valueGetter: p => p.incomeStatement.resultatFinancier.chargesFinancieres,
          periodUpdater: (p, newValue) => ({
            ...p,
            incomeStatement: {
              ...p.incomeStatement,
              resultatFinancier: {
                ...p.incomeStatement.resultatFinancier,
                chargesFinancieres: newValue
                  ? updateNumericData(
                      p.incomeStatement.resultatFinancier.chargesFinancieres,
                      newValue
                    )
                  : undefined
              }
            }
          })
        },
        {
          label: "Dotations aux provisions financières (686)",
          suffix: eurosSuffix(noteSettings.kiloEuros),
          valueGetter: p => p.incomeStatement.resultatFinancier.dotationsAuxProvisionsFinancieres,
          periodUpdater: (p, newValue) => ({
            ...p,
            incomeStatement: {
              ...p.incomeStatement,
              resultatFinancier: {
                ...p.incomeStatement.resultatFinancier,
                dotationsAuxProvisionsFinancieres: newValue
                  ? updateNumericData(
                      p.incomeStatement.resultatFinancier.dotationsAuxProvisionsFinancieres,
                      newValue
                    )
                  : undefined
              }
            }
          })
        },
        ...(noteType === NoteTypeEnum.STRUCTURE_EXISTANTE
          ? ([
              {
                label: "Charges d'intérêts passés (661)",
                suffix: eurosSuffix(noteSettings.kiloEuros),
                valueGetter: p => p.incomeStatement.resultatFinancier.chargesInteretsPasses,
                periodUpdater: (p, newValue) => ({
                  ...p,
                  incomeStatement: {
                    ...p.incomeStatement,
                    resultatFinancier: {
                      ...p.incomeStatement.resultatFinancier,
                      chargesInteretsPasses: newValue
                        ? updateNumericData(
                            p.incomeStatement.resultatFinancier.chargesInteretsPasses,
                            newValue
                          )
                        : undefined
                    }
                  }
                })
              },
              {
                label: "Charges d'intérêts futurs (661)",
                suffix: eurosSuffix(noteSettings.kiloEuros),
                onlyForFuturePeriods: true,
                valueGetter: p => p.incomeStatement.resultatFinancier.chargesInteretsFuturs,
                periodUpdater: (p, newValue) => ({
                  ...p,
                  incomeStatement: {
                    ...p.incomeStatement,
                    resultatFinancier: {
                      ...p.incomeStatement.resultatFinancier,
                      chargesInteretsFuturs: newValue
                        ? updateNumericData(
                            p.incomeStatement.resultatFinancier.chargesInteretsFuturs,
                            newValue
                          )
                        : undefined
                    }
                  }
                })
              }
            ] as IncomeStatementTableRow[])
          : ([
              {
                label: "Charges d'intérêts (661)",
                suffix: eurosSuffix(noteSettings.kiloEuros),
                valueGetter: p => p.incomeStatement.resultatFinancier.chargeInterets,
                periodUpdater: (p, newValue) => ({
                  ...p,
                  incomeStatement: {
                    ...p.incomeStatement,
                    resultatFinancier: {
                      ...p.incomeStatement.resultatFinancier,
                      chargeInterets: newValue
                        ? updateNumericData(
                            p.incomeStatement.resultatFinancier.chargeInterets,
                            newValue
                          )
                        : undefined
                    }
                  }
                })
              }
            ] as IncomeStatementTableRow[]))
      ])
    }
  ])

export const generateIncomeStatementTable5 = (
  noteSettings: NoteSettings
): IncomeStatementTableRow[] =>
  withKeys("datasource5", [
    {
      key: "income_statement_exceptional_result",
      label: "Résultat exceptionnel",
      suffix: eurosSuffix(noteSettings.kiloEuros),
      className: "result-row",
      valueGetter: p => p && p.incomeStatement.resultatExceptionnel.total,
      hasSomeFilledDetails: periods =>
        periods.some(p => p.incomeStatement.resultatExceptionnel.hasDetail),
      periodUpdater: (p, newValue) =>
        newValue
          ? {
              ...p,
              incomeStatement: {
                ...p.incomeStatement,
                resultatExceptionnel: {
                  ...p.incomeStatement.resultatExceptionnel,
                  total: updateNumericData(p.incomeStatement.resultatExceptionnel.total, newValue)
                }
              }
            }
          : p,
      details: withKeys("datasource5_details1", [
        {
          label: "Produits exceptionnels (hors 775 / 787 / 777)",
          suffix: eurosSuffix(noteSettings.kiloEuros),
          valueGetter: p => p.incomeStatement.resultatExceptionnel.produitsExceptionnels,
          periodUpdater: (p, newValue) => ({
            ...p,
            incomeStatement: {
              ...p.incomeStatement,
              resultatExceptionnel: {
                ...p.incomeStatement.resultatExceptionnel,
                produitsExceptionnels: newValue
                  ? updateNumericData(
                      p.incomeStatement.resultatExceptionnel.produitsExceptionnels,
                      newValue
                    )
                  : undefined
              }
            }
          })
        },
        {
          label: "Produits des cessions d'actifs (775)",
          suffix: eurosSuffix(noteSettings.kiloEuros),
          valueGetter: p => p.incomeStatement.resultatExceptionnel.produitsCessionsActifs,
          periodUpdater: (p, newValue) => ({
            ...p,
            incomeStatement: {
              ...p.incomeStatement,
              resultatExceptionnel: {
                ...p.incomeStatement.resultatExceptionnel,
                produitsCessionsActifs: newValue
                  ? updateNumericData(
                      p.incomeStatement.resultatExceptionnel.produitsCessionsActifs,
                      newValue
                    )
                  : undefined
              }
            }
          })
        },
        {
          label: "Reprises sur provisions exceptionnelles (787)",
          suffix: eurosSuffix(noteSettings.kiloEuros),
          valueGetter: p =>
            p.incomeStatement.resultatExceptionnel.reprisesSurProvisionsExceptionnelles,
          periodUpdater: (p, newValue) => ({
            ...p,
            incomeStatement: {
              ...p.incomeStatement,
              resultatExceptionnel: {
                ...p.incomeStatement.resultatExceptionnel,
                reprisesSurProvisionsExceptionnelles: newValue
                  ? updateNumericData(
                      p.incomeStatement.resultatExceptionnel.reprisesSurProvisionsExceptionnelles,
                      newValue
                    )
                  : undefined
              }
            }
          })
        },
        {
          label: "Quote-part de subventions virée au résultat (777)",
          suffix: eurosSuffix(noteSettings.kiloEuros),
          valueGetter: p =>
            p.incomeStatement.resultatExceptionnel.quotePartSubventionsVireesAuResultat,
          periodUpdater: (p, newValue) => ({
            ...p,
            incomeStatement: {
              ...p.incomeStatement,
              resultatExceptionnel: {
                ...p.incomeStatement.resultatExceptionnel,
                quotePartSubventionsVireesAuResultat: newValue
                  ? updateNumericData(
                      p.incomeStatement.resultatExceptionnel.quotePartSubventionsVireesAuResultat,
                      newValue
                    )
                  : undefined
              }
            }
          })
        },
        {
          label: "Charges exceptionnelles (hors 675 / 687)",
          suffix: eurosSuffix(noteSettings.kiloEuros),
          valueGetter: p => p.incomeStatement.resultatExceptionnel.chargesExceptionnelles,
          periodUpdater: (p, newValue) => ({
            ...p,
            incomeStatement: {
              ...p.incomeStatement,
              resultatExceptionnel: {
                ...p.incomeStatement.resultatExceptionnel,
                chargesExceptionnelles: newValue
                  ? updateNumericData(
                      p.incomeStatement.resultatExceptionnel.chargesExceptionnelles,
                      newValue
                    )
                  : undefined
              }
            }
          })
        },
        {
          label: "Dotations aux provisions exceptionnelles (687)",
          suffix: eurosSuffix(noteSettings.kiloEuros),
          valueGetter: p =>
            p.incomeStatement.resultatExceptionnel.dotationsAuxProvisionsExceptionnelles,
          periodUpdater: (p, newValue) => ({
            ...p,
            incomeStatement: {
              ...p.incomeStatement,
              resultatExceptionnel: {
                ...p.incomeStatement.resultatExceptionnel,
                dotationsAuxProvisionsExceptionnelles: newValue
                  ? updateNumericData(
                      p.incomeStatement.resultatExceptionnel.dotationsAuxProvisionsExceptionnelles,
                      newValue
                    )
                  : undefined
              }
            }
          })
        },
        {
          label: "Valeurs comptables des cessions d'actifs (675)",
          suffix: eurosSuffix(noteSettings.kiloEuros),
          valueGetter: p =>
            p.incomeStatement.resultatExceptionnel.valeursComptablesDesCessionsDactifs,
          periodUpdater: (p, newValue) => ({
            ...p,
            incomeStatement: {
              ...p.incomeStatement,
              resultatExceptionnel: {
                ...p.incomeStatement.resultatExceptionnel,
                valeursComptablesDesCessionsDactifs: newValue
                  ? updateNumericData(
                      p.incomeStatement.resultatExceptionnel.valeursComptablesDesCessionsDactifs,
                      newValue
                    )
                  : undefined
              }
            }
          })
        }
      ])
    }
  ])

export const generateIncomeStatementTable6 = (
  noteSettings: NoteSettings
): IncomeStatementTableRow[] =>
  withKeys("datasource6", [
    {
      label: "Participation et intéressement",
      suffix: eurosSuffix(noteSettings.kiloEuros),
      valueGetter: p => p.incomeStatement.participationInteressement,
      periodUpdater: (p, newValue) =>
        newValue
          ? {
              ...p,
              incomeStatement: {
                ...p.incomeStatement,
                participationInteressement: updateNumericData(
                  p.incomeStatement.participationInteressement,
                  newValue
                )
              }
            }
          : p
    },
    {
      label: "Report de ressources non utilisées des exercices antérieurs",
      suffix: eurosSuffix(noteSettings.kiloEuros),
      valueGetter: p => p.incomeStatement.reportRessourcesNonUtilisees,
      periodUpdater: (p, newValue) =>
        newValue
          ? {
              ...p,
              incomeStatement: {
                ...p.incomeStatement,
                reportRessourcesNonUtilisees: updateNumericData(
                  p.incomeStatement.reportRessourcesNonUtilisees,
                  newValue
                )
              }
            }
          : p
    },
    {
      label: "Engagement à réaliser sur ressources affectées",
      suffix: eurosSuffix(noteSettings.kiloEuros),
      valueGetter: p => p.incomeStatement.engagementARealiserRessourcesAffectees,
      periodUpdater: (p, newValue) =>
        newValue
          ? {
              ...p,
              incomeStatement: {
                ...p.incomeStatement,
                engagementARealiserRessourcesAffectees: updateNumericData(
                  p.incomeStatement.engagementARealiserRessourcesAffectees,
                  newValue
                )
              }
            }
          : p
    },
    {
      label: `Impôts sur les bénéfices ${
        noteSettings.assujettiImpotsSocietes ? "" : " (non assujetti)"
      }`,
      isCorporateTaxesLine: true,
      suffix: eurosSuffix(noteSettings.kiloEuros),
      disabled: !noteSettings.assujettiImpotsSocietes,
      valueGetter: p =>
        noteSettings.assujettiImpotsSocietes
          ? p.type === ApiFinancialPeriodPeriodTypeEnum.PREVISIONNEL &&
            noteSettings.impotsSurBeneficeComputed
            ? p.incomeStatement.impotsSurBenefices
            : p.incomeStatement.impotsSurBeneficesInput
          : undefined,
      periodUpdater: (p, newValue) =>
        newValue
          ? {
              ...p,
              incomeStatement: {
                ...p.incomeStatement,
                impotsSurBeneficesInput: updateNumericData(
                  p.incomeStatement.impotsSurBeneficesInput,
                  newValue
                )
              }
            }
          : p
    }
  ])

export const generateIncomeStatementTable7 = (
  noteSettings: NoteSettings
): IncomeStatementTableRow[] =>
  withKeys("datasource7", [
    {
      label: "Résultat net",
      suffix: eurosSuffix(noteSettings.kiloEuros),
      valueGetter: p => p.incomeStatement.resultatNet,
      isTotal: true,
      highlighted: true
    },
    {
      label: "Charges fixes",
      suffix: eurosSuffix(noteSettings.kiloEuros),
      valueGetter: p => p.incomeStatement.totalChargesFixes,
      isTotal: true
    },
    {
      label: "Remboursements des emprunts",
      suffix: eurosSuffix(noteSettings.kiloEuros),
      valueGetter: p => p.remboursementsEmprunts.total,
      isTotal: true,
      isLoansRepaymentLine: true
    },
    {
      label: "Besoins fixes",
      suffix: eurosSuffix(noteSettings.kiloEuros),
      valueGetter: p => p.besoinsFixes,
      isTotal: true
    },
    {
      label: "Charges variables",
      suffix: eurosSuffix(noteSettings.kiloEuros),
      valueGetter: p => p.incomeStatement.totalChargesVariables,
      isTotal: true
    },
    {
      label: "Taux de charges variables",
      suffix: Suffixes.PERCENTAGE,
      valueGetter: p => p.incomeStatement.tauxChargesVariables,
      isTotal: true
    },
    {
      label: "Point mort",
      suffix: eurosSuffix(noteSettings.kiloEuros),
      valueGetter: p => p.pointMort,
      isTotal: true,
      isDeadCenterLine: true
    },
    {
      label: "Marge de manœuvre",
      suffix: Suffixes.PERCENTAGE,
      valueGetter: p => p.margeDeManoeuvre,
      isTotal: true
    }
  ])

export const generateIncomeStatementTable8 = (
  noteSettings: NoteSettings
): IncomeStatementTableRow[] =>
  withKeys("datasource8", [
    {
      label: "Chiffre d'affaires",
      suffix: eurosSuffix(noteSettings.kiloEuros),
      valueGetter: p => p.incomeStatement.chiffre_affaire.total,
      isTotal: true
    },
    {
      label: "Production de l'exercice",
      suffix: eurosSuffix(noteSettings.kiloEuros),
      valueGetter: p => p.incomeStatement.productionExercice,
      isTotal: true
    },
    {
      label: "Marge globale",
      suffix: eurosSuffix(noteSettings.kiloEuros),
      valueGetter: p => p.incomeStatement.margeGlobale,
      isTotal: true
    },
    {
      label: "Valeur ajoutée",
      suffix: eurosSuffix(noteSettings.kiloEuros),
      valueGetter: p => p.incomeStatement.valeurAjoutee,
      isTotal: true
    },
    {
      label: "Excédent brut d'exploitation",
      suffix: eurosSuffix(noteSettings.kiloEuros),
      valueGetter: p => p.incomeStatement.excedentBrutExploitation,
      isTotal: true
    },
    {
      label: "Résultat d’exploitation",
      suffix: eurosSuffix(noteSettings.kiloEuros),
      valueGetter: p => p.incomeStatement.resultatsExploitation,
      isTotal: true
    },
    {
      label: "Résultat financier",
      suffix: eurosSuffix(noteSettings.kiloEuros),
      valueGetter: p => p.incomeStatement.resultatFinancier.total,
      isTotal: true
    },
    {
      label: "Résultat exceptionnel",
      suffix: eurosSuffix(noteSettings.kiloEuros),
      valueGetter: p => p.incomeStatement.resultatExceptionnel.total,
      isTotal: true
    },
    {
      label: "Résultat net",
      suffix: eurosSuffix(noteSettings.kiloEuros),
      valueGetter: p => p.incomeStatement.resultatNet.numericData,
      isTotal: true
    },
    {
      label: "CAF",
      suffix: eurosSuffix(noteSettings.kiloEuros),
      valueGetter: p => p.caf.caf,
      linkRelativePath: "financial/caf",
      isTotal: true
    }
  ])

export const tnsModalContent = (tnsInIncomeStatement: boolean) =>
  tnsInIncomeStatement ? (
    <>
      <Paragraph>
        Le prélèvement du TNS ne sera plus intégré comme un besoin du plan de financement.
      </Paragraph>
      <Paragraph>
        Il sera remis dans le <Text strong>CR prévisionnel</Text> et réintégré dans les calculs
        intermédiaires, y compris la CAF. Vous pouvez toujours modifier le montant.
      </Paragraph>
      <Paragraph>
        Cette opération est réversible : vous pourrez le réintégrer dans le plan de financement à
        tout moment.
      </Paragraph>
    </>
  ) : (
    <>
      <Paragraph>
        Le prélèvement du TNS ne sera plus pris en compte dans le CR prévisionnel et ne sera plus
        intégré dans les calculs intermédiaires, y compris la CAF.
      </Paragraph>
      <Paragraph>
        Il sera intégré comme un besoin dans le <Text strong>plan de financement</Text> pour que le
        solde reste juste. Vous pouvez toujours modifier le montant.
      </Paragraph>
      <Paragraph>
        Cette opération est réversible : vous pourrez le réintégrer dans le CR prévisionnel à tout
        moment.
      </Paragraph>
    </>
  )

export const corporateTaxesModalContent = (computedCorporateTaxes: boolean) =>
  computedCorporateTaxes ? (
    <>
      <Paragraph>
        Les données renseignées automatiquement vont être supprimées afin de vous permettre de
        saisir manuellement l’
        <Text strong>impôt sur les bénéfices</Text>.
      </Paragraph>
      <Paragraph>Vous pourrez toujours revenir au calcul automatique ultérieurement.</Paragraph>
    </>
  ) : (
    <>
      <Paragraph>
        Les données renseignées manuellement vont être supprimées, et l’
        <Text strong>impôt sur les bénéfices</Text> va être calculé automatiquement.
      </Paragraph>
      <Paragraph>Vous pourrez toujours revenir à la saisie manuelle ultérieurement.</Paragraph>
    </>
  )
