import { ReactNode, useContext } from "react"
import { creationDictionary } from "./creation-dictionary"
import { repriseDictionary } from "./reprise-dictionary"
import { structureExistanteDictionary } from "./structure-existante-dictionary"
import { NoteContext } from "../../pages/NotePage"
import {
  AddFinancialPeriodRequestPeriodTypeEnum,
  ApiFinancialPeriodPeriodTypeEnum,
  NoteTypeEnum
} from "../../client/backend-client/generated"
import { PeriodModel } from "../../pages/financial/PeriodModel"

export interface NoteDictionary {
  cr: NavigationDetails
  bfr: {
    menuLabel: string
  }
  differencesSaisies: {
    targetTitle: string
    crSuccessText: ReactNode
    crExplanation: ReactNode
    pfSuccessText: ReactNode
    pfExplanation: ReactNode
    masseSalarialeMoreInformation: ReactNode
  }
  noteSettings: {
    tabExerciceTitle: string
    baseDateLabel: (periodType?: ApiFinancialPeriodPeriodTypeEnum) => string
    tvaTabLink: (periodType?: ApiFinancialPeriodPeriodTypeEnum) => string
    addPeriodLabel: (periodType?: AddFinancialPeriodRequestPeriodTypeEnum) => string
  }
  periodTitle: (p: PeriodModel, index: number) => string
  bilan: NavigationDetails
  exerciceCedantLabel: string
  commentAnterieurLabel: string
  benchmarkAnterieurPeriodLabel: string
  operations: NavigationDetails
  pretTVADemarrage: PretTVADemarrageDetails
  pretsPasse: NavigationDetails
  noExerciceText: string
  noExerciceLink: string
  evolutionPercentagePassedLabel: string
}

export interface PretTVADemarrageDetails {
  label: string
  button: string
}

export interface NavigationDetails {
  ongletName: string
  pageTitle: string
  linkText: string
}

export const useDictionary: () => NoteDictionary = () => {
  const { note } = useContext(NoteContext)
  switch (note.type) {
    case NoteTypeEnum.REPRISE:
      return repriseDictionary
    case NoteTypeEnum.STRUCTURE_EXISTANTE:
      return structureExistanteDictionary
    case NoteTypeEnum.CREATION:
    case NoteTypeEnum.UNDEFINED_CREATION:
      return creationDictionary
  }
}
