import React from "react"
import { ConfigProvider, Empty, Layout, ThemeConfig } from "antd"
import "antd/dist/reset.css"
import { NavBar } from "./components/NavBar/NavBar"
import { Navigate, Route, Routes } from "react-router-dom"
import { routes } from "./config/router/Routes"
import { RouteWithSubRoutes } from "./config/router/RouteWithSubRoutes"
import frFR from "antd/locale/fr_FR"
import dayjs from "dayjs"
import customParseFormat from "dayjs/plugin/customParseFormat"
import isSameOrAfter from "dayjs/plugin/isSameOrAfter"
import isSameOrBefore from "dayjs/plugin/isSameOrBefore"
import minMax from "dayjs/plugin/minMax"
import isBetween from "dayjs/plugin/isBetween"

import { ScrollToTop } from "./components/ScrollToTopButton/ScrollToTopButton"
import utc from "dayjs/plugin/utc"
import Timezone from "dayjs/plugin/timezone"

const { Content } = Layout
require("dayjs/locale/fr")

dayjs.locale("fr")
dayjs.extend(customParseFormat)
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)
dayjs.extend(minMax)
dayjs.extend(isBetween)
dayjs.extend(utc)
dayjs.extend(Timezone)

const theme = {
  token: {
    colorPrimary: "#00ccaa",
    colorLink: "#00ccaa",
    borderRadius: 3,
    colorBgLayout: "#f4f7fc",
    fontFamily:
      '"Montserrat", "Lato", -apple-system, BlinkMacSystemFont,\n' +
      '    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",\n' +
      '    "Droid Sans", "Helvetica Neue", sans-serif'
  },
  components: {
    Table: {
      rowHoverBg: "var(--main-green-2)"
    }
  }
} as ThemeConfig

const customizeRenderEmpty = () => (
  <Empty
    image={Empty.PRESENTED_IMAGE_SIMPLE}
    description={"Pas de données"}
    style={{ marginBlock: "0" }}
  />
)

const App: React.FC = () => (
  <ConfigProvider theme={theme} renderEmpty={customizeRenderEmpty} locale={frFR}>
    <Layout>
      <NavBar />
      <Content className="content">
        <ScrollToTop />
        <Routes>
          {routes
            .filter(route => route.enabled)
            .map(route => (
              <Route
                key={route.path}
                path={route.path}
                element={<RouteWithSubRoutes key={route.path} {...route} />}
              />
            ))}
          <Route path="*" element={<Navigate to={"/"} />} />
          <Route path="/" element={<Navigate to={"/home"} />} />
        </Routes>
      </Content>
    </Layout>
  </ConfigProvider>
)

export default App
