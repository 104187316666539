import React, { FC, useEffect, useLayoutEffect, useState } from "react"
import { handleUserKeyPress } from "../../utils/keyboard-navigation"
import { Checkbox } from "antd"

export const AppMetierCheckbox: FC<{
  defaultChecked: boolean
  onChange: (checked: boolean) => void
  style?: React.CSSProperties
  name: string
}> = ({ defaultChecked, onChange, style, name }) => {
  const [checked, setChecked] = useState(defaultChecked)

  useEffect(() => {
    setChecked(checked)
  }, [defaultChecked])

  useLayoutEffect(() => {
    window.addEventListener("keydown", handleUserKeyPress)
  })

  return (
    <Checkbox
      checked={checked}
      onChange={e => {
        const checked = e.target.checked
        setChecked(checked)
        onChange(checked)
      }}
      style={style}
      name={name}
    />
  )
}
