import React, { useContext } from "react"
import { NoteContext } from "../../pages/NotePage"
import { useDictionary } from "../../utils/dictionary/dictionary"
import {
  FilePdfOutlined,
  HistoryOutlined,
  HomeOutlined,
  PieChartOutlined,
  ProjectOutlined,
  SettingOutlined,
  StarOutlined,
  TableOutlined
} from "@ant-design/icons"
import { NoteTypeEnum } from "../../client/backend-client/generated"
import { INFOS_PAGE_NAME } from "../../pages/infos/InfosPageWrapper"

export type SiderMenuItem = {
  label: string
  key: string
  icon?: React.JSX.Element
  link?: string
  children?: SiderMenuItem[]
}

export const useSiderMenuItems: () => SiderMenuItem[] = () => {
  const { note, baseNotePath } = useContext(NoteContext)
  const dictionary = useDictionary()

  return [
    {
      label: "Présentation",
      key: "presentation",
      icon: <HomeOutlined />,
      link: `${baseNotePath}/presentation`
    },
    /*{
      label: INFOS_PAGE_NAME,
      key: "infos",
      icon: <ProjectOutlined />,
      children: [
        {
          label: "Projet",
          key: "project",
          link: `${baseNotePath}/infos/project`
        },
        {
          label: "Structure",
          key: "structure",
          link: `${baseNotePath}/infos/structure`
        },
        {
          label: "Porteurs",
          key: "holders",
          link: `${baseNotePath}/infos/holders`
        }
      ]
    },*/
    {
      label: "Analyse",
      key: "analysis",
      icon: <PieChartOutlined />,
      children: [
        {
          label: "Vue d'ensemble",
          key: "overview",
          link: `${baseNotePath}/analysis/overview`
        },
        {
          label: "Environnement",
          key: "environment",
          link: `${baseNotePath}/analysis/environment`
        },
        {
          label: "Positionnement",
          key: "positioning",
          link: `${baseNotePath}/analysis/positioning`
        },
        {
          label: "Moyen",
          key: "means",
          link: `${baseNotePath}/analysis/means`
        },
        {
          label: "Équipe",
          key: "team",
          link: `${baseNotePath}/analysis/team`
        }
      ]
    },
    {
      label: "Révélateur d'engagement",
      key: "involvement-check",
      icon: <StarOutlined />,
      link: `${baseNotePath}/involvement-check`
    },
    {
      label: "Volet financier",
      key: "financial",
      icon: <TableOutlined />,
      children: [
        ...([NoteTypeEnum.REPRISE, NoteTypeEnum.STRUCTURE_EXISTANTE].includes(note.type)
          ? [
              {
                label: dictionary.bilan.ongletName,
                key: "past-balance-sheet",
                link: `${baseNotePath}/financial/past-balance-sheet`
              }
            ]
          : []),
        {
          label: "Plan de financement",
          key: "plan",
          link: `${baseNotePath}/financial/plan`
        },
        {
          label: dictionary.cr.ongletName,
          key: "income-statement",
          link: `${baseNotePath}/financial/income-statement`
        },
        {
          label: dictionary.operations.ongletName,
          key: "operations",
          link: `${baseNotePath}/financial/operations`
        },
        ...(note.type === NoteTypeEnum.STRUCTURE_EXISTANTE
          ? [
              {
                label: dictionary.pretsPasse.ongletName,
                key: "past-loans",
                link: `${baseNotePath}/financial/past-loans`
              }
            ]
          : []),
        {
          label: "BFR",
          key: "bfr",
          link: `${baseNotePath}/financial/bfr`
        },
        {
          label: "CAF",
          key: "caf",
          link: `${baseNotePath}/financial/caf`
        },
        {
          label: "Investissements",
          key: "investments",
          link: `${baseNotePath}/financial/investments`
        },
        {
          label: "Masse salariale",
          key: "salary-mass",
          link: `${baseNotePath}/financial/salary-mass`
        },
        {
          label: "Benchmark",
          key: "benchmark",
          link: `${baseNotePath}/financial/benchmark`
        },
        {
          label: "Paramètres",
          key: "settings",
          icon: <SettingOutlined />,
          link: `${baseNotePath}/financial/settings`
        }
      ]
    },
    {
      label: "Mémento",
      key: "memento",
      icon: <FilePdfOutlined />,
      link: `${baseNotePath}/memento`
    },
    {
      label: "Historique",
      key: "history",
      icon: <HistoryOutlined />,
      link: `${baseNotePath}/history`
    }
  ]
}
