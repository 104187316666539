/* tslint:disable */
/* eslint-disable */
/**
 * Appli métier
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: Yoda 1 & 2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface InvolvementQuestion
 */
export interface InvolvementQuestion {
  /**
   *
   * @type {number}
   * @memberof InvolvementQuestion
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof InvolvementQuestion
   */
  category: InvolvementQuestionCategoryEnum
  /**
   *
   * @type {string}
   * @memberof InvolvementQuestion
   */
  text: string
  /**
   *
   * @type {string}
   * @memberof InvolvementQuestion
   */
  description?: string
  /**
   *
   * @type {number}
   * @memberof InvolvementQuestion
   */
  questionVersion: number
  /**
   *
   * @type {string}
   * @memberof InvolvementQuestion
   */
  questionType: InvolvementQuestionQuestionTypeEnum
}

/**
 * @export
 * @enum {string}
 */
export enum InvolvementQuestionCategoryEnum {
  EMPLOYMENT = "EMPLOYMENT",
  TERRITORY = "TERRITORY",
  SOCIAL = "SOCIAL",
  ENVIRONMENT = "ENVIRONMENT",
  GOVERNANCE = "GOVERNANCE",
  OTHER = "OTHER"
}
/**
 * @export
 * @enum {string}
 */
export enum InvolvementQuestionQuestionTypeEnum {
  QUESTION = "QUESTION",
  EXCLUDE_CRITERIA = "EXCLUDE_CRITERIA",
  PREVENTION = "PREVENTION"
}
