/* tslint:disable */
/* eslint-disable */
/**
 * Appli métier
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: Yoda 1 & 2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { ApiComputedPret } from "./api-computed-pret"
import { ApiNumericData } from "./api-numeric-data"

/**
 *
 * @export
 * @interface ApiBankLoan
 */
export interface ApiBankLoan {
  /**
   *
   * @type {string}
   * @memberof ApiBankLoan
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof ApiBankLoan
   */
  amortizationPeriodicityCode: ApiBankLoanAmortizationPeriodicityCodeEnum
  /**
   *
   * @type {ApiNumericData}
   * @memberof ApiBankLoan
   */
  amount: ApiNumericData
  /**
   *
   * @type {number}
   * @memberof ApiBankLoan
   */
  delay: number
  /**
   *
   * @type {string}
   * @memberof ApiBankLoan
   */
  disbursementDate: string
  /**
   *
   * @type {number}
   * @memberof ApiBankLoan
   */
  duration: number
  /**
   *
   * @type {ApiNumericData}
   * @memberof ApiBankLoan
   */
  financialPlanStartup?: ApiNumericData
  /**
   *
   * @type {string}
   * @memberof ApiBankLoan
   */
  firstRepaymentDate: string
  /**
   *
   * @type {string}
   * @memberof ApiBankLoan
   */
  interestsPeriodicityCode?: ApiBankLoanInterestsPeriodicityCodeEnum
  /**
   *
   * @type {boolean}
   * @memberof ApiBankLoan
   */
  isInStartup: boolean
  /**
   *
   * @type {number}
   * @memberof ApiBankLoan
   */
  rate: number
  /**
   *
   * @type {boolean}
   * @memberof ApiBankLoan
   */
  showIsInStartupOption: boolean
  /**
   *
   * @type {string}
   * @memberof ApiBankLoan
   */
  title: string
  /**
   *
   * @type {string}
   * @memberof ApiBankLoan
   */
  financialPlanEntryId: string
  /**
   *
   * @type {ApiComputedPret}
   * @memberof ApiBankLoan
   */
  computedLoan?: ApiComputedPret
}

/**
 * @export
 * @enum {string}
 */
export enum ApiBankLoanAmortizationPeriodicityCodeEnum {
  MONTHLY = "MONTHLY",
  BIMONTHLY = "BIMONTHLY",
  QUARTERLY = "QUARTERLY",
  SEMI_ANNUAL = "SEMI_ANNUAL",
  ANNUAL = "ANNUAL",
  IN_FINE = "IN_FINE"
}
/**
 * @export
 * @enum {string}
 */
export enum ApiBankLoanInterestsPeriodicityCodeEnum {
  MONTHLY = "MONTHLY",
  BIMONTHLY = "BIMONTHLY",
  QUARTERLY = "QUARTERLY",
  SEMI_ANNUAL = "SEMI_ANNUAL",
  ANNUAL = "ANNUAL"
}
