/* tslint:disable */
/* eslint-disable */
/**
 * Appli métier
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: Yoda 1 & 2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from "axios"
import { Configuration } from "../configuration"
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction
} from "../common"
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from "../base"
// @ts-ignore
import { InvolvementProfileConfig } from "../model"
// @ts-ignore
import { InvolvementQuestion } from "../model"
// @ts-ignore
import { QuestionInvolvementSaveRequest } from "../model"
/**
 * InvolvementCheckResourceApi - axios parameter creator
 * @export
 */
export const InvolvementCheckResourceApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @summary Get question categories
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCategories: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/involvement_check/categories`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary Update involvement profiles
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProfiles: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/involvement_check/profiles`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary Get questions by note id filtered by category
     * @param {string} [noteId]
     * @param {'EMPLOYMENT' | 'TERRITORY' | 'SOCIAL' | 'ENVIRONMENT' | 'GOVERNANCE' | 'OTHER'} [category]
     * @param {boolean} [forceV2]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getQuestion: async (
      noteId?: string,
      category?: "EMPLOYMENT" | "TERRITORY" | "SOCIAL" | "ENVIRONMENT" | "GOVERNANCE" | "OTHER",
      forceV2?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/involvement_check/questions`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      if (noteId !== undefined) {
        localVarQueryParameter["noteId"] = noteId
      }

      if (category !== undefined) {
        localVarQueryParameter["category"] = category
      }

      if (forceV2 !== undefined) {
        localVarQueryParameter["forceV2"] = forceV2
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary Create a question
     * @param {QuestionInvolvementSaveRequest} questionInvolvementSaveRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    insertQuestion: async (
      questionInvolvementSaveRequest: QuestionInvolvementSaveRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'questionInvolvementSaveRequest' is not null or undefined
      assertParamExists(
        "insertQuestion",
        "questionInvolvementSaveRequest",
        questionInvolvementSaveRequest
      )
      const localVarPath = `/involvement_check/questions`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        questionInvolvementSaveRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary Update a profile\'s configuration
     * @param {'NO_INVOLVEMENT' | 'EMPLOYER' | 'TERRITORIAL' | 'SOCIAL' | 'ECOLO' | 'ETHICS' | 'OTHER' | 'MULTI_COMMITTED'} profile
     * @param {string} label
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setProfileConfig: async (
      profile:
        | "NO_INVOLVEMENT"
        | "EMPLOYER"
        | "TERRITORIAL"
        | "SOCIAL"
        | "ECOLO"
        | "ETHICS"
        | "OTHER"
        | "MULTI_COMMITTED",
      label: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'profile' is not null or undefined
      assertParamExists("setProfileConfig", "profile", profile)
      // verify required parameter 'label' is not null or undefined
      assertParamExists("setProfileConfig", "label", label)
      const localVarPath = `/involvement_check/profiles`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      if (profile !== undefined) {
        localVarQueryParameter["profile"] = profile
      }

      if (label !== undefined) {
        localVarQueryParameter["label"] = label
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary Update a question
     * @param {number} questionId
     * @param {QuestionInvolvementSaveRequest} questionInvolvementSaveRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateQuestion: async (
      questionId: number,
      questionInvolvementSaveRequest: QuestionInvolvementSaveRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'questionId' is not null or undefined
      assertParamExists("updateQuestion", "questionId", questionId)
      // verify required parameter 'questionInvolvementSaveRequest' is not null or undefined
      assertParamExists(
        "updateQuestion",
        "questionInvolvementSaveRequest",
        questionInvolvementSaveRequest
      )
      const localVarPath = `/involvement_check/questions/{questionId}`.replace(
        `{${"questionId"}}`,
        encodeURIComponent(String(questionId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        questionInvolvementSaveRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary Update multiple questions
     * @param {{ [key: string]: QuestionInvolvementSaveRequest; }} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateQuestions: async (
      requestBody: { [key: string]: QuestionInvolvementSaveRequest },
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'requestBody' is not null or undefined
      assertParamExists("updateQuestions", "requestBody", requestBody)
      const localVarPath = `/involvement_check/questions`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        requestBody,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * InvolvementCheckResourceApi - functional programming interface
 * @export
 */
export const InvolvementCheckResourceApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = InvolvementCheckResourceApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @summary Get question categories
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCategories(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCategories(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Update involvement profiles
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getProfiles(
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InvolvementProfileConfig>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getProfiles(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Get questions by note id filtered by category
     * @param {string} [noteId]
     * @param {'EMPLOYMENT' | 'TERRITORY' | 'SOCIAL' | 'ENVIRONMENT' | 'GOVERNANCE' | 'OTHER'} [category]
     * @param {boolean} [forceV2]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getQuestion(
      noteId?: string,
      category?: "EMPLOYMENT" | "TERRITORY" | "SOCIAL" | "ENVIRONMENT" | "GOVERNANCE" | "OTHER",
      forceV2?: boolean,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InvolvementQuestion>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getQuestion(
        noteId,
        category,
        forceV2,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Create a question
     * @param {QuestionInvolvementSaveRequest} questionInvolvementSaveRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async insertQuestion(
      questionInvolvementSaveRequest: QuestionInvolvementSaveRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvolvementQuestion>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.insertQuestion(
        questionInvolvementSaveRequest,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Update a profile\'s configuration
     * @param {'NO_INVOLVEMENT' | 'EMPLOYER' | 'TERRITORIAL' | 'SOCIAL' | 'ECOLO' | 'ETHICS' | 'OTHER' | 'MULTI_COMMITTED'} profile
     * @param {string} label
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async setProfileConfig(
      profile:
        | "NO_INVOLVEMENT"
        | "EMPLOYER"
        | "TERRITORIAL"
        | "SOCIAL"
        | "ECOLO"
        | "ETHICS"
        | "OTHER"
        | "MULTI_COMMITTED",
      label: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvolvementProfileConfig>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.setProfileConfig(
        profile,
        label,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Update a question
     * @param {number} questionId
     * @param {QuestionInvolvementSaveRequest} questionInvolvementSaveRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateQuestion(
      questionId: number,
      questionInvolvementSaveRequest: QuestionInvolvementSaveRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvolvementQuestion>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateQuestion(
        questionId,
        questionInvolvementSaveRequest,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Update multiple questions
     * @param {{ [key: string]: QuestionInvolvementSaveRequest; }} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateQuestions(
      requestBody: { [key: string]: QuestionInvolvementSaveRequest },
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InvolvementQuestion>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateQuestions(
        requestBody,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    }
  }
}

/**
 * InvolvementCheckResourceApi - factory interface
 * @export
 */
export const InvolvementCheckResourceApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = InvolvementCheckResourceApiFp(configuration)
  return {
    /**
     *
     * @summary Get question categories
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCategories(options?: any): AxiosPromise<Array<string>> {
      return localVarFp.getCategories(options).then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Update involvement profiles
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProfiles(options?: any): AxiosPromise<Array<InvolvementProfileConfig>> {
      return localVarFp.getProfiles(options).then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Get questions by note id filtered by category
     * @param {string} [noteId]
     * @param {'EMPLOYMENT' | 'TERRITORY' | 'SOCIAL' | 'ENVIRONMENT' | 'GOVERNANCE' | 'OTHER'} [category]
     * @param {boolean} [forceV2]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getQuestion(
      noteId?: string,
      category?: "EMPLOYMENT" | "TERRITORY" | "SOCIAL" | "ENVIRONMENT" | "GOVERNANCE" | "OTHER",
      forceV2?: boolean,
      options?: any
    ): AxiosPromise<Array<InvolvementQuestion>> {
      return localVarFp
        .getQuestion(noteId, category, forceV2, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Create a question
     * @param {QuestionInvolvementSaveRequest} questionInvolvementSaveRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    insertQuestion(
      questionInvolvementSaveRequest: QuestionInvolvementSaveRequest,
      options?: any
    ): AxiosPromise<InvolvementQuestion> {
      return localVarFp
        .insertQuestion(questionInvolvementSaveRequest, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Update a profile\'s configuration
     * @param {'NO_INVOLVEMENT' | 'EMPLOYER' | 'TERRITORIAL' | 'SOCIAL' | 'ECOLO' | 'ETHICS' | 'OTHER' | 'MULTI_COMMITTED'} profile
     * @param {string} label
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setProfileConfig(
      profile:
        | "NO_INVOLVEMENT"
        | "EMPLOYER"
        | "TERRITORIAL"
        | "SOCIAL"
        | "ECOLO"
        | "ETHICS"
        | "OTHER"
        | "MULTI_COMMITTED",
      label: string,
      options?: any
    ): AxiosPromise<InvolvementProfileConfig> {
      return localVarFp
        .setProfileConfig(profile, label, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Update a question
     * @param {number} questionId
     * @param {QuestionInvolvementSaveRequest} questionInvolvementSaveRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateQuestion(
      questionId: number,
      questionInvolvementSaveRequest: QuestionInvolvementSaveRequest,
      options?: any
    ): AxiosPromise<InvolvementQuestion> {
      return localVarFp
        .updateQuestion(questionId, questionInvolvementSaveRequest, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Update multiple questions
     * @param {{ [key: string]: QuestionInvolvementSaveRequest; }} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateQuestions(
      requestBody: { [key: string]: QuestionInvolvementSaveRequest },
      options?: any
    ): AxiosPromise<Array<InvolvementQuestion>> {
      return localVarFp
        .updateQuestions(requestBody, options)
        .then(request => request(axios, basePath))
    }
  }
}

/**
 * Request parameters for getQuestion operation in InvolvementCheckResourceApi.
 * @export
 * @interface InvolvementCheckResourceApiGetQuestionRequest
 */
export interface InvolvementCheckResourceApiGetQuestionRequest {
  /**
   *
   * @type {string}
   * @memberof InvolvementCheckResourceApiGetQuestion
   */
  readonly noteId?: string

  /**
   *
   * @type {'EMPLOYMENT' | 'TERRITORY' | 'SOCIAL' | 'ENVIRONMENT' | 'GOVERNANCE' | 'OTHER'}
   * @memberof InvolvementCheckResourceApiGetQuestion
   */
  readonly category?: "EMPLOYMENT" | "TERRITORY" | "SOCIAL" | "ENVIRONMENT" | "GOVERNANCE" | "OTHER"

  /**
   *
   * @type {boolean}
   * @memberof InvolvementCheckResourceApiGetQuestion
   */
  readonly forceV2?: boolean
}

/**
 * Request parameters for insertQuestion operation in InvolvementCheckResourceApi.
 * @export
 * @interface InvolvementCheckResourceApiInsertQuestionRequest
 */
export interface InvolvementCheckResourceApiInsertQuestionRequest {
  /**
   *
   * @type {QuestionInvolvementSaveRequest}
   * @memberof InvolvementCheckResourceApiInsertQuestion
   */
  readonly questionInvolvementSaveRequest: QuestionInvolvementSaveRequest
}

/**
 * Request parameters for setProfileConfig operation in InvolvementCheckResourceApi.
 * @export
 * @interface InvolvementCheckResourceApiSetProfileConfigRequest
 */
export interface InvolvementCheckResourceApiSetProfileConfigRequest {
  /**
   *
   * @type {'NO_INVOLVEMENT' | 'EMPLOYER' | 'TERRITORIAL' | 'SOCIAL' | 'ECOLO' | 'ETHICS' | 'OTHER' | 'MULTI_COMMITTED'}
   * @memberof InvolvementCheckResourceApiSetProfileConfig
   */
  readonly profile:
    | "NO_INVOLVEMENT"
    | "EMPLOYER"
    | "TERRITORIAL"
    | "SOCIAL"
    | "ECOLO"
    | "ETHICS"
    | "OTHER"
    | "MULTI_COMMITTED"

  /**
   *
   * @type {string}
   * @memberof InvolvementCheckResourceApiSetProfileConfig
   */
  readonly label: string
}

/**
 * Request parameters for updateQuestion operation in InvolvementCheckResourceApi.
 * @export
 * @interface InvolvementCheckResourceApiUpdateQuestionRequest
 */
export interface InvolvementCheckResourceApiUpdateQuestionRequest {
  /**
   *
   * @type {number}
   * @memberof InvolvementCheckResourceApiUpdateQuestion
   */
  readonly questionId: number

  /**
   *
   * @type {QuestionInvolvementSaveRequest}
   * @memberof InvolvementCheckResourceApiUpdateQuestion
   */
  readonly questionInvolvementSaveRequest: QuestionInvolvementSaveRequest
}

/**
 * Request parameters for updateQuestions operation in InvolvementCheckResourceApi.
 * @export
 * @interface InvolvementCheckResourceApiUpdateQuestionsRequest
 */
export interface InvolvementCheckResourceApiUpdateQuestionsRequest {
  /**
   *
   * @type {{ [key: string]: QuestionInvolvementSaveRequest; }}
   * @memberof InvolvementCheckResourceApiUpdateQuestions
   */
  readonly requestBody: { [key: string]: QuestionInvolvementSaveRequest }
}

/**
 * InvolvementCheckResourceApi - object-oriented interface
 * @export
 * @class InvolvementCheckResourceApi
 * @extends {BaseAPI}
 */
export class InvolvementCheckResourceApi extends BaseAPI {
  /**
   *
   * @summary Get question categories
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InvolvementCheckResourceApi
   */
  public getCategories(options?: AxiosRequestConfig) {
    return InvolvementCheckResourceApiFp(this.configuration)
      .getCategories(options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Update involvement profiles
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InvolvementCheckResourceApi
   */
  public getProfiles(options?: AxiosRequestConfig) {
    return InvolvementCheckResourceApiFp(this.configuration)
      .getProfiles(options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get questions by note id filtered by category
   * @param {InvolvementCheckResourceApiGetQuestionRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InvolvementCheckResourceApi
   */
  public getQuestion(
    requestParameters: InvolvementCheckResourceApiGetQuestionRequest = {},
    options?: AxiosRequestConfig
  ) {
    return InvolvementCheckResourceApiFp(this.configuration)
      .getQuestion(
        requestParameters.noteId,
        requestParameters.category,
        requestParameters.forceV2,
        options
      )
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Create a question
   * @param {InvolvementCheckResourceApiInsertQuestionRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InvolvementCheckResourceApi
   */
  public insertQuestion(
    requestParameters: InvolvementCheckResourceApiInsertQuestionRequest,
    options?: AxiosRequestConfig
  ) {
    return InvolvementCheckResourceApiFp(this.configuration)
      .insertQuestion(requestParameters.questionInvolvementSaveRequest, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Update a profile\'s configuration
   * @param {InvolvementCheckResourceApiSetProfileConfigRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InvolvementCheckResourceApi
   */
  public setProfileConfig(
    requestParameters: InvolvementCheckResourceApiSetProfileConfigRequest,
    options?: AxiosRequestConfig
  ) {
    return InvolvementCheckResourceApiFp(this.configuration)
      .setProfileConfig(requestParameters.profile, requestParameters.label, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Update a question
   * @param {InvolvementCheckResourceApiUpdateQuestionRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InvolvementCheckResourceApi
   */
  public updateQuestion(
    requestParameters: InvolvementCheckResourceApiUpdateQuestionRequest,
    options?: AxiosRequestConfig
  ) {
    return InvolvementCheckResourceApiFp(this.configuration)
      .updateQuestion(
        requestParameters.questionId,
        requestParameters.questionInvolvementSaveRequest,
        options
      )
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Update multiple questions
   * @param {InvolvementCheckResourceApiUpdateQuestionsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InvolvementCheckResourceApi
   */
  public updateQuestions(
    requestParameters: InvolvementCheckResourceApiUpdateQuestionsRequest,
    options?: AxiosRequestConfig
  ) {
    return InvolvementCheckResourceApiFp(this.configuration)
      .updateQuestions(requestParameters.requestBody, options)
      .then(request => request(this.axios, this.basePath))
  }
}
