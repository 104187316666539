import React from "react"
import { Radio, Tooltip } from "antd"
import { SizeType } from "antd/es/config-provider/SizeContext"
import { CheckboxOptionType } from "antd/es/checkbox/Group"

export interface ScoreOptionType extends CheckboxOptionType {
  tooltip?: string
}

interface ScoreInputProps<T = string | number> {
  score?: T
  onChange: (score?: T) => void
  options: Array<ScoreOptionType>
  size?: SizeType
}

export const ScoreInput = <T extends string | number>(props: ScoreInputProps<T>) => {
  const { score, onChange, options, size } = props
  return (
    <Radio.Group
      defaultValue={score}
      size={size}
      onChange={e => onChange(e.target.value)}
      optionType="button"
      buttonStyle="solid"
    >
      {options.map((scoreOption, index) => (
        <Tooltip key={index} placement="bottom" title={scoreOption.tooltip}>
          <Radio.Button value={scoreOption.value} disabled={scoreOption.disabled}>
            {scoreOption.label}
          </Radio.Button>
        </Tooltip>
      ))}
    </Radio.Group>
  )
}
