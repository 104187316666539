import React from "react"
import { Query } from "@tanstack/query-core/src/query"
import { Mutation, QueryKey } from "@tanstack/react-query"
import axios from "axios"
import { AppMetierToaster } from "../components/AppMetierToaster/AppMetierToaster"
import { MessageInstance } from "antd/es/message/interface"

export const DEFAULT_ERROR_MESSAGE: string = "Un problème est survenu"

const REACT_QUERY_MESSAGE_KEY = "react-query-message"

export const DEFAULT_QUERY_RESULT = {
  data: undefined,
  isLoading: false
}

export const handleReactQueryMutationLoader = (
  mutation: Mutation<unknown, unknown, unknown>,
  messageApi: MessageInstance
) => {
  if (!mutation.meta?.disableMutationLoader) {
    messageApi.open({
      key: REACT_QUERY_MESSAGE_KEY,
      type: "loading",
      content: "",
      className: REACT_QUERY_MESSAGE_KEY,
      duration: 0
    })
  }
}

export const handleReactQuerySuccess = (
  mutation: Mutation<unknown, unknown, unknown>,
  messageApi: MessageInstance
) => {
  if (!mutation.meta?.disableMutationLoader) {
    messageApi.open({
      key: REACT_QUERY_MESSAGE_KEY,
      type: "success",
      content: "",
      duration: 1,
      className: REACT_QUERY_MESSAGE_KEY
    })
  }
}

export const handleReactQueryError = (
  error: unknown,
  queryOrMutation: Query<unknown, unknown, unknown, QueryKey> | Mutation<unknown, unknown, unknown>,
  messageApi: MessageInstance
) => {
  if (!axios.isAxiosError(error)) {
    messageApi.open({
      type: "error",
      key: REACT_QUERY_MESSAGE_KEY,
      content: <AppMetierToaster baseMessage={DEFAULT_ERROR_MESSAGE} />
    })
    return
  }

  // eslint-disable-next-line
  // @ts-ignore
  const queryErrorMessage: string | undefined = queryOrMutation.meta?.errorMessage?.toString()
  const baseErrorMessage = queryErrorMessage ?? DEFAULT_ERROR_MESSAGE

  const response = error.response

  if (error.code === "ERR_NETWORK") {
    messageApi.open({
      type: "error",
      key: REACT_QUERY_MESSAGE_KEY,
      content: (
        <AppMetierToaster
          baseMessage={baseErrorMessage}
          detailMessage="Le serveur est injoignable. Veuillez réessayer ultérieurement."
        />
      )
    })
    return
  }
  if (error.code === "ERR_CANCELED") {
    messageApi.open({
      type: "error",
      key: REACT_QUERY_MESSAGE_KEY,
      content: (
        <AppMetierToaster
          baseMessage={baseErrorMessage}
          detailMessage="La connexion au serveur a été interrompue. Veuillez réessayer ultérieurement."
        />
      )
    })
    return
  }
  if (response) {
    const statusCode = response?.status
    let errorMessage = response?.data.message ?? undefined
    if (errorMessage == undefined) {
      switch (statusCode) {
        case 400:
          errorMessage = "La requête n'est pas valide"
          break
        case 401:
          errorMessage = "Vous n'êtes pas autorisé à accéder à cette ressource"
          break
        case 403:
          errorMessage = "Vous n'avez pas les droits suffisants pour accéder à cette ressource"
          break
        case 404:
          errorMessage = "La ressource n'existe pas"
          break
        case 500:
          errorMessage = "Une erreur interne est survenue"
          break
        default:
          break
      }
    }

    messageApi.open({
      type: "error",
      key: REACT_QUERY_MESSAGE_KEY,
      content: <AppMetierToaster baseMessage={baseErrorMessage} detailMessage={errorMessage} />
    })
    return
  }
}
