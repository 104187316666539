/* tslint:disable */
/* eslint-disable */
/**
 * Appli métier
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: Yoda 1 & 2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from "axios"
import { Configuration } from "../configuration"
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction
} from "../common"
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from "../base"
// @ts-ignore
import { ApiPlanFinancement } from "../model"
// @ts-ignore
import { PlanFinancementInput } from "../model"
/**
 * PlanFinancementResourceV2Api - axios parameter creator
 * @export
 */
export const PlanFinancementResourceV2ApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @summary Add a new entry to financial plan
     * @param {string} noteId
     * @param {'BESOINS' | 'APPORTS' | 'PRIMES' | 'SUBVENTIONS_INVESTISSEMENTS' | 'PRETS_AT' | 'PRETS_HORS_AT_GARANTIS' | 'PRETS_HORS_AT_NON_GARANTIS' | 'CREDIT_RELAIS_TVA'} type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addNewEntry: async (
      noteId: string,
      type:
        | "BESOINS"
        | "APPORTS"
        | "PRIMES"
        | "SUBVENTIONS_INVESTISSEMENTS"
        | "PRETS_AT"
        | "PRETS_HORS_AT_GARANTIS"
        | "PRETS_HORS_AT_NON_GARANTIS"
        | "CREDIT_RELAIS_TVA",
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'noteId' is not null or undefined
      assertParamExists("addNewEntry", "noteId", noteId)
      // verify required parameter 'type' is not null or undefined
      assertParamExists("addNewEntry", "type", type)
      const localVarPath = `/v2.0/planFinancement/{noteId}/entry/{type}`
        .replace(`{${"noteId"}}`, encodeURIComponent(String(noteId)))
        .replace(`{${"type"}}`, encodeURIComponent(String(type)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary Delete an entry from financial plan
     * @param {string} noteId
     * @param {string} entryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteEntry: async (
      noteId: string,
      entryId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'noteId' is not null or undefined
      assertParamExists("deleteEntry", "noteId", noteId)
      // verify required parameter 'entryId' is not null or undefined
      assertParamExists("deleteEntry", "entryId", entryId)
      const localVarPath = `/v2.0/planFinancement/{noteId}/entry/{entryId}`
        .replace(`{${"noteId"}}`, encodeURIComponent(String(noteId)))
        .replace(`{${"entryId"}}`, encodeURIComponent(String(entryId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary Delete financial plan by note id
     * @param {string} noteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deletePlanFinancement: async (
      noteId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'noteId' is not null or undefined
      assertParamExists("deletePlanFinancement", "noteId", noteId)
      const localVarPath = `/v2.0/planFinancement/{noteId}`.replace(
        `{${"noteId"}}`,
        encodeURIComponent(String(noteId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary Get financial plan by note id
     * @param {string} noteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    retrievePlanFinancement: async (
      noteId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'noteId' is not null or undefined
      assertParamExists("retrievePlanFinancement", "noteId", noteId)
      const localVarPath = `/v2.0/planFinancement/{noteId}`.replace(
        `{${"noteId"}}`,
        encodeURIComponent(String(noteId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary Update financial plan
     * @param {PlanFinancementInput} planFinancementInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePlanFinancement: async (
      planFinancementInput: PlanFinancementInput,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'planFinancementInput' is not null or undefined
      assertParamExists("updatePlanFinancement", "planFinancementInput", planFinancementInput)
      const localVarPath = `/v2.0/planFinancement`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        planFinancementInput,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * PlanFinancementResourceV2Api - functional programming interface
 * @export
 */
export const PlanFinancementResourceV2ApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = PlanFinancementResourceV2ApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @summary Add a new entry to financial plan
     * @param {string} noteId
     * @param {'BESOINS' | 'APPORTS' | 'PRIMES' | 'SUBVENTIONS_INVESTISSEMENTS' | 'PRETS_AT' | 'PRETS_HORS_AT_GARANTIS' | 'PRETS_HORS_AT_NON_GARANTIS' | 'CREDIT_RELAIS_TVA'} type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addNewEntry(
      noteId: string,
      type:
        | "BESOINS"
        | "APPORTS"
        | "PRIMES"
        | "SUBVENTIONS_INVESTISSEMENTS"
        | "PRETS_AT"
        | "PRETS_HORS_AT_GARANTIS"
        | "PRETS_HORS_AT_NON_GARANTIS"
        | "CREDIT_RELAIS_TVA",
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiPlanFinancement>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.addNewEntry(noteId, type, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Delete an entry from financial plan
     * @param {string} noteId
     * @param {string} entryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteEntry(
      noteId: string,
      entryId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiPlanFinancement>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteEntry(
        noteId,
        entryId,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Delete financial plan by note id
     * @param {string} noteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deletePlanFinancement(
      noteId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deletePlanFinancement(
        noteId,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Get financial plan by note id
     * @param {string} noteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async retrievePlanFinancement(
      noteId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiPlanFinancement>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.retrievePlanFinancement(
        noteId,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Update financial plan
     * @param {PlanFinancementInput} planFinancementInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updatePlanFinancement(
      planFinancementInput: PlanFinancementInput,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiPlanFinancement>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updatePlanFinancement(
        planFinancementInput,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    }
  }
}

/**
 * PlanFinancementResourceV2Api - factory interface
 * @export
 */
export const PlanFinancementResourceV2ApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = PlanFinancementResourceV2ApiFp(configuration)
  return {
    /**
     *
     * @summary Add a new entry to financial plan
     * @param {string} noteId
     * @param {'BESOINS' | 'APPORTS' | 'PRIMES' | 'SUBVENTIONS_INVESTISSEMENTS' | 'PRETS_AT' | 'PRETS_HORS_AT_GARANTIS' | 'PRETS_HORS_AT_NON_GARANTIS' | 'CREDIT_RELAIS_TVA'} type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addNewEntry(
      noteId: string,
      type:
        | "BESOINS"
        | "APPORTS"
        | "PRIMES"
        | "SUBVENTIONS_INVESTISSEMENTS"
        | "PRETS_AT"
        | "PRETS_HORS_AT_GARANTIS"
        | "PRETS_HORS_AT_NON_GARANTIS"
        | "CREDIT_RELAIS_TVA",
      options?: any
    ): AxiosPromise<ApiPlanFinancement> {
      return localVarFp.addNewEntry(noteId, type, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Delete an entry from financial plan
     * @param {string} noteId
     * @param {string} entryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteEntry(noteId: string, entryId: string, options?: any): AxiosPromise<ApiPlanFinancement> {
      return localVarFp
        .deleteEntry(noteId, entryId, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Delete financial plan by note id
     * @param {string} noteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deletePlanFinancement(noteId: string, options?: any): AxiosPromise<boolean> {
      return localVarFp
        .deletePlanFinancement(noteId, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Get financial plan by note id
     * @param {string} noteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    retrievePlanFinancement(noteId: string, options?: any): AxiosPromise<ApiPlanFinancement> {
      return localVarFp
        .retrievePlanFinancement(noteId, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Update financial plan
     * @param {PlanFinancementInput} planFinancementInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePlanFinancement(
      planFinancementInput: PlanFinancementInput,
      options?: any
    ): AxiosPromise<ApiPlanFinancement> {
      return localVarFp
        .updatePlanFinancement(planFinancementInput, options)
        .then(request => request(axios, basePath))
    }
  }
}

/**
 * Request parameters for addNewEntry operation in PlanFinancementResourceV2Api.
 * @export
 * @interface PlanFinancementResourceV2ApiAddNewEntryRequest
 */
export interface PlanFinancementResourceV2ApiAddNewEntryRequest {
  /**
   *
   * @type {string}
   * @memberof PlanFinancementResourceV2ApiAddNewEntry
   */
  readonly noteId: string

  /**
   *
   * @type {'BESOINS' | 'APPORTS' | 'PRIMES' | 'SUBVENTIONS_INVESTISSEMENTS' | 'PRETS_AT' | 'PRETS_HORS_AT_GARANTIS' | 'PRETS_HORS_AT_NON_GARANTIS' | 'CREDIT_RELAIS_TVA'}
   * @memberof PlanFinancementResourceV2ApiAddNewEntry
   */
  readonly type:
    | "BESOINS"
    | "APPORTS"
    | "PRIMES"
    | "SUBVENTIONS_INVESTISSEMENTS"
    | "PRETS_AT"
    | "PRETS_HORS_AT_GARANTIS"
    | "PRETS_HORS_AT_NON_GARANTIS"
    | "CREDIT_RELAIS_TVA"
}

/**
 * Request parameters for deleteEntry operation in PlanFinancementResourceV2Api.
 * @export
 * @interface PlanFinancementResourceV2ApiDeleteEntryRequest
 */
export interface PlanFinancementResourceV2ApiDeleteEntryRequest {
  /**
   *
   * @type {string}
   * @memberof PlanFinancementResourceV2ApiDeleteEntry
   */
  readonly noteId: string

  /**
   *
   * @type {string}
   * @memberof PlanFinancementResourceV2ApiDeleteEntry
   */
  readonly entryId: string
}

/**
 * Request parameters for deletePlanFinancement operation in PlanFinancementResourceV2Api.
 * @export
 * @interface PlanFinancementResourceV2ApiDeletePlanFinancementRequest
 */
export interface PlanFinancementResourceV2ApiDeletePlanFinancementRequest {
  /**
   *
   * @type {string}
   * @memberof PlanFinancementResourceV2ApiDeletePlanFinancement
   */
  readonly noteId: string
}

/**
 * Request parameters for retrievePlanFinancement operation in PlanFinancementResourceV2Api.
 * @export
 * @interface PlanFinancementResourceV2ApiRetrievePlanFinancementRequest
 */
export interface PlanFinancementResourceV2ApiRetrievePlanFinancementRequest {
  /**
   *
   * @type {string}
   * @memberof PlanFinancementResourceV2ApiRetrievePlanFinancement
   */
  readonly noteId: string
}

/**
 * Request parameters for updatePlanFinancement operation in PlanFinancementResourceV2Api.
 * @export
 * @interface PlanFinancementResourceV2ApiUpdatePlanFinancementRequest
 */
export interface PlanFinancementResourceV2ApiUpdatePlanFinancementRequest {
  /**
   *
   * @type {PlanFinancementInput}
   * @memberof PlanFinancementResourceV2ApiUpdatePlanFinancement
   */
  readonly planFinancementInput: PlanFinancementInput
}

/**
 * PlanFinancementResourceV2Api - object-oriented interface
 * @export
 * @class PlanFinancementResourceV2Api
 * @extends {BaseAPI}
 */
export class PlanFinancementResourceV2Api extends BaseAPI {
  /**
   *
   * @summary Add a new entry to financial plan
   * @param {PlanFinancementResourceV2ApiAddNewEntryRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlanFinancementResourceV2Api
   */
  public addNewEntry(
    requestParameters: PlanFinancementResourceV2ApiAddNewEntryRequest,
    options?: AxiosRequestConfig
  ) {
    return PlanFinancementResourceV2ApiFp(this.configuration)
      .addNewEntry(requestParameters.noteId, requestParameters.type, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Delete an entry from financial plan
   * @param {PlanFinancementResourceV2ApiDeleteEntryRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlanFinancementResourceV2Api
   */
  public deleteEntry(
    requestParameters: PlanFinancementResourceV2ApiDeleteEntryRequest,
    options?: AxiosRequestConfig
  ) {
    return PlanFinancementResourceV2ApiFp(this.configuration)
      .deleteEntry(requestParameters.noteId, requestParameters.entryId, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Delete financial plan by note id
   * @param {PlanFinancementResourceV2ApiDeletePlanFinancementRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlanFinancementResourceV2Api
   */
  public deletePlanFinancement(
    requestParameters: PlanFinancementResourceV2ApiDeletePlanFinancementRequest,
    options?: AxiosRequestConfig
  ) {
    return PlanFinancementResourceV2ApiFp(this.configuration)
      .deletePlanFinancement(requestParameters.noteId, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get financial plan by note id
   * @param {PlanFinancementResourceV2ApiRetrievePlanFinancementRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlanFinancementResourceV2Api
   */
  public retrievePlanFinancement(
    requestParameters: PlanFinancementResourceV2ApiRetrievePlanFinancementRequest,
    options?: AxiosRequestConfig
  ) {
    return PlanFinancementResourceV2ApiFp(this.configuration)
      .retrievePlanFinancement(requestParameters.noteId, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Update financial plan
   * @param {PlanFinancementResourceV2ApiUpdatePlanFinancementRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PlanFinancementResourceV2Api
   */
  public updatePlanFinancement(
    requestParameters: PlanFinancementResourceV2ApiUpdatePlanFinancementRequest,
    options?: AxiosRequestConfig
  ) {
    return PlanFinancementResourceV2ApiFp(this.configuration)
      .updatePlanFinancement(requestParameters.planFinancementInput, options)
      .then(request => request(this.axios, this.basePath))
  }
}
