import { LoadingOutlined } from "@ant-design/icons"
import { Spin, Typography } from "antd"

import "./Loader.scss"
import React from "react"

interface LoaderProps {
  label?: string
  spinnerSize?: number
}
export default function Loader({ label = "", spinnerSize = 75 }: LoaderProps) {
  return (
    <div className="loader">
      <div className="wrapper" style={{ height: spinnerSize, width: spinnerSize }}>
        <img
          src={require("../../assets/yoda-icon.png")}
          alt="Logo YODA"
          style={{ width: spinnerSize / 1.5 }}
        />
        <Spin
          indicator={<LoadingOutlined style={{ fontSize: spinnerSize }} />}
          className="spinner"
        />
      </div>
      <Typography.Text type="secondary">{label}</Typography.Text>
    </div>
  )
}
