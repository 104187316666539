/* tslint:disable */
/* eslint-disable */
/**
 * Appli métier
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: Yoda 1 & 2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from "axios"
import { Configuration } from "../configuration"
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction
} from "../common"
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from "../base"
// @ts-ignore
import { Agency } from "../model"
// @ts-ignore
import { Bank } from "../model"
/**
 * FundingResourceApi - axios parameter creator
 * @export
 */
export const FundingResourceApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get agencies
     * @param {string} [bankCode]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAgencies: async (
      bankCode?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/v2.0/funding/agency`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      if (bankCode !== undefined) {
        localVarQueryParameter["bankCode"] = bankCode
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary Get agency by id
     * @param {string} agencyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAgency: async (agencyId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'agencyId' is not null or undefined
      assertParamExists("getAgency", "agencyId", agencyId)
      const localVarPath = `/v2.0/funding/bank-partner/{agencyId}`.replace(
        `{${"agencyId"}}`,
        encodeURIComponent(String(agencyId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary Get bank partner by id
     * @param {string} bankId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBankPartner: async (
      bankId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'bankId' is not null or undefined
      assertParamExists("getBankPartner", "bankId", bankId)
      const localVarPath = `/v2.0/funding/bank-partner/{bankId}`.replace(
        `{${"bankId"}}`,
        encodeURIComponent(String(bankId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary Get bank partners
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBankPartners: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/v2.0/funding/bank-partner`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * FundingResourceApi - functional programming interface
 * @export
 */
export const FundingResourceApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = FundingResourceApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @summary Get agencies
     * @param {string} [bankCode]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAgencies(
      bankCode?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Agency>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAgencies(bankCode, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Get agency by id
     * @param {string} agencyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAgency(
      agencyId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Agency>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAgency(agencyId, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Get bank partner by id
     * @param {string} bankId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBankPartner(
      bankId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Bank>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBankPartner(bankId, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Get bank partners
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBankPartners(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Bank>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBankPartners(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    }
  }
}

/**
 * FundingResourceApi - factory interface
 * @export
 */
export const FundingResourceApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = FundingResourceApiFp(configuration)
  return {
    /**
     *
     * @summary Get agencies
     * @param {string} [bankCode]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAgencies(bankCode?: string, options?: any): AxiosPromise<Array<Agency>> {
      return localVarFp.getAgencies(bankCode, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Get agency by id
     * @param {string} agencyId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAgency(agencyId: string, options?: any): AxiosPromise<Agency> {
      return localVarFp.getAgency(agencyId, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Get bank partner by id
     * @param {string} bankId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBankPartner(bankId: string, options?: any): AxiosPromise<Bank> {
      return localVarFp.getBankPartner(bankId, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Get bank partners
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBankPartners(options?: any): AxiosPromise<Array<Bank>> {
      return localVarFp.getBankPartners(options).then(request => request(axios, basePath))
    }
  }
}

/**
 * Request parameters for getAgencies operation in FundingResourceApi.
 * @export
 * @interface FundingResourceApiGetAgenciesRequest
 */
export interface FundingResourceApiGetAgenciesRequest {
  /**
   *
   * @type {string}
   * @memberof FundingResourceApiGetAgencies
   */
  readonly bankCode?: string
}

/**
 * Request parameters for getAgency operation in FundingResourceApi.
 * @export
 * @interface FundingResourceApiGetAgencyRequest
 */
export interface FundingResourceApiGetAgencyRequest {
  /**
   *
   * @type {string}
   * @memberof FundingResourceApiGetAgency
   */
  readonly agencyId: string
}

/**
 * Request parameters for getBankPartner operation in FundingResourceApi.
 * @export
 * @interface FundingResourceApiGetBankPartnerRequest
 */
export interface FundingResourceApiGetBankPartnerRequest {
  /**
   *
   * @type {string}
   * @memberof FundingResourceApiGetBankPartner
   */
  readonly bankId: string
}

/**
 * FundingResourceApi - object-oriented interface
 * @export
 * @class FundingResourceApi
 * @extends {BaseAPI}
 */
export class FundingResourceApi extends BaseAPI {
  /**
   *
   * @summary Get agencies
   * @param {FundingResourceApiGetAgenciesRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FundingResourceApi
   */
  public getAgencies(
    requestParameters: FundingResourceApiGetAgenciesRequest = {},
    options?: AxiosRequestConfig
  ) {
    return FundingResourceApiFp(this.configuration)
      .getAgencies(requestParameters.bankCode, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get agency by id
   * @param {FundingResourceApiGetAgencyRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FundingResourceApi
   */
  public getAgency(
    requestParameters: FundingResourceApiGetAgencyRequest,
    options?: AxiosRequestConfig
  ) {
    return FundingResourceApiFp(this.configuration)
      .getAgency(requestParameters.agencyId, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get bank partner by id
   * @param {FundingResourceApiGetBankPartnerRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FundingResourceApi
   */
  public getBankPartner(
    requestParameters: FundingResourceApiGetBankPartnerRequest,
    options?: AxiosRequestConfig
  ) {
    return FundingResourceApiFp(this.configuration)
      .getBankPartner(requestParameters.bankId, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Get bank partners
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FundingResourceApi
   */
  public getBankPartners(options?: AxiosRequestConfig) {
    return FundingResourceApiFp(this.configuration)
      .getBankPartners(options)
      .then(request => request(this.axios, this.basePath))
  }
}
