/* tslint:disable */
/* eslint-disable */
/**
 * Appli métier
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: Yoda 1 & 2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Rating } from "./rating"

/**
 *
 * @export
 * @interface Question
 */
export interface Question {
  /**
   *
   * @type {string}
   * @memberof Question
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof Question
   */
  category: QuestionCategoryEnum
  /**
   *
   * @type {string}
   * @memberof Question
   */
  text: string
  /**
   *
   * @type {string}
   * @memberof Question
   */
  description?: string
  /**
   *
   * @type {Array<Rating>}
   * @memberof Question
   */
  ratings: Array<Rating>
  /**
   *
   * @type {number}
   * @memberof Question
   */
  questionOrder: number
  /**
   *
   * @type {string}
   * @memberof Question
   */
  categoryGroup: QuestionCategoryGroupEnum
}

/**
 * @export
 * @enum {string}
 */
export enum QuestionCategoryEnum {
  MARKET_DEMAND = "MARKET_DEMAND",
  MARKET_SUPPLY = "MARKET_SUPPLY",
  COMPETITORS = "COMPETITORS",
  ENVIRONMENT_SYNTHESIS = "ENVIRONMENT_SYNTHESIS",
  PRODUCT_OR_SERVICE = "PRODUCT_OR_SERVICE",
  COMMERCIAL_STRATEGY = "COMMERCIAL_STRATEGY",
  POSITIONING_SYNTHESIS = "POSITIONING_SYNTHESIS",
  GOVERNANCE = "GOVERNANCE",
  OTHER_MEANS = "OTHER_MEANS",
  MEANS_SYNTHESIS = "MEANS_SYNTHESIS",
  MOTIVATIONS = "MOTIVATIONS",
  ANALYZE_MOTIVATIONS = "ANALYZE_MOTIVATIONS",
  ANALYZE_FINANCES = "ANALYZE_FINANCES",
  SAVINGS = "SAVINGS",
  SYNTHESIS = "SYNTHESIS"
}
/**
 * @export
 * @enum {string}
 */
export enum QuestionCategoryGroupEnum {
  ENVIRONMENT = "ENVIRONMENT",
  POSITION = "POSITION",
  MEANS = "MEANS",
  ENTREPRENEUR = "ENTREPRENEUR"
}
