import { useAppContext } from "../config/context"
import { useQuery } from "@tanstack/react-query"
import { extract } from "../client/backend-client/AxiosUtils"
import { useHeaders } from "./axios-headers"
import { RegisAssociation } from "../client/backend-client/generated"

export const useGetAssociations = () => {
  const { associationResourceV2Api } = useAppContext()
  const getHeaders = useHeaders()

  const { isLoading, data } = useQuery({
    queryKey: ["associations"],
    queryFn: async () => {
      const { headers } = await getHeaders()
      return associationResourceV2Api.getAssociations({ headers }).then(extract)
    },
    retry: 0,
    meta: {
      errorMessage: `Erreur lors de la récupération des associations territoriales`
    }
  })

  return {
    isLoading,
    data
  }
}

export const useGetAssociation = (associationId: string) => {
  const { associationResourceV2Api } = useAppContext()
  const getHeaders = useHeaders()

  const { isLoading, data } = useQuery({
    queryKey: ["association", associationId],
    queryFn: async () => {
      const { headers } = await getHeaders()
      return associationResourceV2Api.getAssociation({ associationId }, { headers }).then(extract)
    },
    retry: 0,
    meta: {
      errorMessage: `Erreur lors de la récupération de l'association territoriale ${associationId}`
    }
  })

  return {
    isLoading,
    data
  }
}

export const useGetCollaborators = (associationId: string) => {
  const { associationResourceV2Api } = useAppContext()
  const getHeaders = useHeaders()

  const { isLoading, data } = useQuery({
    queryKey: ["collaborateurs", associationId],
    queryFn: async () => {
      const { headers } = await getHeaders()
      return associationResourceV2Api
        .getAssociationCollaborators({ associationId }, { headers })
        .then(extract)
    },
    retry: 0,
    meta: {
      errorMessage: `Erreur lors de la récupération des collaborateurs de l'association territoriale ${associationId}`
    }
  })

  return {
    isLoading,
    data
  }
}

export const sortAssociationByName = (a: RegisAssociation, b: RegisAssociation): number =>
  a.name.localeCompare(b.name)
