import React, { CSSProperties, FC, useContext, useState } from "react"
import { TreeSelect } from "antd"
import { NoteLifecycleEnum } from "../../../client/backend-client/generated"
import {
  getColumnsFromLifecycle,
  LifecycleChangeDialog
} from "../../presentation/overview/LifecycleChangeDialog"
import {
  useUpdateCreationToCreationExNihilo,
  useUpdateLifecycle
} from "../../../queries/NoteQueries"
import { ProjectContext } from "../../ProjectWrapper"

export const ProjectLifecycleSelector: FC<{
  noteId: string
  lifecycle: NoteLifecycleEnum
  creationExNihilo?: boolean
  style?: CSSProperties
}> = ({ noteId, lifecycle, creationExNihilo, style }) => {
  const { projectId } = useContext(ProjectContext)
  const [targetLifecycle, setTargetLifecycle] = useState<NoteLifecycleEnum>(lifecycle)
  const [targetExNihilo, setTargetExNihilo] = useState<boolean | undefined>(undefined)
  const [isOpenModalChangeLifecycle, setOpenModalChangeLifecycle] = useState(false)

  const updateLifecycle = useUpdateLifecycle(noteId, projectId, lifecycle, targetLifecycle)
  const updateCreationExNihiloMutation = useUpdateCreationToCreationExNihilo(noteId)

  const onChangeExNihilo = (newValue: boolean): void => {
    updateCreationExNihiloMutation.mutate(newValue, {
      onSuccess: () => setOpenModalChangeLifecycle(false)
    })
  }

  const changeLifecycle = () => {
    updateLifecycle.mutate(targetExNihilo, {
      onSuccess: () => setOpenModalChangeLifecycle(false)
    })
  }

  const onChangeLifeCycle = (): void => {
    if (
      lifecycle === NoteLifecycleEnum.CREATION &&
      targetLifecycle === NoteLifecycleEnum.CREATION
    ) {
      onChangeExNihilo(!creationExNihilo)
    } else {
      changeLifecycle()
    }
  }

  const handleAddedPanelData = () => {
    return getAddedColumnForLifecycleChange(
      lifecycle.concat(creationExNihilo ? "_EX_NIHILO" : ""),
      targetLifecycle.concat(targetExNihilo ? "_EX_NIHILO" : "")
    )
  }

  const handleRemovedPanelData = () => {
    return getRemovedColumnForLifecycleChange(
      lifecycle.concat(creationExNihilo ? "_EX_NIHILO" : ""),
      targetLifecycle.concat(targetExNihilo ? "_EX_NIHILO" : "")
    ).concat(["Le score d'octroi calculé"])
  }

  return (
    <>
      <TreeSelect
        value={getLifecycle(lifecycle)}
        style={style ?? { width: "100%" }}
        treeDefaultExpandAll
        disabled={!isLifecycleSwitchEnabled(lifecycle)}
        treeData={buildTreeData(lifecycle, creationExNihilo)}
        onChange={data => {
          setOpenModalChangeLifecycle(!isOpenModalChangeLifecycle)
          if (data === NoteLifecycleEnum.CREATION.concat("_EX_NIHILO")) {
            setTargetExNihilo(true)
            setTargetLifecycle(NoteLifecycleEnum.CREATION)
          } else {
            setTargetLifecycle(data as NoteLifecycleEnum)
            setTargetExNihilo(false)
          }
        }}
      />
      <LifecycleChangeDialog
        isOpen={isOpenModalChangeLifecycle}
        cancelClick={() => setOpenModalChangeLifecycle(false)}
        changeFct={onChangeLifeCycle}
        addedPanel={handleAddedPanelData()}
        removedPanel={handleRemovedPanelData()}
      />
    </>
  )
}

const buildTreeData = (lifecycle: NoteLifecycleEnum, creationExNihilo?: boolean) => {
  return [
    {
      title: "Création",
      value: "0-0",
      selectable: false,
      children: [
        ...(lifecycle !== NoteLifecycleEnum.CREATION || creationExNihilo !== false
          ? [
              {
                title: "Avec exercices passés",
                value: NoteLifecycleEnum.CREATION
              }
            ]
          : []),
        ...(lifecycle !== NoteLifecycleEnum.CREATION || creationExNihilo !== true
          ? [
              {
                title: "Sans exercices passés",
                value: NoteLifecycleEnum.CREATION.concat("_EX_NIHILO")
              }
            ]
          : [])
      ]
    },
    ...(lifecycle !== NoteLifecycleEnum.DEVELOPMENT
      ? [
          {
            title: getLifecycle(NoteLifecycleEnum.DEVELOPMENT),
            value: NoteLifecycleEnum.DEVELOPMENT
          }
        ]
      : []),
    ...(lifecycle !== NoteLifecycleEnum.REPRISE
      ? [
          {
            title: getLifecycle(NoteLifecycleEnum.REPRISE),
            value: NoteLifecycleEnum.REPRISE
          }
        ]
      : []),
    ...(lifecycle !== NoteLifecycleEnum.REBOUND
      ? [
          {
            title: getLifecycle(NoteLifecycleEnum.REBOUND),
            value: NoteLifecycleEnum.REBOUND
          }
        ]
      : []),
    ...(lifecycle !== NoteLifecycleEnum.SCALE
      ? [
          {
            title: getLifecycle(NoteLifecycleEnum.SCALE),
            value: NoteLifecycleEnum.SCALE
          }
        ]
      : [])
  ]
}

const isLifecycleSwitchEnabled = (lifecycle: NoteLifecycleEnum) =>
  lifecycle !== NoteLifecycleEnum.EMERGENCE

export const getLifecycle = (lifecycle: NoteLifecycleEnum) => {
  switch (lifecycle) {
    case NoteLifecycleEnum.EMERGENCE:
      return "Émergence"
    case NoteLifecycleEnum.REPRISE:
      return "Reprise/transmission"
    case NoteLifecycleEnum.DEVELOPMENT:
      return "Développement"
    case NoteLifecycleEnum.REBOUND:
      return "Relance"
    case NoteLifecycleEnum.SCALE:
      return "Changement d'échelle"
    case NoteLifecycleEnum.CREATION:
    default:
      return "Création"
  }
}

const getRemovedColumnForLifecycleChange = (lifecycleA: string, lifecycleB: string): string[] => {
  let res: string[] = []
  const columnsA = getColumnsFromLifecycle(lifecycleA)
  const columnsBNames = getColumnsFromLifecycle(lifecycleB).map(x => x.name)
  for (const columnA of columnsA) {
    if (columnsBNames.indexOf(columnA.name) < 0) {
      res = res.concat(columnA.userInfo)
    }
  }
  return res
}

const getAddedColumnForLifecycleChange = (a: string, b: string) =>
  getRemovedColumnForLifecycleChange(b, a)
