import React, { FC } from "react"

import { Popover, Typography } from "antd"
import {
  ApiCalculatedData,
  ApiFinancialPeriodPeriodTypeEnum,
  ApiNumericData
} from "../../client/backend-client/generated"
import { Suffixes } from "../../utils/suffixes"
import { FormulaPopoverContent } from "../FormulaPopoverContent/FormulaPopoverContent"
import { NumberText } from "../NumberText/NumberText"
import { instanceOfApiCalculatedData } from "../../utils/TableUtils"

const { Text } = Typography

export interface TableTotalCellProps {
  data?: ApiCalculatedData | ApiNumericData
  suffix: Suffixes
  decimals?: number
  showDescription?: (value: number) => boolean
  optionalDescription?: string
  periodType?: ApiFinancialPeriodPeriodTypeEnum
  zeroValueAsEmptyCell?: boolean | undefined
  name?: string
}

export const TableTotalCell: FC<TableTotalCellProps> = ({
  data,
  suffix,
  decimals,
  showDescription,
  optionalDescription,
  periodType,
  zeroValueAsEmptyCell,
  name
}) => {
  if (instanceOfApiCalculatedData(data)) {
    const baseValue = data.numericData.value ?? 0
    const value = suffix === Suffixes.PERCENTAGE ? baseValue * 100 : baseValue
    return (
      <>
        {value === 0 && zeroValueAsEmptyCell === true ? (
          <Text>-</Text>
        ) : value === undefined ? (
          <NumberText value={value} suffix={suffix} decimalScale={0} name={name}/>
        ) : (
          <Popover
            content={
              <FormulaPopoverContent
                calculatedData={data}
                suffix={suffix}
                decimals={decimals}
                periodType={periodType}
                showDescription={showDescription}
                optionalDescription={optionalDescription}
              />
            }
            trigger="click"
            placement={"bottom"}
            className={"pointer-cursor"}
          >
            <NumberText value={value} suffix={suffix} decimalScale={decimals} name={name}/>
          </Popover>
        )}
      </>
    )
  } else {
    return <NumberText value={data?.value ?? 0} suffix={suffix} decimalScale={0} name={name}/>
  }
}
