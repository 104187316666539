import { Select } from "antd"
import React, { CSSProperties, useState } from "react"

export interface SelectItem<T> {
  value: T
  label: string
}

export interface AppMetierSelectProps<T = string | number> {
  value?: T
  options: T[]
  valueToSelectItem: (value: T) => SelectItem<T>
  onChange: (value: T) => void
  loading?: boolean
  disabled?: boolean
  style?: CSSProperties
}

export const AppMetierSelect = <T extends string | number>(props: AppMetierSelectProps<T>) => {
  const { value, options, valueToSelectItem, onChange, loading, disabled, style } = props
  const [item, setItem] = useState(value ? valueToSelectItem(value) : undefined)

  return (
    <Select<SelectItem<T>>
      value={item}
      labelInValue
      style={style ?? { width: "100%" }}
      onChange={(item: SelectItem<T>) => {
        setItem(item)
        onChange(item.value)
      }}
      loading={loading}
      disabled={disabled}
      options={options?.map(valueToSelectItem) ?? []}
    />
  )
}
