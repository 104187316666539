import React, { FC } from "react"
import { Button, Modal, Typography } from "antd"

const { Title, Link } = Typography

export const PdfSuccessModal: FC<{
  pdfSuccess: boolean
  linkPdf: string
  successText: string
  linkText: string
  onClose: () => void
}> = ({ pdfSuccess, linkPdf, successText, linkText, onClose }) => {
  const { Text, Paragraph } = Typography

  return (
    <Modal
      open={pdfSuccess}
      closeIcon={false}
      destroyOnClose={true}
      footer={[
        <Button key="ok-button" onClick={() => onClose()}>
          OK
        </Button>
      ]}
    >
      <>
        <Title level={2}>Félicitations !</Title>
        <Paragraph>
          <Text>{successText}</Text>
        </Paragraph>
        <Paragraph>
          ({linkText}{" "}
          <Link href={linkPdf} target={"_blank imply noopener; support opener"}>
            ici
          </Link>
          )
        </Paragraph>
      </>
    </Modal>
  )
}
