/* tslint:disable */
/* eslint-disable */
/**
 * Appli métier
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: Yoda 1 & 2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Advisor } from "./advisor"

/**
 *
 * @export
 * @interface NoubaLightProject
 */
export interface NoubaLightProject {
  /**
   *
   * @type {number}
   * @memberof NoubaLightProject
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof NoubaLightProject
   */
  structure: string
  /**
   *
   * @type {string}
   * @memberof NoubaLightProject
   */
  label: string
  /**
   *
   * @type {Array<string>}
   * @memberof NoubaLightProject
   */
  holdersPreferredNames: Array<string>
  /**
   *
   * @type {Array<string>}
   * @memberof NoubaLightProject
   */
  holdersBirthNames: Array<string>
  /**
   *
   * @type {string}
   * @memberof NoubaLightProject
   */
  lastModificationDate?: string
  /**
   *
   * @type {string}
   * @memberof NoubaLightProject
   */
  creationDate?: string
  /**
   *
   * @type {string}
   * @memberof NoubaLightProject
   */
  codePeps: string
  /**
   *
   * @type {Advisor}
   * @memberof NoubaLightProject
   */
  advisor?: Advisor
  /**
   *
   * @type {string}
   * @memberof NoubaLightProject
   */
  projectPhase?: NoubaLightProjectProjectPhaseEnum
}

/**
 * @export
 * @enum {string}
 */
export enum NoubaLightProjectProjectPhaseEnum {
  ANALYSE = "ANALYSE",
  ETUDE = "ETUDE",
  APPRV = "APPRV",
  SUIVI = "SUIVI",
  TERMN = "TERMN",
  ABNDN = "ABNDN",
  REFUS = "REFUS"
}
