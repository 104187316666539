/* tslint:disable */
/* eslint-disable */
/**
 * Appli métier
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: Yoda 1 & 2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from "axios"
import { Configuration } from "../configuration"
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction
} from "../common"
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from "../base"
// @ts-ignore
import { NoteAnalysis } from "../model"
// @ts-ignore
import { PAQuestionResult } from "../model"
// @ts-ignore
import { Question } from "../model"
// @ts-ignore
import { QuestionResultSaveRequest } from "../model"
/**
 * ProjectAnalyzeResourceApi - axios parameter creator
 * @export
 */
export const ProjectAnalyzeResourceApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} noteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNoteAnalysis: async (
      noteId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'noteId' is not null or undefined
      assertParamExists("getNoteAnalysis", "noteId", noteId)
      const localVarPath = `/analyzes/analysis/{noteId}`.replace(
        `{${"noteId"}}`,
        encodeURIComponent(String(noteId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} noteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getQuestions: async (
      noteId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'noteId' is not null or undefined
      assertParamExists("getQuestions", "noteId", noteId)
      const localVarPath = `/analyzes/questions/{noteId}`.replace(
        `{${"noteId"}}`,
        encodeURIComponent(String(noteId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} noteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getResponses: async (
      noteId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'noteId' is not null or undefined
      assertParamExists("getResponses", "noteId", noteId)
      const localVarPath = `/analyzes/projects/{noteId}`.replace(
        `{${"noteId"}}`,
        encodeURIComponent(String(noteId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} noteId
     * @param {QuestionResultSaveRequest} questionResultSaveRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveResponse: async (
      noteId: string,
      questionResultSaveRequest: QuestionResultSaveRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'noteId' is not null or undefined
      assertParamExists("saveResponse", "noteId", noteId)
      // verify required parameter 'questionResultSaveRequest' is not null or undefined
      assertParamExists("saveResponse", "questionResultSaveRequest", questionResultSaveRequest)
      const localVarPath = `/analyzes/projects/{noteId}`.replace(
        `{${"noteId"}}`,
        encodeURIComponent(String(noteId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        questionResultSaveRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * ProjectAnalyzeResourceApi - functional programming interface
 * @export
 */
export const ProjectAnalyzeResourceApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ProjectAnalyzeResourceApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {string} noteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNoteAnalysis(
      noteId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NoteAnalysis>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getNoteAnalysis(noteId, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} noteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getQuestions(
      noteId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Question>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getQuestions(noteId, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} noteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getResponses(
      noteId: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PAQuestionResult>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getResponses(noteId, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} noteId
     * @param {QuestionResultSaveRequest} questionResultSaveRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async saveResponse(
      noteId: string,
      questionResultSaveRequest: QuestionResultSaveRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PAQuestionResult>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.saveResponse(
        noteId,
        questionResultSaveRequest,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    }
  }
}

/**
 * ProjectAnalyzeResourceApi - factory interface
 * @export
 */
export const ProjectAnalyzeResourceApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = ProjectAnalyzeResourceApiFp(configuration)
  return {
    /**
     *
     * @param {string} noteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNoteAnalysis(noteId: string, options?: any): AxiosPromise<NoteAnalysis> {
      return localVarFp.getNoteAnalysis(noteId, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} noteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getQuestions(noteId: string, options?: any): AxiosPromise<Array<Question>> {
      return localVarFp.getQuestions(noteId, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} noteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getResponses(noteId: string, options?: any): AxiosPromise<Array<PAQuestionResult>> {
      return localVarFp.getResponses(noteId, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} noteId
     * @param {QuestionResultSaveRequest} questionResultSaveRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveResponse(
      noteId: string,
      questionResultSaveRequest: QuestionResultSaveRequest,
      options?: any
    ): AxiosPromise<PAQuestionResult> {
      return localVarFp
        .saveResponse(noteId, questionResultSaveRequest, options)
        .then(request => request(axios, basePath))
    }
  }
}

/**
 * Request parameters for getNoteAnalysis operation in ProjectAnalyzeResourceApi.
 * @export
 * @interface ProjectAnalyzeResourceApiGetNoteAnalysisRequest
 */
export interface ProjectAnalyzeResourceApiGetNoteAnalysisRequest {
  /**
   *
   * @type {string}
   * @memberof ProjectAnalyzeResourceApiGetNoteAnalysis
   */
  readonly noteId: string
}

/**
 * Request parameters for getQuestions operation in ProjectAnalyzeResourceApi.
 * @export
 * @interface ProjectAnalyzeResourceApiGetQuestionsRequest
 */
export interface ProjectAnalyzeResourceApiGetQuestionsRequest {
  /**
   *
   * @type {string}
   * @memberof ProjectAnalyzeResourceApiGetQuestions
   */
  readonly noteId: string
}

/**
 * Request parameters for getResponses operation in ProjectAnalyzeResourceApi.
 * @export
 * @interface ProjectAnalyzeResourceApiGetResponsesRequest
 */
export interface ProjectAnalyzeResourceApiGetResponsesRequest {
  /**
   *
   * @type {string}
   * @memberof ProjectAnalyzeResourceApiGetResponses
   */
  readonly noteId: string
}

/**
 * Request parameters for saveResponse operation in ProjectAnalyzeResourceApi.
 * @export
 * @interface ProjectAnalyzeResourceApiSaveResponseRequest
 */
export interface ProjectAnalyzeResourceApiSaveResponseRequest {
  /**
   *
   * @type {string}
   * @memberof ProjectAnalyzeResourceApiSaveResponse
   */
  readonly noteId: string

  /**
   *
   * @type {QuestionResultSaveRequest}
   * @memberof ProjectAnalyzeResourceApiSaveResponse
   */
  readonly questionResultSaveRequest: QuestionResultSaveRequest
}

/**
 * ProjectAnalyzeResourceApi - object-oriented interface
 * @export
 * @class ProjectAnalyzeResourceApi
 * @extends {BaseAPI}
 */
export class ProjectAnalyzeResourceApi extends BaseAPI {
  /**
   *
   * @param {ProjectAnalyzeResourceApiGetNoteAnalysisRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectAnalyzeResourceApi
   */
  public getNoteAnalysis(
    requestParameters: ProjectAnalyzeResourceApiGetNoteAnalysisRequest,
    options?: AxiosRequestConfig
  ) {
    return ProjectAnalyzeResourceApiFp(this.configuration)
      .getNoteAnalysis(requestParameters.noteId, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {ProjectAnalyzeResourceApiGetQuestionsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectAnalyzeResourceApi
   */
  public getQuestions(
    requestParameters: ProjectAnalyzeResourceApiGetQuestionsRequest,
    options?: AxiosRequestConfig
  ) {
    return ProjectAnalyzeResourceApiFp(this.configuration)
      .getQuestions(requestParameters.noteId, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {ProjectAnalyzeResourceApiGetResponsesRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectAnalyzeResourceApi
   */
  public getResponses(
    requestParameters: ProjectAnalyzeResourceApiGetResponsesRequest,
    options?: AxiosRequestConfig
  ) {
    return ProjectAnalyzeResourceApiFp(this.configuration)
      .getResponses(requestParameters.noteId, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {ProjectAnalyzeResourceApiSaveResponseRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectAnalyzeResourceApi
   */
  public saveResponse(
    requestParameters: ProjectAnalyzeResourceApiSaveResponseRequest,
    options?: AxiosRequestConfig
  ) {
    return ProjectAnalyzeResourceApiFp(this.configuration)
      .saveResponse(requestParameters.noteId, requestParameters.questionResultSaveRequest, options)
      .then(request => request(this.axios, this.basePath))
  }
}
