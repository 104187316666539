/* tslint:disable */
/* eslint-disable */
/**
 * Appli métier
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: Yoda 1 & 2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface CalculDetail
 */
export interface CalculDetail {
  /**
   *
   * @type {string}
   * @memberof CalculDetail
   */
  labelDetail: string
  /**
   *
   * @type {string}
   * @memberof CalculDetail
   */
  valuedDetail: string
  /**
   *
   * @type {string}
   * @memberof CalculDetail
   */
  unit: CalculDetailUnitEnum
  /**
   *
   * @type {string}
   * @memberof CalculDetail
   */
  link: CalculDetailLinkEnum
}

/**
 * @export
 * @enum {string}
 */
export enum CalculDetailUnitEnum {
  EUROS = "EUROS",
  K_EUROS = "K_EUROS",
  JOURS = "JOURS",
  MONTH = "MONTH",
  NONE = "NONE"
}
/**
 * @export
 * @enum {string}
 */
export enum CalculDetailLinkEnum {
  CR = "CR",
  BFR = "BFR",
  CAF = "CAF",
  PF = "PF",
  TVA = "TVA",
  PRETS = "PRETS",
  PRETS_PASSES = "PRETS_PASSES",
  NONE = "NONE",
  BILANCEDANT = "BILANCEDANT"
}
