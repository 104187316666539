import React, { FC } from "react"
import { Space, Typography } from "antd"

const { Text } = Typography

export const AppMetierToaster: FC<{
  baseMessage: string
  detailMessage?: string
}> = ({ baseMessage, detailMessage }) => (
  <Space direction="vertical">
    <Text>
      {baseMessage}
      {detailMessage ? " :" : "."}
    </Text>
    {detailMessage && <Text strong>{detailMessage}</Text>}
  </Space>
)
