import { default as axios } from "axios"
import React, { createContext, useContext } from "react"
import { KeycloakContext, UserModel } from "../auth/KeycloakContext"
import { Buffer } from "buffer"

import environment from "./environment"
import {
  AddressResourceApi,
  AssociationResourceV2Api,
  BenchMarkResourceV2Api,
  CollaboratorResourceApi,
  Configuration,
  EntrepreneurResourceApi,
  EntrepreneurResourceV2Api,
  FinancialPeriodResourceV2Api,
  FundingResourceApi,
  HistoryRessourceApi,
  HolderResourceApi,
  InvestissementsResourceApi,
  InvolvementCheckResourceApi,
  MasseSalarialeResourceV2Api,
  MementoResourceV2Api,
  NoteResourceV2Api,
  NoteSettingsResourceApi,
  PdfResourceV2Api,
  PlanFinancementRatiosResourceApi,
  PlanFinancementRatiosResourceV2Api,
  PlanFinancementResourceApi,
  PlanFinancementResourceV2Api,
  PretResourceApi,
  ProjectAnalyzeResourceApi,
  ProjectResourceApi,
  ProjectResourceV2Api,
  ReferenceResourceApi,
  ResultResourceApi,
  ScoringOctroiResourceV2Api,
  SettingResourceApi,
  StructureResourceApi,
  TvaResourceApi
} from "../client/backend-client/generated"
import {
  Mutation,
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
  QueryKey
} from "@tanstack/react-query"
import { Query } from "@tanstack/query-core/src/query"
import {
  handleReactQueryError,
  handleReactQueryMutationLoader,
  handleReactQuerySuccess
} from "../utils/reactQueryUtils"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import { message } from "antd"

export interface Services {
  userHeaders: {
    userId: string
    userName: string
    xRequestResourceForAtIdX: string
  }
  associationResourceV2Api: AssociationResourceV2Api
  structureResourceApi: StructureResourceApi
  collaboratorsRessourceApi: CollaboratorResourceApi
  projectResourceApi: ProjectResourceApi
  projectResourceV2Api: ProjectResourceV2Api
  noteResourceV2Api: NoteResourceV2Api
  noteSettingsResourceApi: NoteSettingsResourceApi
  projectAnalyzeResourceApi: ProjectAnalyzeResourceApi
  involvementCheckResourceApi: InvolvementCheckResourceApi
  resultResourceApi: ResultResourceApi
  entrepreneurResourceApi: EntrepreneurResourceApi
  entrepreneurResourceV2Api: EntrepreneurResourceV2Api
  benchMarkResourcesV2Api: BenchMarkResourceV2Api
  financialPeriodResourceV2Api: FinancialPeriodResourceV2Api
  salaryMassResourceV2Api: MasseSalarialeResourceV2Api
  pastLoanResourceApi: PretResourceApi
  investissementsResourceApi: InvestissementsResourceApi
  tvaResourceApi: TvaResourceApi
  planFinancementResourceApi: PlanFinancementResourceApi
  planFinancementResourceV2Api: PlanFinancementResourceV2Api
  planFinancementRatiosResourceApi: PlanFinancementRatiosResourceApi
  planFinancementRatiosResourceV2Api: PlanFinancementRatiosResourceV2Api
  pdfResourceV2Api: PdfResourceV2Api
  fundingResourceApi: FundingResourceApi
  scoringOctroiResourceV2Api: ScoringOctroiResourceV2Api
  mementoResourceV2Api: MementoResourceV2Api
  historyResourceApi: HistoryRessourceApi
  settingResourceApi: SettingResourceApi
  referenceResourceApi: ReferenceResourceApi
  holderResourceApi: HolderResourceApi
  addressResourceApi: AddressResourceApi
}

export interface Stores {
  user?: UserModel
}

type DecodedJwt = {
  exp: number
  sub: string
  name?: string
  fond: string
  muffin_user_id: string
  preferred_username?: string
  resource_access: {
    [key: string]: {
      roles: string[]
    }
  }
  realm_access: {
    roles: string[]
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any
}

const getAssociationId = () => {
  const match = window.location.pathname.match(/\/association\/(\w*)/)
  return (match && match[1]) || "NOT_DEFINED"
}

const useInitServices = (getAccessToken: () => string, login: () => void): Services => {
  const configuration: Configuration = new Configuration({
    basePath: environment.API_URL,
    accessToken: getAccessToken
  })

  const token = getAccessToken()
  const parts: string[] = token.split(".")
  const content: DecodedJwt = JSON.parse(Buffer.from(parts[1], "base64").toString())
  const userId = content.sub
  const userName = content.name
    ? content.name
    : content.preferred_username
      ? content.preferred_username
      : "no name user"

  axios.defaults.withCredentials = false
  axios.interceptors.request.use(
    config => {
      config.headers["x-request-resource-for-at-id-x"] = getAssociationId()
      return config
    },

    error => {
      return Promise.reject(error)
    }
  )
  axios.interceptors.response.use(
    response => {
      response.headers["Access-Control-Allow-Headers"] = "username"
      return response
    },
    error => {
      if (!!error.response && 401 === error.response.status) {
        login()
      } else {
        return Promise.reject(error)
      }
    }
  )

  const associationResourceV2Api = new AssociationResourceV2Api(configuration)
  const structureResourceApi = new StructureResourceApi(configuration)
  const collaboratorsRessourceApi = new CollaboratorResourceApi(configuration)
  const projectResourceApi = new ProjectResourceApi(configuration)
  const projectResourceV2Api = new ProjectResourceV2Api(configuration)
  const noteResourceV2Api = new NoteResourceV2Api(configuration)
  const noteSettingsResourceApi = new NoteSettingsResourceApi(configuration)
  const projectAnalyzeResourceApi = new ProjectAnalyzeResourceApi(configuration)
  const involvementCheckResourceApi = new InvolvementCheckResourceApi(configuration)
  const resultResourceApi = new ResultResourceApi(configuration)
  const entrepreneurResourceApi = new EntrepreneurResourceApi(configuration)
  const entrepreneurResourceV2Api = new EntrepreneurResourceV2Api(configuration)
  const benchMarkResourcesV2Api = new BenchMarkResourceV2Api(configuration)
  const financialPeriodResourceV2Api = new FinancialPeriodResourceV2Api(configuration)
  const salaryMassResourceV2Api = new MasseSalarialeResourceV2Api(configuration)
  const pastLoanResourceApi = new PretResourceApi(configuration)
  const investissementsResourceApi = new InvestissementsResourceApi(configuration)
  const tvaResourceApi = new TvaResourceApi(configuration)
  const planFinancementResourceApi = new PlanFinancementResourceApi(configuration)
  const planFinancementRatiosResourceApi = new PlanFinancementRatiosResourceApi(configuration)
  const planFinancementResourceV2Api = new PlanFinancementResourceV2Api(configuration)
  const planFinancementRatiosResourceV2Api = new PlanFinancementRatiosResourceV2Api(configuration)
  const pdfResourceV2Api = new PdfResourceV2Api(configuration)
  const fundingResourceApi = new FundingResourceApi(configuration)
  const scoringOctroiResourceV2Api = new ScoringOctroiResourceV2Api(configuration)
  const mementoResourceV2Api = new MementoResourceV2Api(configuration)
  const historyResourceApi = new HistoryRessourceApi(configuration)
  const settingResourceApi = new SettingResourceApi(configuration)
  const referenceResourceApi = new ReferenceResourceApi(configuration)
  const holderResourceApi = new HolderResourceApi(configuration)
  const addressResourceApi = new AddressResourceApi(configuration)

  return {
    userHeaders: {
      userId,
      userName,
      xRequestResourceForAtIdX: getAssociationId()
    },
    associationResourceV2Api,
    structureResourceApi,
    collaboratorsRessourceApi,
    projectResourceApi,
    projectResourceV2Api,
    noteResourceV2Api,
    noteSettingsResourceApi,
    projectAnalyzeResourceApi,
    involvementCheckResourceApi,
    resultResourceApi,
    entrepreneurResourceApi,
    entrepreneurResourceV2Api,
    benchMarkResourcesV2Api,
    financialPeriodResourceV2Api,
    salaryMassResourceV2Api,
    pastLoanResourceApi,
    investissementsResourceApi,
    tvaResourceApi,
    planFinancementResourceApi,
    planFinancementRatiosResourceApi,
    planFinancementResourceV2Api,
    planFinancementRatiosResourceV2Api,
    pdfResourceV2Api,
    fundingResourceApi,
    scoringOctroiResourceV2Api,
    mementoResourceV2Api,
    historyResourceApi,
    settingResourceApi,
    referenceResourceApi,
    holderResourceApi,
    addressResourceApi
  }
}

const useInitStore = (): Stores | undefined => {
  return {}
}

export const AppContext = createContext<Services | null>(null)

const AppCtx = ({ children }: { children: React.JSX.Element }) => {
  const { keycloak } = useContext(KeycloakContext)
  const getAccessToken = () => keycloak.token || ""

  const services = useInitServices(getAccessToken, keycloak.login)
  const stores = useInitStore()

  const [messageApi, contextHolder] = message.useMessage()

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 300000,
        refetchOnWindowFocus: false
      }
    },
    queryCache: new QueryCache({
      // @ts-expect-error : Types of parameters query and query are incompatible.
      onError: (error: unknown, query: Query<unknown, unknown, unknown, QueryKey>) =>
        handleReactQueryError(error, query, messageApi)
    }),
    mutationCache: new MutationCache({
      onMutate: (_, mutation) => handleReactQueryMutationLoader(mutation, messageApi),
      onSuccess: (_, __, ___, mutation) => handleReactQuerySuccess(mutation, messageApi),
      onError: (
        error: unknown,
        _: unknown,
        __: unknown,
        mutation: Mutation<unknown, unknown, unknown>
      ) => handleReactQueryError(error, mutation, messageApi)
    })
  })

  return (
    <QueryClientProvider client={queryClient}>
      {contextHolder}
      <AppContext.Provider
        value={{
          ...services,
          ...stores
        }}
      >
        {children}
      </AppContext.Provider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}

export default AppCtx

export const useAppContext: () => Services = () => {
  const context = useContext(AppContext)
  if (context == null) {
    throw new Error("No store provided")
  }
  return context
}
