import { useAppContext } from "../../config/context"
import { useHeaders } from "../../queries/axios-headers"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { extract } from "../../client/backend-client/AxiosUtils"
import { Setting } from "../../client/backend-client/generated"
import { KeycloakContext } from "../../auth/KeycloakContext"
import { useContext } from "react"

export const useGetUserSettings = () => {
  const { settingResourceApi } = useAppContext()
  const getHeaders = useHeaders()
  const { keycloak } = useContext(KeycloakContext)

  const userId = keycloak.profile?.id || ""

  const { isLoading, data, error } = useQuery({
    queryKey: ["settings", userId],
    queryFn: async () => {
      const { headers } = await getHeaders()
      return settingResourceApi.getSetting({ advisorId: userId }, { headers }).then(extract)
    },
    retry: 0,
    meta: {
      errorMessage: `Erreur lors de la récupération des paramètres`
    }
  })

  return {
    isLoading,
    data,
    error
  }
}

export const useUpdateUserSetting = () => {
  const { settingResourceApi } = useAppContext()
  const getHeaders = useHeaders()
  const { keycloak } = useContext(KeycloakContext)

  const userId = keycloak.profile?.id || ""

  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (setting: Setting) => {
      const { headers } = await getHeaders()
      return settingResourceApi
        .saveSetting(
          {
            advisorId: userId,
            setting: setting
          },
          { headers }
        )
        .then(extract)
    },
    meta: {
      errorMessage: `Erreur lors de la mise à jour des paramètres`
    },
    onSuccess: result => {
      queryClient.setQueryData<Setting>(["settings", userId], result)
    }
  })
}
