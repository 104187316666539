import { useAppContext } from "../config/context"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { extract } from "../client/backend-client/AxiosUtils"
import {
  NoubaHolder,
  UpdateContactRoleBeneficiaireRequest,
  UpdateHolderRequest
} from "../client/backend-client/generated"
import { useHeaders } from "./axios-headers"
import { areAddressEqual } from "./AddressQueries"

export const useGetNoteHolders = (projectId: number) => {
  const { holderResourceApi } = useAppContext()
  const getHeaders = useHeaders()

  const { isLoading, data } = useQuery({
    queryKey: ["holders", projectId],
    queryFn: async () => {
      const { headers } = await getHeaders()
      return holderResourceApi.getHolders({ projectId }, { headers }).then(extract)
    },
    retry: 0,
    meta: {
      errorMessage: `Erreur lors de la récupération des porteurs du projet`
    }
  })

  return {
    isLoading,
    data
  }
}

export const useUpdateHolder = (projectId: number, holderId: string) => {
  const { holderResourceApi } = useAppContext()
  const getHeaders = useHeaders()
  const queryClient = useQueryClient()

  return useMutation({
    scope: {
      id: `update-holder-${holderId}`
    },
    mutationFn: async (request: UpdateHolderRequest) => {
      const { headers } = await getHeaders()
      return holderResourceApi
        .updateHolder({ projectId, holderId, updateHolderRequest: request }, { headers })
        .then(extract)
    },
    onSuccess: result => {
      queryClient.setQueryData<NoubaHolder[]>(["holders", projectId], holders =>
        holders?.map(holder => (holder.id === result.id ? result : holder))
      )
    },
    meta: {
      errorMessage: `Erreur lors de la mise à jour du porteur`
    }
  })
}

export const useUpdateContactRoleBeneficiaire = (projectId: number, holderId: string) => {
  const { holderResourceApi } = useAppContext()
  const getHeaders = useHeaders()
  const queryClient = useQueryClient()

  return useMutation({
    scope: {
      id: `update-contact-${holderId}`
    },
    mutationFn: async (request: UpdateContactRoleBeneficiaireRequest) => {
      const { headers } = await getHeaders()
      return holderResourceApi
        .updateContactRoleBeneficiaire(
          {
            projectId,
            holderId,
            updateContactRoleBeneficiaireRequest: request
          },
          { headers }
        )
        .then(extract)
    },
    onSuccess: result => {
      queryClient.setQueryData<NoubaHolder[]>(["holders", projectId], holders =>
        holders?.map(holder => (holder.id === result.id ? result : holder))
      )
    },
    meta: {
      errorMessage: `Erreur lors de la mise à jour du porteur`
    }
  })
}

export const toHolderRequest = (
  initialRequest: UpdateHolderRequest,
  request: UpdateHolderRequest
): UpdateHolderRequest => {
  return {
    maritalStatus:
      initialRequest.maritalStatus?.code !== request.maritalStatus?.code
        ? request.maritalStatus
        : initialRequest.maritalStatus,
    professionalStatus:
      initialRequest.professionalStatus?.code !== request.professionalStatus?.code
        ? request.professionalStatus
        : initialRequest.professionalStatus,
    incomeType:
      initialRequest.incomeType?.code !== request.incomeType?.code
        ? request.incomeType
        : initialRequest.incomeType,
    educationalLevel:
      initialRequest.educationalLevel?.code !== request.educationalLevel?.code
        ? request.educationalLevel
        : initialRequest.educationalLevel,
    poleEmploiInscriptionDate:
      initialRequest.poleEmploiInscriptionDate !== request.poleEmploiInscriptionDate
        ? request.poleEmploiInscriptionDate
        : initialRequest.poleEmploiInscriptionDate,
    minimumSocialBeneficiary:
      initialRequest.minimumSocialBeneficiary?.code !== request.minimumSocialBeneficiary?.code
        ? request.minimumSocialBeneficiary
        : undefined,
    isHandicapped:
      initialRequest.isHandicapped !== request.isHandicapped ? request.isHandicapped : undefined,
    isGrandePrecarite:
      initialRequest.isGrandePrecarite !== request.isGrandePrecarite
        ? request.isGrandePrecarite
        : undefined,
    isGrandePrecariteForced:
      initialRequest.isGrandePrecariteForced !== request.isGrandePrecariteForced
        ? request.isGrandePrecariteForced
        : undefined,
    grandePrecariteComment:
      initialRequest.grandePrecariteComment?.trim() !== request.grandePrecariteComment?.trim()
        ? request.grandePrecariteComment
        : undefined,
    isDeceased: initialRequest.isDeceased !== request.isDeceased ? request.isDeceased : undefined,
    firstContactDate:
      initialRequest.firstContactDate !== request.firstContactDate
        ? request.firstContactDate
        : undefined
  }
}

export const toContactRoleBeneficiaireRequest = (
  initialRequest: UpdateContactRoleBeneficiaireRequest,
  request: UpdateContactRoleBeneficiaireRequest
): UpdateContactRoleBeneficiaireRequest => {
  return {
    email:
      initialRequest.email?.trim() !== request.email?.trim()
        ? request.email?.trim()
        : initialRequest.email,
    phoneNumber1:
      initialRequest.phoneNumber1?.trim() !== request.phoneNumber1?.trim()
        ? request.phoneNumber1?.trim()
        : initialRequest.phoneNumber1,
    phoneNumber2:
      initialRequest.phoneNumber2?.trim() !== request.phoneNumber2?.trim()
        ? request.phoneNumber2?.trim()
        : initialRequest.phoneNumber2,
    jobFunction:
      initialRequest.jobFunction?.trim() !== request.jobFunction?.trim()
        ? request.jobFunction?.trim()
        : undefined,
    lastName:
      initialRequest.lastName?.trim() !== request.lastName?.trim()
        ? request.lastName?.trim()
        : undefined,
    preferredName:
      initialRequest.preferredName?.trim() !== request.preferredName?.trim()
        ? request.preferredName?.trim()
        : initialRequest.preferredName,
    firstName:
      initialRequest.firstName?.trim() !== request.firstName?.trim()
        ? request.firstName?.trim()
        : undefined,
    dateOfBirth:
      initialRequest.dateOfBirth !== request.dateOfBirth
        ? request.dateOfBirth
        : initialRequest.dateOfBirth,
    civility:
      initialRequest.civility?.code !== request.civility?.code ? request.civility : undefined,
    address: !areAddressEqual(initialRequest.address, request.address) ? request.address : undefined
  }
}
