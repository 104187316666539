import React, { FC, useContext, useEffect, useState } from "react"
import { ProjectContext } from "../ProjectWrapper"
import { EyeOutlined, FilePdfOutlined } from "@ant-design/icons"
import {
  Button,
  Card,
  Checkbox,
  CheckboxProps,
  Col,
  Divider,
  Modal,
  Row,
  Space,
  Typography
} from "antd"
import { AppMetierTitle } from "../../components/AppMetierTitle/AppMetierTitle"
import { CheckboxValueType } from "antd/es/checkbox/Group"
import { useGenerateMementoPdf, useUpdateMemento } from "./MementoQueries"
import { Memento, RubriqueMementoAdvisor } from "../../client/backend-client/generated"
import {
  checkboxOptionToCheckboxValue,
  getMementoOptions,
  initCheckList,
  toMementoInput
} from "./MementoUtils"
import _ from "lodash"
import { EditorTextInput } from "../../components/Editor/EditorTextInput"
import { EditorTextParser } from "../../components/Editor/EditorTextParser"
import { OutputData } from "@editorjs/editorjs"
import { areSameOutputData, toOutputData } from "../../components/Editor/utilities"
import { NoteContext } from "../NotePage"
import { useDebouncedCallback } from "use-debounce"
import { NavLink } from "react-router-dom"
import { PdfSuccessModal } from "../../components/PdfSuccessModal/PdfSuccessModal"
import Loader from "../../components/Loader/Loader"
import { getFlushEffectCallback, UPDATE_DEBOUNCE_DEFAULT_DELAY } from "../../utils/DebounceUtils"

const { Text } = Typography

export const MementoForm: FC<{ memento: Memento }> = ({ memento }) => {
  const { projectId } = useContext(ProjectContext)
  const { note, noteId, baseNotePath } = useContext(NoteContext)

  const mementoOptions = getMementoOptions(note.type, note.pdfPreferences)
  const initialCheckList = initCheckList(memento, mementoOptions)

  const [pdfLink, setPdfLink] = useState<string>()
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState<boolean>(false)
  const [checkedList, setCheckedList] = useState<CheckboxValueType[]>(initialCheckList)
  const [sections, setSections] = useState<RubriqueMementoAdvisor[]>(
    memento.lstRubriqueMementoAdvisor
  )

  const checkAll = mementoOptions.length === checkedList.length
  const indeterminate = checkedList.length > 0 && checkedList.length < mementoOptions.length

  const updateMementoMutation = useUpdateMemento(projectId, noteId)
  const {
    isPending,
    isSuccess: isGeneratePdfMutationSuccess,
    isError,
    mutate: generatePdfMutation,
    data: generatePdfMutationResult
  } = useGenerateMementoPdf()

  const debouncedUpdateMemento = useDebouncedCallback(() => {
    if (
      !_.isEqual(checkedList, initialCheckList) ||
      !_.isEqual(sections, memento.lstRubriqueMementoAdvisor)
    ) {
      updateMementoMutation.mutate(toMementoInput(checkedList, sections, mementoOptions))
    }
  }, UPDATE_DEBOUNCE_DEFAULT_DELAY)

  useEffect(debouncedUpdateMemento, [checkedList, sections])
  useEffect(getFlushEffectCallback(debouncedUpdateMemento), [debouncedUpdateMemento])

  const onChange = (list: CheckboxValueType[]) => {
    const childrenOptionsToRemove = mementoOptions
      .filter(option => !!option.enabledOnlyWhen && !list.includes(option.enabledOnlyWhen))
      .map(option => option.value)
    setCheckedList(list.filter(value => !childrenOptionsToRemove.includes(value)))
  }
  const onCheckAllChange: CheckboxProps["onChange"] = e => {
    setCheckedList(e.target.checked ? mementoOptions.map(checkboxOptionToCheckboxValue) : [])
  }
  const onCommentChange = (section: RubriqueMementoAdvisor, updatedOutputData: OutputData) => {
    if (!areSameOutputData(updatedOutputData, toOutputData(section.rubriqueComment))) {
      setSections(sections =>
        sections.map(s =>
          s.rubriqueId === section.rubriqueId
            ? {
                ...section,
                rubriqueComment: JSON.stringify(updatedOutputData)
              }
            : s
        )
      )
    }
  }
  const generatePdf = () => {
    generatePdfMutation({
      projectId,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
    })
  }

  useEffect(() => {
    if (isGeneratePdfMutationSuccess && generatePdfMutationResult) {
      setPdfLink(generatePdfMutationResult.link)
      setIsSuccessModalOpen(true)
      // reason for the setTimeout below : https://github.com/facebook/react/issues/17355 / https://stackoverflow.com/a/76945127
      setTimeout(() => window.open(generatePdfMutationResult.link, "_blank"), 0)
    }
  }, [isGeneratePdfMutationSuccess, isError])

  return (
    <>
      <Row>
        <Col>
          <Button onClick={generatePdf} type="primary" shape="round" size="large">
            <Text strong style={{ color: "white" }}>
              Télécharger le Mémento
            </Text>
            <FilePdfOutlined />
          </Button>
          <Modal
            open={isPending}
            closeIcon={false}
            destroyOnClose={true}
            width="fit-content"
            footer={<></>}
          >
            <Space direction="vertical" align={"center"}>
              <Text>Veuillez patienter pendant la génération du mémento</Text>
              <Loader />
            </Space>
          </Modal>
          {pdfLink && (
            <PdfSuccessModal
              pdfSuccess={isSuccessModalOpen}
              linkPdf={pdfLink}
              successText="Votre memento a bien été généré."
              linkText="Si le memento ne s’ouvre pas automatiquement, retrouvez le"
              onClose={() => setIsSuccessModalOpen(false)}
            />
          )}
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ marginTop: "1em" }}>
        <Col span={8}>
          <Card>
            <AppMetierTitle level={4}>Analyse du projet</AppMetierTitle>
            <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
              <Text strong>Tout sélectionner</Text>
            </Checkbox>
            <Divider />
            <Checkbox.Group value={checkedList} onChange={onChange}>
              <Row gutter={[16, 16]}>
                {mementoOptions.map((option, index) => (
                  <Col key={index} span={24}>
                    <Checkbox
                      style={{ maxWidth: "90%" }}
                      disabled={
                        !!option.enabledOnlyWhen && !checkedList.includes(option.enabledOnlyWhen)
                      }
                      value={option.value}
                    >
                      {option.label}
                    </Checkbox>
                    <NavLink to={`${baseNotePath}/${option.linkRelativePath}`}>
                      <EyeOutlined />
                    </NavLink>
                  </Col>
                ))}
              </Row>
            </Checkbox.Group>
          </Card>
        </Col>
        <Col span={16}>
          <Card>
            <AppMetierTitle level={4}>Accompagnement</AppMetierTitle>
            {sections.map(section => (
              <Row key={section.rubriqueId}>
                <AppMetierTitle level={5}>{section.titre}</AppMetierTitle>
                <Text italic>
                  <EditorTextParser data={toOutputData(section.description)} />
                </Text>
                <EditorTextInput
                  initialValue={toOutputData(section.rubriqueComment)}
                  onChange={value => onCommentChange(section, value)}
                />
              </Row>
            ))}
          </Card>
        </Col>
      </Row>
    </>
  )
}
