/* tslint:disable */
/* eslint-disable */
/**
 * Appli métier
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: Yoda 1 & 2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from "axios"
import { Configuration } from "../configuration"
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction
} from "../common"
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from "../base"
// @ts-ignore
import { Setting } from "../model"
/**
 * SettingResourceApi - axios parameter creator
 * @export
 */
export const SettingResourceApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get advisor\'s settings by its id
     * @param {string} advisorId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSetting: async (
      advisorId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'advisorId' is not null or undefined
      assertParamExists("getSetting", "advisorId", advisorId)
      const localVarPath = `/settings/{advisorId}`.replace(
        `{${"advisorId"}}`,
        encodeURIComponent(String(advisorId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @summary Update advisor\'s settings
     * @param {string} advisorId
     * @param {Setting} setting
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveSetting: async (
      advisorId: string,
      setting: Setting,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'advisorId' is not null or undefined
      assertParamExists("saveSetting", "advisorId", advisorId)
      // verify required parameter 'setting' is not null or undefined
      assertParamExists("saveSetting", "setting", setting)
      const localVarPath = `/settings/{advisorId}`.replace(
        `{${"advisorId"}}`,
        encodeURIComponent(String(advisorId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        setting,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * SettingResourceApi - functional programming interface
 * @export
 */
export const SettingResourceApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = SettingResourceApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @summary Get advisor\'s settings by its id
     * @param {string} advisorId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSetting(
      advisorId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Setting>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getSetting(advisorId, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @summary Update advisor\'s settings
     * @param {string} advisorId
     * @param {Setting} setting
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async saveSetting(
      advisorId: string,
      setting: Setting,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Setting>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.saveSetting(
        advisorId,
        setting,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    }
  }
}

/**
 * SettingResourceApi - factory interface
 * @export
 */
export const SettingResourceApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = SettingResourceApiFp(configuration)
  return {
    /**
     *
     * @summary Get advisor\'s settings by its id
     * @param {string} advisorId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSetting(advisorId: string, options?: any): AxiosPromise<Setting> {
      return localVarFp.getSetting(advisorId, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @summary Update advisor\'s settings
     * @param {string} advisorId
     * @param {Setting} setting
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveSetting(advisorId: string, setting: Setting, options?: any): AxiosPromise<Setting> {
      return localVarFp
        .saveSetting(advisorId, setting, options)
        .then(request => request(axios, basePath))
    }
  }
}

/**
 * Request parameters for getSetting operation in SettingResourceApi.
 * @export
 * @interface SettingResourceApiGetSettingRequest
 */
export interface SettingResourceApiGetSettingRequest {
  /**
   *
   * @type {string}
   * @memberof SettingResourceApiGetSetting
   */
  readonly advisorId: string
}

/**
 * Request parameters for saveSetting operation in SettingResourceApi.
 * @export
 * @interface SettingResourceApiSaveSettingRequest
 */
export interface SettingResourceApiSaveSettingRequest {
  /**
   *
   * @type {string}
   * @memberof SettingResourceApiSaveSetting
   */
  readonly advisorId: string

  /**
   *
   * @type {Setting}
   * @memberof SettingResourceApiSaveSetting
   */
  readonly setting: Setting
}

/**
 * SettingResourceApi - object-oriented interface
 * @export
 * @class SettingResourceApi
 * @extends {BaseAPI}
 */
export class SettingResourceApi extends BaseAPI {
  /**
   *
   * @summary Get advisor\'s settings by its id
   * @param {SettingResourceApiGetSettingRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SettingResourceApi
   */
  public getSetting(
    requestParameters: SettingResourceApiGetSettingRequest,
    options?: AxiosRequestConfig
  ) {
    return SettingResourceApiFp(this.configuration)
      .getSetting(requestParameters.advisorId, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Update advisor\'s settings
   * @param {SettingResourceApiSaveSettingRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SettingResourceApi
   */
  public saveSetting(
    requestParameters: SettingResourceApiSaveSettingRequest,
    options?: AxiosRequestConfig
  ) {
    return SettingResourceApiFp(this.configuration)
      .saveSetting(requestParameters.advisorId, requestParameters.setting, options)
      .then(request => request(this.axios, this.basePath))
  }
}
