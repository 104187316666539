/* tslint:disable */
/* eslint-disable */
/**
 * Appli métier
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: Yoda 1 & 2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Advisor } from "./advisor"
import { BankPartnerV2 } from "./bank-partner-v2"
import { PdfPreferences } from "./pdf-preferences"

/**
 *
 * @export
 * @interface SaveNoteRequest
 */
export interface SaveNoteRequest {
  /**
   *
   * @type {string}
   * @memberof SaveNoteRequest
   */
  noteId: string
  /**
   *
   * @type {string}
   * @memberof SaveNoteRequest
   */
  structureId?: string
  /**
   *
   * @type {string}
   * @memberof SaveNoteRequest
   */
  comment?: string
  /**
   *
   * @type {string}
   * @memberof SaveNoteRequest
   */
  commentCr?: string
  /**
   *
   * @type {string}
   * @memberof SaveNoteRequest
   */
  statut?: SaveNoteRequestStatutEnum
  /**
   *
   * @type {string}
   * @memberof SaveNoteRequest
   */
  conclusion?: string
  /**
   *
   * @type {string}
   * @memberof SaveNoteRequest
   */
  modelEco?: SaveNoteRequestModelEcoEnum
  /**
   *
   * @type {number}
   * @memberof SaveNoteRequest
   */
  noteFinancier?: number
  /**
   *
   * @type {number}
   * @memberof SaveNoteRequest
   */
  noteGlobale?: number
  /**
   *
   * @type {string}
   * @memberof SaveNoteRequest
   */
  avancementBanque?: SaveNoteRequestAvancementBanqueEnum
  /**
   *
   * @type {string}
   * @memberof SaveNoteRequest
   */
  projectCommitteeDate?: string
  /**
   *
   * @type {PdfPreferences}
   * @memberof SaveNoteRequest
   */
  pdfPreferences?: PdfPreferences
  /**
   *
   * @type {string}
   * @memberof SaveNoteRequest
   */
  commentCrAnterieur?: string
  /**
   *
   * @type {string}
   * @memberof SaveNoteRequest
   */
  commentReason?: string
  /**
   *
   * @type {boolean}
   * @memberof SaveNoteRequest
   */
  creationExNihilo?: boolean
  /**
   *
   * @type {string}
   * @memberof SaveNoteRequest
   */
  commentRevelateur?: string
  /**
   *
   * @type {string}
   * @memberof SaveNoteRequest
   */
  commentBilanPasse?: string
  /**
   *
   * @type {Advisor}
   * @memberof SaveNoteRequest
   */
  advisor?: Advisor
  /**
   *
   * @type {BankPartnerV2}
   * @memberof SaveNoteRequest
   */
  bankPartnerV2?: BankPartnerV2
}

/**
 * @export
 * @enum {string}
 */
export enum SaveNoteRequestStatutEnum {
  EN_COURS = "EN_COURS",
  ABANDON = "ABANDON",
  ATTENTE_DECISION = "ATTENTE_DECISION",
  FINALISEE = "FINALISEE"
}
/**
 * @export
 * @enum {string}
 */
export enum SaveNoteRequestModelEcoEnum {
  MARCHAND = "MARCHAND",
  NON_MARCHAND = "NON_MARCHAND",
  HYBRIDE = "HYBRIDE"
}
/**
 * @export
 * @enum {string}
 */
export enum SaveNoteRequestAvancementBanqueEnum {
  PAS_DE_CONTACT_OU_DEBUT_DE_LA_DEMANDE_EN_COURS = "PAS_DE_CONTACT_OU_DEBUT_DE_LA_DEMANDE_EN_COURS",
  EN_ATTENTE_REPONSE_DE_LA_BANQUE = "EN_ATTENTE_REPONSE_DE_LA_BANQUE",
  ACCORD_DE_PRINCIPE_ORAL = "ACCORD_DE_PRINCIPE_ORAL",
  ACCORD_ECRIT = "ACCORD_ECRIT",
  REFUS_DE_LA_BANQUE = "REFUS_DE_LA_BANQUE",
  NON_CONCERNE = "NON_CONCERNE"
}
