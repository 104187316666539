/* tslint:disable */
/* eslint-disable */
/**
 * Appli métier
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: Yoda 1 & 2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { NumericData } from "./numeric-data"

/**
 *
 * @export
 * @interface CapitalSocial
 */
export interface CapitalSocial {
  /**
   *
   * @type {string}
   * @memberof CapitalSocial
   */
  chosenLabel: CapitalSocialChosenLabelEnum
  /**
   *
   * @type {NumericData}
   * @memberof CapitalSocial
   */
  numericData: NumericData
}

/**
 * @export
 * @enum {string}
 */
export enum CapitalSocialChosenLabelEnum {
  CAPITAL_SOCIAL = "CAPITAL_SOCIAL",
  FONDS_ASSOCIATIFS = "FONDS_ASSOCIATIFS",
  APPORTS = "APPORTS"
}
